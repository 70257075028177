import { dateToFriendly, msToTime } from "../../../utils";
import BaseResponseModel from "../../base-response-model";

class Video extends BaseResponseModel {
    static loading_thumbnail_url =
        process.env.RAZZLE_VIDEO_THUMBNAIL_IMAGE_LOADING_IMAGE_URL;

    constructor(props) {
        super(props);
        this.link = `/videos/${this.id}/${this.seo_url_slug}`;
        this.loading_thumbnail_url = `${this.thumbnail_url}?blur=800&px=20&auto=format`;
        this.watch_progress = 0;
    }

    setWatchProgress(watchProgress) {
        this.watch_progress = watchProgress;

        return this;
    }

    thumbnailTime() {
        return msToTime(this.duration_ms);
    }

    thumbnailDate() {
        return dateToFriendly(this.created_at);
    }
}

export default Video;
