import BaseResponseModel from "../../base-response-model";

class AddressDetails extends BaseResponseModel {
    isComplete() {
        return (
            !!this.addressLine1 &&
            !!this.city &&
            !!this.zipCode &&
            !!this.country &&
            !!this.phone
        );
    }
}

export default AddressDetails;
