import React from "react";

function ErrorMessage({ message }) {
    return <p>{message}</p>;
}

ErrorMessage.defaultProps = {
    message: "An error occurred",
};

export default ErrorMessage;
