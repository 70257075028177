/**
 * Two arrays for static category IDs to load
 * Need these to be consistent between component
 * and fetchInitialData on server, so extracting
 * to separate file
 */

export default {
    shows: [
        5678, // The Captain Meets
        5674, // Sound Like
        6587, // Signature Sounds
    ],
    instruments: [
        5806, // Electric Guitars
        5654, // Bass Guitars
        5670, // Acoustic Guitars
        5651, // Drums
        5650, // Synth, Keys & Tech
    ],
};


