import React from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from "react-accessible-accordion";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ErrorBoundary from "../../components/error-boundary";
import ErrorMessage from "../../components/error-message";
import SidebarLayout from "../../components/sidebar-layout";
import CommunicationPreferencesComponent from "../../components/communication-preferences";

function CommunicationPreferences(props) {
    return (
        <>
            <Helmet>
                <title>
                    Your Communication Preferences —{" "}
                    {process.env.RAZZLE_APPLICATION_NAME}
                </title>
                <meta
                    name="description"
                    content="This is where you can modify your personalised communication preferences for Andertons Extra"
                />
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <SidebarLayout
                myAccount
                communicationPreferences
                extraBenefits
                manageSubscription
                contact
            >
                <h1 className="t-extra-benefits-title">
                    Communication Preferences
                </h1>
                <p>
                    Adjust your communication preferences here at any time to
                    opt in or out of emails or device push notifications.
                </p>
                <p>For more information about how we use and process your data, please see our <Link to="/terms">Terms</Link>.</p>
                <CommunicationPreferencesComponent />
            </SidebarLayout>
        </>
    );
}

export default CommunicationPreferences;
