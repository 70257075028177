import React from "react";
import { Helmet } from "react-helmet";
import Imgix from "react-imgix";
import { Link } from "react-router-dom";
import ErrorBoundary from "../../components/error-boundary";
import ErrorMessage from "../../components/error-message";
import FeaturedVideo from "../../components/featured-video";
import PresentersCarousel from "../../components/presenters-carousel";
import ProcessedText from "../../components/processed-text";
import RecentlyAddedCarousel from "../../components/recently-added-carousel";
import YouMightAlsoLikeCarousel from "../../components/you-might-also-like-carousel";
import Category from "../../models/resource/category";
import { useCategory } from "../../providers/resource-provider";

function TopLevelArchive(props) {
    const { category } = useCategory();

    return (
        <>
            <Helmet>
                <title>{category.name} — {process.env.RAZZLE_APPLICATION_NAME}</title>
                <meta
                    name="description"
                    content={category.description}
                />
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            {/* Category Details */}
            <ErrorBoundary
                tags={{
                    page: "top level archive",
                    section: "category details",
                }}
                fallback={() => (
                    <ErrorMessage message="Could not display category details" />
                )}
            >
                <CategoryDetails category={category} />
            </ErrorBoundary>

            {/* Archive Years */}
            <ErrorBoundary
                tags={{
                    page: "top level archive",
                    section: "category child list",
                }}
                fallback={() => (
                    <ErrorMessage message="Could not display archive years" />
                )}
            >
                <CategoryChildList childCategories={category.children} />
            </ErrorBoundary>

            {/* Recently Added Carousel */}
            <ErrorBoundary
                tags={{
                    page: "top level archive",
                    section: "recently added carousel",
                }}
                fallback={() => (
                    <ErrorMessage message="Could not display recently added carousel" />
                )}
            >
                <RecentlyAddedCarousel />
            </ErrorBoundary>

            {/* Featured video */}
            <ErrorBoundary
                tags={{
                    page: "top level archive",
                    section: "featured video",
                }}
                fallback={() => (
                    <ErrorMessage message="Could not display featured video" />
                )}
            >
                <FeaturedVideo />
            </ErrorBoundary>

            {/* You Might Also Like Carousel */}
            <ErrorBoundary
                tags={{
                    page: "top level archive",
                    section: "you might also like carousel",
                }}
                fallback={() => (
                    <ErrorMessage message="Could not display you might also like carousel" />
                )}
            >
                <YouMightAlsoLikeCarousel />
            </ErrorBoundary>

            {/* Our Presenters Carousel */}
            <ErrorBoundary
                tags={{
                    page: "top level archive",
                    section: "presenters carousel",
                }}
                fallback={() => (
                    <ErrorMessage message="Could not display presenters carousel" />
                )}
            >
                <PresentersCarousel />
            </ErrorBoundary>
        </>
    );
}

export default TopLevelArchive;

function CategoryChildList({ childCategories: children }) {
    return (
        <div className="l-padding">
            <div className="l-width">
                <div className="l-grid l-grid--padding l-grid--2 l-grid--3-m l-grid--4-l">
                    {children.map((child, index) => (
                        <ErrorBoundary
                            key={child.id}
                            tags={{
                                page: "top level archive",
                                section: "archive year teaser",
                            }}
                            fallback={() => (
                                <ErrorMessage message="Could not display archive year" />
                            )}
                        >
                            <ArchiveYearTeaser
                                index={index}
                                category={new Category(child)}
                            />
                        </ErrorBoundary>
                    ))}
                </div>
            </div>
        </div>
    );
}

function CategoryDetails({ category }) {
    return (
        <div className="o-hero">
            <picture className="o-hero__picture">
                <Imgix
                    htmlAttributes={{
                        src: category.loading_hero_image,
                        alt: "Archive hero image",
                    }}
                    className="o-hero__img lazyload"
                    src={category.hero_image}
                    imgixParams={{ q: 65 }}
                    attributeConfig={{
                        src: "data-src",
                        srcSet: "data-srcset",
                        sizes: "data-sizes",
                    }}
                    width={1920}
                    height={500}
                />
            </picture>
            <div className="o-hero__info">
                <div className="o-hero__position">
                    <h1 className="o-hero__title">{category.name}</h1>
                    <div className="o-hero__intro">
                        <ProcessedText>{category.description}</ProcessedText>
                    </div>
                </div>
            </div>
        </div>
    );
}

function ArchiveYearTeaser({ category }) {
    const { handleCategoryClick } = useCategory();

    return (
        <Link
            onClick={() => handleCategoryClick(category)}
            to={`/categories/archive/${category.slug}`}
            className="l-grid__link t-archive-year"
        >
            <picture className="l-grid__picture">
                <Imgix
                    htmlAttributes={{
                        src: category.loading_thumbnail_url,
                        alt: `${category.name} thumbnail image`,
                    }}
                    className="l-grid__img lazyload"
                    src={category.thumbnail_url}
                    imgixParams={{ q: 65 }}
                    attributeConfig={{
                        src: "data-src",
                        srcSet: "data-srcset",
                        sizes: "data-sizes",
                    }}
                    width={375}
                    height={375}
                />
            </picture>
        </Link>
    );
}
