import React from "react";
import { Link, useLocation } from "react-router-dom";
import Notice from "../../../notice";

function DesktopUnauthenticatedNavbar({ hide }) {
    const location = useLocation();

    return (
        <div className="c-header">
            <Notice className="t-beta-notice c-beta" hash="beta" dismissable>
                Andertons Extra is currently in Beta. Please Let Us Know If You
                Encounter Any Issues.
            </Notice>

            {!hide && (
                <div className="c-header__wrapper l-width">
                    <div className="c-header--center">
                        <ul className="c-navigation">
                            <li className={"c-navigation__item"}>
                                <Link
                                    to="/get-extra"
                                    className={`c-navigation__link ${
                                        location.pathname === "/get-extra"
                                            ? "is-active"
                                            : ""
                                    }`}
                                >
                                    Get Extra
                                </Link>
                            </li>
                            <li className={"c-navigation__item"}>
                                {/* Might want to change this to load up the modal */}
                                <Link
                                    to="/login"
                                    className={`c-navigation__link ${
                                        location.pathname === "/login"
                                            ? "is-active"
                                            : ""
                                    }`}
                                >
                                    Log In
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
}

export default DesktopUnauthenticatedNavbar;
