import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useSubscription } from "../../providers/subscription-provider";
import AddressDetails from "./address-details";
import BillingDetails from "./billing-details";
import BillingTerm from "./billing-term";
import ExistingCustomer from "./existing-customer";
import JoinView from "./join";
import NewCustomer from "./new-customer";

function Join({ match }) {
    const {
        customerExistence,
        customerDetails,
        addressDetails,
        selectedPlan,
    } = useSubscription();
    const history = useHistory();

    const isEligibleForStep = ({
        step,
        customerExistence,
        customerDetails,
        addressDetails,
        selectedPlan,
    }) => {
        switch (step) {
            /**
             * No step param - initial subscription form
             * Step 1 - Account check
             */
            default:
                return true;

            /**
             * Step 2 - Subscriber details (eCommerce account exists)
             */
            case "existing-customer":
                if (customerExistence && customerExistence.exists()) {
                    return true;
                }
                if (!customerExistence) {
                    return "Whoops! It looks like we haven't checked for an Andertons account yet."
                }
                if (customerExistence.exists() === false) {
                    return "Whoops! It looks like you don't have an existing Andertons account."
                }
                return "An unexpected error occurred.";

            /**
             * Step 2 - Subscriber details (eCommerce account does not exist)
             */
            case "new-customer":
                if (customerExistence && customerExistence.exists() === false) {
                    return true;
                }
                if (!customerExistence) {
                    return "Whoops! It looks like we haven't checked for an Andertons account yet."
                }
                if (customerExistence.exists()) {
                    return "Whoops! It looks like you already have an existing Andertons account."
                }

                return "An unexpected error occurred.";
            /**
             * Step 3 - Address details
             */
            case "address-details":
                if (customerDetails && customerDetails.isComplete()) {
                    return true;
                }
                if (!customerDetails) {
                    return "Whoops! We need your Andertons account details for this step and it looks like we don't have them."
                }
                if (customerDetails.isComplete() === false) {
                    return "Whoops! It looks like there's a problem with your account details."
                }
                return "An unexpected error occurred.";
            /**
             * Step 4 - Billing term
             */
            case "billing-term":
                if (addressDetails && addressDetails.isComplete()) {
                    return true;
                }
                if (!addressDetails) {
                    return "Whoops! We need the address details (for billing purposes) from your Andertons account for this step and it looks like we don't have them."
                }
                if (addressDetails.isComplete() === false) {
                    return "Whoops! It looks like there's a problem with your address details."
                }
                return "An unexpected error occurred.";
            /**
             * Step 5 - Billing details (ChargeBee modal)
             */
            case "billing-details":
                if (selectedPlan && selectedPlan.isComplete()) {
                    return true;
                }
                if (!selectedPlan) {
                    return "Whoops! You need to have selected a valid Andertons Extra plan for this step and it looks like you haven't yet."
                }
                if (selectedPlan.isComplete() === false) {
                    return "Whoops! It looks like there's a problem with the plan you've selected."
                }
                return "An unexpected error occurred.";
        }
    };

    useEffect(() => {
        if (
            !isEligibleForStep({
                step: match.params.step,
                customerExistence,
                customerDetails,
                addressDetails,
                selectedPlan,
            })
        ) {
            return history.push("/join");
        }
    });

    return (
        <>
            <Helmet>
                <title>Subscribe to Andertons Extra</title>
                <meta
                    name="description"
                    content="Get more from your Andertons experience including all our videos completely ad free, brand new content & exclusive deals. Join now from just £2.99/$3.99/€3,49!"
                />
                {/* Meta description is deliberately not drawn from the default so it can be carefully curated. This is a key SEO page. */}
            </Helmet>

            <div className="l-login">
                <Switch>
                    <Route
                        exact
                        path="/join/existing-customer"
                        render={() => {

                            const eligiblity = isEligibleForStep({
                                step: match.params.step,
                                customerExistence,
                                customerDetails,
                                addressDetails,
                                selectedPlan,
                            })

                            if (eligiblity === true) {
                                return <ExistingCustomer />
                            } else {
                                toast(eligiblity)
                                toast("Redirecting you to the start of the sign up process!", {
                                    delay: 200,
                                })
                                return <Redirect to="/join" />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/join/new-customer"
                        render={() => {
                            const eligiblity = isEligibleForStep({
                                step: match.params.step,
                                customerExistence,
                                customerDetails,
                                addressDetails,
                                selectedPlan,
                            })

                            if (eligiblity === true) {
                                return <NewCustomer />;
                            } else {
                                toast(eligiblity)
                                toast("Redirecting you to the start of the sign up process!", {
                                    delay: 200,
                                })
                                return <Redirect to="/join" />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/join/address-details"
                        render={() => {
                            const eligiblity = isEligibleForStep({
                                step: match.params.step,
                                customerExistence,
                                customerDetails,
                                addressDetails,
                                selectedPlan,
                            })

                            if (eligiblity === true) {
                                return <AddressDetails />;
                            } else {
                                toast(eligiblity);
                                toast("Redirecting you to the start of the sign up process!", {
                                    delay: 200,
                                })
                                return <Redirect to="/join" />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/join/billing-term"
                        render={() => {
                            const eligiblity = isEligibleForStep({
                                step: match.params.step,
                                customerExistence,
                                customerDetails,
                                addressDetails,
                                selectedPlan,
                            })

                            if (eligiblity === true) {
                                return <BillingTerm />;
                            } else {
                                toast(eligiblity);
                                toast("Redirecting you to the start of the sign up process!", {
                                    delay: 200,
                                })
                                return <Redirect to="/join" />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/join/billing-details"
                        render={() => {
                            const eligiblity = isEligibleForStep({
                                step: match.params.step,
                                customerExistence,
                                customerDetails,
                                addressDetails,
                                selectedPlan,
                            })

                            if (eligiblity === true) {
                                return <BillingDetails />;
                            } else {
                                toast(eligiblity);
                                toast("Redirecting you to the start of the sign up process!", {
                                    delay: 200,
                                })
                                return <Redirect to="/join" />;
                            }
                        }}
                    />

                    <Route
                        render={() => {
                            const eligiblity = isEligibleForStep({
                                step: match.params.step,
                                customerExistence,
                                customerDetails,
                                addressDetails,
                                selectedPlan,
                            })

                            if (eligiblity === true) {
                                return <JoinView />;
                            } else {
                                toast(eligiblity);
                                toast("Redirecting you to the start of the sign up process!", {
                                    delay: 200,
                                })
                                return <Redirect to="/join" />;
                            }
                        }}
                    />
                </Switch>
            </div>
        </>
    );
}

export default Join;
