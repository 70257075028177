import React from "react";
import { Helmet } from "react-helmet";
import CommunicationPreferences from "../../components/communication-preferences";
import SidebarLayout from "../../components/sidebar-layout";
import { useDetails } from "../../providers/auth-provider";

function MyAccount(props) {
    const {
        details: { email, title, firstName, lastName },
    } = useDetails();

    return (
        <>
            <Helmet>
                <title>
                    My Account — {process.env.RAZZLE_APPLICATION_NAME}
                </title>
                <meta
                    name="description"
                    content="View information about your subscription to Andertons Extra including a summary of your benefits and details about renewal dates for your membership."
                />
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <SidebarLayout
                myAccount
                communicationPreferences
                extraBenefits
                manageSubscription
                contact
            >
                <h1>My Account</h1>

                <p>
                    Within this section you can view details of your
                    subscription, report issues, and read about how to use all
                    of your Andertons Extra benefits.
                </p>

                {/* TODO - Stephen to remove inline styling */}
                <table style={{ marginBottom: "24px" }}>
                    <thead>
                        <tr>
                            <th
                                className="t-my-account-details-table-header"
                                style={{
                                    padding: "10px 0 10px 5px",
                                    backgroundColor: "#D6001C",
                                    textAlign: "center",
                                }}
                                colSpan="2"
                            >
                                Your Details
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td
                                style={{
                                    width: "25%",
                                    padding: "10px 0 10px 5px",
                                }}
                            >
                                Email
                            </td>
                            <td
                                className="t-my-account-details-table-email"
                                style={{
                                    width: "75%",
                                    padding: "10px 0 10px 5px",
                                }}
                            >
                                {email}
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{
                                    width: "25%",
                                    padding: "10px 0 10px 5px",
                                }}
                            >
                                Title
                            </td>
                            <td
                                style={{
                                    width: "75%",
                                    padding: "10px 0 10px 5px",
                                }}
                            >
                                {title}
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{
                                    width: "25%",
                                    padding: "10px 0 10px 5px",
                                }}
                            >
                                First Name
                            </td>
                            <td
                                style={{
                                    width: "75%",
                                    padding: "10px 0 10px 5px",
                                }}
                            >
                                {firstName}
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{
                                    width: "25%",
                                    padding: "10px 0 10px 5px",
                                }}
                            >
                                Last Name
                            </td>
                            <td
                                style={{
                                    width: "75%",
                                    padding: "10px 0 10px 5px",
                                }}
                            >
                                {lastName}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </SidebarLayout>
        </>
    );
}

export default MyAccount;
