import queryString from "query-string";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Link, Redirect, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import ApiClient from "../../models/api-client";
import Auth from "../../models/auth";
import Plan from "../../models/auth/plan";
import { ExtraCookies, unexpectedErrorToast, useAjaxEffect } from "../../utils";
import { useGeolocation } from "../geolocation-provider";

const AuthContext = createContext(null);

const DetailsContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const useDetails = () => useContext(DetailsContext);

function AuthProvider({ children }) {
    /**
     * Create an instance of the auth class.
     * This will be used within this provider and
     * made available to children to make API calls
     */
    const auth = new Auth();

    /**
     * Use hooks
     */
    const [cookies, setCookie, removeCookie] = useCookies();

    const location = useLocation();

    const geolocation = useGeolocation();

    /**
     * Declare authenticated state.
     * User is initially assumed to be authed
     * if they have a token cookie and it is not
     * set to "false", which is considered to be
     * previously authenticated
     */
    const [authenticated, setAuthenticated] = useState(
        // !!cookies[ExtraCookies.getWrappedKey("user_token")]
        // && cookies[ExtraCookies.getWrappedKey("user_token")] !== "false"
        true
    );

    /**
     * Add token from cookie to state
     */
    const [token, setToken] = useState(cookies[ExtraCookies.getWrappedKey("user_token")]);

    /**
     * Declare plans in state
     */
    const [plans, setPlans] = useState(null);

    /**
     * Declare call-to-action plan in state
     */
    const [ctaPlan, setCtaPlan] = useState(null);

    /**
     * Declare primary call-to-action in state
     * e.g. "Sign up now for £2.99"
     */
    const [primaryCta, setPrimaryCta] = useState(
        cookies[ExtraCookies.getWrappedKey("primary_cta")] ??
        Plan.defaultPrimaryCTA
    );

    /**
     * Shortcut function to persist
     * a user details object to cookies
     */
    const setAuthCookies = ({
        userId,
        email,
        title,
        firstName,
        lastName,
    }) => {
        const settings = {
            path: "/",
            maxAge:
                process.env
                    .RAZZLE_USER_COOKIE_MAX_AGE_SECONDS,
        }

        setCookie(
            ExtraCookies.getWrappedKey("user_id"),
            userId,
            settings
        );
        setCookie(
            ExtraCookies.getWrappedKey("user_email"),
            email,
            settings
        );
        setCookie(
            ExtraCookies.getWrappedKey("user_title"),
            title,
            settings
        );
        setCookie(
            ExtraCookies.getWrappedKey("user_first_name"),
            firstName,
            settings
        );
        setCookie(
            ExtraCookies.getWrappedKey("user_last_name"),
            lastName,
            settings
        );
    }

    /**
     * Shortcut function to remove
     * user details and token cookies
     */
    const removeAuthCookies = () => {
        removeCookie(ExtraCookies.getWrappedKey("user_id"));
        removeCookie(ExtraCookies.getWrappedKey("user_email"));
        removeCookie(ExtraCookies.getWrappedKey("user_title"));
        removeCookie(ExtraCookies.getWrappedKey("user_first_name"));
        removeCookie(ExtraCookies.getWrappedKey("user_last_name"));
        removeCookie(ExtraCookies.getWrappedKey("user_token"));
    }

    /**
     * Create wrapper component for a redirect
     * to send the user to the appropriate page
     * if they're hitting an auth only route
     * but they're not authed - the service
     * proposition if they've never been
     * authed before and the login page if they
     * have signed in before
     */
    const UnauthedRedirect = () => null

    // ! Remove auth
    // const UnauthedRedirect = () => <Redirect
    //     to={
    //         cookies[ExtraCookies.getWrappedKey("user_token")] === "false"
    //             ? `/login?from=${queryString.stringifyUrl({
    //                 url: location.pathname,
    //                 query: queryString.parse(location.search, {
    //                     arrayFormat: "bracket",
    //                 }),
    //             })}`
    //             : "/get-extra"
    //     }
    // />

    /**
     * Whenever token in state changes to a non-undefined
     * value (e.g. user logs in) persist this to a cookie
     */
    useEffect(() => {
        if (typeof token !== "undefined") {
            setCookie(ExtraCookies.getWrappedKey("user_token"), token, {
                path: "/",
                maxAge: process.env.RAZZLE_USER_COOKIE_MAX_AGE_SECONDS,
            });
        }
    }, [token, setCookie]);

    /**
     * Retrieve token validity on app init
     * and location change
     */
    useAjaxEffect({
        tags: {
            component: "AuthProvider",
            action: "retrieve token validity on location change",
        },

        /**
         * Only need to check validity if token is
         * set and not "false"
         */
        requestRequired: () => !!token && token !== "false",

        /**
         * If we're skipping token validity check,
         * perform some cleanup to clear cookies
         * and ensure we don't get stuck in a 
         * half authed state
         */
        onNoRequestRequired: () => {
            removeAuthCookies();
        },

        request: (cancelToken) => {
            auth.setCancelToken(cancelToken);

            return auth.getAuthenticated(token);
        },

        onSuccess: (data, mounted) => {
            if (mounted) {
                setToken(data.token);
                setAuthenticated(true);
            }
        },

        onError: (err, mounted) => {
            if (mounted) {
                if (!ApiClient.isCancel(err)) {
                    signOut();
                }
            }
        },

        watch: [location.pathname],

        cancelMessage: "Auth validation cancelled due to component unmount",
    });


    const signIn = ({
        email,
        password,
        onError = () => { },
        onSuccess = () => { },
    }) => {
        return auth
            .signIn(email, password)
            .then((user) => {
                /**
                 * Perform callback
                 */
                onSuccess(user);

                /**
                 * If token is set, 
                 * persist it to state
                 * and cookies and set the app
                 * auth state to true
                 */
                if (user.token) {
                    /**
                     * Auth success and subscribed
                     */
                    setToken(user.token);

                    setAuthCookies(user.details())

                    setAuthenticated(true);

                    return user;
                } else {
                    /**
                     * Auth success and not subscribed
                     */
                    toast(
                        <span>
                            <Link
                                to={`/join?email=${encodeURIComponent(email)}`}>
                                It looks like you haven't subscribed yet.
                                Click here to sign up now.
                                </Link>
                        </span>
                    );
                    signOut();
                }

            })
            .catch((err) => {
                signOut();

                if (err.response && err.response.status) {
                    switch (err.response.status) {
                        /**
                         * Customer with this email exists in
                         * eCommerce, but the password is wrong
                         */
                        case 401:
                            toast(
                                "The provided credentials don't match our records"
                            );
                            break;

                        /**
                         * Customer with this email doesn't
                         * exist in eCommerce
                         */
                        case 403:
                        case 404:
                            toast(
                                <span>
                                    <Link to={`/join?email=${encodeURIComponent(email)}`}>
                                        It looks like you haven't subscribed
                                        yet. Click here to sign up now.
                                    </Link>
                                </span>
                            );
                            break;

                        default:
                            console.error(
                                "Unexpected authentication failure with status",
                                err.response.status
                            );
                            unexpectedErrorToast("/join-help");
                            break;
                    }
                } else {
                    console.error(
                        "Unexpected authentication failure with no status",
                        err
                    );
                    unexpectedErrorToast("/join-help");
                }

                onError(err);
            });
    };

    const signOut = (onSignOut = () => { }) => {
        removeAuthCookies();
        setCookie(ExtraCookies.getWrappedKey("user_token"), "false", {
            path: "/",
            maxAge: process.env.RAZZLE_USER_COOKIE_MAX_AGE_SECONDS,
        });
        setToken(false);
        setAuthenticated(false);

        return onSignOut();
    };

    /**
     * Hook to get subscription plans by geolocation-detected currency
     */

    // ! Remove auth
    // useEffect(() => {
    //     if (!!geolocation && !!auth && !plans) {
    //         auth.getPlansByCurrency(
    //             geolocation.getCurrency().getCurrencyCode().toLowerCase()
    //         ).then((res) => {
    //             setPlans(res);

    //             const annualPlan = res.find(
    //                 (plan) => plan.period_unit === "year"
    //             );

    //             if (annualPlan) {
    //                 setCtaPlan(annualPlan);
    //                 setPrimaryCta(annualPlan.getPrimaryCTA());
    //                 setCookie(
    //                     ExtraCookies.getWrappedKey("primary_cta"),
    //                     annualPlan.getPrimaryCTA(),
    //                     {
    //                         path: "/",
    //                         maxAge:
    //                             process.env.RAZZLE_USER_COOKIE_MAX_AGE_SECONDS,
    //                     }
    //                 );
    //             }
    //         })
    //             .catch(err => {
    //                 console.log(err)
    //             });
    //     }
    // }, [geolocation, auth, plans, setCookie]);

    return (
        <AuthContext.Provider value={{
            auth,
            plans,
            setPlans,
            ctaPlan,
            primaryCta,
            signIn,
            signOut,
            token,
            setToken,
            authenticated,
            setAuthenticated,
            handle401: () => {
                if (location.pathname !== "/login") {
                    /**
                     * Don't have root page as forward url as it'll just lead to another redirect
                     */
                    return signOut(() => <UnauthedRedirect />);
                }
            },
            UnauthedRedirect,
        }}>
            <DetailsContext.Provider value={{
                details: {
                    userId: cookies[ExtraCookies.getWrappedKey("user_id")],
                    email: cookies[ExtraCookies.getWrappedKey("user_email")],
                    title: cookies[ExtraCookies.getWrappedKey("user_title")],
                    firstName: cookies[ExtraCookies.getWrappedKey("user_first_name")],
                    lastName: cookies[ExtraCookies.getWrappedKey("user_last_name")],
                },
                setDetails: (details) => {
                    setCookie(ExtraCookies.getWrappedKey("user_id"), details.userId, {
                        path: "/",
                        maxAge: process.env.RAZZLE_USER_COOKIE_MAX_AGE_SECONDS,
                    });
                    setCookie(ExtraCookies.getWrappedKey("user_email"), details.email, {
                        path: "/",
                        maxAge: process.env.RAZZLE_USER_COOKIE_MAX_AGE_SECONDS,
                    });
                    setCookie(ExtraCookies.getWrappedKey("user_title"), details.title, {
                        path: "/",
                        maxAge: process.env.RAZZLE_USER_COOKIE_MAX_AGE_SECONDS,
                    });
                    setCookie(
                        ExtraCookies.getWrappedKey("user_first_name"),
                        details.firstName,
                        {
                            path: "/",
                            maxAge: process.env.RAZZLE_USER_COOKIE_MAX_AGE_SECONDS,
                        }
                    );
                    setCookie(
                        ExtraCookies.getWrappedKey("user_last_name"),
                        details.lastName,
                        {
                            path: "/",
                            maxAge: process.env.RAZZLE_USER_COOKIE_MAX_AGE_SECONDS,
                        }
                    );
                },
            }}>
                {
                    children
                }
            </DetailsContext.Provider>
        </AuthContext.Provider>
    );
}

export default AuthProvider;
