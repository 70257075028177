import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Form from "../../../components/form";
import Checkbox from "../../../components/form/checkbox";
import Field from "../../../components/form/field";
import Radio from "../../../components/form/radio";
import LoadingIcon from "../../../components/loading-icon";
import SubmitButton from "../../../components/submit-button";
import SelectedPlan from "../../../models/auth/selected-plan";
import { useAuth } from "../../../providers/auth-provider";
import { useSubscription } from "../../../providers/subscription-provider";
import { useAjaxEffect } from "../../../utils";
import Actions from "../common/actions";
import SubscriptionFormLogo from "../subscription-form-logo";

function BillingTerm() {
    const { setSelectedPlan, setOptIn } = useSubscription();
    const { auth, plans, setPlans, ctaPlan } = useAuth();
    const [showCurrencySelect, setShowCurrencySelect] = useState(false);
    const [currency, setCurrency] = useState(
        ctaPlan && ctaPlan.getCurrencyCode()
            ? ctaPlan.getCurrencyCode().toLowerCase()
            : "gbp"
    );

    const [submitting, setSubmitting] = useState(true);

    const history = useHistory();

    const onSubmit = (data) => {
        setSelectedPlan(
            new SelectedPlan(
                plans.find(
                    (plan) =>
                        plan.id.toLowerCase().includes(data.term) &&
                        plan.currency_code.toLowerCase() === currency
                )
            )
        );
        setOptIn(data.optIn ?? false);
        history.push("/join/billing-details");
    };

    useAjaxEffect({
        tags: {
            component: "BillingTerm",
            action: "retrieve plans by currency",
        },

        request: (cancelToken) => {
            setSubmitting(true);

            auth.setCancelToken(cancelToken);

            return auth.getPlansByCurrency(currency);
        },

        onSuccess: (data, mounted) => {
            if (mounted) {
                setPlans(data);
                setSubmitting(false);
            }
        },

        onError: (err, mounted) => {
            if (mounted) {
                console.log(err);
                setSubmitting(false);
            }
        },

        watch: [currency],

        cancelMessage: "Plan data retrieval cancelled due to component unmount",
    });

    const handleCurrencyClick = (currency) => {
        setCurrency(currency);
        setShowCurrencySelect(false);
    };

    /* eslint-disable indent */
    return (
        <div className="l-login__wrapper">
            <div className="l-login__width">
                <SubscriptionFormLogo />

                <div className="l-login__form">
                    <h1 className="l-login__title">Choose Billing Term</h1>
                    <p>Pay yearly for a cheaper overall price.</p>

                    <Form
                        className="c-form t-billing-term-form"
                        useFormParams={{
                            defaultValues: {
                                term: "yearly",
                                optIn: false,
                            },
                        }}
                        onSubmit={onSubmit}
                    >
                        {(formMethods) => (
                            <>
                                <div className="c-form__section">
                                    <Field
                                        {...formMethods}
                                        is={Radio}
                                        labelContents={() => (
                                            <>
                                                <span></span>
                                                Yearly
                                                {plans && !submitting ? (
                                                    <p className="c-form__field--message">
                                                        {
                                                            plans.find((plan) =>
                                                                plan.id
                                                                    .toLowerCase()
                                                                    .includes(
                                                                        "yearly"
                                                                    )
                                                            ).invoice_name
                                                        }
                                                    </p>
                                                ) : (
                                                        <div className="c-form__field--message is-loading">
                                                            <LoadingIcon />
                                                        </div>
                                                    )}
                                            </>
                                        )}
                                        className="c-form__field c-form__field--radio"
                                        validation={{
                                            required: "Term is required",
                                        }}
                                        id="yearly"
                                        value="yearly"
                                        name="term"
                                    />

                                    <Field
                                        {...formMethods}
                                        is={Radio}
                                        labelContents={() => (
                                            <>
                                                <span></span>
                                                Monthly
                                                {plans && !submitting ? (
                                                    <p className="t-monthly-plan-cost c-form__field--message">
                                                        {
                                                            plans.find((plan) =>
                                                                plan.id
                                                                    .toLowerCase()
                                                                    .includes(
                                                                        "monthly"
                                                                    )
                                                            ).invoice_name
                                                        }
                                                    </p>
                                                ) : (
                                                        <div className="c-form__field--message is-loading">
                                                            <LoadingIcon />
                                                        </div>
                                                    )}
                                            </>
                                        )}
                                        className="c-form__field c-form__field--radio t-monthly-term-field"
                                        id="monthly"
                                        value="monthly"
                                        name="term"
                                    />


                                </div>

                                {showCurrencySelect ? (
                                    <>
                                        <div className="currency-selector">
                                            <p>
                                                You can pay for Andertons Extra
                                                in the following currencies.
                                                Please choose which is best for
                                                you
                                            </p>

                                            <div>
                                                <button
                                                    onClick={() =>
                                                        handleCurrencyClick(
                                                            "gbp"
                                                        )
                                                    }
                                                    className="btn btn--primary"
                                                    type="button"
                                                >
                                                    GBP £
                                                </button>
                                                <button
                                                    onClick={() =>
                                                        handleCurrencyClick(
                                                            "usd"
                                                        )
                                                    }
                                                    className="btn btn--primary"
                                                    type="button"
                                                >
                                                    USD $
                                                </button>
                                                <button
                                                    onClick={() =>
                                                        handleCurrencyClick(
                                                            "eur"
                                                        )
                                                    }
                                                    className="btn btn--primary"
                                                    type="button"
                                                >
                                                    EUR €
                                                </button>
                                            </div>
                                        </div>
                                        <button
                                            type="button"
                                            className="text-btn"
                                            onClick={() =>
                                                setShowCurrencySelect(false)
                                            }
                                        >
                                            Close
                                        </button>
                                    </>
                                ) : (
                                        <>
                                            <button
                                                type="button"
                                                className="text-btn"
                                                onClick={() =>
                                                    setShowCurrencySelect(true)
                                                }
                                            >
                                                Wrong Currency?
                                        </button>
                                        </>
                                    )}

                                <Field
                                    {...formMethods}
                                    is={Checkbox}
                                    labelContents={() => (
                                        <>
                                            <span></span>
                                            Opt in to Andertons Email Marketing
                                            to receive subscriber-only deals
                                            direct to your inbox
                                        </>
                                    )}
                                    className="t-opt-in c-form__field c-form__field--checkbox"
                                    id="optIn"
                                    name="optIn"
                                    displayName="Opt In"
                                />

                                <SubmitButton loading={submitting}>
                                    Submit
                                </SubmitButton>
                            </>
                        )}
                    </Form>

                    <div className="o-actions">
                        <Actions />
                        <div></div>
                    </div>
                </div>

                <p className="o-help">
                    {/* Already Subscribed?{" "}
                    <Link to="/login"
                        className="o-help__link">
                        Login Now
                    </Link> */}
                </p>
            </div>
        </div>
    );
    /* eslint-enable indent */
}

export default BillingTerm;
