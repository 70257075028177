import * as Sentry from "@sentry/react";
import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import { Switch, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./components/footer";
import InitialDataLoader from "./components/initial-data-loader";
import Navbar from "./components/navbar";
import CookieNotice from "./components/cookie-notice";
import "./index.scss";
import GlobalHooks from "./models/global-hooks";
import GoogleAnalytics from "./models/google-analytics";
import Error from "./pages/error";
import Providers from "./providers";
import routes from "./routes";

const ga = new GoogleAnalytics();

const App = ({ shellData: { topLevelCategories }, routeData }) => {
    /**
     * Register app-level useEffect hooks.
     */
    GlobalHooks.register();

    const overlayRef = useRef(null);

    const location = useLocation();

    return (
        <Sentry.ErrorBoundary
            showDialog
            fallback={() => (
                <Error reloadUrl={`${location.pathname}${location.search}`} />
            )}
        >
            <Helmet
                onChangeClientState={() =>
                    ga.sendPageview(location.pathname + location.search)
                }
            >
                <title>{process.env.RAZZLE_DEFAULT_META_TITLE}</title>
                <meta
                    name="description"
                    content={process.env.RAZZLE_DEFAULT_META_DESCRIPTION}
                />
            </Helmet>

            <div ref={overlayRef} className="t-overlay overlay is-hidden" />

            <Providers routeData={routeData}>
                <Navbar
                    topLevelCategories={topLevelCategories}
                    overlayRef={overlayRef}
                />

                <Switch>
                    {routes.map(
                        (
                            {
                                path,
                                exact,
                                component: Component,
                                routeType: RouteType,
                                ...rest
                            },
                            index
                        ) => (
                            <RouteType
                                topLevelCategories={topLevelCategories}
                                overlayRef={overlayRef}
                                key={path ?? index}
                                path={path}
                                exact={exact}
                                render={(props) => (
                                    <InitialDataLoader
                                        routeData={routeData}
                                        {...props}
                                        {...rest}
                                    >
                                        {({ routeData }) => (
                                            <div className="content-expand">
                                                <Component
                                                    routeData={routeData}
                                                    {...props}
                                                    {...rest}
                                                />
                                            </div>
                                        )}
                                    </InitialDataLoader>
                                )}
                            />
                        )
                    )}
                </Switch>
                <CookieNotice />
                <Footer />
            </Providers>

            <ToastContainer
                newestOnTop={false}
                position="top-right"
                hideProgressBar={false}
                autoClose={8000}
                progressClassName="notification-progress"
                className="t-toast notification-container"
                toastClassName="notification"
            />
        </Sentry.ErrorBoundary>
    );
};

export default App;
