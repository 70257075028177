import queryString from "query-string";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Imgix from "react-imgix";
import { useLocation } from "react-router-dom";
import ErrorBoundary from "../../components/error-boundary";
import ErrorMessage from "../../components/error-message";
import HasPaginationLinks from "../../components/has-pagination-links";
import LoadingIcon from "../../components/loading-icon";
import PresentersCarousel from "../../components/presenters-carousel";
import ProcessedText from "../../components/processed-text";
import RecentlyAddedCarousel from "../../components/recently-added-carousel";
import SearchBar from "../../components/search-bar";
import VideoThumbnailSet from "../../components/video-thumbnail-set";
import GridVideoThumbnail from "../../components/video-thumbnail-set/grid-video-thumbnail";
import YouMightAlsoLikeCarousel from "../../components/you-might-also-like-carousel";
import ApiClient from "../../models/api-client";
import { useCategory, usePagination, useResource } from "../../providers/resource-provider";
import { parseSort } from "../../utils";

function VideoGridCategory(props) {
    const { category } = useCategory();

    return (
        <>
            <Helmet>
                <title>{category.name} — {process.env.RAZZLE_APPLICATION_NAME}</title>
                <meta
                    name="description"
                    content={category.description}
                />
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            {/* Category Details */}
            <ErrorBoundary
                tags={{
                    page: "video grid category",
                    section: "category details",
                }}
                fallback={() => (
                    <ErrorMessage message="Could not display category details" />
                )}
            >
                <CategoryDetails category={category} />
            </ErrorBoundary>

            {/* Category Videos */}
            <ErrorBoundary
                tags={{
                    page: "video grid category",
                    section: "videos",
                }}
                fallback={() => (
                    <ErrorMessage message="Could not display category videos" />
                )}
            >
                <CategoryVideos />
            </ErrorBoundary>

            {/* Recently Added Carousel */}
            <ErrorBoundary
                tags={{
                    page: "video grid category",
                    section: "recently added carousel",
                }}
                fallback={() => (
                    <ErrorMessage message="Could not display recently added carousel" />
                )}
            >
                <RecentlyAddedCarousel />
            </ErrorBoundary>

            {/* You Might Also Like Carousel */}
            <ErrorBoundary
                tags={{
                    page: "video grid category",
                    section: "you might also like carousel",
                }}
                fallback={() => (
                    <ErrorMessage message="Could not display you might also like carousel" />
                )}
            >
                <YouMightAlsoLikeCarousel />
            </ErrorBoundary>

            {/* Our Presenters Carousel */}
            <ErrorBoundary
                tags={{
                    page: "video grid category",
                    section: "presenters carousel",
                }}
                fallback={() => (
                    <ErrorMessage message="Could not display presenters carousel" />
                )}
            >
                <PresentersCarousel />
            </ErrorBoundary>
        </>
    );
}

export default VideoGridCategory;

function CategoryDetails({ category }) {
    return (
        <div className="o-narrow">
            <div className="o-narrow__wrapper l-width">
                <picture className="o-narrow__picture">
                    <Imgix
                        key={category.id}
                        htmlAttributes={{
                            src: category.loading_hero_image,
                            alt: `${category.name} hero image`,
                        }}
                        className="o-narrow__img lazyload"
                        src={category.hero_image}
                        imgixParams={{ q: 65 }}
                        width={682}
                        height={409}
                        attributeConfig={{
                            src: "data-src",
                            srcSet: "data-srcset",
                            sizes: "data-sizes",
                        }}
                    />
                </picture>
                <div className="o-narrow__info">
                    <div className="o-narrow__content">
                        <h1 className="o-narrow__title">{category.name}</h1>
                        <div className="o-narrow__intro">
                            <ProcessedText>
                                {category.description}
                            </ProcessedText>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function CategoryVideos() {
    const { category } = useCategory();

    const { resource } = useResource();

    const pagination = usePagination();

    const [videos, setVideos] = useState(category.videos);

    const [loading, setLoading] = useState(false);

    const onSearchComplete = (({ videos, pagination: responsePagination }) => {
        pagination.setNumberOfPages(responsePagination.getNumberOfPages());

        pagination.setTotalItems(responsePagination.getTotalItems());

        pagination.setShowPagination(responsePagination.getTotalItems() > 0);

        pagination.setPageSize(20);

        setVideos(videos);

        setLoading(false);
    });

    const onSearchError = (err) => {
        if (!ApiClient.isCancel(err)) {
            setLoading(false);
        }
    };

    const search = ({ q: terms, sort, page, cancelToken }) => {
        setLoading(true);

        setVideos([]);

        resource.setCancelToken(cancelToken);

        return resource
            .getMediaByCategory(category.id, page, 20, terms, parseSort(sort))
    };

    return (
        <HasPaginationLinks className="l-width">

            <SearchBar
                onSuccess={onSearchComplete}
                onError={onSearchError}
                callback={search} />

            <div className="o-videos">
                {/* Video Grid */}
                <ErrorBoundary
                    tags={{
                        page: "video grid category",
                        section: "video grid",
                    }}
                    fallback={() => (
                        <ErrorMessage message="Could not display video grid" />
                    )}
                >
                    <VideoGrid
                        videos={videos}
                        loading={loading}
                        category={category}
                    />
                </ErrorBoundary>
            </div>
        </HasPaginationLinks>
    );
}

function VideoGrid({ videos, loading, category }) {

    const location = useLocation();
    const query = queryString.parse(location.search, {
        arrayFormat: "bracket",
    });

    return (
        <ul className="o-videos__list">
            {!loading ? (
                <VideoThumbnailSet videos={videos}>
                    {({ videos }) =>
                        videos.map((video) => {
                            const link = queryString.stringifyUrl({
                                url: video.link,
                                query: {
                                    category: category.id,
                                },
                            });

                            return (
                                <ErrorBoundary
                                    key={video.id}
                                    tags={{
                                        page: "video grid category",
                                        section: "video grid thumbnail",
                                    }}
                                    fallback={() => (
                                        <ErrorMessage message="Could not display video" />
                                    )}
                                >
                                    <GridVideoThumbnail
                                        video={video}
                                        link={link}
                                    />
                                </ErrorBoundary>
                            );
                        })
                    }
                </VideoThumbnailSet>
            ) : (
                    <li className="loading">
                        <LoadingIcon />
                    </li>
                )}

            {!!videos &&
                videos.length < 1 &&
                query.q &&
                query.q.length > 0 &&
                !loading && <li className="no-results">No results!</li>}
        </ul>
    );
}
