import queryString from "query-string";
import ApiClient from "../api-client";
import CreatedCustomer from "./created-customer";
import CreatedTicket from "./created-ticket";
import EmailPreferences from "./customer-email-preferences";
import CustomerExistence from "./customer-existence";
import PasswordReset from "./password-reset";
import Plan from "./plan";
import PortalSession from "./portal-session";
import ResetPassword from "./reset-password";
import Subscription from "./subscription";
import TokenValidity from "./token-validity";
import User from "./user";

class Auth {
    constructor({
        reqInterceptor = config => config,
        token = false
    } = {
            reqInterceptor: config => config,
            token: false
        }) {
        this.client = new ApiClient({
            baseUrl: process.env.RAZZLE_AUTH_BASE_URL,
            reqInterceptor,
        });
        this.entity = "";
        this.query = {};
        this.token = token;
        /**
         * Should correspond to an axios method
         */
        this.method = "get";
        this.body = {};
        this.headers = {};
    }

    setCancelToken(cancelToken) {
        this.client.setCancelToken(cancelToken);

        return this;
    }

    setToken(token) {
        this.token = token;

        return this;
    }

    setEntity(entity) {
        this.entity = entity;

        return this;
    }

    setBody(body) {
        this.body = body;

        return this;
    }

    addToHeaders(obj) {
        this.headers = {
            ...this.headers,
            ...obj,
        };

        return this;
    }

    addToBody(obj) {
        this.body = {
            ...this.body,
            ...obj,
        };

        return this;
    }

    where(key, value) {
        this.query[key] = value;

        return this;
    }

    authenticate(email, password) {
        return this.setEntity("authenticate")
            .addToBody({
                email,
                password,
            })
            .setMethod("post");
    }

    validate(token) {
        return this.setEntity("validate")
            .addToHeaders({
                Authorization: `Bearer ${token}`,
            })
            .setMethod("post");
    }

    resetPassword(id) {
        return this.setEntity(id ? `reset-password/${id}` : "reset-password");
    }

    plans(id = false) {
        return this.setEntity(id ? `plans/${id}` : "plans");
    }

    customers(id = false) {
        return this.setEntity(id ? `customers/${id}` : "customers");
    }

    tickets() {
        return this.setEntity(`${this.entity}/tickets`);
    }

    customersByEmail(email) {
        return this.setEntity(`customers-by-email/${email}`);
    }

    subscriptionDetails() {
        return this.setEntity(`${this.entity}/subscription-details`);
    }

    customerEmailPreferences(id) {
        return this.setEntity(`customers/${id}/email-preferences`);
    }

    signUpIssues() {
        return this.setEntity("sign-up-issues");
    }

    copyrightConcerns() {
        return this.setEntity("copyright-concerns");
    }

    details() {
        return this.setEntity(`${this.entity}/details`);
    }

    portalSessions() {
        return this.setEntity(`${this.entity}/portal-sessions`);
    }

    setMethod(method) {
        this.method = method;

        return this;
    }

    reset() {
        this.entity = "";
        this.token = false;
        this.query = {};
        this.method = "get";
        this.body = {};
        this.headers = {};
        this.setCancelToken(null);

        return this;
    }

    request() {
        this.client.setPath(this.entity).setQueryString(
            queryString.stringify(this.query, {
                arrayFormat: "bracket",
            })
        );

        const promise = this.client.request(
            this.method,
            this.body,
            this.headers
        );

        this.reset();

        return promise;
    }

    /**
     * Perhaps not the clearest here,
     * but we're treating "authenticate"
     * as validating the token and we'll
     * use the terminology of "sign in"
     * for getting a new token
     */
    getAuthenticated(token) {
        return this.validate(token)
            .request()
            .then((response) => new TokenValidity(response.data.data));
    }

    whereMany(query) {
        Object.keys(query).forEach((key) => this.where(key, query[key]));

        return this;
    }

    signIn(email, password, query = {}) {
        return this.authenticate(email, password)
            .whereMany(query)
            .request()
            .then((response) => new User(response.data.data));
    }

    getSubscriptionDetails(id) {
        return this.addToHeaders({
            Authorization: `Bearer ${this.token}`,
        })
            .customers(id)
            .subscriptionDetails()
            .request()
            .then((response) => new Subscription(response.data.data));
    }

    cancelSubscription(id) {
        return this.addToHeaders({
            Authorization: `Bearer ${this.token}`,
        })
            .customers(id)
            .subscriptionDetails()
            .setMethod("delete")
            .request()
            .then((response) => new Subscription(response.data.data));
    }

    performPasswordReset(email, password, token) {
        return this.resetPassword()
            .setMethod("put")
            .setBody({
                email,
                password,
                token,
            })
            .request()
            .then((response) => new PasswordReset(response.data.data));
    }

    getResetPasswordToken(email) {
        return this.resetPassword()
            .setMethod("post")
            .setBody({
                email,
            })
            .request()
            .then((response) => new ResetPassword(response.data.data));
    }

    getPlan(id) {
        return this.plans(id)
            .setMethod("get")
            .request()
            .then((response) => new Plan(response.data.data));
    }

    getPlansByCurrency(currency) {
        return this.plans()
            .where("currency", currency)
            .setMethod("get")
            .request()
            .then((response) =>
                response.data.data.map((plan) => new Plan(plan))
            );
    }

    getCustomerExists(email) {
        return this.customersByEmail(email)
            .setMethod("get")
            .request()
            .then((response) => new CustomerExistence(response.data.data));
    }

    getCustomerEmailPreferences(id) {
        return this.addToHeaders({
            Authorization: `Bearer ${this.token}`,
        })
            .customerEmailPreferences(id)
            .setMethod("get")
            .request()
            .then((response) => new EmailPreferences(response.data.data));
    }

    createCustomer({
        email,
        title,
        firstName,
        lastName,
        password,
        passwordConfirm,
        addressLine1,
        addressLine2,
        city,
        state,
        zipCode,
        country,
        phone,
    }) {
        return this.customers()
            .setBody({
                email,
                title,
                first_name: firstName,
                last_name: lastName,
                password,
                password_confirm: passwordConfirm,
                address_line_1: addressLine1,
                address_line_2: addressLine2,
                city,
                state,
                zip_code: zipCode,
                country,
                phone,
            })
            .setMethod("post")
            .request()
            .then((response) => new CreatedCustomer(response.data.data));
    }

    createCustomerTicket({ id, email, subject, message }) {
        return this.addToHeaders({
            Authorization: `Bearer ${this.token}`,
        })
            .customers(id)
            .tickets()
            .setBody({
                email,
                subject,
                message,
            })
            .setMethod("post")
            .request()
            .then((response) => new CreatedTicket(response.data.data));
    }

    createSignupIssueTicket({ name, email, subject, message }) {
        return this.signUpIssues()
            .setBody({
                email,
                subject,
                message,
                name,
            })
            .setMethod("post")
            .request()
            .then((response) => new CreatedTicket(response.data.data));
    }

    createCopyrightConcernTicket({ name, email, url, subject, message }) {
        return this.copyrightConcerns()
            .setBody({
                email,
                url,
                subject,
                message,
                name,
            })
            .setMethod("post")
            .request()
            .then((response) => new CreatedTicket(response.data.data));
    }

    createCustomerPortalSession(id) {
        return this.addToHeaders({
            Authorization: `Bearer ${this.token}`,
        })
            .customers(id)
            .portalSessions()
            .setMethod("post")
            .request()
            .then((response) => new PortalSession(response.data.data));
    }

    updateOptInAndSubscriptionStatus(id, optIn) {
        return this.addToHeaders({
            Authorization: `Bearer ${this.token}`,
        })
            .setBody({
                opt_in: optIn,
            })
            .customers(id)
            .setMethod("put")
            .request();
    }
}

export default Auth;
