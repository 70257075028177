import React from "react";
import Imgix from "react-imgix";
import { Link } from "react-router-dom";
import { useVideo } from "../../../providers/resource-provider";

function ListVideoThumbnail({ video, onClick, link }) {
    const { handleVideoClick } = useVideo();

    let handleClick = () => handleVideoClick(video);

    if (!!onClick && typeof onClick === "function") {
        handleClick = onClick;
    }

    return (
        <Link
            onClick={() => handleClick(video)}
            key={video.id}
            to={link ?? video.link}
            className="o-series__link t-series-list-item-link"
        >
            <div className="o-series--left">
                <picture>
                    <Imgix
                        htmlAttributes={{
                            src: video.loading_thumbnail_url,
                            alt: `${video.name} thumbnail image`,
                        }}
                        className="lazyload"
                        src={video.thumbnail_url}
                        imgixParams={{ q: 65 }}
                        attributeConfig={{
                            src: "data-src",
                            srcSet: "data-srcset",
                            sizes: "data-sizes",
                        }}
                        width={669}
                        height={376}
                    />
                    <div
                        className="in-progress in-progress--overlay"
                        style={{
                            width: `${video.watch_progress}%`,
                        }}
                    ></div>
                </picture>
            </div>
            <div className="o-series--right">
                <h3 className="o-series__video">{video.name}</h3>
                <div className="o-series__description">
                    {video.video_summary}
                </div>
                <span className="o-series__date">{video.thumbnailDate()}</span>
                <span className="o-series__time">{video.thumbnailTime()}</span>
                <div className="o-series__arrow"></div>
            </div>
        </Link>
    );
}

export default ListVideoThumbnail;
