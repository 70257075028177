import { useEffect } from "react";
import SentryHook from "./sentry-hook";
import ChargebeeHook from "./chargebee-hook";

/**
 * Array of hooks to register
 * Each hook must be a function that
 * returns an object when called similar
 * to the below
 *
 * {
 *      effect: () => {
 *          // Functionality here which
 *          // will be executed when
 *          // useEffect fires
 *      },
 *      watch: [
 *          // Variables to watch
 *          // to determine when effect
 *          // function should fire
 *      ]
 * }
 */
// const globalHooks = [SentryHook, ChargebeeHook];
const globalHooks = [SentryHook];

class GlobalHooks {
    static register() {
        const executor = new HooksExecutor();

        globalHooks.forEach((hook) => executor.add(hook));

        return executor.yield();
    }
}

export default GlobalHooks;

class HooksExecutor {
    constructor() {
        this.hooks = [];
    }

    add(hook) {
        this.hooks.push(hook);

        return this;
    }

    yield() {
        this.hooks.map((hook) => {
            const { effect, watch } = hook();

            return useEffect(effect, watch);
        });
    }
}
