import React from "react";

export default function Checkbox({
    watch,
    register,
    validation,
    name,
    children,
    toggleIsEmpty,
    ...rest
}) {
    return (
        <input
            name={name}
            ref={register(validation)}
            {...rest}
            type="checkbox"
        />
    );
}

Checkbox.defaultProps = {
    validation: {},
};
