import React, { Component } from "react";
import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";
import ErrorMessage from "../error-message";

class ErrorBoundary extends Component {
    static getDerivedStateFromError(error) {
        if (!toast.isActive("errorToast")) {
            toast("An error occurred. We've been notified!", {
                toastId: "errorToast",
            });
        }

        return { hasError: true };
    }

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error) {
        const { tags } = this.props;

        console.error("Error caught in error boundary", {
            error,
            tags,
        });
        Sentry.captureException(error, {
            tags,
        });
    }

    render() {
        const { fallback: Fallback } = this.props;

        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <Fallback />;
        }

        return this.props.children;
    }
}

ErrorBoundary.defaultProps = {
    fallback: () => <ErrorMessage />,
    tags: {},
};

export default ErrorBoundary;
