import BaseResponseModel from "../../base-response-model";

class CustomerDetails extends BaseResponseModel {
    isComplete() {
        return (
            !!this.email &&
            !!this.firstName &&
            !!this.lastName &&
            !!this.password
        );
    }

    hasToken() {
        return !!this.token;
    }

    details() {
        return {
            email: this.email,
            userId: this.user_id,
            firstName: this.first_name,
            lastName: this.last_name,
            title: this.title,
        };
    }
}

export default CustomerDetails;
