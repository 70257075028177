import React, { useState } from "react";

import { useLocalStorage } from "../../../providers/local-storage-provider";
import ToggleSwitch from "../../toggle-switch";

import { usePushNotification } from "../../../providers/push-notification-provider";

function PushPreferenceToggle(props) {
    const {
        getNotificationPreference,
        setNotificationPreference,
        browserSupportsNotifications,
    } = usePushNotification();
    const { disabled, label } = props;

    const [isChecked, setIsChecked] = useState(getNotificationPreference());

    const handleToggle = (preference, topics = []) => {
        return new Promise((resolve) => {
            setNotificationPreference(preference).then((result) => {
                setIsChecked(result);
                resolve(result);
            });
        });
    };

    return browserSupportsNotifications() ? (
        <ToggleSwitch
            checked={isChecked}
            disabled={disabled}
            onToggle={handleToggle}
            label={label}
            successMessage={`We've successfully updated your push notification preferences!`}
        />
    ) : null;
}

export default PushPreferenceToggle;
