import React from "react";

import { render } from "storyblok-rich-text-react-renderer";

const RichText = ({ blok }) => {
    return (
        <div className="last:mb-0" data-cy="richtext">
            {render(blok.content)}
        </div>
    );
};

export default RichText;
