import React from "react";

import Imgix from "react-imgix";

const BannerGrid = ({ blok }) => {
    return (
        <div className="o-section o-black">
            <div className="l-width">
                <div className="l-grid l-grid--padding l-grid--1-s l-grid--2-m">
                    <div>
                        {blok?.image1?.filename && (
                            <a className="l-grid__link" href={blok?.link1?.url}>
                                <picture className="l-grid__picture">
                                    <Imgix
                                        htmlAttributes={{
                                            src: blok?.image1?.filename,
                                            alt: "",
                                        }}
                                        width={800}
                                        height={800}
                                        className="l-grid__img lazyload"
                                        src={blok?.image1?.filename}
                                        imgixParams={{ q: 65 }}
                                        attributeConfig={{
                                            src: "data-src",
                                            srcSet: "data-srcset",
                                            sizes: "data-sizes",
                                        }}
                                    />
                                </picture>
                            </a>
                        )}
                    </div>
                    <div className="l-grid l-grid--2">
                        {blok?.image2?.filename && (
                            <a
                                className="t-static-category-grid-link l-grid__link"
                                href={blok?.link2?.url}
                            >
                                <picture className="l-grid__picture">
                                    <Imgix
                                        htmlAttributes={{
                                            src: blok?.image2?.filename,
                                            alt: "",
                                        }}
                                        width={800}
                                        height={800}
                                        className="l-grid__img lazyload"
                                        src={blok?.image2?.filename}
                                        imgixParams={{ q: 65 }}
                                        attributeConfig={{
                                            src: "data-src",
                                            srcSet: "data-srcset",
                                            sizes: "data-sizes",
                                        }}
                                    />
                                </picture>
                            </a>
                        )}
                        {blok?.image3?.filename && (
                            <a
                                className="t-static-category-grid-link l-grid__link"
                                href={blok?.link3?.url}
                            >
                                <picture className="l-grid__picture">
                                    <Imgix
                                        htmlAttributes={{
                                            src: blok?.image3?.filename,
                                            alt: "",
                                        }}
                                        width={800}
                                        height={800}
                                        className="l-grid__img lazyload"
                                        src={blok?.image3?.filename}
                                        imgixParams={{ q: 65 }}
                                        attributeConfig={{
                                            src: "data-src",
                                            srcSet: "data-srcset",
                                            sizes: "data-sizes",
                                        }}
                                    />
                                </picture>
                            </a>
                        )}
                        {blok?.image4?.filename && (
                            <a
                                className="t-static-category-grid-link l-grid__link"
                                href={blok?.link4?.url}
                            >
                                <picture className="l-grid__picture">
                                    <Imgix
                                        htmlAttributes={{
                                            src: blok?.image4?.filename,
                                            alt: "",
                                        }}
                                        width={800}
                                        height={800}
                                        className="l-grid__img lazyload"
                                        src={blok?.image4?.filename}
                                        imgixParams={{ q: 65 }}
                                        attributeConfig={{
                                            src: "data-src",
                                            srcSet: "data-srcset",
                                            sizes: "data-sizes",
                                        }}
                                    />
                                </picture>
                            </a>
                        )}
                        {blok?.image5?.filename && (
                            <a
                                className="t-static-category-grid-link l-grid__link"
                                href={blok?.link5?.url}
                            >
                                <picture className="l-grid__picture">
                                    <Imgix
                                        htmlAttributes={{
                                            src: blok?.image5?.filename,
                                            alt: "",
                                        }}
                                        width={800}
                                        height={800}
                                        className="l-grid__img lazyload"
                                        src={blok?.image5?.filename}
                                        imgixParams={{ q: 65 }}
                                        attributeConfig={{
                                            src: "data-src",
                                            srcSet: "data-srcset",
                                            sizes: "data-sizes",
                                        }}
                                    />
                                </picture>
                            </a>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BannerGrid;
