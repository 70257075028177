import React, { useState, useEffect } from "react";
import Imgix from "react-imgix";
import { Link, useLocation } from "react-router-dom";
import Notice from "../../../notice";
import NavbarItem from "../../navbar-item";
import FirstLoadCookie from "../../../first-load-cookie";

function DesktopAuthenticatedNavbar({ overlayRef, topLevelCategories }) {
    const location = useLocation();

    const [activeChildIndex, setActiveChildIndex] = useState(false);

    const handleViewChildrenClick = (index) => {
        if (index === false) {
            deactivateOverlay();
        } else {
            activateOverlay();
        }

        setActiveChildIndex(index);
    };

    let scrollPosition = 0;

    const activateOverlay = () => {
        overlayRef.current.classList.add("is-active");
        overlayRef.current.classList.remove("is-hidden");
        overlayRef.current.addEventListener("click", () =>
            handleViewChildrenClick(false)
        );
        document.body.classList.add("no-scroll");
        scrollPosition = window.pageYOffset;
        document.body.style.overflow = "hidden";
        document.body.style.position = "fixed";
        document.body.style.top = `-${scrollPosition}px`;
        document.body.style.width = "100%";
    };

    const deactivateOverlay = () => {
        overlayRef.current.classList.add("is-hidden");
        overlayRef.current.classList.remove("is-active");
        overlayRef.current.removeEventListener("click", () =>
            handleViewChildrenClick(false)
        );
        document.body.classList.remove("no-scroll");
        document.body.style.removeProperty("overflow");
        document.body.style.removeProperty("position");
        document.body.style.removeProperty("top");
        document.body.style.removeProperty("width");
        window.scrollTo(0, scrollPosition);
    };

    return (
        <div className="c-header c-header--desktop">
            {/* uncomment this to allow first load toast for PUSH NOTIFICATIONS */}
            <FirstLoadCookie />
            {
                // ! Remove auth
            }
            {/* <Notice hash="beta" dismissable>
                We are currently experiencing technical issues, you may experience issues trying to log in.
                For more information or help please use the contact form at the bottom of this page. 
            </Notice> */}

            <div className="c-header__wrapper l-width">
                <Link
                    onClick={() => handleViewChildrenClick(false)}
                    to="/home"
                    className="t-authenticated-desktop-logo-link c-header--left"
                >
                    <Imgix
                        htmlAttributes={{ alt: "Andertons Extra Logo" }}
                        className="o-logo"
                        src={
                            "https://andertonsextra.imgix.net/static/logos/andertons-extra-x-logo-red.png"
                        }
                        imgixParams={{ q: 65 }}
                        width={40}
                        height={40}
                    />
                </Link>

                <div className="c-header--center">
                    <ul className="c-navigation">
                        <li className={"c-navigation__item"}>
                            <Link
                                onClick={() => handleViewChildrenClick(false)}
                                to="/home"
                                className={`c-navigation__link ${
                                    location.pathname === "/home"
                                        ? "is-active"
                                        : ""
                                }`}
                            >
                                Home
                            </Link>
                        </li>
                        {topLevelCategories.map((category, index) => (
                            <li
                                key={category.id}
                                className="c-navigation__item"
                            >
                                <NavbarItem
                                    handleViewChildrenClick={
                                        handleViewChildrenClick
                                    }
                                    viewChildren={activeChildIndex === index}
                                    index={index}
                                    category={category}
                                />
                            </li>
                        ))}
                        <li className={"c-navigation__item"}>
                            <Link
                                onClick={() => handleViewChildrenClick(false)}
                                to="/playlists"
                                className={`c-navigation__link ${
                                    location.pathname === "/playlists"
                                        ? "is-active"
                                        : ""
                                }`}
                            >
                                Playlists
                            </Link>
                        </li>
                        <li className={"c-navigation__item"}>
                            <Link
                                onClick={() => handleViewChildrenClick(false)}
                                to="/search"
                                className={`c-navigation__link ${
                                    location.pathname === "/search"
                                        ? "is-active"
                                        : ""
                                }`}
                            >
                                Search
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className="c-header--right">
                    <ul className="c-navigation">
                        <li className={"c-navigation__item"}>
                            { 
                                // ! Remove auth
                            }
                            {/* <Link
                                onClick={() => handleViewChildrenClick(false)}
                                to="/my-account"
                                className={`c-navigation__link ${
                                    location.pathname === "/my-account"
                                        ? "is-active"
                                        : ""
                                }`}
                            >
                                My Account
                            </Link> */}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default DesktopAuthenticatedNavbar;
