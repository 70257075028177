class BaseResponseModel {
    constructor(data) {
        Object.keys(data).forEach((key) => {
            this[key] = data[key];
        });
    }

    decode(str) {
        return str.replace(/&#(\d+);/g, (match, dec) =>
            String.fromCharCode(dec)
        );
    }
}

export default BaseResponseModel;
