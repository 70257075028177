import React from "react";
import { Link } from "react-router-dom";

function Actions({ hideBack }) {
    return (
            <div>
                {!hideBack ? (
                    <Link to="/join">
                        Back
                    </Link>
                ) : null}
                {/* <div>
                <Link to="/join-help">
                    Having Issues?
                </Link>
            </div> */}
            </div>
    );
}

Actions.defaultProps = {
    hideBack: false,
};

export default Actions;
