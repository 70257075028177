import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSubscription } from "../../../providers/subscription-provider";
import SubscriptionFormLogo from "../subscription-form-logo";
import ChargebeeCheckoutModal from "./chargebee-checkout-modal";

function BillingDetails() {
    const { setShowCheckout } = useSubscription();

    useEffect(() => {
        setShowCheckout(true);
    }, [setShowCheckout]);

    return (
        <>
            <div className="t-checkout-wrapper">
                <ChargebeeCheckoutModal />
            </div>

            <div className="l-login__wrapper">
                <div className="l-login__width">
                    <SubscriptionFormLogo />

                    <div className="l-login__form">
                        <h1 className="l-login__title">Payment Information</h1>

                        <p>Loading our secure payment gateway...</p>

                        <p className="o-help">
                            Nothing loading?{" "}
                            <Link to="/join-help" className="o-help__link">
                                Click here to contact us
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BillingDetails;
