import React from "react";

import Imgix from "react-imgix";

import { render } from "storyblok-rich-text-react-renderer";

// import Imgix from "react-imgix";
// import { Storyblok } from '../../storyblok-client';

const ServicePropositionBannerRight = ({ blok }) => {
    // const html = Storyblok.richTextResolver.render(blok.Content);
    // const image = blok.Image;
    // const resize = (image, option) => {
    //     var imageService = '//img2.storyblok.com/'
    //     var path = image.replace('//a.storyblok.com', `${imageService}${option}`)
    //     return path
    // };

    // console.log(blok);

    return (
        <div className="service-proposition-section-2">
            <div className="service-proposition-section-2__section service-proposition-section-2__section--dark">
                <div className="service-proposition-section-2__width">
                    {blok?.image?.filename && (
                        <picture className="service-proposition-section-2__picture">
                            <Imgix
                                htmlAttributes={{
                                    src: `${blok?.image?.filename}?bg=000000&blur=800&px=20&auto=format`,
                                    alt: "",
                                }}
                                className="service-proposition-section-2__img service-proposition-section-2__img--left lazyload"
                                src={blok?.image?.filename}
                                imgixParams={{ q: 65 }}
                                width={520}
                            />
                        </picture>
                    )}

                    <div className="service-proposition-section-2__content service-proposition-section-2__content--right">
                        <div className="service-proposition-section-2__text">
                            {blok?.title && <h2>{blok?.title}</h2>}
                            {blok?.body && render(blok?.body)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServicePropositionBannerRight;
