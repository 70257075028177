import React, { useState } from "react";
import { useAuth } from "../../../providers/auth-provider";
import { useResource } from "../../../providers/resource-provider";
import { useAjaxEffect } from "../../../utils";
import HoldingNavbar from "../holding-navbar";
import DesktopAuthenticatedNavbar from "./desktop-authenticated-navbar";
import MobileAuthenticatedNavbar from "./mobile-authenticated-navbar";

function AuthenticatedNavbar({ overlayRef, topLevelCategories }) {
    const { resource } = useResource();

    const [cats, setCats] = useState(topLevelCategories);
    const [loading, setLoading] = useState(false);

    const { handle401 } = useAuth();

    useAjaxEffect({
        handle401,

        tags: {
            component: "AuthenticatedNavbar",
            action: "retrieve top level categories",
        },

        requestRequired: () => !cats || cats.length < 1,

        request: (cancelToken) => {
            resource.setCancelToken(cancelToken);

            return resource.getTopLevelCategories();
        },

        onSuccess: (data, mounted) => {
            if (mounted) {
                setCats(data);
                setLoading(false);
            }
        },

        onError: (err, mounted) => {
            if (mounted) {
                console.log(err);
                setLoading(false);
            }
        },

        watch: [],

        cancelMessage:
            "Top level category data retrieval cancelled due to component unmount",
    });

    return !loading ? (
        <>
            <DesktopAuthenticatedNavbar
                topLevelCategories={cats}
                overlayRef={overlayRef}
            />
            <MobileAuthenticatedNavbar
                topLevelCategories={cats}
                overlayRef={overlayRef}
            />
        </>
    ) : (
            <HoldingNavbar />
        );
}

export default AuthenticatedNavbar;
