import BaseResponseModel from "../../base-response-model";
import Currency from "../currency";

class Location extends BaseResponseModel {
    constructor(props) {
        super(props);
        this.currency = new Currency(this.currency);
    }

    getLanguage() {
        return this.languages[0];
    }

    getCurrency() {
        return this.currency;
    }

    getCountryCode() {
        return this.country_code;
    }

    getContinentCode() {
        return this.continent_code;
    }

    getFormattedAmount(amount) {
        return this.currency.getFormattedAmount(amount, this.getLanguage());
    }

    /**
     * Get the correct currency by region,
     * within the confines of which currencies
     * we support
     *
     * See https://andertons.atlassian.net/wiki/spaces/EXTRA/pages/917798917/Signup+Page
     */
    getPlanFriendlyCurrency() {
        switch (this.getCurrency().getCurrencyCode()) {
            case "GBP":
                return "GBP";

            case "EUR":
                return "EUR";

            case "USD":
                return "USD";

            default:
                switch (true) {
                    case this.getContinentCode() === "EU" &&
                        this.getCountryCode() !== "GB":
                        return "EUR";

                    case this.getContinentCode() &&
                        this.getContinentCode() !== "EU":
                        return "USD";

                    default:
                        return "GBP";
                }
        }
    }
}

export default Location;
