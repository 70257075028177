import React from "react";

import { useCookies } from "react-cookie";
import { ExtraCookies } from "../../utils";

/*
 * cookie notice - EXTRA-254.
 * Displays a sticky footer overlay if a user hasn't accepted the cookie notice.
 * If they have accepted, it'll remain hidden up until the application's defined max cookie age
 */

export default function CookieNotice() {
    const [cookies, setCookie, removeCookie] = useCookies();
    const hasAcceptedNotice =
        cookies[ExtraCookies.getWrappedKey("accept-cookies")];
    const handleCookieClose = () => {
        setCookie(ExtraCookies.getWrappedKey("accept-cookies"), true, {
            maxAge: process.env.RAZZLE_USER_COOKIE_MAX_AGE_SECONDS,
        });
    };
    return (
        <>
            {!hasAcceptedNotice && (
                <div className="c-cookie-notice">
                    <p className="c-cookie-notice__text">
                        This website uses cookies to ensure you get the best
                        experience.&nbsp;
                        <a
                            href="https://www.andertons.co.uk/privacy-policy"
                            target="_blank"
                        >
                            Learn more
                        </a>
                    </p>

                    <button
                        className="t-notice-close-button c-cookie-notice__close"
                        onClick={handleCookieClose}
                    >
                        Got it
                    </button>
                </div>
            )}
        </>
    );
}
