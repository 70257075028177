import queryString from "query-string";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { usePagination } from "../../providers/resource-provider";
import { scrollToTop } from "../../utils";

function HasPaginationLinks({ className, children }) {
    const pagination = usePagination();

    const location = useLocation();

    const paginationSettings = {
        arrayFormat: "bracket",
    };

    const query = queryString.parse(location.search, paginationSettings);

    const currentPage = query.page ? parseInt(query.page, 10) : 1;

    let pagesToShow = [];

    if (currentPage <= 3) {
        /**
         * Page 1 to 3, show 1, 2, 3, 4, 5, or 1 to max if max < 5
         */

        pagesToShow = [
            1,
            2,
            3,
            4,
            // 5
        ].filter((page) => page <= pagination.getNumberOfPages());
    } else if (currentPage >= pagination.getNumberOfPages() - 2) {
        /**
         * Page (max-2) to max, show n-4, n-3, n-2, n-1, n, or 1 to max if max < 5
         */
        pagesToShow = [
            // pagination.getNumberOfPages() - 4,
            pagination.getNumberOfPages() - 3,
            pagination.getNumberOfPages() - 2,
            pagination.getNumberOfPages() - 1,
            pagination.getNumberOfPages(),
        ].filter((page) => page >= 1);
    } else {
        /**
         * Page n to (max-2), show n-2, n-1, n, n+1, n+2
         */
        pagesToShow = [
            // currentPage - 2,
            currentPage - 1,
            currentPage,
            currentPage + 1,
            currentPage + 2,
        ].filter((page) => page >= 1 && page <= pagination.getNumberOfPages());
    }

    return (
        <div className={className}>
            {children}

            <div className="o-pagination">
                {pagination.getShowPagination() && (
                    <ul className="o-pagination__list">
                        <li
                            key="firstPage"
                            className={`o-pagination__item o-pagination__first ${
                                currentPage === 1 ? "is-disabled" : ""
                                }`}
                        >
                            <Link
                                onClick={() => scrollToTop()}
                                to={queryString.stringifyUrl(
                                    {
                                        url: location.pathname,
                                        query: { ...query, page: 1 },
                                    },
                                    paginationSettings
                                )}
                                className="t-pagination-link-first o-pagination__button"
                            >
                                {"<<"}
                            </Link>
                        </li>
                        <li
                            key="backPage"
                            className={`o-pagination__item o-pagination__previous ${
                                currentPage <= 1 ? "is-disabled" : ""
                                }`}
                        >
                            <Link
                                onClick={() => scrollToTop()}
                                to={queryString.stringifyUrl(
                                    {
                                        url: location.pathname,
                                        query: {
                                            ...query,
                                            page: currentPage - 1,
                                        },
                                    },
                                    paginationSettings
                                )}
                                className="t-pagination-link-previous o-pagination__button"
                            >
                                {"<"}
                            </Link>
                        </li>
                        {pagesToShow.map((page) => (
                            <li
                                key={page}
                                className={`o-pagination__item ${
                                    currentPage === page ? "is-active" : ""
                                    }`}
                            >
                                {
                                    <Link
                                        onClick={() => scrollToTop()}
                                        to={queryString.stringifyUrl(
                                            {
                                                url: location.pathname,
                                                query: { ...query, page },
                                            },
                                            paginationSettings
                                        )}
                                        className="t-pagination-link o-pagination__button"
                                    >
                                        {page}
                                    </Link>
                                }
                            </li>
                        ))}
                        <li
                            key="forwardPage"
                            className={`o-pagination__item o-pagination__next ${
                                currentPage >= pagination.getNumberOfPages()
                                    ? "is-disabled"
                                    : ""
                                }`}
                        >
                            <Link
                                onClick={() => scrollToTop()}
                                to={queryString.stringifyUrl(
                                    {
                                        url: location.pathname,
                                        query: {
                                            ...query,
                                            page: currentPage + 1,
                                        },
                                    },
                                    paginationSettings
                                )}
                                className="t-pagination-link-next o-pagination__button"
                            >
                                {">"}
                            </Link>
                        </li>
                        <li
                            key="lastPage"
                            className={`o-pagination__item o-pagination__last ${
                                currentPage >= pagination.getNumberOfPages()
                                    ? "is-disabled"
                                    : ""
                                }`}
                        >
                            <Link
                                onClick={() => scrollToTop()}
                                to={queryString.stringifyUrl(
                                    {
                                        url: location.pathname,
                                        query: {
                                            ...query,
                                            page: pagination.getNumberOfPages(),
                                        },
                                    },
                                    paginationSettings
                                )}
                                className="t-pagination-link-last o-pagination__button"
                            >
                                {">>"}
                            </Link>
                        </li>
                    </ul>
                )}
            </div>
        </div>
    );
}

HasPaginationLinks.defaultProps = {
    className: "",
};

export default HasPaginationLinks;
