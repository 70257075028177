import React from "react";

import EmailPreferenceToggle from "./email";
import PushPreferenceToggle from "./push";
import { usePushNotification } from "../../providers/push-notification-provider";

export default function CommunicationPreferences() {
    const { notificationsActive } = usePushNotification();

    return (
        <>
            {/* env-level config for turning notifications on */}
            {notificationsActive() && (
                <>
                    <h3>Push Notifications</h3>
                    <ul className="c-commprefs">
                        <li className="c-commprefs__item">
                            <PushPreferenceToggle
                                label={`Receive Push Notifications on this device`}
                            />
                        </li>
                    </ul>
                    <p>
                        Allow us to send this device notifications when we
                        release new content!
                    </p>
                </>
            )}
            <h3>Marketing Emails</h3>
            <ul className="c-commprefs">
                <li className="c-commprefs__item">
                    <EmailPreferenceToggle
                        label={`Receive Andertons Marketing emails`}
                    />
                </li>
            </ul>
            <p>
                Opt in to Andertons Email Marketing to receive subscriber-only
                deals direct to your inbox!
            </p>
        </>
    );
}
