import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Form from "../../../components/form";
import Field from "../../../components/form/field";
import Select from "../../../components/form/select";
import Text from "../../../components/form/text";
import SubmitButton from "../../../components/submit-button";
import AddressDetailsModel from "../../../models/auth/address-details";
import CustomerDetails from "../../../models/auth/customer-details";
import { useAuth } from "../../../providers/auth-provider";
import { useGeolocation } from "../../../providers/geolocation-provider";
import { useSubscription } from "../../../providers/subscription-provider";
import { countries, getRegex, states, unexpectedErrorToast } from "../../../utils";
import SubscriptionFormLogo from "../subscription-form-logo";

function AddressDetails() {
    const {
        customerExistence,
        setAddressDetails,
        customerDetails,
        setCustomerDetails,
    } = useSubscription();
    const { auth } = useAuth();

    const [submitting, setSubmitting] = useState(false);

    const geolocation = useGeolocation();

    const history = useHistory();

    const onSubmit = (data) => {
        setSubmitting(true);

        return auth
            .createCustomer({ ...customerDetails, ...data })
            .then((res) => {
                setSubmitting(false);
                setCustomerDetails(
                    new CustomerDetails({
                        password: customerDetails.password,
                        ...res.details(),
                    })
                );
                setAddressDetails(new AddressDetailsModel(data));
                history.push("/join/billing-term");
            })
            .catch((err) => {
                setSubmitting(false);
                if (err.response && err.response.status) {
                    switch (err.response.status) {
                        default:
                            console.error(
                                "Unexpected customer creation error with status",
                                err.response.status
                            );
                            unexpectedErrorToast("/join-help");
                            break;
                    }
                } else {
                    console.error(
                        "Unexpected customer creation error with no status",
                        err
                    );
                    unexpectedErrorToast("/join-help");
                }
            });
    };

    /* eslint-disable indent */
    return (
        <div className="l-login__wrapper">
            <SubscriptionFormLogo />

            <div className="l-login__width">
                <div className="l-login__form">
                    <h1 className="l-login__title">Address Details</h1>

                    <Form
                        className="c-form t-address-details-form"
                        useFormParams={{
                            defaultValues: {
                                country:
                                    !!geolocation &&
                                        geolocation.country_code !== "ZZ"
                                        ? geolocation.country_code
                                        : "GB",
                            },
                        }}
                        onSubmit={onSubmit}
                    >
                        {({ watch, ...formMethods }) => (
                            <>
                                <Field
                                    watch={watch}
                                    focusOnMount
                                    {...formMethods}
                                    disabled={submitting}
                                    validation={{
                                        required: "Address line 1 is required",
                                        pattern: {
                                            value: getRegex("address"),
                                            message:
                                                "Address line 1 contains invalid characters",
                                        },
                                    }}
                                    required
                                    id="addressLine1"
                                    type="text"
                                    name="addressLine1"
                                    displayName="Address line 1"
                                />

                                <Field
                                    watch={watch}
                                    {...formMethods}
                                    disabled={submitting}
                                    validation={{
                                        pattern: {
                                            value: getRegex("address"),
                                            message:
                                                "Address line 2 contains invalid characters",
                                        },
                                    }}
                                    id="addressLine2"
                                    type="text"
                                    name="addressLine2"
                                    displayName="Address line 2"
                                />

                                <Field
                                    watch={watch}
                                    {...formMethods}
                                    disabled={submitting}
                                    validation={{
                                        required: "City is required",
                                        pattern: {
                                            value: getRegex("address"),
                                            message:
                                                "City contains invalid characters",
                                        },
                                    }}
                                    id="city"
                                    type="text"
                                    name="city"
                                    displayName="City"
                                />

                                <Field
                                    watch={watch}
                                    {...formMethods}
                                    is={watch("country") === "US" ? Select : Text}
                                    validation={{
                                        required: "State/Region is required",
                                        validate: (val) =>
                                            (watch("country") === "US"
                                                ? states
                                                    .map((states) => states.code)
                                                    .includes(val) &&
                                                val !== "DEFAULT"
                                                : true) ||
                                            "Invalid state/region selected",
                                    }}
                                    required
                                    id="state"
                                    type="text"
                                    name="state"
                                    displayName="State/Region"
                                >
                                    {watch("country") === "US" && (
                                        <>
                                            <option value={"DEFAULT"} disabled>
                                                Select State/County
                                        </option>
                                            {states.map((state) => (
                                                <option
                                                    value={state.code}
                                                    key={state.code}
                                                >
                                                    {state.name}
                                                </option>
                                            ))}
                                        </>
                                    )}
                                </Field>

                                <Field
                                    watch={watch}
                                    {...formMethods}
                                    is={Select}
                                    validation={{
                                        required: "Country is required",
                                        validate: (val) =>
                                            countries
                                                .map((country) => country.code)
                                                .includes(val) ||
                                            "Invalid country selected",
                                    }}
                                    required
                                    id="country"
                                    name="country"
                                    displayName="Country"
                                >
                                    <optgroup>
                                        <option value="GB">United Kingdom</option>
                                        <option value="US">United States</option>
                                    </optgroup>
                                    <optgroup label="--">
                                        {countries
                                            .filter(
                                                (country) =>
                                                    !["US", "GB"].includes(
                                                        country.code
                                                    )
                                            )
                                            .map((country) => (
                                                <option
                                                    value={country.code}
                                                    key={country.code}
                                                >
                                                    {country.name}
                                                </option>
                                            ))}
                                    </optgroup>
                                </Field>

                                <Field
                                    watch={watch}
                                    {...formMethods}
                                    disabled={submitting}
                                    validation={{
                                        required: "Zip code/postcode is required",
                                        pattern: {
                                            value: getRegex("address"),
                                            message:
                                                "Zip code/Postcode contains invalid characters",
                                        },
                                    }}
                                    required
                                    id="zipCode"
                                    type="text"
                                    name="zipCode"
                                    displayName="Zip Code/Postcode"
                                />

                                <Field
                                    watch={watch}
                                    {...formMethods}
                                    disabled={submitting}
                                    required
                                    validation={{
                                        required: "Phone is required",
                                        pattern: {
                                            value: getRegex("phone"),
                                            message:
                                                "Phone contains invalid characters",
                                        },
                                    }}
                                    id="phone"
                                    type="tel"
                                    name="phone"
                                    displayName="Phone"
                                />

                                <SubmitButton loading={submitting}>
                                    Submit
                            </SubmitButton>
                            </>
                        )}
                    </Form>

                    <div className="o-actions">
                        <div>
                            <Link to={
                                customerExistence.exists()
                                    ? "/join/existing-customer"
                                    : "/join/new-customer"
                            } >
                                Back
                            </Link>
                        </div>
                        <div></div>
                    </div>
                </div>
            </div>

            <p className="o-help">
                {/* Already Subscribed?{" "}
                <Link to="/login"
                    className="o-help__link">
                    Login Now
                </Link> */}
            </p>
        </div>
    );
    /* eslint-enable indent */
}

export default AddressDetails;
