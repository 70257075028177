import React, { useState } from "react";
import Carousel from "../carousel";
import { useResource, useCarousels } from "../../providers/resource-provider";
import { useAjaxEffect } from "../../utils";
import CarouselVideoThumbnail from "../video-thumbnail-set/carousel-video-thumbnail";
import VideoThumbnailSet from "../video-thumbnail-set";
import { useAuth } from "../../providers/auth-provider";

function RecentlyAddedCarousel() {
    const {
        recentlyAdded: { recentlyAddedVideos, setRecentlyAddedVideos },
    } = useCarousels();
    const [loading, setLoading] = useState(!recentlyAddedVideos);
    const { resource } = useResource();
    const { handle401 } = useAuth();

    useAjaxEffect({
        handle401,

        tags: {
            component: "RecentlyAddedCarousel",
            action: "retrieve recently added videos",
        },

        requestRequired: () => !recentlyAddedVideos,

        request: (cancelToken) => {
            resource.setCancelToken(cancelToken);

            return resource.getRecentlyAdded();
        },

        onSuccess: (data, mounted) => {
            if (mounted) {
                setRecentlyAddedVideos(data);
                setLoading(false);
            }
        },

        onError: (err, mounted) => {
            if (mounted) {
                console.log(err);
                setLoading(false);
            }
        },

        watch: [],

        cancelMessage:
            "Recently added carousel data retrieval cancelled due to component unmount",
    });

    return (
        <>
            <div className="t-recently-added-carousel-section o-section">
                <div className="o-section--wrapper">
                    <div className="o-section--left">
                        <h2 className="t-recently-added-carousel-title o-section__title">
                            Recently Added
                        </h2>
                    </div>
                </div>
                {!loading ? (
                    <VideoThumbnailSet videos={recentlyAddedVideos}>
                        {({ videos }) => (
                            <Carousel className="t-recently-added-carousel o-carousel">
                                {videos.map((video) => (
                                    <CarouselVideoThumbnail
                                        key={video.id}
                                        video={video}
                                    />
                                ))}
                            </Carousel>
                        )}
                    </VideoThumbnailSet>
                ) : (
                        <Carousel>{[]}</Carousel>
                    )}
            </div>
        </>
    );
}

export default RecentlyAddedCarousel;
