import React from "react";

import {
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from "react-accessible-accordion";

import { render } from "storyblok-rich-text-react-renderer";

const FAQItem = ({ blok }) => {
    return (
        <AccordionItem className="t-service-proposition-accordion-item accordion__item">
            <AccordionItemHeading className="t-service-proposition-accordion-item-heading accordion__heading">
                <AccordionItemButton className="t-service-proposition-accordion-item-button accordion__button">
                    {blok?.title && blok.title}
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="t-service-proposition-accordion-item-panel accordion__panel">
                {render(blok.content)}
            </AccordionItemPanel>
        </AccordionItem>
    );
};

export default FAQItem;
