import queryString from "query-string";
import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Imgix from "react-imgix";
import { useHistory, useLocation } from "react-router-dom";
import ErrorBoundary from "../../components/error-boundary";
import ErrorMessage from "../../components/error-message";
import HasPaginationLinks from "../../components/has-pagination-links";
import LoadingIcon from "../../components/loading-icon";
import PresentersCarousel from "../../components/presenters-carousel";
import ProcessedText from "../../components/processed-text";
import VideoThumbnailSet from "../../components/video-thumbnail-set";
import ListVideoThumbnail from "../../components/video-thumbnail-set/list-video-thumbnail";
import YouMightAlsoLikeCarousel from "../../components/you-might-also-like-carousel";
import Category from "../../models/resource/category";
import { useAuth } from "../../providers/auth-provider";
import { useCategory, usePagination, useResource } from "../../providers/resource-provider";
import { parseSort, useAjaxEffect } from "../../utils";

function SeriesSelectCategory({ match }) {
    const { category } = useCategory();

    return (
        <>
            <Helmet>
                <title>
                    {category.name} — {process.env.RAZZLE_APPLICATION_NAME}
                </title>
                <meta
                    name="description"
                    content={category.description}
                />
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            {/* Category Series Videos */}
            <ErrorBoundary
                tags={{
                    page: "series select category",
                    section: "categoryt details",
                }}
                fallback={() => (
                    <ErrorMessage message="Could not display category series videos" />
                )}
            >
                <CategoryDetails match={match} category={category} />
            </ErrorBoundary>

            {/* Category Series Videos */}
            <ErrorBoundary
                tags={{
                    page: "series select category",
                    section: "category series video",
                }}
                fallback={() => (
                    <ErrorMessage message="Could not display category series videos" />
                )}
            >
                <CategorySeriesVideos />
            </ErrorBoundary>

            {/* You Might Also Like Carousel */}
            <ErrorBoundary
                tags={{
                    page: "series select category",
                    section: "you might also like category",
                }}
                fallback={() => (
                    <ErrorMessage message="Could not display you might also like carousel" />
                )}
            >
                <YouMightAlsoLikeCarousel />
            </ErrorBoundary>

            {/* Our Presenters Carousel */}
            <ErrorBoundary
                tags={{
                    page: "series select category",
                    section: "our presenters carousel",
                }}
                fallback={() => (
                    <ErrorMessage message="Could not display presenters carousel" />
                )}
            >
                <PresentersCarousel />
            </ErrorBoundary>
        </>
    );
}

export default SeriesSelectCategory;

function CategoryDetails({ category, match }) {
    return (
        <div className="o-hero o-hero--series">
            <picture className="o-hero__picture">
                {category && (
                    <Imgix
                        key={category.hero_image}
                        htmlAttributes={{
                            src: category.loading_hero_image,
                            alt: `${category.name} hero image`,
                        }}
                        className="o-hero__img lazyload"
                        src={category.hero_image}
                        imgixParams={{ q: 65 }}
                        attributeConfig={{
                            src: "data-src",
                            srcSet: "data-srcset",
                            sizes: "data-sizes",
                        }}
                        width={1920}
                        height={500}
                    />
                )}
            </picture>
            <div className="o-hero__info">
                <div className="o-hero__position">
                    <h1 className="o-hero__title">{category.name}</h1>
                    <CategorySeriesSelect match={match} />
                    <div className="o-hero__intro">
                        <ProcessedText>{category.description}</ProcessedText>
                    </div>
                </div>
            </div>
        </div>
    );
}

function CategorySeriesSelect({ match }) {
    const { category, handleCategoryClick } = useCategory();
    const history = useHistory();

    const handleSiblingSelect = (e) => {
        const selected = category.siblings.find(
            (sibling) => sibling.slug === e.target.value
        );

        if (selected) {
            handleCategoryClick(new Category({
                ...selected,
                siblings: category.siblings,
                /**
                 * Set videos to null so the route data fetcher
                 * knows that only videos need to be retrieved
                 */
                videos: null
            }));

            history.push(selected.link);
        }
    };

    return (
        <div className="o-hero__select">
            <select
                className="t-series-select"
                value={match.params.slug}
                onChange={handleSiblingSelect}
            >
                {category.siblings.map((sibling) => (
                    <option
                        disabled={!sibling.has_videos}
                        value={sibling.slug}
                        key={sibling.id}
                    >
                        {sibling.name}
                    </option>
                ))}
            </select>
        </div>
    );
}

function CategorySeriesVideos() {
    const { category } = useCategory();

    const { handle401 } = useAuth();

    const pagination = usePagination();

    const [videos, setVideos] = useState(category.videos);
    const [loading, setLoading] = useState(false);
    const { resource } = useResource();
    const location = useLocation();

    const query = queryString.parse(location.search);

    const isInitialMount = useRef(true);

    useAjaxEffect({
        handle401,

        tags: {
            component: "SeriesSelectCategory",
            action: "retrieve series videos",
        },

        requestRequired: () => !isInitialMount.current,

        onNoRequestRequired: () => {
            isInitialMount.current = false;
        },

        request: (cancelToken) => {
            resource.setCancelToken(cancelToken);

            setLoading(true);

            return resource.getMediaByCategory(
                category.id,
                query.page ?? 1,
                10,
                [],
                parseSort("created_at-desc")
            );
        },

        onSuccess: (data, mounted) => {
            if (mounted) {
                pagination.setPageSize(20);

                pagination.setNumberOfPages(data.pagination.getNumberOfPages());

                pagination.setTotalItems(data.pagination.getTotalItems());

                pagination.setShowPagination(data.pagination.getTotalItems() > 0);

                setVideos(data.videos);

                setLoading(false);
            }
        },

        onError: (err, mounted) => {
            if (mounted) {
                console.log(err);
                setLoading(false);
            }
        },

        watch: [location.search],

        cancelMessage:
            "Series video retrieval cancelled due to component unmount",
    });

    return (
        <HasPaginationLinks>
            <div className="o-series l-width">
                <h4 className="o-series__title">
                    {loading ? "Loading..." : `Episodes (${pagination.getTotalItems()})`}
                </h4>
                <ul className="o-series__list">
                    {!loading ? (
                        <VideoThumbnailSet videos={videos}>
                            {({ videos }) =>
                                videos.map((video) => {
                                    const link = queryString.stringifyUrl({
                                        url: video.link,
                                        query: {
                                            category: category.id,
                                        },
                                    });

                                    return (
                                        <li
                                            key={video.id}
                                            className="t-series-item o-series__item"
                                        >
                                            <ListVideoThumbnail
                                                video={video}
                                                link={link}
                                            />
                                        </li>
                                    );
                                })
                            }
                        </VideoThumbnailSet>
                    ) : (
                            <li className="loading">
                                <LoadingIcon />
                            </li>
                        )}
                </ul>
            </div>
        </HasPaginationLinks>
    );
}
