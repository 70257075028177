import BaseResponseModel from "../../base-response-model";

class Subscription extends BaseResponseModel {
    isActive() {
        return (
            this.subscription.status === "active" ||
            this.subscription.status === "in_trial"
        );
    }

    isScheduledForCancellation() {
        return this.subscription.status === "non_renewing";
    }
}

export default Subscription;
