import React from "react";

import Imgix from "react-imgix";

import { Link } from "react-router-dom";
import { useAuth } from "../../providers/auth-provider";

const ServicePropositionHero = ({ blok }) => {
    const { primaryCta } = useAuth();
    const trialText = "Sign Up now to activate your 7-day free trial";
    return (
        <div className="service-proposition-section-1">
            <div className="service-proposition-section-1__background"></div>

            <div className="service-proposition-section-1__width">
                <div className="service-proposition-section-1__title">
                    {blok?.image?.filename && (
                        <Imgix
                            htmlAttributes={{
                                src: blok?.image?.filename,
                                alt: "Andertons Extra Logo",
                            }}
                            className="service-proposition-section-1__logo"
                            src={blok?.image?.filename}
                            imgixParams={{ q: 65 }}
                            width={260}
                            height={73}
                        />
                    )}
                    {blok?.title && <h4>{blok?.title}</h4>}
                </div>

                <div className="service-proposition-section-1__columns">
                    <div>
                        {blok?.col1title && <h4>{blok?.col1title}</h4>}
                        {blok?.col1text && <p>{blok?.col1text}</p>}
                    </div>
                    <div>
                        {blok?.col2title && <h4>{blok?.col2title}</h4>}
                        {blok?.col2text && <p>{blok?.col2text}</p>}
                    </div>
                    <div>
                        {blok?.col3title && <h4>{blok?.col3title}</h4>}
                        {blok?.col3text && <p>{blok?.col3text}</p>}
                    </div>
                </div>

                <Link to="/join" className="btn btn--primary btn--full">
                    <p className="service-proposition-section-1__cta-text">{primaryCta}</p>
                    <p className="service-proposition-section-1__cta-text">{trialText}</p>
                </Link>

                <p className="o-login-link">
                    Already a member?
                    <Link to="/login">Log In!</Link>
                </p>
            </div>
        </div>
    );
};

export default ServicePropositionHero;
