import React from "react";

function SubmitButton({
    loading,
    children,
    staticClasses,
    loadingClasses,
    innerElement,
    type,
    onClick,
    style,
    ...rest
}) {
    return (
        <button
            style={style}
            type={type}
            onClick={onClick}
            className={`${staticClasses} ${loading ? loadingClasses : ""}`}
            disabled={loading}
            {...rest}
        >
            {!loading && children}
            {innerElement}
        </button>
    );
}

SubmitButton.defaultProps = {
    type: "submit",
    style: {},
    onClick: () => {},
    children: "Submit",
    loading: false,
    staticClasses: "btn btn--primary",
    loadingClasses: "btn--loading",
    innerElement: <span></span>,
};

export default SubmitButton;
