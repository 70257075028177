import { useState } from "react";

function StaticCategories({ children, data }) {
    const output = {};

    data.forEach((category) => {
        output[category.id] = category;
    });

    const [categories] = useState(output);

    return children({
        categories,
    });
}

export default StaticCategories;
