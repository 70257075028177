import queryString from "query-string";
import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Imgix from "react-imgix";
import { useLocation } from "react-router-dom";
import ErrorBoundary from "../../components/error-boundary";
import ErrorMessage from "../../components/error-message";
import HasPaginationLinks from "../../components/has-pagination-links";
import LoadingIcon from "../../components/loading-icon";
import ProcessedText from "../../components/processed-text";
import VideoThumbnailSet from "../../components/video-thumbnail-set";
import GridVideoThumbnail from "../../components/video-thumbnail-set/grid-video-thumbnail";
import { useAuth } from "../../providers/auth-provider";
import { usePagination, usePlaylist, useResource } from "../../providers/resource-provider";
import { useAjaxEffect } from "../../utils";

function Playlist() {

    const { playlist } = usePlaylist();

    const pagination = usePagination();

    return (
        <>
            <Helmet>
                <title>{playlist.name} — {process.env.RAZZLE_APPLICATION_NAME}</title>
                <meta
                    name="description"
                    content={playlist.description}
                />
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <>
                <ErrorBoundary
                    tags={{
                        page: "playlist",
                        section: "playlist details",
                    }}
                    fallback={() => (
                        <ErrorMessage message="Could not display playlist details" />
                    )}
                >
                    <PlaylistDetails playlist={playlist} />
                </ErrorBoundary>
                <ErrorBoundary
                    tags={{
                        page: "playlist",
                        section: "playlist videos",
                    }}
                    fallback={() => (
                        <ErrorMessage message="Could not display playlist videos" />
                    )}
                >
                    <PlaylistVideoGrid
                        pagination={pagination}
                        playlist={playlist}
                    />
                </ErrorBoundary>
            </>
        </>
    );
}

export default Playlist;

function PlaylistDetails({ playlist }) {
    return (
        <div className="o-narrow">
            <div className="o-narrow__wrapper l-width">
                <picture className="o-narrow__picture">
                    <Imgix
                        key={playlist.id}
                        htmlAttributes={{
                            src: playlist.loading_hero_image,
                            alt: `${playlist.name} hero image`,
                        }}
                        className="o-narrow__img lazyload"
                        src={playlist.hero_image}
                        imgixParams={{ q: 65 }}
                        width={682}
                        height={409}
                        attributeConfig={{
                            src: "data-src",
                            srcSet: "data-srcset",
                            sizes: "data-sizes",
                        }}
                    />
                </picture>
                <div className="o-narrow__info">
                    <div className="o-narrow__content">
                        <h1 className="o-narrow__title">{playlist.name}</h1>
                        <div className="o-narrow__intro">
                            <ProcessedText>
                                {playlist.description}
                            </ProcessedText>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function PlaylistVideoGrid({ playlist }) {
    const location = useLocation();
    const { resource } = useResource();
    const pagination = usePagination();
    const { handle401 } = useAuth();

    const [videos, setVideos] = useState(playlist.videos ?? []);
    const [loading, setLoading] = useState(false);

    /**
     * If page changes, get new videos
     */
    const isInitialMount = useRef(true);

    useAjaxEffect({
        handle401,

        tags: {
            component: "Playlist",
            action: "retrieve playlist videos",
        },

        requestRequired: () => !isInitialMount.current,

        onNoRequestRequired: () => {
            isInitialMount.current = false;
        },

        request: (cancelToken) => {
            resource.setCancelToken(cancelToken);

            const query = queryString.parse(location.search);

            pagination.setPageSize(20);

            setLoading(true);

            return resource.getMediaByPlaylist(
                playlist.id,
                query.page ?? 1,
                20
            );
        },

        onSuccess: (data, mounted) => {
            if (mounted) {
                setVideos(data.videos);
                pagination.setNumberOfPages(data.pagination.getNumberOfPages());
                pagination.setTotalItems(data.pagination.getTotalItems());
                pagination.setShowPagination(pagination.getTotalItems() > 0);
                setLoading(false);
            }
        },

        onError: (err, mounted) => {
            if (mounted) {
                console.log(err);
                setLoading(false);
            }
        },

        watch: [location.search],

        cancelMessage:
            "Playlist video retrieval cancelled due to component unmount",
    });

    return (
        <HasPaginationLinks className="l-width">
            <div className="o-videos">
                <ul className="o-videos__list">
                    {!loading ? (
                        <VideoThumbnailSet videos={videos}>
                            {({ videos }) =>
                                videos.map((video) => {
                                    const link = queryString.stringifyUrl({
                                        url: video.link,
                                        query: {
                                            playlist: playlist.id,
                                        },
                                    });

                                    return (
                                        <GridVideoThumbnail
                                            key={video.id}
                                            video={video}
                                            link={link}
                                        />
                                    );
                                })
                            }
                        </VideoThumbnailSet>
                    ) : (
                            <li className="loading">
                                <LoadingIcon />
                            </li>
                        )}
                </ul>
            </div>
        </HasPaginationLinks>
    );
}
