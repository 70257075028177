import React from "react";

export default function Radio({
    watch,
    register,
    validation,
    name,
    value,
    toggleIsEmpty,
    children,
    ...rest
}) {
    return (
        <input
            name={name}
            ref={register(validation)}
            value={value}
            {...rest}
            type="radio"
        />
    );
}

Radio.defaultProps = {
    validation: {},
};
