import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Form from "../../../components/form";
import Field from "../../../components/form/field";
import SubmitButton from "../../../components/submit-button";
import ApiClient from "../../../models/api-client";
import { useAuth } from "../../../providers/auth-provider";
import { useSubscription } from "../../../providers/subscription-provider";
import { getRegex, unexpectedErrorToast, useAjaxEffect } from "../../../utils";
import Actions from "../common/actions";
import SubscriptionFormLogo from "../subscription-form-logo";

function Join() {
    const { setCustomerExistence, setCouponCode } = useSubscription();

    const { auth } = useAuth();

    const location = useLocation();

    /**
     * EXTRA-95 - look for email param to skip first
     * join step
     */
    const query = queryString.parse(location.search, {
        arrayFormat: "bracket",
    });

    const [immediateCheck] = useState(!!query.email);

    /**
     * EXTRA-373 - Soft go live!
     *
     * This will check for a coupon code in the
     * query string and store it in the subscription
     * context
     */
    useEffect(() => {
        if (!!query.coupon) {
            setCouponCode(query.coupon);
        }
    }, [query.coupon])

    const [submitting, setSubmitting] = useState(false);

    const history = useHistory();

    const handleExistenceCheckSuccess = (data, direct = false) => {
        setSubmitting(false);

        setCustomerExistence(data);

        if (data.exists()) {
            history.push({
                pathname: "/join/existing-customer",
                search: queryString.stringify({
                    direct,
                }),
            });
        } else {
            toast(
                "It looks like there's no Andertons account for your email address!",
                {
                    delay: 0,
                }
            );

            toast(
                "No problem. This will be created as part of the subscription process to ensure you can get the most out of your membership.",
                {
                    delay: 500,
                }
            );

            history.push({
                pathname: "/join/new-customer",
                search: queryString.stringify({
                    direct,
                }),
            });
        }
    };

    useAjaxEffect({
        tags: {
            component: "Join",
            action: "retrieve customer existence",
        },

        requestRequired: () => immediateCheck,

        request: (cancelToken) => {
            setSubmitting(true);

            auth.setCancelToken(cancelToken);

            return auth.getCustomerExists(query.email);
        },

        onSuccess: (data, mounted) => {
            if (mounted) {
                handleExistenceCheckSuccess(data, true);
            }
        },

        onError: (err, mounted) => {
            if (mounted) {
                setSubmitting(false);

                if (
                    !ApiClient.isCancel(err) &&
                    err.response &&
                    err.response.status
                ) {
                    switch (err.response.status) {
                        default:
                            console.error(
                                "Unexpected customer existence failure with status",
                                err.response.status
                            );
                            unexpectedErrorToast("/join-help");
                            break;
                    }

                    history.push("/join")
                }
            }
        },

        watch: [],
    });

    const fetchCustomerExistenceAndProceed = (email, search) => {
        setSubmitting(true);

        auth.getCustomerExists(email)
            .then((res) => {
                handleExistenceCheckSuccess(res);
            })
            .catch((err) => {
                setSubmitting(false);

                if (
                    !ApiClient.isCancel(err) &&
                    err.response &&
                    err.response.status
                ) {
                    switch (err.response.status) {
                        default:
                            console.error(
                                "Unexpected authentication failure with status",
                                err.response.status
                            );
                            unexpectedErrorToast("/join-help");
                            break;
                    }
                } else {
                    console.error(
                        "Unexpected authentication failure with no status",
                        err
                    );
                    unexpectedErrorToast("/join-help");
                }
            });
    };

    const onSubmit = (data) => {
        fetchCustomerExistenceAndProceed(data.email);
    };

    return immediateCheck ? null : (
        <div className="l-login__wrapper">
            <div className="l-login__width">
                <SubscriptionFormLogo />

                <div className="l-login__form">
                    <h1 className="l-login__title">Sign Up</h1>

                    <Form className="c-form t-join-form" onSubmit={onSubmit}>
                        {(formMethods) => (
                            <>
                                <Field
                                    {...formMethods}
                                    focusOnMount
                                    disabled={submitting}
                                    validation={{
                                        required: "Email is required",
                                        pattern: {
                                            value: getRegex("email"),
                                            message:
                                                "Email must be a valid email address",
                                        },
                                    }}
                                    required
                                    id="email"
                                    type="email"
                                    name="email"
                                    displayName="Email"
                                />

                                <SubmitButton loading={submitting}>
                                    Submit
                                </SubmitButton>
                            </>
                        )}
                    </Form>

                    <div className="o-actions">
                        <Actions hideBack />
                        <div></div>
                    </div>
                </div>

                <p className="o-help">
                    Already Subscribed?{" "}
                    <Link to="/login"
                        className="o-help__link">
                        Login Now
                    </Link>
                </p>
            </div>
        </div>
    );
}

export default Join;
