import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Imgix from "react-imgix";
import { Link } from "react-router-dom";

class NoMatch extends Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Page Not Found — {process.env.RAZZLE_APPLICATION_NAME}</title>
                    <meta
                        name="description"
                        content="Sorry, we couldn't find the page you were looking for. If you were expecting to use one of your Extra benefits maybe you need to log in? Not a member? Join now!"
                    />
                    <meta name="robots" content="noindex, nofollow" />
                </Helmet>
                <div className="error-page">
                    <div className="error-page__message">
                        <h1>Something went wrong</h1>
                        <p>Whoops! It looks like this page doesn't exist.</p>
                        <Link to="/home" className="btn">
                            Go back to the homepage
                        </Link>
                    </div>
                    <Imgix
                        htmlAttributes={{
                            src:
                                "https://andertonsextra.imgix.net/static/logos/andertons-extra-x-logo.jpg",
                            alt: "Andertons Extra Logo",
                        }}
                        width={260}
                        className="error-page__image"
                        src={
                            "https://andertonsextra.imgix.net/static/logos/andertons-extra-x-logo.jpg"
                        }
                        imgixParams={{ q: 65 }}
                    />
                </div>
            </>
        );
    }
}

export default NoMatch;
