import React from "react";

export default function TextArea({
    watch,
    register,
    validation,
    focusOnMount,
    children,
    ...rest
}) {
    return <textarea ref={register(validation)} {...rest}></textarea>;
}

TextArea.defaultProps = {
    className: "",
    validation: {},
    focusOnMount: false,
};
