import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { LocalStorage } from "../../utils";
import { useDetails } from "../auth-provider";

const LocalStorageContext = createContext(null);

export const useLocalStorage = () => useContext(LocalStorageContext);

/**
 * Load localstorage into a provider
 *
 * Required as this data comes from localstorage, but
 * we can't use conventional methods as this isn't
 * available with an SSR flow. Abstracting into
 * a wrapper means we don't need to repeat the logic
 * in multiple places
 */
function LocalStorageProvider({ children }) {
    const { details } = useDetails();

    const [instance, setInstance] = useState(
        LocalStorage.isInited() ? LocalStorage.instance() : null
    );

    const location = useLocation();

    /**
     * We want to initialise the LocalStorage instance
     * as soon as possible. Wait for user details to
     * be in the store (needed for LocalStorage constructor)
     * and then do this. Wrapper isn't ready until this is done
     */
    useEffect(() => {
        if (
            // ! Remove auth
            // !!details &&
            // details.userId &&
            !LocalStorage.isInited() &&
            !instance
        ) {
            LocalStorage.init(details);
            setInstance(LocalStorage.instance());
        }
    }, [location.pathname, instance]);
    // ! Remove auth 
    // }, [details, location.pathname, instance]);

    return (
        <LocalStorageContext.Provider value={instance}>
            {children}
        </LocalStorageContext.Provider>
    );
}

export default LocalStorageProvider;
