import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Form from "../../components/form";
import Field from "../../components/form/field";
import SubmitButton from "../../components/submit-button";
import { useAuth } from "../../providers/auth-provider";
import { getRegex, unexpectedErrorToast } from "../../utils";
import SubscriptionFormLogo from "../join/subscription-form-logo";

function ResetPassword(props) {
    return (
        <>
            <Helmet>
                <title>Reset Your Password — {process.env.RAZZLE_APPLICATION_NAME}</title>
                <meta
                    name="description"
                    content="If you have requested a password reset email from Andertons Extra, you can now set your new password here."
                />
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <div className="l-login">
                <ResetPasswordForm />
            </div>
        </>
    );
}

function ResetPasswordForm() {
    const { auth } = useAuth();

    const [submitting, setSubmitting] = useState(false);

    const location = useLocation();

    const query = queryString.parse(location.search);

    const history = useHistory();

    /**
     * User shouldn't be able to access this page
     * without a token and email param - just send
     * them to login
     */
    useEffect(() => {
        if (!query.token || !query.email) {
            history.push("/login");
        }
    }, []);

    const onSubmit = ({ email, password, token }) => {
        setSubmitting(true);

        auth.performPasswordReset(email, password, token)
            .then(() => {
                setSubmitting(false);
                history.push("/login?reset=true");
            })
            .catch((err) => {
                setSubmitting(false);

                if (err.response && err.response.status) {
                    switch (err.response.status) {
                        case 422:
                            toast(
                                "An error occurred. Please ensure you are using a new password which contains at least 6 charatcers and at least one letter and at least one number or symbol"
                            );
                            break;
                        /**
                         * Reset token is not valid,
                         * Reset token has expired,
                         * or Email does not match reset token
                         */
                        case 403:
                            toast(
                                "It looks like you're using an invalid password reset link. Please try requesting a new one."
                            );

                            history.push('/forgot-password');

                            break;

                        default:
                            console.error(
                                "Unexpected error with status",
                                err.response.status
                            );
                            unexpectedErrorToast("/join-help");
                            break;
                    }
                } else {
                    console.error("Unexpected error with no status", err);
                    unexpectedErrorToast("/join-help");
                }
            });
    };

    /* eslint-disable indent */
    return (
        <div className="l-login__wrapper">
            <div className="l-login__width">
                <SubscriptionFormLogo />

                <div className="l-login__form">
                    <h1 className="l-login__title">Reset Password</h1>

                    <Form
                        useFormParams={{
                            defaultValues: {
                                token: query.token,
                                email: query.email,
                            },
                        }}
                        onSubmit={onSubmit}
                    >
                        {({ watch, ...formMethods }) => (
                            <>
                                <Field
                                    watch={watch}
                                    {...formMethods}
                                    required
                                    disabled
                                    id="token"
                                    displayName="Token"
                                    validation={{
                                        required: "Token is required",
                                    }}
                                    type="hidden"
                                    name="token"
                                />

                                <Field
                                    watch={watch}
                                    {...formMethods}
                                    required
                                    disabled
                                    id="email"
                                    displayName="Email"
                                    validation={{
                                        required: "Email is required",
                                    }}
                                    type="hidden"
                                    name="email"
                                />

                                <Field
                                    watch={watch}
                                    {...formMethods}
                                    focusOnMount
                                    validation={{
                                        required: "Password is required",
                                        minLength: {
                                            value: 6,
                                            message:
                                                "Password must be at least 6 characters",
                                        },
                                        pattern: {
                                            value: getRegex("password"),
                                            message:
                                                "Password must be at least 6 characters and contain at least one letter and at least one number or symbol",
                                        },
                                    }}
                                    required
                                    id="password"
                                    type="password"
                                    name="password"
                                    displayName="Password"
                                />

                                <Field
                                    watch={watch}
                                    {...formMethods}
                                    validation={{
                                        required:
                                            "Password confirmation is required",
                                        validate: (value) =>
                                            value === watch("password") ||
                                            "Password confirmation must match password",
                                    }}
                                    required
                                    id="passwordConfirm"
                                    type="password"
                                    name="passwordConfirm"
                                    displayName="Confirm Password"
                                />

                                <SubmitButton loading={submitting}>
                                    Submit
                                </SubmitButton>
                            </>
                        )}
                    </Form>

                    <div className="o-actions">
                        <div>
                            <Link to="/login">Log in</Link>
                        </div>
                        <div>
                            <Link to="/join-help">Having Issues?</Link>
                        </div>
                    </div>
                </div>

                <p className="o-help">
                    New to andertons extra?{" "}
                    <Link to="/join"
                        className="o-help__link">
                        Sign Up Now
                    </Link>
                </p>
            </div>
        </div>
    );
    /* eslint-enable indent */
}

export default ResetPassword;
