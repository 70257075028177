import queryString from "query-string";
import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { Link, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Form from "../../../components/form";
import Field from "../../../components/form/field";
import SubmitButton from "../../../components/submit-button";
import AddressDetails from "../../../models/auth/address-details";
import CustomerDetails from "../../../models/auth/customer-details";
import User from "../../../models/auth/user";
import { useAuth, useDetails } from "../../../providers/auth-provider";
import { useSubscription } from "../../../providers/subscription-provider";
import { ExtraCookies, unexpectedErrorToast } from "../../../utils";
import Actions from "../common/actions";
import SubscriptionFormLogo from "../subscription-form-logo";

function ExistingCustomer() {
    const [, setCookie] = useCookies();

    const { auth, setToken, setAuthenticated } = useAuth();
    const {
        customerExistence,
        setAddressDetails,
        setCustomerDetails,
        setAuthenticatedCustomer,
    } = useSubscription();

    const { setDetails } = useDetails();

    const [submitting, setSubmitting] = useState(false);

    const location = useLocation();

    const query = queryString.parse(location.search, {
        arrayFormat: "bracket",
    });

    const history = useHistory();

    const onSubmit = ({ email, password }) => {
        setSubmitting(true);

        return auth
            .signIn(email, password, {
                "check-email": true,
            })
            .then((res) => {
                if (res.hasToken()) {
                    /**
                     * Customer already subscribed
                     */
                    toast(
                        "It looks like you're already subscribed! Logging you in..."
                    );
                }

                if (res.hasToken()) {
                    /**
                     * Customer already subscribed
                     */
                    setCookie(
                        ExtraCookies.getWrappedKey("user_token"),
                        res.token,
                        {
                            path: "/",
                            maxAge:
                                process.env
                                    .RAZZLE_USER_COOKIE_MAX_AGE_SECONDS,
                        }
                    );
                    setToken(res.token);

                    const {
                        userId,
                        email,
                        title,
                        firstName,
                        lastName,
                    } = res.details();

                    setCookie(
                        ExtraCookies.getWrappedKey("user_id"),
                        userId,
                        {
                            path: "/",
                            maxAge:
                                process.env
                                    .RAZZLE_USER_COOKIE_MAX_AGE_SECONDS,
                        }
                    );
                    setCookie(
                        ExtraCookies.getWrappedKey("user_email"),
                        email,
                        {
                            path: "/",
                            maxAge:
                                process.env
                                    .RAZZLE_USER_COOKIE_MAX_AGE_SECONDS,
                        }
                    );
                    setCookie(
                        ExtraCookies.getWrappedKey("user_title"),
                        title,
                        {
                            path: "/",
                            maxAge:
                                process.env
                                    .RAZZLE_USER_COOKIE_MAX_AGE_SECONDS,
                        }
                    );
                    setCookie(
                        ExtraCookies.getWrappedKey("user_first_name"),
                        firstName,
                        {
                            path: "/",
                            maxAge:
                                process.env
                                    .RAZZLE_USER_COOKIE_MAX_AGE_SECONDS,
                        }
                    );
                    setCookie(
                        ExtraCookies.getWrappedKey("user_last_name"),
                        lastName,
                        {
                            path: "/",
                            maxAge:
                                process.env
                                    .RAZZLE_USER_COOKIE_MAX_AGE_SECONDS,
                        }
                    );

                    setDetails({
                        userId,
                        email,
                        title,
                        firstName,
                        lastName,
                    });

                    setSubmitting(false);

                    return setAuthenticated(true);
                } else {
                    throw new Error(
                        "Unexpected authentication response. Expected error or token, but received success and no token"
                    );
                }
            })
            .catch((err) => {
                setSubmitting(false);

                if (err.response && err.response.status) {
                    switch (err.response.status) {
                        /**
                         * Customer with this email exists in
                         * eCommerce, but the password is wrong
                         */
                        case 401:
                            toast(
                                "The provided credentials don't match our records"
                            );
                            break;

                        /**
                         * Customer exists in commerce and password
                         * is correct, but not subscribed
                         */
                        case 403:
                            const user = new User(err.response.data.data);

                            setAuthenticatedCustomer(user);

                            setAddressDetails(
                                new AddressDetails(user.address())
                            );

                            setCustomerDetails(
                                new CustomerDetails({
                                    password,
                                    ...user.details(),
                                })
                            );

                            history.push("/join/billing-term");

                            break;

                        default:
                            console.error(
                                "Unexpected authentication failure with status",
                                err.response.status
                            );
                            unexpectedErrorToast("/join-help");
                            break;
                    }
                } else {
                    console.error(
                        "Unexpected authentication failure with no status",
                        err
                    );
                    unexpectedErrorToast("/join-help");
                }
            });
    };

    /* eslint-disable indent */
    return (
        <div className="l-login__wrapper">
            <div className="l-login__width">
                <SubscriptionFormLogo />

                <div className="l-login__form">
                    <h1 className="l-login__title">Existing Account</h1>

                    {!!query.direct && query.direct === "true" ? (
                        <>
                            <p>Welcome back!</p>
                            <p>
                                Please enter your Andertons password to proceed
                                with the Andertons Extra subscription process.
                            </p>
                        </>
                    ) : (
                            <>
                                <p>
                                    Good news! It looks like you already have an
                                    Andertons account.
                            </p>
                                <p>
                                    Please enter your password to proceed with the
                                    Andertons Extra subscription process.
                            </p>
                            </>
                        )}

                    <Form
                        className="c-form t-existing-customer-form"
                        useFormParams={{
                            defaultValues: {
                                email:
                                    customerExistence && customerExistence.email
                                        ? customerExistence.email
                                        : "",
                            },
                        }}
                        onSubmit={onSubmit}
                    >
                        {(formMethods) => (
                            <>
                                <Field
                                    {...formMethods}
                                    disabled
                                    validation={{
                                        required: "Email is required",
                                    }}
                                    required
                                    id="email"
                                    type="email"
                                    name="email"
                                    displayName="Email"
                                />
                                <Field
                                    {...formMethods}
                                    disabled={submitting}
                                    focusOnMount
                                    validation={{
                                        required: "Password is required",
                                    }}
                                    required
                                    id="password"
                                    type="password"
                                    name="password"
                                    displayName="Password"
                                />
                                <SubmitButton loading={submitting}>
                                    Submit
                                </SubmitButton>
                            </>
                        )}
                    </Form>

                    <div className="o-actions">
                        <Actions />
                        <div>
                            <Link to="/forgot-password">
                                Forgot Password
                            </Link>
                        </div>
                    </div>
                </div>

                <p className="o-help">
                    {/* Already Subscribed?{" "}
                    <Link to="/login"
                        className="o-help__link">
                        Login Now
                    </Link> */}
                </p>
            </div>
        </div>
    );
    /* eslint-enable indent */
}

export default ExistingCustomer;
