import React from "react";

import { Accordion } from "react-accessible-accordion";

import { Link } from "react-router-dom";

import DynamicComponent from "../dynamic-component/DynamicComponent";

const FAQWrapper = ({ blok }) => {
    return (
        <div className="service-proposition-section-4">
            <h2 className="service-proposition-section-4__title">
                Frequently Asked Questions
            </h2>
            <Accordion allowZeroExpanded allowMultipleExpanded>
                {blok.item.map((blok) => (
                    <div key={blok._uid}>
                        <DynamicComponent blok={blok} key={blok._uid} />
                    </div>
                ))}
            </Accordion>
            <Link
                to="/join"
                className="t-service-proposition-cta btn btn--primary btn--full"
            >
                Sign Up Now and Start Watching
            </Link>
        </div>
    );
};

export default FAQWrapper;
