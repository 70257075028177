import React from "react";

import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";

import { useStoryblok } from "../../storyblok-client";
import DynamicComponent from "../../components/dynamic-component/DynamicComponent";

import SbEditable from "storyblok-react";

function ServiceProposition({ routeData: { story: originalStory } }) {
    const location = useLocation();
    const slug = location.pathname;

    const preview = process.env.NODE_ENV === "development";
    const { story, loading } = useStoryblok(slug, originalStory, preview);

    if (loading) {
        return <div>Loading....</div>;
    }

    return (
        <>
            <Helmet>
                <title>
                    Andertons Extra: More Videos, More Deals, No Ads, Just
                    £2.99!
                </title>
                <meta
                    name="description"
                    content="Subscribe to Andertons Extra for ten years of the best videos for musicians, completely ad free, exclusive content & deals. Just £2.99/$3.99/€3,49 per month."
                />
                {/* Meta description is deliberately not drawn from the default so it can be carefully curated. This is a key SEO page. */}
            </Helmet>

            {story && (
                <SbEditable content={story}>
                    {Array.isArray(story.content.body) ? (
                        story.content.body.map((blok) => (
                            <DynamicComponent blok={blok} key={blok._uid} />
                        ))
                    ) : (
                        <div>Missing Content</div>
                    )}
                </SbEditable>
            )}
        </>
    );
}

export default ServiceProposition;
