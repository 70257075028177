import React from "react";
import Slider from "react-slick";
import CarouselItem from "./carousel-item";


function Carousel({ children, ...props }) {
    return (
        <Slider {...props}>
            {children.map((child, index) => (
                <CarouselItem key={index}>{child}</CarouselItem>
            ))}
        </Slider>
    );
}

Carousel.defaultProps = {
    className: "o-carousel",
    adaptiveHeight: false,
    arrows: true,
    draggable: false,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 6,
    variableWidth: false,
    centerMode: false,
    centerPadding: 0,
    speed: 500,
    swipeToSlide: true,
    responsive: [
        {
            breakpoint: 1920,
            settings: {
                arrows: true,
                slidesToShow: 6,
            },
        },
        {
            breakpoint: 1200,
            settings: {
                arrows: true,
                slidesToShow: 5,
            },
        },
        {
            breakpoint: 992,
            settings: {
                arrows: true,
                slidesToShow: 4,
            },
        },
        {
            breakpoint: 768,
            settings: {
                arrows: true,
                slidesToShow: 3,
            },
        },
        {
            breakpoint: 576,
            settings: {
                arrows: true,
                slidesToShow: 2,
            },
        },
    ],
};

export default Carousel;
