import React, { useEffect, useState } from 'react';
import { useNotice } from '../../providers/notice-provider';

export default function Notice({ children, className, dismissable, hash: providedHash, dismissForSeconds, ...props }) {

    const { addNotice, dismissNotice, noticeIsDismissed } = useNotice();

    const [hash, setHash] = useState(providedHash);

    const [registered, setRegistered] = useState(false);

    useEffect(() => {
        if (!providedHash) {
            setHash(addNotice(children));
        } else {
            addNotice(children, hash)
        }

        setRegistered(true);
    }, [children]);

    return (dismissable && hash && !noticeIsDismissed(hash)) || !dismissable ? <div className={`t-notice ${className}`} {...props}>
        {
            dismissable && registered && <button className="t-notice-close-button c-beta__close" onClick={() => dismissNotice(hash, dismissForSeconds)}>X</button>
        }
        <p>
            {
                children
            }
        </p>
    </div> : null
}

Notice.defaultProps = {
    className: "c-beta",
    dismissable: false,
    hash: null,
    dismissForSeconds: 86400,
}
