import React from "react";
import GlobalLoadingIcon from "../../components/global-loading-icon";
import { useCategory } from "../../providers/resource-provider";
import VideoGridCategory from "../video-grid-category";

function IntermediateCategory(props) {
    const { category } = useCategory();

    if (category && category.is_bottom_level === false) {
        return <GlobalLoadingIcon />;
    } else {
        return <VideoGridCategory {...props} />;
    }
}

export default IntermediateCategory;
