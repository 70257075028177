import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import ErrorBoundary from "../../components/error-boundary";
import ErrorMessage from "../../components/error-message";
import LoadingIcon from "../../components/loading-icon";
import SidebarLayout from "../../components/sidebar-layout";
import SubmitButton from "../../components/submit-button";
import ApiClient from "../../models/api-client";
import { useAuth, useDetails } from "../../providers/auth-provider";
import { useSubscription } from "../../providers/subscription-provider";
import { dateToFriendly, useAjaxEffect } from "../../utils";

function ManageSubscription(props) {
    return (
        <>
            <Helmet>
                <title>
                    Manage Your Subscription —{" "}
                    {process.env.RAZZLE_APPLICATION_NAME}
                </title>
                <meta
                    name="description"
                    content="This is where you can manage your subscription to Andertons Extra including updating your payment method or cancelling your membership."
                />
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <ErrorBoundary
                tags={{
                    page: "manage subscription",
                    section: "page wrapper",
                }}
                fallback={() => (
                    <ErrorMessage message="Could not display manage subscription page" />
                )}
            >
                <SidebarLayout
                    myAccount
                    communicationPreferences
                    extraBenefits
                    manageSubscription
                    contact
                >
                    <h1>Manage Subscription</h1>
                    <ErrorBoundary
                        tags={{
                            page: "manage subscription",
                            section: "subscription management options",
                        }}
                        fallback={() => (
                            <ErrorMessage message="Could not display subscription management options" />
                        )}
                    >
                        <SubscriptionManagement />
                    </ErrorBoundary>
                </SidebarLayout>
            </ErrorBoundary>
        </>
    );
}

export default ManageSubscription;

function SubscriptionManagement(props) {
    const { subscriptionDetails, setSubscriptionDetails } = useSubscription();
    const { auth, token, handle401 } = useAuth();
    const {
        details: { userId },
    } = useDetails();

    const [loading, setLoading] = useState(true);

    const [showConfirm, setShowConfirm] = useState(false);

    const [cancellationPending, setCancellationPending] = useState(false);

    const [creatingPortalSession, setCreatingPortalSession] = useState(false);

    const location = useLocation();
    const history = useHistory();

    const query = queryString.parse(location.search, {
        arrayFormat: "bracket",
    });

    /**
     * If entering this page with the query string
     * param show-payment-sources-modal eval'ing to
     * true, wait until page is loaded and then
     * open modal for payment source update.
     *
     * This will be used when directing from
     * failed payment email
     */
    useEffect(() => {
        if (
            !loading &&
            !creatingPortalSession &&
            query["show-payment-sources-modal"]
        ) {
            createPortalSession();
            history.push(location.pathname);
        }
    }, [loading]);

    useAjaxEffect({
        handle401,

        tags: {
            component: "ManageSubscription",
            action: "retrieve customer subscription details",
        },

        request: (cancelToken) => {
            auth.setCancelToken(cancelToken);

            return auth.setToken(token).getSubscriptionDetails(userId);
        },

        onSuccess: (data, mounted) => {
            if (mounted) {
                setSubscriptionDetails(data);
                setLoading(false);
            }
        },

        onError: (err, mounted) => {
            if (mounted) {
                console.log(err);
                setLoading(false);
            }
        },

        watch: [],

        cancelMessage:
            "Subscription data retrieval cancelled due to component unmount",
    });

    const cancel = () => {
        setCancellationPending(true);
        auth.setToken(token)
            .cancelSubscription(userId)
            .then((res) => {
                setSubscriptionDetails(res);
                setCancellationPending(false);
                toast("Your subscription renewal has been cancelled");
                setShowConfirm(false);
            })
            .catch((err) => {
                if (ApiClient.isStatus(err, 401)) {
                    handle401();
                }
                toast("An error occurred");
                setCancellationPending(false);
                setShowConfirm(false);
            });
    };

    const createPortalSession = () => {
        setCreatingPortalSession(true);

        const chargebeeInstance = window.Chargebee.getInstance();

        chargebeeInstance.setPortalSession(() =>
            auth.setToken(token).createCustomerPortalSession(userId)
        );

        const chargebeePortalInstance = chargebeeInstance.createChargebeePortal();

        chargebeePortalInstance.openSection(
            {
                sectionType: window.Chargebee.getPortalSections()
                    .PAYMENT_SOURCES,
            },
            {
                close: () => {
                    chargebeeInstance.logout();
                    setCreatingPortalSession(false);
                },
                loaded: () => setCreatingPortalSession(false),
            }
        );
    };

    // TODO - Stephen to remove inline styles
    if (loading) {
        return (
            <div style={{ textAlign: "center", paddingTop: "20px" }}>
                <LoadingIcon />
            </div>
        );
    }

    return (
        <>
            <ErrorBoundary
                tags={{
                    page: "manage subscription",
                    section: "subscription details",
                }}
                fallback={() => (
                    <ErrorMessage message="Could not display subscription details" />
                )}
            >
                <SubscriptionDetails />
            </ErrorBoundary>
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                {!showConfirm && (
                    <SubmitButton
                        staticClasses="btn btn--primary t-manage-payment-details-button"
                        style={{ flex: "1" }}
                        onClick={createPortalSession}
                        loading={creatingPortalSession}
                    >
                        Manage Payment Details
                    </SubmitButton>
                )}
                {cancellationPending && <LoadingIcon />}
                {!cancellationPending &&
                !showConfirm &&
                subscriptionDetails.isActive() ? (
                    <button
                        style={{ flex: "1", backgroundColor: "#1f1f1f" }}
                        className="btn btn--primary"
                        onClick={() => setShowConfirm(true)}
                    >
                        Cancel Subscription
                    </button>
                ) : null}
                {!cancellationPending &&
                    showConfirm &&
                    subscriptionDetails.isActive() && (
                        <div>
                            <p>{`This will stop your subscription renewing on ${subscriptionDetails.subscription.next_billing_date}. After this date, you'll no longer be able to log into Andertons Extra.`}</p>

                            <p>Are you sure you'd like to proceed?</p>

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                }}
                            >
                                <button
                                    style={{ flex: "1" }}
                                    disabled={cancellationPending}
                                    className="btn btn--primary"
                                    onClick={() => setShowConfirm(false)}
                                >
                                    I've changed my mind
                                </button>
                                <SubmitButton
                                    style={{
                                        flex: "1",
                                        backgroundColor: "#1f1f1f",
                                    }}
                                    loading={cancellationPending}
                                    onClick={cancel}
                                    type="button"
                                >
                                    Cancel my subscription
                                </SubmitButton>
                            </div>
                        </div>
                    )}
            </div>
        </>
    );
}

function SubscriptionDetails(props) {
    const { subscriptionDetails: details } = useSubscription();

    const isCancelledTrialPeriod =
        details.subscription.next_billing_date == "01-01-1970" &&
        details.subscription.status === "in_trial";

    const isActiveSub = !!(
        details.subscription.status === "active" ||
        details.subscription.status === "in_trial"
    );

    // TODO - Stephen to remove inline styling
    return (
        <table style={{ marginBottom: "24px" }}>
            <thead>
                <tr>
                    <th
                        style={{
                            padding: "10px 0 10px 5px",
                            backgroundColor: "#D6001C",
                            textAlign: "center",
                        }}
                        colSpan="2"
                    >
                        {details.plan.name}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style={{ width: "25%", padding: "10px 0 10px 5px" }}>
                        Plan Price
                    </td>
                    <td style={{ width: "75%", padding: "10px 0 10px 5px" }}>
                        {details.plan.invoice_name}
                    </td>
                </tr>
                <tr>
                    <td style={{ width: "25%", padding: "10px 0 10px 5px" }}>
                        Plan Description
                    </td>
                    <td style={{ width: "75%", padding: "10px 0 10px 5px" }}>
                        {details.plan.description}
                    </td>
                </tr>
                <tr>
                    <td style={{ width: "25%", padding: "10px 0 10px 5px" }}>
                        Plan Start Date
                    </td>
                    <td style={{ width: "75%", padding: "10px 0 10px 5px" }}>
                        {dateToFriendly(details.subscription.start_date)}
                    </td>
                </tr>
                <tr>
                    <td style={{ width: "25%", padding: "10px 0 10px 5px" }}>
                        Last Payment Date
                    </td>
                    <td style={{ width: "75%", padding: "10px 0 10px 5px" }}>
                        {/* small hack to manage trial payment dates */}
                        {details.subscription.current_term_start ===
                        "01-01-1970"
                            ? "No payment taken yet!"
                            : dateToFriendly(
                                  details.subscription.current_term_start
                              )}
                    </td>
                </tr>
                <tr>
                    <td style={{ width: "25%", padding: "10px 0 10px 5px" }}>
                        {isActiveSub || isCancelledTrialPeriod
                            ? "Next Payment Date"
                            : "Subscription End Date"}
                    </td>
                    <td style={{ width: "75%", padding: "10px 0 10px 5px" }}>
                        {isCancelledTrialPeriod
                            ? "No further payments - subscription cancelled"
                            : isActiveSub
                            ? dateToFriendly(
                                  details.subscription.next_billing_date
                              )
                            : dateToFriendly(
                                  details.subscription.current_term_end
                              )}
                    </td>
                </tr>
            </tbody>
        </table>
    );
}
