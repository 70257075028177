export default [
    '/login',
    '/join',
    '/join/new-customer',
    '/join/existing-customer',
    '/join/address-details',
    '/join/billing-term',
    '/join/billing-details',
    '/forgot-password'
];
