import React, { createContext, useContext, useEffect, useState } from "react";
import Resource from "../../models/resource";
import Pagination from "../../models/resource/pagination";
import AuthHoldingPage from "../../pages/auth-holding-page";
import { scrollToTop } from "../../utils";
import { useAuth } from "../auth-provider";

const ResourceContext = createContext(null);
const PaginationContext = createContext(null);
const GlobalVideoContext = createContext(null);
const GlobalCategoryContext = createContext(null);
const CarouselsContext = createContext(null);
const GlobalPlaylistContext = createContext(null);

export const useCarousels = () => useContext(CarouselsContext);

export const useResource = () => useContext(ResourceContext);

export const usePagination = () => useContext(PaginationContext);

export const useVideo = () => useContext(GlobalVideoContext);

export const useCategory = () => useContext(GlobalCategoryContext);

export const usePlaylist = () => useContext(GlobalPlaylistContext);

function ResourceProvider({ children, routeData: { video: initVideo, pagination: initPagination, category: initCategory, playlist: initPlaylist } }) {
    const { token, authenticated } = useAuth();

    /**
     * TODO -
     * Rather than returning the same resource instance
     * which has to be reset after each request,
     * it might be better to use a getter method
     * to create and return a request instance
     */
    const [resource, setResource] = useState(
        authenticated ? new Resource({ token }) : null
    );

    useEffect(() => {
        if (authenticated && !resource) {
            setResource(
                new Resource({
                    token,
                })
            );
        } else if (!authenticated && !!resource) {
            setResource(null);
        }
    }, [authenticated]);

    /**
     * We're going to have an app level
     * pagination resource.
     * 
     * This will allow components to
     * ignore hierarchy when retrieving
     * pagination info
     */
    const [pagination] = useState(initPagination ?? new Pagination({
        total_items: 0,
        number_of_pages: 1,
        page_size: 1,
    }));

    /**
     * We're going to have an app level
     * playlist resource. There's only ever
     * one playlist being viewed at a time.
     * This will allow for much quicker
     * loading where the user is clicking
     * to load the page for a playlist
     * we already have the data for
     */
    const [playlist, setPlaylist] = useState(initPlaylist ?? null);

    const handlePlaylistClick = (playlist) => {
        setPlaylist(playlist);
        scrollToTop();
    };

    /**
     * We're going to have an app level
     * video resource. There's only ever
     * one video being watched at a time.
     * This will allow for much quicker
     * loading where the user is clicking
     * to load the watch page for a video
     * we already have the data for
     */
    const [video, setVideo] = useState(initVideo ?? null);

    const handleVideoClick = (video) => {
        setVideo(video);
        scrollToTop();
    };

    /**
     * We're going to have an app level
     * category resource. There's only ever
     * one category being viewed at a time.
     * This will allow for much quicker
     * loading where the user is clicking
     * to load the watch page for a category
     * we already have the data for
     */
    const [category, setCategory] = useState(initCategory ?? null);

    const handleCategoryClick = (category) => {
        console.log('handleCategoryClick', category)

        setCategory(category);
        scrollToTop();
    };

    const globalVideo = {
        video,
        handleVideoClick,
    };

    const globalPlaylist = {
        playlist,
        handlePlaylistClick,
    };

    const globalCategory = {
        category,
        handleCategoryClick,
    };

    /**
     * Global store for various carousel videos.
     * Stops the need for a new request every time the component
     * is recreated.
     */
    const [recentlyAddedVideos, setRecentlyAddedVideos] = useState(null);
    const [trendingNowVideos, setTrendingNowVideos] = useState(null);
    const [presenters, setPresenters] = useState(null);

    const carouselsValues = {
        recentlyAdded: {
            recentlyAddedVideos,
            setRecentlyAddedVideos,
        },
        trendingNow: {
            trendingNowVideos,
            setTrendingNowVideos,
        },
        presenters: {
            presenters,
            setPresenters,
        },
    };

    return (
        <PaginationContext.Provider value={pagination}>
            <ResourceContext.Provider value={{ resource, setResource }}>
                <GlobalVideoContext.Provider value={globalVideo}>
                    <GlobalCategoryContext.Provider value={globalCategory}>
                        <GlobalPlaylistContext.Provider value={globalPlaylist}>
                            <CarouselsContext.Provider value={carouselsValues}>
                                {authenticated && !resource ? (
                                    <div className="content-expand">
                                        <AuthHoldingPage redirect={false} />
                                    </div>
                                ) : (
                                        children
                                    )}
                            </CarouselsContext.Provider>
                        </GlobalPlaylistContext.Provider>
                    </GlobalCategoryContext.Provider>
                </GlobalVideoContext.Provider>
            </ResourceContext.Provider>
        </PaginationContext.Provider>
    );
}

export default ResourceProvider;
