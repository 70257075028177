import React, { useState } from "react";
import Carousel from "../carousel";
import LoadingIcon from "../loading-icon";
import { useResource } from "../../providers/resource-provider";
import { useLocalStorage } from "../../providers/local-storage-provider";
import { useAjaxEffect } from "../../utils";
import VideoThumbnailSet from "../video-thumbnail-set";
import CarouselVideoThumbnail from "../video-thumbnail-set/carousel-video-thumbnail";
import { useAuth } from "../../providers/auth-provider";

function YouMightAlsoLikeCarousel(props) {
    const aexLocalStorage = useLocalStorage();
    const { resource } = useResource();

    const [loading, setLoading] = useState(false);
    const [videos, setVideos] = useState([]);

    const youMightAlsoLike = aexLocalStorage
        ? aexLocalStorage.getYouMightAlsoLike()
        : [];

    const { handle401 } = useAuth();

    useAjaxEffect({
        handle401,

        tags: {
            component: "YouMightAlsoLikeCarousel",
            action: "retrieve you might also like videos",
        },

        requestRequired: () => youMightAlsoLike.length > 0,

        request: (cancelToken) => {
            setLoading(true);

            resource.setCancelToken(cancelToken);

            return resource.getVideosByIds(youMightAlsoLike);
        },

        onSuccess: (data, mounted) => {
            if (mounted) {
                setVideos(data);
                setLoading(false);
            }
        },

        onError: (err, mounted) => {
            if (mounted) {
                console.log(err);
                setLoading(false);
            }
        },

        watch: [aexLocalStorage],

        cancelMessage:
            "You might also like carousel data retrieval cancelled due to component unmount",
    });

    // TODO - Stephen to check CSS
    if (loading) {
        return (
            <div className="o-section o-section--loading">
                <LoadingIcon />
            </div>
        );
    }

    return (
        videos.length > 0 && (
            <>
                <div className="o-section">
                    <div className="o-section--wrapper">
                        <div className="o-section--left">
                            <h2 className="o-section__title">
                                You Might Also Like
                            </h2>
                        </div>
                    </div>
                    {!loading ? (
                        <VideoThumbnailSet videos={videos}>
                            {({ videos }) => (
                                <Carousel>
                                    {videos.map((video) => (
                                        <CarouselVideoThumbnail
                                            key={video.id}
                                            video={video}
                                        />
                                    ))}
                                </Carousel>
                            )}
                        </VideoThumbnailSet>
                    ) : (
                            <Carousel>{[]}</Carousel>
                        )}
                </div>
            </>
        )
    );
}

export default YouMightAlsoLikeCarousel;
