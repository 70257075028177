import React, { createContext, useContext, useReducer } from "react";
import { useCookies } from "react-cookie";
import { ExtraCookies, getHashFromString } from "../../utils";

const NoticeContext = createContext(null);

export const useNotice = () => useContext(NoticeContext);

const initialState = {}

const reducer = (notices, action) => {
    return { ...notices, [action.hash]: action.notice };
}

function NoticeProvider({ children }) {

    const [notices, dispatch] = useReducer(reducer, initialState);

    const [cookies, setCookie] = useCookies();

    const getHash = string => getHashFromString(string);

    const addNotice = (content, hash = null) => {
        hash = hash ?? getHash(content);

        dispatch({
            hash,
            notice: {
                content,
            }
        })

        return hash;
    };

    const noticeIsDismissed = hash => {
        return !!cookies[ExtraCookies.getWrappedKey(`dismiss_notice_${hash}`)];
    }

    const dismissNotice = (hash, maxAge = 86400) => {
        setCookie(ExtraCookies.getWrappedKey(`dismiss_notice_${hash}`), true, {
            path: "/",
            maxAge
        })
    };

    const value = {
        notices,
        addNotice,
        dismissNotice,
        noticeIsDismissed,
    };

    return (
        <NoticeContext.Provider value={value}>
            {children}
        </NoticeContext.Provider>
    );
}

export default NoticeProvider;
