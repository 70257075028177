import React from "react";

import { render } from "storyblok-rich-text-react-renderer";

import Carousel from "../../components/carousel";

const TestimonialCarousel = ({ blok }) => {
    return (
        <div className="service-proposition-section-3">
            <div className="service-proposition-section-3__width">
                <Carousel slidesToShow={1} responsive={null}>
                    {blok?.content1 && <div>{render(blok.content1)}</div>}
                    {blok?.content2 && <div>{render(blok.content2)}</div>}
                    {blok?.content3 && <div>{render(blok.content3)}</div>}
                    {blok?.content4 && <div>{render(blok.content4)}</div>}
                </Carousel>
            </div>
        </div>
    );
};

export default TestimonialCarousel;
