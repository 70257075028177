import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

function SentryHook() {
    return {
        effect: () => {
            Sentry.init({
                dsn: process.env.RAZZLE_SENTRY_DSN,
                autoSessionTracking: true,
                integrations: [
                    new Integrations.BrowserTracing(),
                ],
                tracesSampleRate: parseFloat(process.env.RAZZLE_SENTRY_TRACES_SAMPLE_RATE),
            });
        },
        watch: [],
    };
}

export default SentryHook;
