import React from "react";
import { useAuth } from "../../providers/auth-provider";
import AuthenticatedNavbar from "./authenticated-navbar";
import HoldingNavbar from "./holding-navbar";
import UnauthenticatedNavbar from "./unauthenticated-navbar";

function Navbar(props) {
    const { authenticated } = useAuth();

    if (authenticated === null) {
        return <HoldingNavbar />;
    }

    return authenticated ? (
        <AuthenticatedNavbar {...props} />
    ) : (
            <UnauthenticatedNavbar />
        );
}

export default Navbar;
