import React, { useEffect, useRef } from "react";

export default function Text({
    watch,
    register,
    validation,
    name,
    className,
    children,
    focusOnMount,
    ...rest
}) {
    const ref = useRef();

    const empty = !watch(name);

    useEffect(() => {
        if (focusOnMount) {
            ref.current.focus();
        }
    }, [focusOnMount]);

    return (
        <input
            name={name}
            className={`${className} ${empty ? "is-empty" : ""}`}
            ref={(e) => {
                register(e, validation);

                ref.current = e;
            }}
            {...rest}
        />
    );
}

Text.defaultProps = {
    className: "",
    validation: {},
    focusOnMount: false,
};
