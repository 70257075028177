import * as Sentry from "@sentry/react";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ErrorBoundary from "../../components/error-boundary";
import ErrorMessage from "../../components/error-message";
import Form from "../../components/form";
import Field from "../../components/form/field";
import TextArea from "../../components/form/textarea";
import SidebarLayout from "../../components/sidebar-layout";
import SubmitButton from "../../components/submit-button";
import { useAuth } from "../../providers/auth-provider";

function JoinHelp(props) {
    return (
        <>
            <Helmet>
                <title>Issues Joining Andertons Extra? — {process.env.RAZZLE_APPLICATION_NAME}</title>
                <meta
                    name="description"
                    content="If you're having difficulty signing up to Andertons Extra please let us know. One of our team will look into the problem. Subscribe from just £2.99/$3.99/€3,49."
                />
            </Helmet>

            <SidebarLayout legal copyright termsAndConditions joinHelp>
                <h1>Having Issues Joining Andertons Extra?</h1>
                <ErrorBoundary
                    tags={{
                        page: "join help",
                        section: "join help form",
                    }}
                    fallback={() => (
                        <ErrorMessage message="Could not display contact form" />
                    )}
                >
                    <JoinHelpForm />
                </ErrorBoundary>
            </SidebarLayout>
        </>
    );
}

export default JoinHelp;

function JoinHelpForm() {
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [createdId, setCreatedId] = useState(false);

    const { auth } = useAuth();

    const onSubmit = (data) => {
        setSubmitting(true);

        auth.createSignupIssueTicket(data)
            .then((ticket) => {
                setCreatedId(ticket.id);
                setSubmitting(false);
                setSubmitted(true);
            })
            .catch((err) => {
                Sentry.captureException(err, {
                    tags: {
                        page: "join help",
                        action: "join help form submission",
                    },
                });
                toast("An error occurred when submitting the form");
                setSubmitting(false);
            });
    };

    return (
        <>
            {!submitted && (
                <>
                  <p>
                      Please only fill in this form if you are having trouble
                      subscribing to Andertons Extra.
                  </p>
                  <p>
                      For all other enquiries, please{" "}
                      <a href="https://help.andertons.co.uk" target="_blank">
                          visit our help centre
                      </a>
                      .
                  </p>
                  <Form className="o-form" onSubmit={onSubmit}>
                      {(formMethods) => (
                          <>
                              <Field
                                  {...formMethods}
                                  placeholder="Your Email Address"
                                  className="o-form__field"
                                  labelPosition="above"
                                  disabled={submitting}
                                  validation={{
                                      required: "Your email address is required",
                                  }}
                                  required
                                  focusOnMount
                                  id="email"
                                  type="email"
                                  name="email"
                                  displayName="Email"
                              />

                              <Field
                                  {...formMethods}
                                  placeholder="Your Name"
                                  className="o-form__field"
                                  labelPosition="above"
                                  errorPosition="below"
                                  disabled={submitting}
                                  validation={{
                                      required: "Your name is required",
                                      maxLength: {
                                          message:
                                              "Name must be 128 characters or fewer",
                                          value: 128,
                                      },
                                  }}
                                  required
                                  id="name"
                                  type="text"
                                  name="name"
                                  displayName="Name"
                              />

                              <Field
                                  {...formMethods}
                                  className="o-form__field"
                                  placeholder="e.g. Secure payment gateway won't load"
                                  labelPosition="above"
                                  disabled={submitting}
                                  validation={{
                                      required: "Summary is required",
                                      maxLength: {
                                          message:
                                              "Summary must be 128 characters or fewer",
                                          value: 128,
                                      },
                                  }}
                                  required
                                  id="subject"
                                  type="text"
                                  name="subject"
                                  displayName="A brief Summary of the Issue"
                              />

                              <Field
                                  {...formMethods}
                                  className="o-form__field"
                                  labelPosition="above"
                                  placeholder="e.g. When the page says 'Loading our secure payment gateway', nothing happens."
                                  is={TextArea}
                                  disabled={submitting}
                                  required
                                  validation={{
                                      required: "Description is required",
                                      maxLength: {
                                          message:
                                              "Description must be 1024 characters or fewer",
                                          value: 1024,
                                      },
                                  }}
                                  id="message"
                                  name="message"
                                  displayName="Description of Issue"
                              >
                                  <p>
                                      Please give as many details as possible
                                      about the issue you are experiencing. It
                                      would be useful to know the exact steps
                                      you followed, which device and web
                                      browser you were using, and what
                                      time/date it was when you experienced the
                                      issues.
                                  </p>
                              </Field>

                              <SubmitButton
                                  staticClasses="btn btn--primary btn--full"
                                  loading={submitting}
                              >
                                  Submit
                              </SubmitButton>
                          </>
                      )}
                  </Form>
                </>
            )}

            {
                // TODO - Stephen to remove inline styles
                submitted && (
                    <div style={{ marginBottom: "24px" }}>
                        <p className="t-contact-form-confirmation-ticket-id">{`Thanks for submitting your information. A ticket with the ID: ${createdId} has been created.`}</p>
                        <p>
                            We'll look into the issue and be in touch if any
                            further information is required.
                        </p>
                        <button
                            className="btn btn--primary btn--full"
                            onClick={() =>
                                setSubmitted(false) && setCreatedId(false)
                            }
                        >
                            Report another problem?
                        </button>
                    </div>
                )
            }

            <p>
                By submitting this form, you are agreeing to our{" "}
                <Link to="/terms">Terms & Conditions of use.</Link>
            </p>
        </>
    );
}
