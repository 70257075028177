import React from "react";
import PropTypes from "prop-types";
import ServicePropositionHero from "../service-proposition-hero";
import ServicePropositionButton from "../service-proposition-button";
import ServicePropositionBannerLeft from "../service-proposition-banner";
import ServicePropositionVideo from "../service-proposition-video";
import ServicePropositionBannerRight from "../service-proposition-banner/variants";
import RichText from "../richtext";
import BannerGrid from "../bannergrid";
import Image from "../image";
import TestimonialCarousel from "../testimonial-carousel";
import FAQWrapper from "../faq-wrapper";
import FAQItem from "../faq-item";

const Components = {
    servicebannerleft: ServicePropositionBannerLeft,
    servicebannerright: ServicePropositionBannerRight,
    richtext: RichText,
    bannergrid: BannerGrid,
    image: Image,
    servicehero: ServicePropositionHero,
    servicebutton: ServicePropositionButton,
    servicevideo: ServicePropositionVideo,
    testimonialcarousel: TestimonialCarousel,
    faqwrapper: FAQWrapper,
    faqitem: FAQItem,
};

const DynamicComponent = ({ blok }) => {
    if (typeof Components[blok.component] !== "undefined") {
        const Component = Components[blok.component];
        return <Component blok={blok} />;
    }
    return <div>Placeholder</div>;
};

DynamicComponent.propTypes = {
    blok: PropTypes.any,
};

export default DynamicComponent;
