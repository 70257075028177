import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Form from "../../components/form";
import Field from "../../components/form/field";
import SubmitButton from "../../components/submit-button";
import { useAuth } from "../../providers/auth-provider";
import SubscriptionFormLogo from "../join/subscription-form-logo";

function ForgotPassword(props) {
    return (
        <>
            <Helmet>
                <title>Reset Your Password — {process.env.RAZZLE_APPLICATION_NAME}</title>
                <meta
                    name="description"
                    content="Extra forgetful? No worries! Reset your Andertons account password here in just two easy steps. Join Andertons Extra today for ad free Andertons content & more!"
                />
            </Helmet>
            <div className="l-login">
                <ForgotPasswordForm />
            </div>
        </>
    );
}

function ForgotPasswordForm() {
    const { auth } = useAuth();

    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const onSubmit = ({ email }) => {
        setSubmitting(true);

        auth.getResetPasswordToken(email)
            .then(() => {
                setSubmitting(false);
                setSubmitted(true);
            })
            .catch(() => {
                setSubmitting(false);
                setSubmitted(false);
            });
    };

    return (
        <div className="l-login__wrapper">
            <div className="l-login__width">
                <SubscriptionFormLogo />

                <div className="l-login__form">
                    <h1 className="l-login__title">Forgot Password</h1>

                    <Form onSubmit={onSubmit}>
                        {(formMethods) => (
                            <>
                                {!submitted && (
                                    <Field
                                        {...formMethods}
                                        disabled={submitting}
                                        validation={{
                                            required: "Email is required",
                                        }}
                                        required
                                        focusOnMount
                                        id="email"
                                        type="email"
                                        name="email"
                                        displayName="Email"
                                    />
                                )}
                                {!submitted && (
                                    <SubmitButton loading={submitting}>
                                        Submit
                                    </SubmitButton>
                                )}
                                {submitted && (
                                    <p className="c-form__message">
                                        Password reset request submitted. Please
                                        check your emails for instructions on
                                        how to reset your password.
                                    </p>
                                )}
                            </>
                        )}
                    </Form>

                    <div className="o-actions">
                        <div>
                            <Link to="/login">
                                Log in
                            </Link>
                        </div>
                        <div>
                            <Link to="/join-help">
                                Having Issues?
                            </Link>
                        </div>
                    </div>
                </div>

                <p className="o-help">
                    New to andertons extra?{" "}
                    <Link to="/join"
                        className="o-help__link" >
                        Sign Up Now
                    </Link>
                </p>
            </div>
        </div>
    );
}

export default ForgotPassword;
