import React from "react";
import { useState, useEffect } from "react";

import StoryblokClient from "storyblok-js-client";

export const Storyblok = new StoryblokClient({
    // The access token should not be used on the clinet eg. proccess.env.STORYBLOK_PUBLIC_API_KEY
    accessToken: process.env.RAZZLE_STORYBLOK_PUBLIC_API_KEY,
    cache: {
        clear: "auto",
        type: "memory",
    },
});

export const useStoryblok = (slug, originalStory, preview) => {
    if (!slug) {
        throw Error(`
      No slug value provided to useStorybook hook!
      Please make sure you are providing a slug string when calling useStoryblok.
    `);
    }
    const [story, setStory] = useState(originalStory);
    const [loading, setLoading] = useState(false);

    const initStoryblokEventListeners = (story, setStory) => {
        // once the scrip is loaded, init the event listeners
        const { StoryblokBridge } = window;

        if (typeof StoryblokBridge !== "undefined") {
            // initialize the bridge with your token
            const storyblokInstance = new StoryblokBridge({
                // resolveRelations: [
                //     "featured-posts.posts",
                //     "selected-posts.posts",
                // ],
            });
            // reload the page on save or publish event in the Visual Editor
            storyblokInstance.on(["change", "published"], () =>
                window.location.reload(true)
            );

            // live update the story on input events
            storyblokInstance.on("input", (event) => {
                if (event.story._uid === story._uid) {
                    setStory(event.story);
                }
            });

            storyblokInstance.on("enterEditmode", (event) => {
                // loading the draft version on initial enter of editor
                Storyblok.get(`cdn/stories/${event.storyId}`, {
                    version: "draft",
                    // resolve_relations: [
                    //     "featured-posts.posts",
                    //     "selected-posts.posts",
                    // ],
                })
                    .then(({ data }) => {
                        if (data.story) {
                            setStory(data.story);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            });
        }
    };

    const addStoryblokBridge = (callback, story, setStory) => {
        // check if the script is already present
        const existingScript = document.getElementById("storyblokBridge");
        if (existingScript) {
            callback(story, setStory);
        } else {
            const script = document.createElement("script");
            script.src = "//app.storyblok.com/f/storyblok-v2-latest.js";
            script.id = "storyblokBridge";
            document.body.appendChild(script);
            script.onload = () => {
                // once the scrip is loaded, init the event listeners
                callback(story, setStory);
            };
        }
    };

    useEffect(() => {
        // This fetch should be removed once we find a way to pass the story from the server
        Storyblok.get(`cdn/stories${slug}`, {
            version: process.env.RAZZLE_DEFAULT_STORYBLOK_API_VERSION,
        }).then((response) => {
            const story = response?.data?.story;
            setStory(story);
            setLoading(false);
            if (preview) {
                addStoryblokBridge(
                    initStoryblokEventListeners,
                    story,
                    setStory
                );
            }
        });
    }, [loading]);

    return {
        loading,
        story,
    };
};
