import * as Sentry from "@sentry/react";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ErrorBoundary from "../../components/error-boundary";
import ErrorMessage from "../../components/error-message";
import Form from "../../components/form";
import Field from "../../components/form/field";
import TextArea from "../../components/form/textarea";
import SidebarLayout from "../../components/sidebar-layout";
import SubmitButton from "../../components/submit-button";
import { useAuth } from "../../providers/auth-provider";

function Copyright(props) {
    const { authenticated } = useAuth();

    return (
        <>
            <Helmet>
                <title>Copyright — {process.env.RAZZLE_APPLICATION_NAME}</title>
                <meta
                    name="description"
                    content="If you have concerns about copyright relating to content appearing on Andertons Extra, please contact us. Subscribe to EXTRA today from just £2.99 per month!"
                />
            </Helmet>
            <SidebarLayout
                legal
                termsAndConditions
                copyright
                contact={authenticated}
            >
                <h1>Copyright Concerns</h1>

                <ErrorBoundary
                    tags={{
                        page: "copyright",
                        section: "copyright form",
                    }}
                    fallback={() => (
                        <ErrorMessage message="Could not display copyright concerns form" />
                    )}
                >
                    <CopyrightForm />
                </ErrorBoundary>
            </SidebarLayout>
        </>
    );
}

export default Copyright;

function CopyrightForm() {
    const { auth } = useAuth();

    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [createdId, setCreatedId] = useState(false);

    const onSubmit = (data) => {
        setSubmitting(true);

        auth.createCopyrightConcernTicket(data)
            .then((ticket) => {
                setCreatedId(ticket.id);
                setSubmitting(false);
                setSubmitted(true);
            })
            .catch((err) => {
                Sentry.captureException(err, {
                    tags: {
                        page: "copyright",
                        action: "copyright concerns form submission",
                    },
                });
                toast("An error occurred when submitting the form");
                setSubmitting(false);
            });
    };

    return (
        <>
            {!submitted && (
                <>
                    <p>If you have a concern over copyrighted material being used without permission in a video hosted on Andertons Extra, please fill in the form below and provide clear details of the issue and the copyright owner.</p>
                    <p>Andertons Music Co. takes all copyright issues seriously, and we will review your submission promptly.</p>
                    <p>Please only fill in this form if you have a copyright concern over content on Andertons Extra. We are unable to respond to any other enquiries made via this form.</p>

                    <Form
                        useFormParams={{
                            defaultValues: {
                                subject:
                                    "Andertons Extra COPYRIGHT CONCERNS FORM Submitted",
                            },
                        }}
                        className="t-copyright-form o-form"
                        onSubmit={onSubmit}
                    >

                        {(formMethods) => (
                            <>
                                <Field
                                    {...formMethods}
                                    required
                                    disabled
                                    id="subject"
                                    displayName="Subject"
                                    validation={{
                                        required: "Subject is required",
                                    }}
                                    type="hidden"
                                    name="subject"
                                />

                                <Field
                                    {...formMethods}
                                    placeholder="Your Email Address"
                                    className="o-form__field"
                                    labelPosition="above"
                                    disabled={submitting}
                                    validation={{
                                        required: "Your email address is required",
                                    }}
                                    required
                                    focusOnMount
                                    id="email"
                                    type="email"
                                    name="email"
                                    displayName="Email"
                                />

                                <Field
                                    {...formMethods}
                                    placeholder="Your Name"
                                    className="o-form__field"
                                    labelPosition="above"
                                    errorPosition="below"
                                    disabled={submitting}
                                    validation={{
                                        required: "Your name is required",
                                        maxLength: {
                                            message:
                                                "Name must be 128 characters or fewer",
                                            value: 128,
                                        },
                                    }}
                                    required
                                    id="name"
                                    type="text"
                                    name="name"
                                    displayName="Name"
                                />

                                <Field
                                    {...formMethods}
                                    placeholder="The URL of the video you are referring to on Andertons Extra"
                                    className="o-form__field"
                                    labelPosition="above"
                                    errorPosition="below"
                                    disabled={submitting}
                                    validation={{
                                        required: "A link to a video on Andertons Extra is required",
                                    }}
                                    required
                                    id="url"
                                    type="text"
                                    name="url"
                                    displayName="Video URL"
                                />

                                <Field
                                    {...formMethods}
                                    className="o-form__field"
                                    labelPosition="above"
                                    placeholder="Please describe the nature of your claim to copyright of the material including as many details as possible."
                                    is={TextArea}
                                    disabled={submitting}
                                    required
                                    validation={{
                                        required:
                                            "A description of your issue is required",
                                        maxLength: {
                                            message:
                                                "Your description is too long. Please summarise the issue in under 1024 characters",
                                            value: 1024,
                                        },
                                    }}
                                    id="message"
                                    name="message"
                                    displayName="Description of Claim"
                                />

                                <SubmitButton
                                    staticClasses="btn btn--primary btn--full"
                                    loading={submitting}
                                >
                                    Submit
                              </SubmitButton>
                            </>
                        )}
                    </Form>
                </>
            )}

            {
                // TODO - Stephen to remove inline styles
                submitted && (
                    <div style={{ marginBottom: "24px" }}>
                        <p className="t-contact-form-confirmation-ticket-id">{`Thanks for submitting your information. A ticket with the ID: ${createdId} has been created.`}</p>
                        <p>
                            We'll look into the issue and be in touch if any
                            further information is required.
                        </p>
                        <button
                            className="btn btn--primary btn--full"
                            onClick={() =>
                                setSubmitted(false) && setCreatedId(false)
                            }
                        >
                            Report another problem?
                        </button>
                    </div>
                )
            }

            <p>
                By submitting this form, you are agreeing to our{" "}
                <Link to="/terms">Terms & Conditions of Use</Link>.
            </p>
        </>
    );
}
