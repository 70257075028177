import React from "react";
import AuthenticatedFooter from "./authenticated-footer";
import UnauthenticatedFooter from "./unauthenticated-footer";
import HoldingFooter from "./holding-footer";
import { useAuth } from "../../providers/auth-provider";

function Footer() {
    const { authenticated } = useAuth();

    if (authenticated === null) {
        return <HoldingFooter />;
    }

    return authenticated ? <AuthenticatedFooter /> : <UnauthenticatedFooter />;
}

export default Footer;
