import React from "react";
import { Helmet } from "react-helmet";
import Imgix from "react-imgix";
import { Link, useLocation } from "react-router-dom";
import ErrorBoundary from "../../components/error-boundary";
import ErrorMessage from "../../components/error-message";
import FeaturedVideo from "../../components/featured-video";
import LoadingIcon from "../../components/loading-icon";
import PresentersCarousel from "../../components/presenters-carousel";
import ProcessedText from "../../components/processed-text";
import RecentlyAddedCarousel from "../../components/recently-added-carousel";
import YouMightAlsoLikeCarousel from "../../components/you-might-also-like-carousel";
import { useCategory } from "../../providers/resource-provider";

import { useStoryblok } from "../../storyblok-client";
import DynamicComponent from "../../components/dynamic-component/DynamicComponent";

import SbEditable from "storyblok-react";

function MidLevelArchive({ routeData: { story: originalStory } }) {
    const { category } = useCategory();

    const location = useLocation();
    const slug = location.pathname;

    const preview = process.env.NODE_ENV === "development";
    const { story, loading } = useStoryblok(slug, originalStory, preview);

    if (loading) {
        return <div>Loading....</div>;
    }

    return (
        <>
            <>
                <Helmet>
                    <title>
                        {category.name} — {process.env.RAZZLE_APPLICATION_NAME}
                    </title>
                    <meta name="description" content={category.description} />
                    <meta name="robots" content="noindex, nofollow" />
                </Helmet>

                {story && (
                    <SbEditable content={story}>
                        {Array.isArray(story.content.body) ? (
                            story.content.body.map((blok) => (
                                <DynamicComponent blok={blok} key={blok._uid} />
                            ))
                        ) : (
                            <div>Missing Content</div>
                        )}
                    </SbEditable>
                )}

                <ErrorBoundary
                    tags={{
                        page: "mid level archive",
                        section: "category details",
                    }}
                    fallback={() => (
                        <ErrorMessage message="Could not display category details" />
                    )}
                >
                    <CategoryDetails category={category} />
                </ErrorBoundary>

                <div className="l-padding">
                    <div className="l-width">
                        <div className="l-grid l-grid--padding l-grid--2 l-grid--3-m l-grid--4-l">
                            <ErrorBoundary
                                tags={{
                                    page: "mid level archive",
                                    section: "archive month list",
                                }}
                                fallback={() => (
                                    <ErrorMessage message="Could not display archive" />
                                )}
                            >
                                <ArchiveMonthList months={category.months} />
                            </ErrorBoundary>
                        </div>
                    </div>
                </div>
            </>

            {/* Recently Added Carousel */}
            <ErrorBoundary
                tags={{
                    page: "mid level archive",
                    section: "recently added carousel",
                }}
                fallback={() => (
                    <ErrorMessage message="Could not display recently added carousel" />
                )}
            >
                <RecentlyAddedCarousel />
            </ErrorBoundary>

            {/* Featured Video */}
            <ErrorBoundary
                tags={{
                    page: "mid level archive",
                    section: "featured video",
                }}
                fallback={() => (
                    <ErrorMessage message="Could not display featured video" />
                )}
            >
                <FeaturedVideo />
            </ErrorBoundary>

            {/* You Might Also Like Carousel */}
            <ErrorBoundary
                tags={{
                    page: "mid level archive",
                    section: "you might also like carousel",
                }}
                fallback={() => (
                    <ErrorMessage message="Could not display you might also like carousel" />
                )}
            >
                <YouMightAlsoLikeCarousel />
            </ErrorBoundary>

            {/* Our Presenters Carousel */}
            <ErrorBoundary
                tags={{
                    page: "mid level archive",
                    section: "our presenters carousel",
                }}
                fallback={() => (
                    <ErrorMessage message="Could not display presenters carousel" />
                )}
            >
                <PresentersCarousel />
            </ErrorBoundary>
        </>
    );
}

export default MidLevelArchive;

function CategoryDetails({ category }) {
    return (
        <div className="o-hero">
            <picture className="o-hero__picture">
                <Imgix
                    htmlAttributes={{ alt: `${category.name} hero image` }}
                    className="o-hero__img"
                    src={category.hero_image}
                    imgixParams={{ q: 65 }}
                    width={1920}
                    height={500}
                />
            </picture>
            <div className="o-hero__info">
                <div className="o-hero__position">
                    <h1 className="o-hero__title">{category.name}</h1>
                    <div className="o-hero__intro">
                        <ProcessedText>{category.description}</ProcessedText>
                    </div>
                </div>
            </div>
        </div>
    );
}

function ArchiveMonthList({ months }) {
    return months ? (
        months.map((month, index) => (
            <ErrorBoundary
                key={month.id}
                tags={{
                    page: "mid level archive",
                    section: "archive month teaser",
                }}
                fallback={() => (
                    <ErrorMessage message="Could not display archive month" />
                )}
            >
                <ArchiveMonthTeaser index={index} category={month} />
            </ErrorBoundary>
        ))
    ) : (
        <LoadingIcon />
    );
}

function ArchiveMonthTeaser({ category }) {
    return category.has_videos ? (
        <Link to={category.link} className="l-grid__link t-archive-month">
            <picture className="l-grid__picture">
                <Imgix
                    htmlAttributes={{
                        src: category.loading_thumbnail_url,
                        alt: `${category.name} thumbnail image`,
                    }}
                    src={category.thumbnail_url}
                    className="l-grid__img lazyload"
                    imgixParams={{ q: 65 }}
                    attributeConfig={{
                        src: "data-src",
                        srcSet: "data-srcset",
                        sizes: "data-sizes",
                    }}
                    width={375}
                    height={375}
                />
            </picture>
        </Link>
    ) : (
        <span
            className={`t-archive-month ${
                category.has_children ? "l-grid__link" : ""
            }`}
        >
            <picture className="l-grid__picture">
                <Imgix
                    htmlAttributes={{
                        src: category.loading_thumbnail_url,
                        alt: `${category.name} thumbnail image`,
                    }}
                    src={category.thumbnail_url}
                    className="l-grid__img lazyload"
                    imgixParams={{ q: 65 }}
                    attributeConfig={{
                        src: "data-src",
                        srcSet: "data-srcset",
                        sizes: "data-sizes",
                    }}
                    width={375}
                    height={375}
                />
            </picture>
        </span>
    );
}
