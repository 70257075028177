import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Form from "../../components/form";
import Field from "../../components/form/field";
import SubmitButton from "../../components/submit-button";
import { useAuth } from "../../providers/auth-provider";
import SubscriptionFormLogo from "../join/subscription-form-logo";

function Login(props) {
    return (
        <>
            <Helmet>
                <title>Log In — {process.env.RAZZLE_APPLICATION_NAME}</title>
                <meta
                    name="description"
                    content="Log in to here to start using your Andertons Extra benefits. Watch ad-free, exclusive content and get your exclusive deals. Not a member? Sign up today!"
                />
            </Helmet>
            <div className="l-login">
                <LoginForm />
            </div>
        </>
    );
}

function LoginForm() {
    const { signIn } = useAuth();

    const location = useLocation();

    const query = queryString.parse(location.search);

    const [submitting, setSubmitting] = useState(false);

    const onSubmit = ({ email, password }) => {
        setSubmitting(true);

        return signIn({
            email,
            password,
            onSuccess: () => setSubmitting(false),
            onError: () => setSubmitting(false),
        });
    };

    useEffect(() => {
        if (query.reset) {
            toast("Password successfully changed");
        }

        return () => {
            setSubmitting(false);
        };
    }, []);

    return (
        <div className="l-login__wrapper">
            <div className="l-login__width">
                <SubscriptionFormLogo />

                <div className="l-login__form">
                    <h1 className="l-login__title">Log In</h1>

                    <Form className="c-form t-login-form" onSubmit={onSubmit}>
                        {(formMethods) => (
                            <>
                                <Field
                                    {...formMethods}
                                    focusOnMount
                                    disabled={submitting}
                                    validation={{
                                        required: "Email is required",
                                    }}
                                    required
                                    id="email"
                                    type="email"
                                    name="email"
                                    displayName="Email"
                                />
                                <Field
                                    {...formMethods}
                                    disabled={submitting}
                                    validation={{
                                        required: "Password is required",
                                    }}
                                    required
                                    id="password"
                                    type="password"
                                    name="password"
                                    displayName="Password"
                                />
                                <SubmitButton loading={submitting}>
                                    Submit
                                </SubmitButton>
                            </>
                        )}
                    </Form>

                    <div className="o-actions">
                        <div></div>
                        <div>
                            <Link to="/forgot-password">
                                Forgot Password
                            </Link>
                        </div>
                    </div>
                </div>

                <p className="o-help">
                    New to andertons extra?{" "}
                    <Link to="/join"
                        className="o-help__link">
                        Sign Up Now
                    </Link>
                </p>
            </div>
        </div>
    );
}

export default Login;
