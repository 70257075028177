import queryString from "query-string";
import ApiClient from "../api-client";
import Location from "./location";

class Geolocation {
    constructor(options = {}) {
        this.client = new ApiClient({
            baseUrl: process.env.RAZZLE_GDS_BASE_URL,
            reqInterceptor: options.reqInterceptor ?? ((config) => config),
            accessor: (res) => res,
        });
        this.entity = "";
        this.query = {};
        this.method = "get";
        this.body = {};
    }

    setEntity(entity) {
        this.entity = entity;

        return this;
    }

    setBody(body) {
        this.body = body;

        return this;
    }

    addToHeaders(obj) {
        this.headers = {
            ...this.headers,
            ...obj,
        };

        return this;
    }

    addToBody(obj) {
        this.body = {
            ...this.body,
            ...obj,
        };

        return this;
    }

    where(key, value) {
        this.query[key] = value;

        return this;
    }

    setMethod(method) {
        this.method = method;

        return this;
    }

    self() {
        return this.setEntity("self");
    }

    request() {
        this.client.setPath(this.entity).setQueryString(
            queryString.stringify(this.query, {
                arrayFormat: "bracket",
            })
        );

        return this.client.request(this.method, this.body, this.headers);
    }

    getLocationData() {
        return this.self()
            .where("fields", [
                "languages",
                "ip_address",
                "country_code",
                "continent_code",
                "currency",
                "state_prov",
            ])
            .request()
            .then((response) => new Location(response.data));
    }
}

export default Geolocation;
