import React, { useEffect, useRef } from 'react';

export default function SearchBarForm({ focusOnMount, onSubmit, disabled, formClassName, ...props }) {

    const ref = useRef();

    useEffect(() => {
        if (focusOnMount) {
            ref.current.focus();
        }
    }, [focusOnMount]);


    return <form onSubmit={onSubmit} className={`t-search-form ${formClassName}`}>
        <input
            ref={(e) => {
                ref.current = e;
            }}
            disabled={disabled}
            {...props}
        />
        <button
            disabled={disabled}
            type="submit"
            className="o-search__button"
        >
            Search
            </button>
    </form>

}

SearchBarForm.defaultProps = {
    focusOnMount: false,
    formClassName: "o-search__form",
    onSubmit: () => { },
    disabled: false,
}