import queryString from 'query-string';
import React, { useEffect, useState } from "react";
import Imgix from "react-imgix";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useAuth } from "../../../../providers/auth-provider";
import { useCategory, useResource } from "../../../../providers/resource-provider";
import LoadingIcon from "../../../loading-icon";
import Notice from '../../../notice';
import SearchBarForm from "../../../search-bar/search-bar-form";

function MobileAuthenticatedNavbar({ topLevelCategories, overlayRef }) {
    const history = useHistory();
    const location = useLocation();
    const { resource } = useResource();
    const [childrenBeingViewed, setChildrenBeingViewed] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [children, setChildren] = useState(null);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const { signOut } = useAuth();
    const { handleCategoryClick } = useCategory();

    /**
     * Close overlay with escape
     *
     * Add event listener and handler to document
     */
    const handleKeyDown = e => {
        if (e.key === "Escape") {
            handleHide();
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [])

    const handleSearch = e => {
        e.preventDefault();

        setShowSearch(false);
        setSearchTerm("");
        deactivateOverlay();
        document.body.classList.remove('mobile-search-open');

        history.push(queryString.stringifyUrl({
            url: '/search',
            query: {
                q: [
                    searchTerm,
                ]
            }
        }, {
            arrayFormat: 'bracket',
        }))
    }

    const handleToggleSearch = () => {
        (showSearch ? deactivateOverlay : activateOverlay)();

        const classListMethod = showSearch ? 'remove' : 'add';
        document.body.classList[classListMethod]('mobile-search-open');
        setShowSearch(!showSearch);
    }

    const handleSignOut = () => {
        handleHide();
        signOut(() => {
            history.push("/login");
        });
    };

    const handleViewChildren = (category, index) => {
        setLoading(true);

        resource
            .getCategoriesByTopLevelParent(category.slug)
            .then((response) => {
                setChildren(response);

                setLoading(false);
            });

        setChildrenBeingViewed(true);
    };

    const handleHideChildren = () => {
        setChildrenBeingViewed(false);
    };

    const handleHide = () => {
        setShow(false);
        setChildrenBeingViewed(false);
        setShowSearch(false);
        deactivateOverlay();
    };

    const handleShow = () => {
        setShow(true);
        activateOverlay();
        setShowSearch(false);
        document.body.classList.remove('mobile-search-open');
    };

    let scrollPosition = 0;

    const activateOverlay = () => {
        overlayRef.current.classList.add("is-active");
        overlayRef.current.classList.remove("is-hidden");
        overlayRef.current.addEventListener("click", handleHide);
        document.body.classList.add("no-scroll");
        scrollPosition = window.pageYOffset;
        document.body.style.overflow = "hidden";
        document.body.style.position = "fixed";
        document.body.style.top = `-${scrollPosition}px`;
        document.body.style.width = "100%";
    };

    const deactivateOverlay = () => {
        overlayRef.current.classList.add("is-hidden");
        overlayRef.current.classList.remove("is-active");
        overlayRef.current.removeEventListener("click", handleHide);
        document.body.classList.remove("no-scroll");
        document.body.style.removeProperty("overflow");
        document.body.style.removeProperty("position");
        document.body.style.removeProperty("top");
        document.body.style.removeProperty("width");
        window.scrollTo(0, scrollPosition);
    };

    return (
        <div className={"c-header c-header--mobile"}>
            <Notice hash="beta" dismissable>
                Andertons Extra is currently in Beta. Please Let Us Know If You
                Encounter Any Issues.
            </Notice>

            <div className="c-header__wrapper l-width">
                <div className="c-header--left">
                    <Link to="/home">
                        <Imgix
                            htmlAttributes={{ alt: "Andertons Extra Logo" }}
                            className="t-authenticated-mobile-logo-link o-logo"
                            src={
                                "https://andertonsextra.imgix.net/static/logos/andertons-extra-x-logo-red.png"
                            }
                            imgixParams={{ q: 65 }}
                            width={40}
                            height={40}
                        />
                    </Link>
                    <div className="c-header--links">
                        <button
                            className="t-show-mobile-search-button show-mobile-search-button"
                            onClick={handleToggleSearch}
                        >
                            <div className="o-searchicon"></div>
                        </button>
                        <button
                            onClick={() => (show ? handleHide() : handleShow())}
                            className={
                                "t-mobile-navbar-menu-button c-header__mobilemenu"
                            }
                        >
                            <div></div>
                            <div></div>
                            <div></div>
                        </button>
                    </div>
                </div>
            </div>

            {showSearch && (
                <div className="m-search__form">
                    <SearchBarForm
                        placeholder="Search"
                        type="search"
                        focusOnMount
                        onChange={(e) => setSearchTerm(e.target.value)}
                        value={searchTerm}
                        name="term"
                        onSubmit={handleSearch}
                        /**
                         * FAO Stephen
                         * The className here gets passed to the input.
                         * To apply classes to the form, use formClassName
                         * default for this is o-search__form
                         */
                        className="t-mobile-search-input o-search__input"
                    />
                </div>
            )}

            <div
                className={`t-mobile-navbar c-panel ${
                    !show ? "is-hidden" : ""
                }`}
            >
                <button
                    onClick={() => (show ? handleHide() : setShow(true))}
                    className={"t-mobile-navbar-close-button c-panel__close"}
                >
                    X
                </button>

                <div
                    className={
                        "t-mobile-navbar-top-level-panel mobile-header-top-level"
                    }
                >
                    <div className="">
                        <div className={"c-panel__title"}>Menu</div>
                        <ul className="c-panel__list">
                            <li className={"c-panel__item"}>
                                <Link
                                    onClick={handleHide}
                                    to="/home"
                                    className={`c-panel__link ${
                                        location.pathname === "/home"
                                            ? "is-active"
                                            : ""
                                    }`}
                                >
                                    Home
                                </Link>
                            </li>
                            {!loading &&
                                topLevelCategories.map((category, index) => (
                                    <li
                                        onClick={() =>
                                            handleViewChildren(category, index)
                                        }
                                        key={category.id}
                                        className="c-panel__item t-mobile-navbar-category-link"
                                    >
                                        <span
                                            className={`c-panel__link c-panel__link--sub ${
                                                category.link ===
                                                location.pathname
                                                    ? "is-active"
                                                    : ""
                                            }`}
                                        >
                                            {category.name}
                                        </span>
                                        <span className={"c-panel__forward"}>
                                            &gt;
                                        </span>
                                    </li>
                                ))}
                            <li className={"c-panel__item"}>
                                <Link
                                    onClick={handleHide}
                                    to="/playlists"
                                    className={`c-panel__link ${
                                        location.pathname === "/playlists"
                                            ? "is-active"
                                            : ""
                                    }`}
                                >
                                    Playlists
                                </Link>
                            </li>
                            <li className={"c-panel__item"}>
                                <Link
                                    onClick={handleHide}
                                    to="/search"
                                    className={`c-panel__link ${
                                        location.pathname === "/search"
                                            ? "is-active"
                                            : ""
                                    }`}
                                >
                                    Search
                                </Link>
                            </li>
                            <li className={"c-panel__item"}>
                                <Link
                                    onClick={handleHide}
                                    to="/my-account"
                                    className={`c-panel__link ${
                                        location.pathname === "/my-account"
                                            ? "is-active"
                                            : ""
                                    }`}
                                >
                                    My Account
                                </Link>
                            </li>
                            {/* <li className={"c-panel__item"}>
                                <button
                                    onClick={handleSignOut}
                                    className={"c-panel__link"}
                                >
                                    Log Out
                                </button>
                            </li> */}
                        </ul>
                    </div>
                </div>

                <div
                    className={`t-mobile-navbar-bottom-level-panel mobile-header-bottom-level ${
                        !childrenBeingViewed ? "is-hidden" : ""
                    }`}
                >
                    <div className="">
                        <ul className="c-panel__list">
                            {
                                /**
                                 * Show panel if...
                                 */
                                !!children && !loading ? (
                                    <>
                                        <li className="c-panel__item">
                                            <button
                                                onClick={handleHideChildren}
                                                className={
                                                    "t-mobile-navbar-back-button c-panel__back"
                                                }
                                            >
                                                <span
                                                    className={
                                                        "c-panel__back--icon"
                                                    }
                                                >
                                                    &lt;
                                                </span>
                                                Back
                                            </button>
                                        </li>
                                        {children.map((category) => (
                                            <li
                                                key={category.id}
                                                className="c-panel__item"
                                            >
                                                <Link
                                                    onClick={() => {
                                                        handleCategoryClick(
                                                            category
                                                        );
                                                        handleHide();
                                                    }}
                                                    to={category.link}
                                                    className={`t-mobile-navbar-category-child-link c-panel__link ${
                                                        category.link ===
                                                        location.pathname
                                                            ? "is-active"
                                                            : ""
                                                    }`}
                                                >
                                                    {category.name}
                                                </Link>
                                            </li>
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        <li className="c-panel__item">
                                            <button
                                                onClick={handleHideChildren}
                                                className={"c-panel__back"}
                                            >
                                                <span
                                                    className={
                                                        "c-panel__back--icon"
                                                    }
                                                >
                                                    &lt;
                                                </span>
                                                Back
                                            </button>
                                        </li>
                                        <li className="c-panel__item">
                                            {/* TODO - Stephen to check CSS */}
                                            <span className="c-panel__link c-panel__link--loading">
                                                <LoadingIcon />
                                            </span>
                                        </li>
                                    </>
                                )
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MobileAuthenticatedNavbar;
