import React from "react";
import Imgix from "react-imgix";

function HoldingNavbar() {
    return (
        <>
            <div className="t-holding-navbar c-header c-header--desktop c-header--desktop__loading">
                <div className="c-header__wrapper l-width">
                    <span className="c-header--left">

                    </span>

                    <div className="c-header--center">
                        <ul className="c-navigation">
                            <li className={"c-navigation__item"}>
                                <span
                                    className={`c-navigation__link c-navigation__link__empty`}
                                ></span>
                            </li>
                        </ul>
                    </div>

                    <div className="c-header--right">
                        <ul className="c-navigation"></ul>
                    </div>
                </div>
            </div>
            <div className={"c-header c-header--mobile"}>
                <div className="c-header__wrapper l-width">
                    <div className="c-header--left">
                        <Imgix
                            htmlAttributes={{ alt: "Andertons Extra Logo" }}
                            className="o-logo"
                            src={
                                "https://andertonsextra.imgix.net/static/logos/andertons-extra-x-logo-red.png"
                            }
                            imgixParams={{ q: 65 }}
                            width={40}
                            height={40}
                        />
                        <button className={"c-header__mobilemenu"}>
                            <div></div>
                            <div></div>
                            <div></div>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HoldingNavbar;
