import React from "react";
import Sidebar from "../sidebar";

function SidebarLayout({ children, ...props }) {
    return (
        <div className="l-padding">
            <div className="l-width">
                <div className="l-account">
                    <div className="l-account__left">
                        <Sidebar {...props} />
                    </div>
                    <div className="l-account__right">{children}</div>
                </div>
            </div>
        </div>
    );
}

export default SidebarLayout;
