import axios from "axios";

class ApiClient {
    constructor({ baseUrl, path, reqInterceptor, accessor }) {
        this.baseUrl = baseUrl;
        this.path = path;
        this.queryString = false;
        this.cancelToken = null;
        this.axios = axios.create({
            headers: {
                "X-Requested-With": "XMLHttpRequest",
            }
        });

        this.accessor =
            accessor ??
            ((res) => {
                if (res && res.data) {
                    return res.data;
                }
                throw new Error(
                    `No data property in API response. URL: ${this.getFullUrl()}`
                );
            });

        // Add a request interceptor
        this.axios.interceptors.request.use(
            reqInterceptor ?? (config => config),
            (error) =>
                // Do something with request error
                Promise.reject(error)
        );
    }

    static isStatus(thrown, status) {
        return (
            thrown &&
            thrown.response &&
            thrown.response.status &&
            thrown.response.status === status
        );
    }

    static isCancel(thrown) {
        return axios.isCancel(thrown);
    }

    static generateCancelTokenSource() {
        return axios.CancelToken.source();
    }

    setCancelToken(cancelToken) {
        this.cancelToken = cancelToken;

        return this;
    }

    setPath(path) {
        this.path = path;

        return this;
    }

    setBaseUrl(baseUrl) {
        this.baseUrl = baseUrl;

        return this;
    }

    setQueryString(queryString) {
        this.queryString = queryString;

        return this;
    }

    getFullUrl() {
        return (
            this.baseUrl +
            (this.path ? `/${this.path}` : "") +
            (this.queryString ? `?${this.queryString}` : "")
        );
    }

    request(method = "get", data = {}, headers = {}) {
        return ["get", "delete"].includes(method)
            ? this.axios[method](this.getFullUrl(), {
                headers,
                cancelToken:
                    this.cancelToken ??
                    ApiClient.generateCancelTokenSource().token,
            })
            : this.axios[method](this.getFullUrl(), data, {
                headers,
                cancelToken: this.cancelToken,
            });
    }
}

export default ApiClient;
