import * as Sentry from "@sentry/react";
import React, { useEffect, useRef, useState } from "react";
import Imgix from "react-imgix";
import { toast } from "react-toastify";
import { useLocalStorage } from "../../providers/local-storage-provider";
import { useDebounce } from "../../utils";
import chromecastConfig from "../../models/vixy-player/chromecast-config";

function VideoPlayer({ video }) {
    const [startProgress, setStartProgress] = useState(0);

    const [time, setTime] = useState(0);

    const [play, setPlay] = useState(false);

    const [ready, setReady] = useState(false);

    const aexLocalStorage = useLocalStorage();

    /**
     * Not convinced this is the best way to go
     * about this, so could be worth revisiting.
     *
     * The Vixy script needs to load and create
     * window.kWidget before the player can be
     * embedded.
     *
     * On inital load, we can pass a callback
     * to embed the player onload
     *
     * For future loads, other than unless we
     * destroy the script and recreate it each time a
     * videoplayer is made, we'll need to check
     * the kwidget exists before attempting to
     * embed. If it doesn't exist, then we need
     * to wait for some time (500ms) and try again.
     *
     * At the moment, this will continue infinitely.
     *
     * I'm not sure how necessary this is, but the idea
     * is that if the script is created, but the kwidget
     * has not yet been created, we'll need to wait.
     *
     */
    const embedPlayer = () => {
        const recentlyWatched = aexLocalStorage
            ? aexLocalStorage.getRecentlyWatched()
            : [];

        const recentlyWatchedEntry = recentlyWatched.find(
            (entry) => entry.id === video.id
        );

        const startPoint = recentlyWatchedEntry
            ? recentlyWatchedEntry.progress
            : 0;

        setStartProgress(
            recentlyWatchedEntry ? recentlyWatchedEntry.progressPercentage : 0
        );

        window.kWidget.embed({
            targetId: "vixyVideoPlayer",
            wid: "_439",
            uiconf_id: process.env.RAZZLE_VIXY_PLAYER_ID,
            flashvars: {
                "embedPlayer.externalFullScreenControl": true,
                /**
                 * Only start from progress point if not in last 10 secs of video
                 */
                "mediaProxy.mediaPlayFrom":
                    startPoint <= video.duration - 10 ? startPoint : 0,
                autoPlay: false,
                autoMute: false,
                ...chromecastConfig
            },
            entry_id: video.id,
            readyCallback: () => {
                registerPlayheadUpdate();
                registerPlaybackComplete();
                registerPlayerError();
                registerMediaError();
                setReady(true);
            },
        });
    };

    const destroyPlayer = (callback) => {
        window.kWidget.destroy("vixyVideoPlayer");
        setReady(false);
        setPlay(false);
        callback();
    };

    const checkWidgetAndEmbedPlayer = () => {
        if (window.kWidget) {
            return embedPlayer();
        } else {
            return setTimeout(checkWidgetAndEmbedPlayer, 500);
        }
    };

    const checkWidgetAndDestroyPlayer = (callback = () => { }) => {
        if (window.kWidget) {
            return destroyPlayer(callback);
        } else {
            return setTimeout(checkWidgetAndDestroyPlayer, 500);
        }
    };

    /**
     * We'll debounce progress updates
     * so we don't spam localstorage retrieval
     */
    const debouncedTime = useDebounce(time, 100);

    const registerPlayheadUpdate = () => {
        const kdp = document.getElementById("vixyVideoPlayer");

        if (typeof kdp.kBind === "function") {
            kdp.kBind("playerUpdatePlayhead.amc", (time) => {
                setTime(time);
            });
        }
    };

    /**
     * Debounce progress updates
     */
    useEffect(() => {
        if (play) {
            /**
             * EXTRA-57 - arbitrary percentage above which
             * we'd consider the video to be 'watched'
             *
             * After this point, just set the watch percentage
             * to 0 as we do not want to start the video at
             * this point in the future or feature it in
             * "Continue Watching" carousels
             */
            const resetPercentage =
                process.env.RAZZLE_VIDEO_PROGRESS_RESET_PERCENTAGE ?? 95;

            const percentage = Math.floor(
                (debouncedTime / video.duration) * 100
            );

            aexLocalStorage &&
                aexLocalStorage.setRecentlyWatchedEntry(
                    video.id,
                    percentage >= resetPercentage ? 0 : debouncedTime,
                    percentage >= resetPercentage ? 0 : percentage
                );
        }
    }, [time, aexLocalStorage, debouncedTime, video.duration,]);

    const isInitialMount = useRef(true);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            checkWidgetAndDestroyPlayer(checkWidgetAndEmbedPlayer);
        }
    }, [video.id]);

    const registerPlaybackComplete = () => {
        const kdp = document.getElementById("vixyVideoPlayer");

        if (typeof kdp.kBind === "function") {
            kdp.kBind("playbackComplete.amc", () => {
                /**
                 * Do something on playback complete
                 */

                kdp.kUnbind("playbackComplete");
            });
        }

        return null;
    };

    const registerPlayerError = () => {
        const kdp = document.getElementById("vixyVideoPlayer");

        if (typeof kdp.kBind === "function") {
            kdp.kBind("playerError.amc", (error) => {
                switch (error.key) {
                    case "UNAUTHORIZED_IP_ADDRESS":
                    case "OUT_OF_SCHEDULING":
                    default:

                        /**
                         * Log error, go back to pre-play screen
                         * and display error notification to the user
                         */
                        console.error("Player error occurred", error.key);
                        toast("An error occurred during video playback")
                        Sentry.captureException(new Error('Vixy player error'), {
                            key: error.key,
                            error
                        })
                        setPlay(false);
                        setReady(false);
                        break;
                }
            });
        }

        return null;
    };

    const registerMediaError = () => {
        const kdp = document.getElementById("vixyVideoPlayer");

        if (typeof kdp.kBind === "function") {
            kdp.kBind("mediaError.amc", (error) => {

                /**
                 * Log error, go back to pre-play screen
                 * and display error notification to the user
                 */
                console.error("Media error occurred", error.key);
                toast("An error occurred during video playback")
                Sentry.captureException(new Error('Vixy media error'), {
                    key: error.key,
                    error
                })
                setPlay(false);
                setReady(false);
            });
        }
    };

    /**
     * Clear old recently watched entries
     * and you might also like entries
     * if required
     */
    useEffect(() => {
        !!aexLocalStorage && aexLocalStorage.clearOldRecentlyWatched(video.id);
        !!aexLocalStorage && aexLocalStorage.clearOldYouMightAlsoLike();
    }, [aexLocalStorage, video.id]);

    /**
     * Embed player
     */
    useEffect(() => {
        let timeout;

        if (aexLocalStorage) {
            /**
             * Check if script already exists
             */
            let script = document.getElementById("vixyVideoPlayerScript");

            if (!script) {
                script = document.createElement("script");

                script.src = process.env.RAZZLE_VIXY_WIDGET_SCRIPT_SRC;

                script.async = true;

                script.id = "vixyVideoPlayerScript";

                script.onload = embedPlayer;

                document.body.appendChild(script);
            } else {
                timeout = checkWidgetAndEmbedPlayer();
            }
        }

        return () => {
            clearTimeout(timeout);
        };
    }, [aexLocalStorage]);

    const triggerPlayback = () => {
        try {
            setPlay(true);

            const kdp = document.getElementById("vixyVideoPlayer");

            kdp.sendNotification("doPlay");
        } catch (e) {
            console.error(e);
            setPlay(false);
        }
    };

    return (
        <div className="c-player">
            <div className="c-player__wrapper l-width">
                {/* Show if not yet played */}
                {!play && (
                    <div
                        onClick={ready ? triggerPlayback : () => { }}
                        className={`c-player__load ${
                            ready ? "has-pointer" : ""
                            }`}
                    >
                        <picture className="c-player__picture">
                            <Imgix
                                key={video.id}
                                htmlAttributes={{
                                    src: video.loading_thumbnail_url,
                                    alt: `${video.name} thumbnail image`,
                                }}
                                className="c-player__img lazyload"
                                src={video.thumbnail_url}
                                imgixParams={{ q: 65 }}
                                width={2048}
                                height={1152}
                                attributeConfig={{
                                    src: "data-src",
                                    srcSet: "data-srcset",
                                    sizes: "data-sizes",
                                }}
                            />
                        </picture>
                        <div className="c-player__position">
                            {/* Only show play button once player has embedded */}
                            {ready && (
                                <button className="c-player__button"></button>
                            )}
                            <div className="c-player__progress">
                                <div
                                    className="c-player__scrubber"
                                    style={{ width: `${startProgress}%` }}
                                ></div>
                            </div>
                        </div>
                    </div>
                )}

                {/*
                Have to put a wrapper here as I think something about the player
                embed fiddles with the DOM element and makes it so you can't change classes
                on the vixyVideoPlayer element after embed
            */}
                <div className={`c-player__video ${play ? "" : "is-hidden"}`}>
                    <div
                        id="vixyVideoPlayer"
                        className={"c-player__player"}
                    ></div>
                </div>
            </div>
        </div>
    );
}

export default VideoPlayer;
