import React from "react";
import Imgix from "react-imgix";
import { Link } from "react-router-dom";
import { useCategory } from "../../providers/resource-provider";

function StaticCategory({ category, image }) {
    const { handleCategoryClick } = useCategory();

    const handleClick = () => handleCategoryClick(category);

    return category ? (
        <Link
            onClick={handleClick}
            to={category.link}
            className="t-static-category-grid-link l-grid__link"
        >
            <picture className="l-grid__picture">
                <Imgix
                    htmlAttributes={{
                        src: category[`loading_${image}_image`],
                        alt: `${category.name} hero image`,
                    }}
                    width={800}
                    height={800}
                    className="l-grid__img lazyload"
                    src={category[`${image}_image`]}
                    imgixParams={{ q: 65 }}
                    attributeConfig={{
                        src: "data-src",
                        srcSet: "data-srcset",
                        sizes: "data-sizes",
                    }}
                />
            </picture>
        </Link>
    ) : null;
}

StaticCategory.defaultProps = {
    image: "hero_square",
};

export default StaticCategory;
