import expectedKeys from "./expected-keys";

export default class DeserializedResponse {
    static get(response) {

        const output = {};

        if (typeof response === 'object') {
            Object.keys(response).forEach(key => {
                output[key] = !!expectedKeys[key] && !!response[key] ? expectedKeys[key](response[key]) : response[key];
            });
        }

        return output;

    }
}