import { useLocalStorage } from "../../providers/local-storage-provider";

function VideoThumbnailSet({ videos, children }) {
    const aexLocalStorage = useLocalStorage();

    const recentlyWatched = aexLocalStorage
        ? aexLocalStorage.getRecentlyWatched()
        : [];

    const updatedVideos = videos.map((video) => {
        /**
         * TODO - maybe find a way of doing this async?
         * nested loop blocking rendering...
         */
        const recentlyWatchedEntry = recentlyWatched.find(
            (entry) => entry.id === video.id
        );
        const watchProgress = recentlyWatchedEntry
            ? recentlyWatchedEntry.progressPercentage
            : 0;

        return video.setWatchProgress(watchProgress);
    });

    return children({
        videos: updatedVideos,
    });
}

export default VideoThumbnailSet;
