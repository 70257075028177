import BaseResponseModel from "../../base-response-model";

class Category extends BaseResponseModel {
    constructor(props) {
        super(props);
        this.link = `/categories/${
            this.slugs
                ? this.slugs.map((slug) => encodeURIComponent(slug)).join("/")
                : encodeURIComponent(this.slug)
            }${
            this.children && this.children.length > 0
                ? `/${encodeURIComponent(this.children[0].slug)}`
                : ""
            }`;
        this.loading_hero_square_image = `${this.hero_square_image}?blur=800&px=20&auto=format`;
        this.loading_hero_image = `${this.hero_image}?blur=800&px=20&auto=format`;
        this.loading_portrait_image = `${this.portrait_image}?blur=800&px=20&auto=format`;
        this.loading_thumbnail_url = `${this.thumbnail_url}?blur=800&px=20&auto=format`;
    }

    getArchiveYear() {
        return this.name.replace("Archive ", "");
    }
}

export default Category;
