import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useLocation } from "react-router-dom";
import ErrorBoundary from "../../components/error-boundary";
import ErrorMessage from "../../components/error-message";
import LoadingIcon from "../../components/loading-icon";
import ProcessedText from "../../components/processed-text";
import VideoPlayer from "../../components/video-player";
import VideoThumbnailSet from "../../components/video-thumbnail-set";
import QueueVideoThumbnail from "../../components/video-thumbnail-set/queue-video-thumbnail";
import WatchNextCarousel from "../../components/watch-next-carousel";
import ApiClient from "../../models/api-client";
import Category from "../../models/resource/category";
import { useAuth } from "../../providers/auth-provider";
import { useResource, useVideo } from "../../providers/resource-provider";
import { scrollToTop } from "../../utils";

function Video({ match }) {
    const { video } = useVideo();
    const location = useLocation();

    const query = queryString.parse(location.search);

    return (
        <>
            <Helmet>
                <title>{video.name} — {process.env.RAZZLE_APPLICATION_NAME}</title>
                <meta
                    name="description"
                    content={video.video_summary}
                />
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <VideoPlayer video={video} />

            <div className="l-description l-width">
                <div className="l-description__left">
                    <ErrorBoundary
                        tags={{
                            page: "video",
                            section: "video details",
                        }}
                        fallback={() => (
                            <ErrorMessage message="Could not display video details" />
                        )}
                    >
                        <VideoDetails video={video} />
                    </ErrorBoundary>
                </div>

                <div className="l-description__right">
                    <ErrorBoundary
                        tags={{
                            page: "video",
                            section: "playlist queue",
                        }}
                        fallback={() => (
                            <ErrorMessage message="Could not display video presenters" />
                        )}
                    >
                        {query.playlist ? (
                            <QueueList
                                videoId={match.params.id}
                                resourceId={query.playlist}
                                resourceVideosMethod="getMediaByPlaylist"
                                resourceMethod="getPlaylist"
                                resourceName="playlist"
                            />
                        ) : null}
                    </ErrorBoundary>

                    <ErrorBoundary
                        tags={{
                            page: "video",
                            section: "playlist queue",
                        }}
                        fallback={() => (
                            <ErrorMessage message="Could not display video presenters" />
                        )}
                    >
                        {query.category ? (
                            <QueueList
                                videoId={match.params.id}
                                resourceId={query.category}
                                resourceVideosMethod="getMediaByCategory"
                                resourceMethod="getCategory"
                                resourceName="category"
                            />
                        ) : null}
                    </ErrorBoundary>

                    <ErrorBoundary
                        tags={{
                            page: "video",
                            section: "presenters",
                        }}
                        fallback={() => (
                            <ErrorMessage message="Could not display video presenters" />
                        )}
                    >
                        <VideoPresenters presenters={video.presenters} />
                    </ErrorBoundary>

                    <ErrorBoundary
                        tags={{
                            page: "video",
                            section: "tags",
                        }}
                        fallback={() => (
                            <ErrorMessage message="Could not display video tags" />
                        )}
                    >
                        <VideoTags tags={video.tags} />
                    </ErrorBoundary>

                    <ErrorBoundary
                        tags={{
                            page: "video",
                            section: "categories",
                        }}
                        fallback={() => (
                            <ErrorMessage message="Could not display video categories" />
                        )}
                    >
                        <VideoCategories categories={video.categories} />
                    </ErrorBoundary>
                </div>
            </div>

            <ErrorBoundary
                tags={{
                    page: "video",
                    section: "watch next carousel",
                }}
                fallback={() => (
                    <ErrorMessage message="Could not display watch next carousel" />
                )}
            >
                <WatchNextCarousel videoId={match.params.id} />
            </ErrorBoundary>
        </>
    );
}

export default Video;

function QueueList({
    resourceId,
    videoId,
    resourceVideosMethod,
    resourceMethod,
    resourceName,
}) {
    const { resource } = useResource();
    const { handle401 } = useAuth();
    const [queueRequested, setQueueRequested] = useState(false);
    const [queueResource, setQueueResource] = useState(null);
    const [page, setPage] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [queue, setQueue] = useState([]);

    const fetchData = (queueResource) =>
        resource[resourceVideosMethod](queueResource.id, page + 1, 5, [], {
            field: "created_at",
            order: "desc",
        }).then((response) => {
            setNumberOfPages(response.pagination.number_of_pages);

            setQueue(queue.concat(response.videos));

            setPage(response.pagination.current_page);
        });

    useEffect(() => {
        if (!queueRequested && !!resourceId) {
            setQueueRequested(true);

            resource[resourceMethod](resourceId)
                .then((res) => {
                    setQueueResource(res);
                    fetchData(res);
                })
                .catch((err) => {
                    if (!ApiClient.isCancel(err)) {
                        if (ApiClient.isStatus(err, 401)) {
                            handle401();
                        }
                    }
                });
        }
    }, [resourceId, fetchData, handle401, queueRequested, resource, resourceMethod]);

    return (
        <div className="o-playlist">
            <h3 className="o-playlist__title t-queue-title">
                {queueResource ? queueResource.name : "Loading..."}
            </h3>
            <div className="o-playlist__wrapper">
                <div className="o-playlist__list">
                    {!!queueResource && (
                        <InfiniteScroll
                            className="t-queue"
                            height={400}
                            dataLength={queue.length} // This is important field to render the next data
                            next={() => fetchData(queueResource)}
                            hasMore={!numberOfPages || numberOfPages > page}
                            loader={
                                <div style={{ textAlign: "center" }}>
                                    <LoadingIcon />
                                </div>
                            }
                            endMessage={
                                <div className="o-playlist__end-message">
                                    No more items to display
                                </div>
                            }
                        >
                            <VideoThumbnailSet videos={queue}>
                                {({ videos }) =>
                                    videos.map((video) => {
                                        const link = queryString.stringifyUrl({
                                            url: video.link,
                                            query: {
                                                [resourceName]: resourceId,
                                            },
                                        });

                                        return (
                                            <QueueVideoThumbnail
                                                link={link}
                                                active={video.id === videoId}
                                                key={video.id}
                                                video={video}
                                            />
                                        );
                                    })
                                }
                            </VideoThumbnailSet>
                        </InfiniteScroll>
                    )}
                </div>
            </div>
        </div>
    );
}

function VideoPresenters({ presenters }) {
    return !!presenters && presenters.length > 0 ? (
        <div className="o-filter">
            <h3 className="o-filter__title">Presenters</h3>
            <ul className="o-filter__list">
                {presenters.map((presenter, index) => {
                    presenter = new Category(presenter);

                    return (
                        <li
                            key={`video_presenters_${index}`}
                            className="o-filter__item"
                        >
                            <Link onClick={scrollToTop} to={presenter.link}>
                                {presenter.name}
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </div>
    ) : null;
}

function VideoTags({ tags }) {
    return tags && tags.length > 0 ? (
        <div className="o-filter o-filter--tags">
            <h3 className="o-filter__title">Tags</h3>
            <ul className="o-filter__list">
                {tags.map((tag, index) => {
                    const url = queryString.stringifyUrl(
                        {
                            url: "/search",
                            query: {
                                q: [tag],
                            },
                        },
                        {
                            arrayFormat: "bracket",
                        }
                    );

                    return (
                        <li
                            key={`video_tag_${index}`}
                            className="o-filter__item"
                        >
                            <Link onClick={scrollToTop} to={url}>
                                {tag}
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </div>
    ) : null;
}

function VideoCategories({ categories }) {
    return categories && categories.length > 0 ? (
        <div className="o-filter">
            <h3 className="o-filter__title">Categories</h3>
            <ul className="o-filter__list">
                {categories.map((category, index) => {
                    category = new Category(category);

                    return (
                        <li
                            key={`video_categories_${index}`}
                            className="o-filter__item"
                        >
                            <Link onClick={scrollToTop} to={category.link}>
                                {category.name}
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </div>
    ) : null;
}

function VideoDetails({ video }) {
    return (
        <>
            <h1>{video.name}</h1>
            <div className="l-description__datetime">
                <span className="l-description__date">
                    {video.thumbnailDate()}
                </span>
                <span className="l-description__time">
                    {video.thumbnailTime()}
                </span>
            </div>
            <div>
                <ProcessedText>{video.description}</ProcessedText>
            </div>
        </>
    );
}
