import * as Sentry from "@sentry/react";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ErrorBoundary from "../../components/error-boundary";
import ErrorMessage from "../../components/error-message";
import Form from "../../components/form";
import Field from "../../components/form/field";
import TextArea from "../../components/form/textarea";
import SidebarLayout from "../../components/sidebar-layout";
import SubmitButton from "../../components/submit-button";
import { useAuth, useDetails } from "../../providers/auth-provider";

function Contact(props) {
    return (
        <>
            <Helmet>
                <title>
                    Report An Issue — {process.env.RAZZLE_APPLICATION_NAME}
                </title>
                <meta
                    name="description"
                    content="Please use this form to report any issues you encounter when using Andertons Extra."
                />
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <SidebarLayout
                // ! Remove auth
                termsAndConditions
                copyright
                legal
                // ! 
                myAccount
                communicationPreferences
                extraBenefits
                manageSubscription
                contact
            >
                <h1>Having Issues With Andertons Extra?</h1>
                <ErrorBoundary
                    tags={{
                        page: "contact",
                        section: "contact form",
                    }}
                    fallback={() => (
                        <ErrorMessage message="Could not display contact form" />
                    )}
                >
                    <ContactForm />
                </ErrorBoundary>
            </SidebarLayout>
        </>
    );
}

export default Contact;

function ContactForm() {
    const { auth, token } = useAuth();
    const {
        details: { userId, email },
    } = useDetails();

    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [createdId, setCreatedId] = useState(false);

    const onSubmit = (data) => {
        setSubmitting(true);

        auth.setToken(token)
            .createCustomerTicket(data)
            .catch((e) => {
                console.error(e);
                setSubmitted(false);
                setSubmitting(false);
                Sentry.captureException(e, {
                    tags: {
                        page: "contact",
                        action: "contact form submission",
                    },
                });
                toast("An error occurred when submitting the form");
            })
            .then((ticket) => {
                setCreatedId(ticket.id);
                setSubmitting(false);
                setSubmitted(true);
            });
    };

    return (
        <>
            {!submitted && (
                <>
                    <p>
                        Please use this form to let us know about any issues you
                        are having with Andertons Extra.
                    </p>
                    <p>
                        For questions about our online store,{" "}
                        {process.env.RAZZLE_ECOMMERCE_SITE_DOMAIN}, please{" "}
                        <a
                            href="https://help.andertons.co.uk"
                            target="_blank"
                            rel="noopener"
                        >
                            visit our help centre
                        </a>
                        .
                    </p>
                    <Form
                        useFormParams={{
                            defaultValues: {
                                email: email,
                                id: userId,
                            },
                        }}
                        className="t-contact-form o-form"
                        onSubmit={onSubmit}
                    >
                        {(formMethods) => (
                            <>
                                <Field
                                    {...formMethods}
                                    required
                                    disabled
                                    id="id"
                                    displayName="ID"
                                    validation={{
                                        required: "ID is required",
                                    }}
                                    type="hidden"
                                    name="id"
                                />

                                <Field
                                    {...formMethods}
                                    placeholder="Your Email Address"
                                    className="o-form__field"
                                    labelPosition="above"
                                    disabled={submitting}
                                    validation={{
                                        required:
                                            "Your email address is required",
                                    }}
                                    required
                                    id="email"
                                    type="hidden"
                                    name="email"
                                    displayName="Email"
                                />

                                <Field
                                    {...formMethods}
                                    className="o-form__field"
                                    focusOnMount
                                    placeholder="e.g. Error when watching a video"
                                    labelPosition="above"
                                    disabled={submitting}
                                    validation={{
                                        required: "Summary is required",
                                        maxLength: {
                                            message:
                                                "Summary must be 128 characters or fewer",
                                            value: 128,
                                        },
                                    }}
                                    required
                                    id="subject"
                                    type="text"
                                    name="subject"
                                    displayName="A brief Summary of the Issue"
                                />

                                <Field
                                    {...formMethods}
                                    className="o-form__field"
                                    labelPosition="above"
                                    placeholder="e.g. I tried to watch 'The Captain Meets Pete Honoré' in Google Chrome and I saw the error message 'Unable to display video'"
                                    is={TextArea}
                                    disabled={submitting}
                                    required
                                    validation={{
                                        required: "Description is required",
                                        maxLength: {
                                            message:
                                                "Description must be 1024 characters or fewer",
                                            value: 1024,
                                        },
                                    }}
                                    id="message"
                                    name="message"
                                    displayName="Description of Issue"
                                >
                                    <p>
                                        Please give as many details as possible
                                        about the issue you are experiencing. It
                                        would be useful to know the exact steps
                                        you followed, which device and web
                                        browser you were using, and what
                                        time/date it was when you experienced
                                        the issues.
                                    </p>
                                </Field>

                                <SubmitButton
                                    staticClasses="btn btn--primary btn--full"
                                    loading={submitting}
                                >
                                    Submit
                                </SubmitButton>
                            </>
                        )}
                    </Form>
                </>
            )}

            {
                // TODO - Stephen to remove inline styles
                submitted && (
                    <div style={{ marginBottom: "24px" }}>
                        <p className="t-contact-form-confirmation-ticket-id">{`Thanks for submitting your information. A ticket with the ID: ${createdId} has been created.`}</p>
                        <p>
                            We'll look into the issue and be in touch if any
                            further information is required.
                        </p>
                        <button
                            className="btn btn--primary btn--full"
                            onClick={() =>
                                setSubmitted(false) && setCreatedId(false)
                            }
                        >
                            Report another problem?
                        </button>
                    </div>
                )
            }

            <p>
                By submitting this form, you are agreeing to our{" "}
                <Link to="/terms">Terms & Conditions of use.</Link>
            </p>
        </>
    );
}
