import React from "react";
import { Helmet } from "react-helmet";
import Imgix from "react-imgix";
import { Link, useLocation } from "react-router-dom";
import Carousel from "../../components/carousel";
// import StaticFeaturedVideo from "../../components/static-featured-video";
import ContinueWatchingCarousel from "../../components/continue-watching-carousel";
import ErrorBoundary from "../../components/error-boundary";
import ErrorMessage from "../../components/error-message";
import PresentersCarousel from "../../components/presenters-carousel";
import RecentlyAddedCarousel from "../../components/recently-added-carousel";
import StaticCategories from "../../components/static-categories";
import StaticCategory from "../../components/static-category";
import StaticCategoryCarouselItem from "../../components/static-category-carousel-item";
import TrendingNowCarousel from "../../components/trending-now-carousel";
import YouMightAlsoLikeCarousel from "../../components/you-might-also-like-carousel";
import staticCategoryIdMap from "./static-categories";

import { useStoryblok } from "../../storyblok-client";
import DynamicComponent from "../../components/dynamic-component/DynamicComponent";

import SbEditable from "storyblok-react";

function Home({ routeData: { staticCategories, story: originalStory } }) {
    const location = useLocation();
    const slug = location.pathname;
    
    const preview = process.env.NODE_ENV === "development";
    const { story, loading } = useStoryblok(slug, originalStory, preview);

    if (loading) {
        return <div>Loading....</div>;
    }

    return (
        <>
            <Helmet>
                <title>Home — {process.env.RAZZLE_APPLICATION_NAME}</title>
                <meta
                    name="description"
                    content="Start your Extra journey here. From the Andertons Extra homepage you can reach all your Extra benefits including ad-free video content & subscriber-only deals."
                />
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <div>
                {/* Static show grid */}
                {/* <div className="o-section o-black">
                    <div className="l-width">
                        <ErrorBoundary
                            tags={{
                                page: "home",
                                section: "shows grid",
                            }}
                            fallback={() => (
                                <ErrorMessage message="Could not display shows grid" />
                            )}
                        >
                            <ShowsGrid staticCategories={staticCategories} />
                        </ErrorBoundary>
                    </div>
                </div> */}

                {story && (
                    <SbEditable content={story}>
                        {Array.isArray(story.content.body) ? (
                            story.content.body.map((blok) => (
                                <DynamicComponent blok={blok} key={blok._uid} />
                            ))
                        ) : (
                            <div>Missing Content</div>
                        )}
                    </SbEditable>
                )}

                {/* Recently Added Carousel */}
                <ErrorBoundary
                    tags={{
                        page: "home",
                        section: "recently added carousel",
                    }}
                    fallback={() => (
                        <ErrorMessage message="Could not display recently added carousel" />
                    )}
                >
                    <RecentlyAddedCarousel />
                </ErrorBoundary>

                {/* Static instruments carousel */}
                <div className="o-section o-section--instruments l-width-small">
                    <div className="o-section--wrapper">
                        <div className="o-section--left">
                            <h2 className="o-section__title">
                                Browse by Instrument
                            </h2>
                        </div>
                    </div>
                    <ErrorBoundary
                        tags={{
                            page: "home",
                            section: "instruments carousel",
                        }}
                        fallback={() => (
                            <ErrorMessage message="Could not display instruments carousel" />
                        )}
                    >
                        <InstrumentsCarousel
                            staticCategories={staticCategories}
                        />
                    </ErrorBoundary>
                </div>

                {/* Hard coded Featured Category */}
                <div className="o-featured">
                    <picture className="o-featured__background">
                        <Imgix
                            htmlAttributes={{
                                src:
                                    "https://andertonsextra.imgix.net/videos/demo-dummy-featured-video/featured-background.jpg",
                                alt:
                                    'Play Like Pete "Danish Pete" Honoré background image',
                            }}
                            className="lazyload"
                            src={
                                "https://andertonsextra.imgix.net/videos/demo-dummy-featured-video/featured-background.jpg"
                            }
                            imgixParams={{ q: 65 }}
                            attributeConfig={{
                                src: "data-src",
                                srcSet: "data-srcset",
                                sizes: "data-sizes",
                            }}
                            width={1250}
                            height={703}
                        />
                    </picture>
                    <div className="o-featured__content">
                        <div className="o-featured--left">
                            <Link
                                to={
                                    "/categories/shows/play-like/series-1-_-play-like-peter-honoré"
                                }
                            >
                                <picture className="o-featured__picture">
                                    <Imgix
                                        htmlAttributes={{
                                            src:
                                                "https://andertonsextra.imgix.net/videos/demo-dummy-featured-video/default.jpg",
                                            alt:
                                                'Play Like Pete "Danish Pete" Honoré thumbnail image',
                                        }}
                                        className="o-featured__img lazyload"
                                        src={
                                            "https://andertonsextra.imgix.net/videos/demo-dummy-featured-video/default.jpg"
                                        }
                                        imgixParams={{ q: 65 }}
                                        attributeConfig={{
                                            src: "data-src",
                                            srcSet: "data-srcset",
                                            sizes: "data-sizes",
                                        }}
                                        width={1250}
                                        height={703}
                                    />
                                    <div
                                        className="in-progress in-progress--overlay"
                                        style={{
                                            width: "0%",
                                        }}
                                    ></div>
                                </picture>
                            </Link>
                        </div>
                        <div className="o-featured--right">
                            <h3 className="o-featured__title">
                                Play Like Pete "Danish Pete" Honoré
                            </h3>
                            <p className="o-featured__intro">
                                He’s toured and worked with Tom Jones, Il Divo,
                                Lionel Richie, Nicole Scherzinger, Atomic
                                Kitten, West Life, Girls Aloud, Paloma Faith and
                                of course Andertons! In this episode of ‘Play
                                Like’ Sam explores the playing style of our very
                                own guitar guru, Pete “Danish Pete” Honoré
                            </p>
                            <Link
                                to={
                                    "/categories/shows/play-like/series-1-_-play-like-peter-honoré"
                                }
                                className="btn btn--primary"
                            >
                                Watch Now
                            </Link>
                        </div>
                    </div>
                </div>

                {
                    // ! Remove auth
                }
                {/* Extra Deals Banner */}
                {/* <ErrorBoundary
                    tags={{
                        page: "home",
                        section: "extra deals banner",
                    }}
                    fallback={() => (
                        <ErrorMessage message="Could not display recently added carousel" />
                    )}
                >
                    <ExtraDealsBanner />
                </ErrorBoundary> */}

                {/* Trending Now Carousel */}
                {
                    // ! Remove auth
                }
                {/* <ErrorBoundary
                    tags={{
                        page: "home",
                        section: "trending now carousel",
                    }}
                    fallback={() => (
                        <ErrorMessage message="Could not display trending now carousel" />
                    )}
                >
                    <TrendingNowCarousel />
                </ErrorBoundary> */}

                {/* Continue Watching Carousel */}
                <ErrorBoundary
                    tags={{
                        page: "home",
                        section: "continue watching carousel",
                    }}
                    fallback={() => (
                        <ErrorMessage message="Could not display continue watching carousel" />
                    )}
                >
                    <ContinueWatchingCarousel />
                </ErrorBoundary>

                {/* You Might Also Like Carousel */}
                <ErrorBoundary
                    tags={{
                        page: "home",
                        section: "you might also like carousel",
                    }}
                    fallback={() => (
                        <ErrorMessage message="Could not display you might also like carousel" />
                    )}
                >
                    <YouMightAlsoLikeCarousel />
                </ErrorBoundary>

                {/* Our Presenters Carousel */}
                <ErrorBoundary
                    tags={{
                        page: "home",
                        section: "our presenters carousel",
                    }}
                    fallback={() => (
                        <ErrorMessage message="Could not display presenters carousel" />
                    )}
                >
                    <PresentersCarousel />
                </ErrorBoundary>
            </div>
        </>
    );
}

export default Home;

function ExtraDealsBanner() {
    return (
        <div className="o-section-small l-padding-small">
            <div className="l-width-small">
                <a
                    href={`${process.env.RAZZLE_ECOMMERCE_SITE_BASE_URL}/extra-deals`}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Andertons Extra Deals"
                    className="l-grid__link"
                >
                    <picture className="delivery-banner__picture">
                        <Imgix
                            htmlAttributes={{
                                src:
                                    "https://andertonsextra.imgix.net/static/pages/home/extra-benefits/banner.jpg",
                                alt:
                                    "Exclusive deals for Andertons Extra subscribers",
                            }}
                            className="delivery-banner__img lazyload"
                            src="https://andertonsextra.imgix.net/static/pages/home/extra-benefits/banner.jpg"
                            imgixParams={{ q: 80 }}
                            width={1150}
                            height={341}
                        />
                    </picture>
                </a>
            </div>
        </div>
    );
}

function ShowsGrid({ staticCategories }) {
    return (
        <StaticCategories data={staticCategories}>
            {({ categories }) => (
                <div className="l-grid l-grid--padding l-grid--1-s l-grid--2-m">
                    <div>
                        {/* Extra Exclusive Shows */}
                        <Link
                            to={`/exclusive-to-extra`}
                            title="Subscriber Only Content"
                            className="l-grid__link"
                        >
                            <picture className="l-grid__picture">
                                <Imgix
                                    htmlAttributes={{
                                        src:
                                            "https://andertonsextra.imgix.net/static/pages/home/extra-exclusive-content/hero-square.jpg",
                                        alt:
                                            "Extra Exclusive Content hero image",
                                    }}
                                    width={800}
                                    height={800}
                                    className="l-grid__img lazyload"
                                    src={
                                        "https://andertonsextra.imgix.net/static/pages/home/extra-exclusive-content/hero-square.jpg"
                                    }
                                    imgixParams={{ q: 65 }}
                                    attributeConfig={{
                                        src: "data-src",
                                        srcSet: "data-srcset",
                                        sizes: "data-sizes",
                                    }}
                                />
                            </picture>
                        </Link>
                    </div>
                    <div className="l-grid l-grid--2">
                        {/* The Captain Meets */}
                        <StaticCategory
                            category={categories[staticCategoryIdMap.shows[0]]}
                        />

                        {/* Sound Like */}
                        <StaticCategory
                            category={categories[staticCategoryIdMap.shows[1]]}
                        />

                        {/* Archive */}
                        <StaticCategory
                            category={categories[staticCategoryIdMap.shows[2]]}
                        />

                        {/* Extra Deals */}
                        <a
                            href={`${process.env.RAZZLE_ECOMMERCE_SITE_BASE_URL}/extra-deals`}
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Andertons Extra Deals"
                            className="l-grid__link"
                        >
                            <picture className="l-grid__picture">
                                <Imgix
                                    htmlAttributes={{
                                        src:
                                            "https://andertonsextra.imgix.net/static/pages/home/extra-benefits/hero-square.jpg",
                                        alt: "Extra Benefits hero image",
                                    }}
                                    width={800}
                                    height={800}
                                    className="l-grid__img lazyload"
                                    src={
                                        "https://andertonsextra.imgix.net/static/pages/home/extra-benefits/hero-square.jpg"
                                    }
                                    imgixParams={{ q: 65 }}
                                    attributeConfig={{
                                        src: "data-src",
                                        srcSet: "data-srcset",
                                        sizes: "data-sizes",
                                    }}
                                />
                            </picture>
                        </a>
                    </div>
                </div>
            )}
        </StaticCategories>
    );
}

function InstrumentsCarousel({ staticCategories }) {
    return (
        <StaticCategories data={staticCategories}>
            {({ categories }) => (
                <Carousel
                    slidesToShow={5}
                    responsive={[
                        {
                            breakpoint: 1200,
                            settings: {
                                arrows: true,
                                slidesToShow: 4,
                            },
                        },
                        {
                            breakpoint: 992,
                            settings: {
                                arrows: true,
                                slidesToShow: 3,
                            },
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                arrows: true,
                                slidesToShow: 2,
                            },
                        },
                        {
                            breakpoint: 576,
                            settings: {
                                arrows: true,
                                slidesToShow: 2,
                            },
                        },
                    ]}
                >
                    {/* Electric Guitars */}
                    <StaticCategoryCarouselItem
                        category={
                            categories[staticCategoryIdMap.instruments[0]]
                        }
                    />

                    {/* Bass Guitars */}
                    <StaticCategoryCarouselItem
                        category={
                            categories[staticCategoryIdMap.instruments[1]]
                        }
                    />

                    {/* Acoustic Guitars */}
                    <StaticCategoryCarouselItem
                        category={
                            categories[staticCategoryIdMap.instruments[2]]
                        }
                    />

                    {/* Drums */}
                    <StaticCategoryCarouselItem
                        category={
                            categories[staticCategoryIdMap.instruments[3]]
                        }
                    />

                    {/* Synth, Keys & tech */}
                    <StaticCategoryCarouselItem
                        category={
                            categories[staticCategoryIdMap.instruments[4]]
                        }
                    />
                </Carousel>
            )}
        </StaticCategories>
    );
}
