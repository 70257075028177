import React from "react";

const Image = ({ blok }) => {
    return (
        <picture>
            <img src={blok?.upload?.filename} />
        </picture>
    );
};

export default Image;
