import React from "react";

const processString = require("react-process-string");

function ProcessedText({ linksToAnchors, newlinesToParagraphs, children }) {
    let output = children;

    const convertLinksToAnchors = (string) => {
        const config = [
            {
                regex: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
                fn: (key, result) => (
                    <span key={key}>
                        <a target="_blank" rel="noreferrer" href={result[0]}>
                            {result[0]}
                        </a>
                    </span>
                ),
            },
        ];

        return processString(config)(string);
    };

    const convertNewlinesToParagraphs = (string) => {
        const config = [
            {
                regex: /\n/gi,
                fn: (key, result) => <p key={key}>{result}</p>,
            },
        ];

        return processString(config)(string);
    };

    if (linksToAnchors) {
        output = convertLinksToAnchors(output);
    }

    if (newlinesToParagraphs) {
        output = convertNewlinesToParagraphs(output);
    }

    return output;
}

ProcessedText.defaultProps = {
    linksToAnchors: true,
    newlinesToParagraphs: true,
    children: [],
};

export default ProcessedText;
