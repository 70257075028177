import React, { useState } from 'react';
import { useAuth, useDetails } from "../../../providers/auth-provider";
import { useAjaxEffect } from "../../../utils";
import ToggleSwitch from '../../toggle-switch';
import LoadingIcon from '../../loading-icon';

function EmailPreferenceToggle(props) {

    const {
        disabled,
        label
    } = props;

    const { auth, token, handle401 } = useAuth();
    const { details: { userId } } = useDetails();

    const [isLoading, setIsLoading] = useState(true);
    const [hasFailed, setHasFailed] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    // Check initial state
    useAjaxEffect({
        handle401,

        tags: {
            component: "EmailPreferenceToggle",
            action: "retrieve customer email preferences",
        },

        request: (cancelToken) => {
            auth.setCancelToken(cancelToken);
            return auth.setToken(token).getCustomerEmailPreferences(userId);
        },

        onSuccess: (data, mounted) => {
            if (mounted) {
                
                // If the email preferences are `null`, no 
                // customer was found in emarsys with this 
                // IBM ID. This isn't handled as a "create" 
                // in the API, currently, so we treat as a 
                // failed retrieval.
                if(!data.email_preferences) {
                    throw new Error(`No contact found with this IBM ID.`);
                }

                // String "1" indicates user is opted in
                // "2" indicates opted out.
                setIsChecked(data.email_preferences.optin === "1");
                setIsLoading(false);
            }
        },

        onError: (err, mounted) => {
            if (mounted) {
                setHasFailed(true);
                console.warn(`Failed to retrieve email preferences. ${err.message}`);
            }
        },

        watch: [],

        cancelMessage: "Email preference data retrieval cancelled due to component unmount"
    });

    /**
     * Sets the user's email marketing preference in Emarsys 
     * and IBM, via the Auth API
     * 
     * @param   {Boolean} optin true for opt in, false for opt out
     * @returns {Boolean}       returns a promise resolving the preference 
     *                          the user hoped for on success, or the opposite 
     *                          on failure
     */
    const setPreference = (optin) => {
        return new Promise((resolve) => {
            auth
                .setToken(token)
                .updateOptInAndSubscriptionStatus(userId, optin)
                .then((result) => {
                    if(result.status === 200) {
                        resolve(optin);
                    } else {
                        resolve(!optin);
                    }
                }).catch(() => {
                    resolve(!optin);
                });
        }); 
    }

    // Render
    if(hasFailed) return null;

    return isLoading ? <LoadingIcon /> : (
        <ToggleSwitch 
            checked={isChecked}
            disabled={disabled}
            onToggle={setPreference}
            label={label}
            successMessage={`We've successfully updated your email preferences!`}
            errorMessage={`Sorry, we could not update your email preferences. Please try again.`}
        >                  
        </ToggleSwitch>
    );
}

export default EmailPreferenceToggle;