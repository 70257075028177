/**
 * Exports a config object for the Firebase V9 SDK
 * See: https://support.google.com/firebase/answer/7015592 for more information
 *
 * Allows user to supply new config options, or falls back to env defaults
 */
export default class FirebaseConfig {
    constructor(overrides = {}) {
        const {
            apiKey,
            authDomain,
            projectId,
            storageBucket,
            messagingSenderId,
            appId,
            measurementId,
        } = overrides;

        this.apiKey = apiKey || process.env.RAZZLE_FIREBASE_API_KEY;
        this.authDomain = authDomain || process.env.RAZZLE_FIREBASE_AUTH_DOMAIN;
        this.projectId = projectId || process.env.RAZZLE_FIREBASE_PROJECT_ID;
        this.storageBucket =
            storageBucket || process.env.RAZZLE_FIREBASE_STORAGE_BUCKET;
        this.messagingSenderId =
            messagingSenderId ||
            process.env.RAZZLE_FIREBASE_MESSAGING_SENDER_ID;
        this.appId = appId || process.env.RAZZLE_FIREBASE_APP_ID;
        this.measurementId =
            measurementId || process.env.RAZZLE_FIREBASE_MEASUREMENT_ID;
    }
}
