import React from "react";
import Imgix from "react-imgix";
import { Link } from "react-router-dom";
import { useCategory } from "../../providers/resource-provider";

function StaticCategoryCarouselItem({ category }) {
    const { handleCategoryClick } = useCategory();

    return category ? (
        <Link
            onClick={() => handleCategoryClick(category)}
            to={category.link}
            className="t-static-category-carousel-link l-grid-link"
        >
            <picture className="l-grid-picture">
                <Imgix
                    htmlAttributes={{
                        src: category.portrait_image,
                        alt: `${category.name} portrait image`,
                    }}
                    className="t-static-category-carousel-image slick-img lazyload"
                    src={category.portrait_image}
                    imgixParams={{ q: 65 }}
                    attributeConfig={{
                        src: "data-src",
                        srcSet: "data-srcset",
                        sizes: "data-sizes",
                    }}
                    width={215}
                    height={292}
                />
            </picture>
        </Link>
    ) : null;
}

export default StaticCategoryCarouselItem;
