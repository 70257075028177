import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../auth-provider";

const SubscriptionContext = createContext(null);

export const useSubscription = () => useContext(SubscriptionContext);

function SubscriptionProvider({ children }) {
    const { authenticated } = useAuth();

    const [customerExistence, setCustomerExistence] = useState(null);
    const [subscriptionDetails, setSubscriptionDetails] = useState(null);
    const [customerDetails, setCustomerDetails] = useState(null);
    const [addressDetails, setAddressDetails] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [optIn, setOptIn] = useState(null);
    const [authenticatedCustomer, setAuthenticatedCustomer] = useState(null);
    const [showCheckout, setShowCheckout] = useState(false);
    const [couponCode, setCouponCode] = useState(false);
    const history = useHistory();

    /**
     * Hook to check whether we need to redirect
     * back to first sign up step due to abandoned
     * checkout. Can't put this in the chargebee hooks
     * as it doesn't seem to be able to keep authenticated
     * in scope after initialisation (so it stays false).
     * After initial mount, keep an eye out for changes in
     * showCheckout to false. If showCheckout changes to false
     * (i.e. checkout closed and CB callback fired) and user
     * is not authenticated, we should send them back to the
     * first sign up step
     */
    const isInitialMount = useRef(true);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else if (!showCheckout && !authenticated) {
            history.push("/join");
        }
    }, [showCheckout]);

    const value = {
        customerExistence,
        setCustomerExistence,
        customerDetails,
        setCustomerDetails,
        subscriptionDetails,
        setSubscriptionDetails,
        addressDetails,
        setAddressDetails,
        selectedPlan,
        setSelectedPlan,
        optIn,
        setOptIn,
        authenticatedCustomer,
        setAuthenticatedCustomer,
        showCheckout,
        setShowCheckout,
        couponCode,
        setCouponCode,
    };

    return (
        <SubscriptionContext.Provider value={value}>
            {children}
        </SubscriptionContext.Provider>
    );
}

export default SubscriptionProvider;
