import React, { createContext, useContext, useEffect } from "react";
// ere
import FirebaseConfig from "../../models/firebase/config";

import { toast } from "react-toastify";
import { initializeApp } from "firebase/app";
import {
    getMessaging,
    onMessage,
    getToken,
    deleteToken,
} from "firebase/messaging";
import { useLocalStorage } from "../local-storage-provider";

const PushNotificationContext = createContext(null);
var messaging;
export const usePushNotification = () => useContext(PushNotificationContext);

// Initialise Firebase
initializeApp(new FirebaseConfig());
const vapidKey = process.env.RAZZLE_FIREBASE_VAPID_PUBLIC_KEY;

function PushNotificationProvider({ children }) {
    const aexLocalStorage = useLocalStorage();

    useEffect(() => {
        if (notificationsActive) {
            // Get the Firebase messaging handler.
            // Run in useEffect so it only runs client-side
            messaging = getMessaging();

            // Handle incoming push messages when
            // app is in the foreground
            onMessage(messaging, (payload) => {
                const ToastMessage = () => (
                    <div>
                        <p>{payload.notification.title}</p>
                        <p>{payload.notification.body}</p>
                    </div>
                );
                toast(<ToastMessage />);
            });
        }
    }, []);

    /* Returns true if we're running in the browser, false if not */
    const isInBrowser = () =>
        typeof window !== "undefined" && !!window.Notification;

    /* Returns true if the browser supports notifications, false if not */
    const browserSupportsNotifications = () => {
        if (isInBrowser()) {
            return "Notification" in window;
        } else {
            return false;
        }
    };

    /* Sets notification preference - either by creating the FCM token or by deleting it*/
    const setNotificationPreference = (preference) => {
        // If the user is trying to opt in, create an FCM subscription
        // and store the token
        if (preference === true) {
            return getToken(messaging, { vapidKey })
                .then((token) => {
                    if (token) {
                        // We have an FCM token so the user is subscribed
                        aexLocalStorage.setFcmToken(token);
                        return preference;
                    }
                })
                .catch((error) => {
                    // The subscription failed
                    console.error(error.message);

                    let advice = `Sorry, we could not update your push notification preferences. `;

                    if (Notification.permission === "denied") {
                        advice += `Please change your browser settings to allow notifications from this site.`;
                    } else if (Notification.permission === "default") {
                        advice += `Please ensure you click "Allow" when your browser asks for permission to send you notifications.`;
                    }

                    // Tell the user we couldn't enact their preference and advise
                    // on the best course of action.
                    toast(advice);

                    // Tell the component that we couldn't enact the user's preference
                    return !preference;
                });
        }

        // If the user is trying to opt out, delete their FCM subscription
        else {
            return deleteToken(messaging)
                .then((deleted) => {
                    if (deleted) {
                        aexLocalStorage.clearFcmToken();
                        return preference;
                    }
                })
                .catch((error) => {
                    // Unsubscribing failed
                    console.error(error.message);

                    // Tell the user that we couldn't enact the their preference
                    toast(
                        `Sorry we could not unsubscribe you from push notifications. Please try again.`
                    );

                    // Tell the component that we couldn't enact the user's preference
                    return !preference;
                });
        }
    };
    const getNotificationPreference = () => {
        if (isInBrowser()) {
            // this is hacky but the LocalStorage provider is idiosyncratic
            // TODO: refactor this at some point
            return (
                Object.keys(localStorage).filter((key) =>
                    key.includes("andertons_extra_fcm_token")
                ).length > 0
            );
        } else return false;
    };

    const notificationsActive = () =>
        process.env.RAZZLE_PUSH_NOTIFICATIONS_ACTIVE.toLowerCase() === "true";

    const value = {
        setNotificationPreference,
        getNotificationPreference,
        browserSupportsNotifications,
        notificationsActive,
    };

    return (
        <PushNotificationContext.Provider value={value}>
            {children}
        </PushNotificationContext.Provider>
    );
}

export default PushNotificationProvider;
