import Category from "../models/resource/category";
import Pagination from "../models/resource/pagination";
import AuthHoldingPage from "../pages/auth-holding-page";
import Contact from "../pages/contact";
import Copyright from "../pages/copyright";
import ErrorPage from "../pages/error";
import ExclusiveToExtra from "../pages/exclusive-to-extra";
import exclusiveStaticCategories from "../pages/exclusive-to-extra/static-categories";
import ExtraBenefits from "../pages/extra-benefits";
import ForgotPassword from "../pages/forgot-password";
import Home from "../pages/home";
import homePageStaticCategories from "../pages/home/static-categories";
import IntermediateCategory from "../pages/intermediate-category";
import Join from "../pages/join";
import JoinHelp from "../pages/join-help";
import Legal from "../pages/legal";
import Login from "../pages/login";
import ManageSubscription from "../pages/manage-subscription";
import CommunicationPreferences from "../pages/communication-preferences";
import MidLevelArchive from "../pages/mid-level-archive";
import MyAccount from "../pages/my-account";
import NoMatch from "../pages/no-match";
import Playlist from "../pages/playlist";
import PlaylistsIndex from "../pages/playlists-index";
import ResetPassword from "../pages/reset-password";
import Search from "../pages/search";
import SeriesSelectCategory from "../pages/series-select-category";
import ServiceProposition from "../pages/service-proposition";
import Terms from "../pages/terms";
import TopLevelArchive from "../pages/top-level-archive";
import TopLevelCategory from "../pages/top-level-category";
import TopLevelInstruments from "../pages/top-level-instruments";
import TopLevelPresenters from "../pages/top-level-presenters";
import Video from "../pages/video";
import { parseSort } from "../utils";
import AuthenticatedOnlyRoute from "./authenticated-only-route";
import UnauthenticatedOnlyRoute from "./unauthenticated-only-route";
import UnrestrictedRoute from "./unrestricted-route";

const routes = [
    /**
     * OVERRIDE ROUTES
     *
     * Routes that do not follow "normal" behaviour
     * e.g. a certain URL that renders a different
     * component to all others of a similar URL pattern
     */
    {
        path: "/categories/archive",
        // ! Remove auth
        routeType: UnrestrictedRoute,
        component: TopLevelArchive,
        exact: true,
        fetchRequired: ({ routeData: { category } }) =>
            !category || category.slug !== "archive" || !category.children,
        fetchInitialData: ({ resource, handleCategoryClick }) => {
            const promise = resource.getTopLevelCategory("archive");

            return promise.then((category) => {
                handleCategoryClick(category);

                return { category };
            });
        },
    },

    {
        path: "/categories/presenters",
        // ! Remove auth
        routeType: UnrestrictedRoute,
        component: TopLevelPresenters,
        exact: true,
        fetchRequired: ({ routeData: { category } }) =>
            !category || category.slug !== "presenters" || !category.children,
        fetchInitialData: ({ resource, handleCategoryClick }) => {
            const promise = resource.getTopLevelCategory("presenters");

            return promise.then((category) => {
                handleCategoryClick(category);

                return { category };
            });
        },
    },

    {
        path: "/categories/instruments",
        // ! Remove auth
        routeType: UnrestrictedRoute,
        component: TopLevelInstruments,
        exact: true,
        fetchRequired: ({ routeData: { category } }) =>
            !category || category.slug !== "instruments" || !category.children,
        fetchInitialData: ({ resource, handleCategoryClick }) => {
            const promise = resource.getTopLevelCategory("instruments");

            return promise.then((category) => {
                handleCategoryClick(category);

                return { category };
            });
        },
    },

    {
        path: "/categories/archive/:slug",
        // ! Remove auth
        routeType: UnrestrictedRoute,
        component: MidLevelArchive,
        exact: true,
        fetchRequired: ({ routeData: { category }, match }) =>
            !category ||
            category.slug !== match.params.slug ||
            !category.months,
        fetchInitialData: ({ match, resource, handleCategoryClick }) => {
            const promise = resource.getTopLevelCategoryGrandchildren(
                "archive",
                match.params.slug
            );

            return promise.then((categories) => {
                const category = new Category({
                    description: categories[0].parent_description,
                    hero_image: categories[0].parent_hero_image,
                    slug: match.params.slug,
                    id: categories[0].parent_id,
                    name: categories[0].parent_name,
                    months: categories,
                });

                handleCategoryClick(category);

                return { category };
            });
        },
    },

    /**
     * Splash Pages
     */
    // {
    //     path: "/",
    //     exact: true,
    //     routeType: UnrestrictedRoute,
    //     component: AuthHoldingPage,
    // },
    {
        path: "/home",
        // ! Remove auth
        routeType: UnrestrictedRoute,
        exact: true,
        component: Home,
        fetchRequired: ({ routeData: { staticCategories } }) =>
            !staticCategories,
        fetchInitialData: ({ resource }) =>
            Promise.all([
                resource.getCategoriesByIds(
                    homePageStaticCategories.shows.concat(
                        homePageStaticCategories.instruments
                    )
                ),
            ]).then((responses) => ({
                staticCategories: responses[0],
            })),
    },

    {
        path: "/exclusive-to-extra",
        // ! Remove auth
        routeType: UnrestrictedRoute,
        exact: true,
        component: ExclusiveToExtra,
        fetchRequired: ({ routeData: { staticCategories } }) =>
            !staticCategories,
        fetchInitialData: ({ resource }) =>
            Promise.all([
                resource.getCategoriesByIds(exclusiveStaticCategories.shows),
            ]).then((responses) => ({
                staticCategories: responses[0],
            })),
    },

    {
        path: "/get-extra",
        routeType: UnauthenticatedOnlyRoute,
        exact: true,
        component: ServiceProposition,
    },

    /**
     * Account / Authentication
     */
    {
        path: "/login",
        routeType: UnauthenticatedOnlyRoute,
        exact: true,
        component: Login,
    },

    {
        path: "/forgot-password",
        routeType: UnauthenticatedOnlyRoute,
        exact: true,
        component: ForgotPassword,
    },

    {
        path: "/reset-password",
        routeType: UnauthenticatedOnlyRoute,
        exact: true,
        component: ResetPassword,
    },

    {
        path: "/extra-benefits",
        routeType: AuthenticatedOnlyRoute,
        exact: true,
        component: ExtraBenefits,
    },
    {
        path: "/communication-preferences",
        routeType: AuthenticatedOnlyRoute,
        exact: true,
        component: CommunicationPreferences,
    },

    {
        path: "/my-account",
        routeType: AuthenticatedOnlyRoute,
        exact: true,
        component: MyAccount,
    },

    {
        path: "/my-account/manage-subscription",
        routeType: AuthenticatedOnlyRoute,
        exact: true,
        component: ManageSubscription,
    },

    {
        path: "/contact",
        routeType: AuthenticatedOnlyRoute,
        exact: true,
        component: Contact,
    },

    {
        path: "/join/:step?",
        routeType: UnauthenticatedOnlyRoute,
        exact: true,
        component: Join,
    },

    {
        path: "/join-help",
        routeType: UnauthenticatedOnlyRoute,
        exact: true,
        component: JoinHelp,
    },

    {
        path: "/legal",
        routeType: UnrestrictedRoute,
        exact: true,
        component: Legal,
    },

    {
        path: "/terms",
        routeType: UnrestrictedRoute,
        exact: true,
        component: Terms,
    },

    {
        path: "/copyright",
        routeType: UnrestrictedRoute,
        exact: true,
        component: Copyright,
    },

    /**
     * Navigation
     */
    {
        path: "/categories/:slug",
        // ! Remove auth
        routeType: UnrestrictedRoute,
        component: TopLevelCategory,
        exact: true,
        fetchRequired: ({ routeData: { category }, match }) =>
            !category ||
            category.slug !== match.params.slug ||
            !category.children,
        fetchInitialData: ({ match, resource, handleCategoryClick }) => {
            const promise = resource.getTopLevelCategory(match.params.slug);

            return promise.then((category) => {
                handleCategoryClick(category);

                return { category };
            });
        },
    },

    /**
     * Intermediate category route.
     *
     * As the application does not reliably have enough
     * data pre-navigation to this route to know whether
     * the target category is bottom level, we must hit
     * an intermediate component whilst the category
     * is retrieved. If the category is bottom level,
     * then proceed to render a bottom level category
     * component. Otherwise, we will need to route to
     * another level down the category tree.
     */
    {
        path: "/categories/:parentSlug/:slug",
        // ! Remove auth
        routeType: UnrestrictedRoute,
        component: IntermediateCategory,
        exact: true,
        fetchRequired: ({ routeData: { category }, query, match }) =>
            /**
             * We need to load page critical data if there's
             * no category in provider or if it has no videos.
             * Don't need to worry about non-bottom level as we'd be hitting
             * a different route by the time that's relevant per
             * the redirect below.
             */
            !category ||
            !category.videos ||
            category.slug !== match.params.slug ||
            !category.last_page_fetched ||
            parseInt(category.last_page_fetched) !== parseInt(query.page ?? 1),

        fetchInitialData: ({
            match,
            resource,
            redirect,
            handleCategoryClick,
            routeData: { category, pagination },
            query,
        }) => {
            let promise = Promise.resolve(false);

            /**
             * We can skip one request if we already have the category
             * in the provider. If we don't, then the first thing to
             * do is get that and set it in the provider
             */
            if (!category || category.slug !== match.params.slug) {
                /**
                 * Get the category
                 */
                promise = resource
                    .getTopLevelCategoryChild(
                        match.params.parentSlug,
                        match.params.slug
                    )
                    .then((response) => {
                        category = response;

                        /**
                         * If the category is bottom level, get the videos for it
                         * otherwise, get its children so we can redirect to the
                         * first child
                         */
                        return category.is_bottom_level
                            ? resource.getMediaByCategory(
                                  category.id,
                                  query.page ?? 1,
                                  20,
                                  query.q ?? [],
                                  parseSort(query.sort ?? "created_at-desc")
                              )
                            : resource.getCategoriesByParent(category.id);
                    });
            } else if (!!category && category.slug === match.params.slug) {
                /**
                 * We know that we have a category in the provider and it's the
                 * right one for our route, so we just need to either get
                 * the videos or children for it, dependent on whether it's bottom
                 * level
                 */
                promise = category.is_bottom_level
                    ? resource.getMediaByCategory(
                          category.id,
                          query.page ?? 1,
                          20,
                          query.q ?? [],
                          parseSort(query.sort ?? "created_at-desc")
                      )
                    : resource.getCategoriesByParent(category.id);
            }

            return promise.then((response) => {
                /**
                 * If we're dealing with a non-bottom level category,
                 * we need to redirect to the category's first child.
                 * By now, we should have the children.
                 */
                if (!category.is_bottom_level) {
                    /**
                     * Check there's at least one child
                     * for the category
                     */
                    if (response.length > 0) {
                        /**
                         * Set the category in the provider and
                         * redirect to the first child
                         */
                        handleCategoryClick(category);

                        redirect(response[0].link);

                        return { category };
                    } else {
                        throw new Error(
                            "Category appears as mid level but has no children"
                        );
                    }
                } else {
                    category.videos = response.videos;
                    category.last_page_fetched = query.page ?? 1;

                    /**
                     * We're dealing with a bottom level category,
                     * so update the category in the provider to include
                     * videos. This is done as a nested property rather than
                     * a separate array so that we know to get new
                     * videos when the category changes - otherwise,
                     * we might end up having category A's videos and
                     * category B's other data, with no real way of knowing
                     */
                    handleCategoryClick(category);

                    if (!(response.pagination instanceof Pagination)) {
                        response.pagination = new Pagination(
                            response.pagination
                        );
                    }

                    pagination.setNumberOfPages(
                        response.pagination.getNumberOfPages()
                    );
                    pagination.setTotalItems(
                        response.pagination.getTotalItems()
                    );
                    pagination.setPageSize(20);
                    pagination.setShowPagination(
                        response.pagination.getTotalItems() > 0
                    );

                    return {
                        category,
                        pagination,
                    };
                }
            });
        },
    },
    {
        path: "/categories/:grandparentSlug/:parentSlug/:slug",
        // ! Remove auth
        routeType: UnrestrictedRoute,
        component: SeriesSelectCategory,
        exact: true,
        fetchRequired: ({ routeData: { category }, match }) => {
            return (
                !category ||
                category.slug !== match.params.slug ||
                !category.videos
            );
        },
        fetchInitialData: ({
            match,
            resource,
            handleCategoryClick,
            query,
            routeData: { pagination, category },
        }) => {
            /**
             * If the category is already correct in the context provider,
             * we don't need to fetch it again, so just resolve to the siblings
             * and skip retrieval of them
             *
             * Either way, we'll need to subsequently fetch the videos for the
             * category
             */
            const categoryPromise =
                category &&
                category.slug === match.params.slug &&
                category.siblings
                    ? Promise.resolve(category.siblings)
                    : resource.getTopLevelCategoryGrandchildren(
                          match.params.grandparentSlug,
                          match.params.parentSlug
                      );

            let activeCategory = null;

            return categoryPromise
                .then((categories) => {
                    activeCategory = categories.find(
                        (category) => category.slug === match.params.slug
                    );

                    activeCategory = {
                        ...activeCategory,
                        siblings: categories,
                    };

                    return resource.getMediaByCategory(
                        activeCategory.id,
                        query.page ?? 1,
                        10,
                        [],
                        parseSort("created_at-desc")
                    );
                })
                .then(({ videos, pagination: responsePagination }) => {
                    activeCategory = {
                        ...activeCategory,
                        videos,
                    };

                    pagination.setPageSize(10);

                    pagination.setNumberOfPages(
                        responsePagination.getNumberOfPages()
                    );

                    pagination.setTotalItems(
                        responsePagination.getTotalItems()
                    );

                    pagination.setShowPagination(
                        responsePagination.getTotalItems() > 0
                    );

                    handleCategoryClick(activeCategory);

                    return {
                        category: activeCategory,
                        pagination,
                    };
                });
        },
    },
    {
        path: "/search",
        // ! Remove auth
        routeType: UnrestrictedRoute,
        component: Search,
        exact: true,
        onNoFetchRequired: ({ query, routeData: { pagination } }) => {
            if (!query.q) {
                pagination.setShowPagination(false);
            }
        },
        fetchRequired: ({ query, routeData }) => {
            const diff = (routeData.searchTerms ?? []).filter(
                (term) => !(query.q ?? []).includes(term)
            );

            return (!!query.q && !routeData.results) || diff.length > 0;
        },
        fetchInitialData: ({ query, resource, routeData: { pagination } }) =>
            resource
                .getSearchResults(
                    query.q,
                    query.page ?? 1,
                    20,
                    parseSort(query.sort ?? "created_at-desc")
                )
                .then(({ videos, ...response }) => {
                    /**
                     * If we're in a client side rendered flow,
                     * we'll have a pagination instance in the provider.
                     * If so, update our pagination data. If in an SSR flow,
                     * this will get passed down when we initialise the provider
                     * instance
                     */
                    if (pagination) {
                        if (!(response.pagination instanceof Pagination)) {
                            response.pagination = new Pagination(
                                response.pagination
                            );
                        }

                        pagination.setNumberOfPages(
                            response.pagination.getNumberOfPages()
                        );
                        pagination.setTotalItems(
                            response.pagination.getTotalItems()
                        );
                        pagination.setPageSize(20);
                        pagination.setShowPagination(
                            query.q && response.pagination.getTotalItems() > 0
                        );
                    }

                    return {
                        results: videos,
                        searchTerms: query.q,
                        pagination,
                    };
                }),
    },
    {
        // ? Need to check if this is still valid in open model
        path: "/playlists",
        // ! Remove auth
        routeType: UnrestrictedRoute,
        component: PlaylistsIndex,
        exact: true,
        fetchRequired: ({ routeData: { playlists } }) => !playlists,
        fetchInitialData: ({ resource, query, routeData: { pagination } }) =>
            resource
                .getPlaylists(query.page ?? 1)
                .then(({ playlists, pagination: responsePagination }) => {
                    pagination.setNumberOfPages(
                        responsePagination.getNumberOfPages()
                    );
                    pagination.setTotalItems(
                        responsePagination.getTotalItems()
                    );
                    pagination.setPageSize(20);
                    pagination.setShowPagination(
                        responsePagination.getTotalItems() > 0
                    );

                    return {
                        playlists,
                        pagination,
                    };
                }),
    },
    {
        path: "/playlists/:id/:slug?",
        routeType: AuthenticatedOnlyRoute,
        component: Playlist,
        exact: true,
        fetchRequired: ({ match, routeData: { playlist } }) => {
            return (
                !playlist || playlist.id !== match.params.id || !playlist.videos
            );
        },
        fetchInitialData: ({
            resource,
            match,
            query,
            playlist,
            handlePlaylistClick,
            routeData: { pagination },
        }) =>
            /**
             * We may already have the correct playlist in the provider,
             * so check whether we only need to retrieve videos
             */
            Promise.all([
                !!playlist && playlist.id === match.params.id
                    ? Promise.resolve(playlist)
                    : resource.getPlaylist(match.params.id),
                resource.getMediaByPlaylist(
                    match.params.id,
                    query.page ?? 1,
                    20
                ),
            ]).then((responses) => {
                const [playlist, media] = responses;

                const { pagination: responsePagination, videos } = media;

                playlist.videos = videos;

                handlePlaylistClick(playlist);

                pagination.setNumberOfPages(
                    responsePagination.getNumberOfPages()
                );
                pagination.setTotalItems(responsePagination.getTotalItems());
                pagination.setPageSize(20);
                pagination.setShowPagination(
                    responsePagination.getTotalItems() > 0
                );

                return {
                    playlist,
                    pagination,
                };
            }),
    },

    /**
     * Watch Pages
     */
    {
        path: "/videos/:id/:slug?",
        // ! Remove auth
        routeType: UnrestrictedRoute,
        component: Video,
        exact: true,
        fetchRequired: ({ routeData: { video }, match }) =>
            !video || video.id !== match.params.id,
        fetchInitialData: ({ match, resource, handleVideoClick }) => {
            const promise = resource.getVideo(match.params.id);

            return promise.then((video) => {
                handleVideoClick(video);

                return {
                    video,
                };
            });
        },
    },

    /**
     * ERROR ROUTE
     */
    {
        path: "/error",
        routeType: UnrestrictedRoute,
        component: ErrorPage,
        exact: true,
    },

    /**
     * FALLBACK ROUTES
     */
    {
        path: "/app-shell",
        exact: true,
        component: NoMatch,
        routeType: UnrestrictedRoute,
    },

    {
        routeType: UnrestrictedRoute,
        component: NoMatch,
    },
];

export default routes;
