import BaseResponseModel from "../../base-response-model";

class Pagination extends BaseResponseModel {

    constructor(data) {
        super(data);

        this.show_pagination = this.total_items > 0;
    }

    getTotalItems() {
        return this.total_items;
    }

    setTotalItems(totalItems) {
        this.total_items = totalItems;

        return this;
    }

    getNumberOfPages() {
        return this.number_of_pages;
    }

    setNumberOfPages(numberOfPages) {
        this.number_of_pages = numberOfPages;

        return this;
    }

    getPageSize() {
        return this.page_size;
    }

    setPageSize(pageSize) {
        this.page_size = pageSize;

        return this;
    }

    getShowPagination() {
        return this.show_pagination;
    }

    setShowPagination(showPagination) {
        this.show_pagination = showPagination;

        return this;
    }
}

export default Pagination;
