import React from "react";
import { Helmet } from "react-helmet";
import Imgix from "react-imgix";
import { Link } from "react-router-dom";

export default function Error({ reloadUrl, message }) {
    return (
        <>
            <Helmet>
                <title>An Error Occurred — {process.env.RAZZLE_APPLICATION_NAME}</title>
                <meta
                    name="description"
                    content="We're sorry but something has gone wrong! You can report this error to the Andertons Extra team and we'll look into it. Join Extra today from just £2.99!"
                />
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <div className="error-page">
                <div className="error-page__message">
                    <h1>Something went wrong</h1>
                    <p>{message}</p>
                    <Link to={reloadUrl} className="btn">
                        Reload the page
                    </Link>
                </div>
                <Imgix
                    htmlAttributes={{
                        src:
                            "https://andertonsextra.imgix.net/static/logos/andertons-extra-x-logo.jpg",
                        alt: "Andertons Extra Logo",
                    }}
                    width={260}
                    className="error-page__image"
                    src={
                        "https://andertonsextra.imgix.net/static/logos/andertons-extra-x-logo.jpg"
                    }
                    imgixParams={{ q: 65 }}
                />
            </div>
        </>
    );
}

Error.defaultProps = {
    message: "Whoops! An unexpected error occurred. If you're seeing this page, we've been notified and we're working on it!",
    reloadUrl: "/home",
}