import React from "react";
import VideoThumbnail from "../video-thumbnail";

function GridVideoThumbnail(props) {
    return (
        <li>
            <VideoThumbnail {...props} />
        </li>
    );
}

export default GridVideoThumbnail;
