import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import SidebarLayout from "../../components/sidebar-layout";
import { useAuth } from "../../providers/auth-provider";

function Legal(props) {
    const { authenticated } = useAuth();

    return (
        <>
            <Helmet>
                <title>Legal Information — {process.env.RAZZLE_APPLICATION_NAME}</title>
                <meta
                    name="description"
                    content="Click to view our Legal information for the Andertons Extra subscription service. Subscribe to EXTRA today from just £2.99 per month!"
                />
            </Helmet>
            <SidebarLayout
                termsAndConditions
                copyright
                legal
                contact={authenticated}
            >
                <h1>Legal Information</h1>

                <p>
                    Follow the links below to view our terms of service, privacy
                    policy and copyright information.
                </p>

                <ul>
                    <li>
                        <Link to="/terms">
                            Terms & Conditions / Privacy Policy
                        </Link>
                    </li>
                    <li>
                        <Link to="/copyright">Copyright</Link>
                    </li>
                </ul>
            </SidebarLayout>
        </>
    );
}

export default Legal;
