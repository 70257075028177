import queryString from "query-string";
import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { useAuth } from "../../providers/auth-provider";

function UnauthenticatedOnlyRoute({ topLevelCategories, overlayRef, ...props }) {
    const location = useLocation();
    const { authenticated } = useAuth();

    const query = queryString.parse(location.search);

    /**
     * EXTRA-101 - don't redirect to /home if authed and attempting
     * to access /join-help. Instead redirect to /contact
     */
    if (location.pathname === "/join-help" && authenticated) {
        return <Redirect to="/contact" />;
    }

    return authenticated ? (
        <Redirect to={query.from ? query.from : "/home"} />
    ) : (
            <Route {...props} />
        );
}

export default UnauthenticatedOnlyRoute;
