import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../../providers/auth-provider";
import { useCategory, useResource } from "../../../providers/resource-provider";
import { useAjaxEffect } from "../../../utils";
import LoadingIcon from "../../loading-icon";

function NavbarItem({ category, index, viewChildren, handleViewChildrenClick }) {
    const [loading, setLoading] = useState(true);
    const [children, setChildren] = useState(null);
    const { resource } = useResource();
    const { handle401 } = useAuth();
    const { handleCategoryClick } = useCategory();
    const location = useLocation();

    useAjaxEffect({
        handle401,

        tags: {
            component: "NavbarItem",
            action: "retrieve top level category children",
        },

        requestRequired: () => viewChildren && !children,

        request: (cancelToken) => {
            resource.setCancelToken(cancelToken);

            return resource.getCategoriesByTopLevelParent(category.slug);
        },

        onSuccess: (data, mounted) => {
            if (mounted) {
                setChildren(data);
                setLoading(false);
            }
        },

        onError: (err, mounted) => {
            if (mounted) {
                handleViewChildrenClick(false);
                setLoading(false);
            }
        },

        watch: [viewChildren, children],

        cancelMessage:
            "Navbar item data retrieval cancelled due to component unmount",
    });

    return (
        <>
            <a
                onClick={e => {
                    e.preventDefault();
                    return handleViewChildrenClick(viewChildren ? false : index)
                }}
                href={category.link}
                className={`c-navigation__link t-desktop-navbar-category-link ${
                    viewChildren ? "is-active" : ""
                    }`}
            >
                {category.name}
            </a>
            {
                <div
                    className={`t-desktop-navbar-dropdown c-dropdown ${
                        viewChildren ? "" : "is-hidden"
                        }`}
                >
                    <ul className="c-dropdown__list">
                        {
                            loading ? (
                                <li className="c-dropdown__item--loading">
                                    <LoadingIcon />
                                </li>
                            ) : (
                                    children.map((child) => (
                                        <li
                                            key={child.id}
                                            className="c-dropdown__item"
                                        >
                                            <Link
                                                onClick={() => {
                                                    handleViewChildrenClick(false);

                                                    /**
                                                     * Don't trigger nav if already in the right place
                                                     * Messes up provider instances of category
                                                     */
                                                    if (
                                                        location.pathname !==
                                                        child.link
                                                    ) {
                                                        handleCategoryClick(child);
                                                    }
                                                }}
                                                to={child.link}
                                                className="t-desktop-navbar-dropdown-category-link c-dropdown__link"
                                            >
                                                {child.name}
                                            </Link>
                                        </li>
                                    ))
                                )
                        }
                    </ul>
                </div>
            }
        </>
    );
}

export default NavbarItem;
