import queryString from "query-string";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Imgix from "react-imgix";
import { Link, useLocation } from "react-router-dom";
import ErrorBoundary from "../../components/error-boundary";
import ErrorMessage from "../../components/error-message";
import HasPaginationLinks from "../../components/has-pagination-links";
import LoadingIcon from "../../components/loading-icon";
import { useAuth } from "../../providers/auth-provider";
import { usePagination, usePlaylist, useResource } from "../../providers/resource-provider";
import { useAjaxEffect } from "../../utils";

function PlaylistsIndex(props) {
    return (
        <>
            <Helmet>
                <title>All Playlists — {process.env.RAZZLE_APPLICATION_NAME}</title>
                <meta
                    name="description"
                    content="Here's where we've curated our favourite playlists of Andertons videos. Start here if you're looking for the best of the back-catalogue or popular videos."
                />
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <ErrorBoundary
                tags={{
                    page: "playlists index",
                    section: "playlist list",
                }}
                fallback={() => (
                    <ErrorMessage message="Could not display playlists" />
                )}
            >
                <PlaylistList {...props} />
            </ErrorBoundary>
        </>
    );
}

function PlaylistList({
    routeData: {
        playlists: initialPlaylists
    },
}) {
    const pagination = usePagination();

    const { handle401 } = useAuth();

    const { resource } = useResource();

    const location = useLocation();

    const query = queryString.parse(location.search, {
        arrayFormat: "bracket",
    });

    const [loading, setLoading] = useState(false);

    const [playlists, setPlaylists] = useState(initialPlaylists);

    useEffect(() => {
        pagination.setPageSize(20);
        pagination.setNumberOfPages(pagination.getNumberOfPages());
        pagination.setTotalItems(pagination.getTotalItems());
        pagination.setShowPagination(pagination.getTotalItems() > 0);
    }, []);

    const isInitialMount = useRef(true);

    useAjaxEffect({
        handle401,

        tags: {
            component: "PlaylistsIndex",
            action: "retrieve playlists",
        },

        requestRequired: () => !isInitialMount.current,

        onNoRequestRequired: () => {
            isInitialMount.current = false;
        },

        request: (cancelToken) => {
            resource.setCancelToken(cancelToken);

            setLoading(true);

            return resource.getPlaylists(query.page ?? 1);
        },

        onSuccess: ({ playlists, pagination }, mounted) => {
            if (mounted) {
                pagination.setNumberOfPages(pagination.getNumberOfPages());

                pagination.setTotalItems(pagination.getTotalItems());

                pagination.setShowPagination(pagination.getTotalItems() > 0);

                pagination.setPageSize(20);

                setPlaylists(playlists);

                setLoading(false);
            }
        },

        onError: (err, mounted) => {
            if (mounted) {
                console.log(err);
                setLoading(false);
            }
        },

        watch: [query.page],

        cancelMessage:
            "Playlists index page request cancelled due to component unmount",
    });

    return (
        <HasPaginationLinks className="l-padding">
            <div className="l-width">
                <div className="o-videos">
                    <ul className="o-videos__list">
                        {loading ? (
                            <LoadingIcon />
                        ) : (
                                playlists.map((playlist) => {
                                    return (
                                        <ErrorBoundary
                                            key={playlist.id}
                                            tags={{
                                                page: "playlists index",
                                                section: "playlist teaser",
                                            }}
                                            fallback={() => (
                                                <ErrorMessage message="Could not display playlist" />
                                            )}
                                        >
                                            <PlaylistTeaser playlist={playlist} />
                                        </ErrorBoundary>
                                    );
                                })
                            )}
                    </ul>
                </div>
            </div>
        </HasPaginationLinks>
    );
}

export default PlaylistsIndex;

function PlaylistTeaser({ playlist }) {

    const { handlePlaylistClick } = usePlaylist();

    return (
        <li>
            <Link onClick={() => handlePlaylistClick(playlist)} to={playlist.link} className="o-videos__item">
                <picture className="o-videos__picture">
                    <Imgix
                        htmlAttributes={{
                            src: playlist.loading_thumbnail_url,
                            alt: `${playlist.name} thumbnail image`,
                        }}
                        className="o-videos__img lazyload"
                        src={playlist.thumbnail_url}
                        imgixParams={{ q: 65 }}
                        attributeConfig={{
                            src: "data-src",
                            srcSet: "data-srcset",
                            sizes: "data-sizes",
                        }}
                        width={500}
                        height={295}
                    />
                </picture>

                <div className="o-videos__content">
                    <div className="o-videos__overflow">
                        <h3>{playlist.name}</h3>
                    </div>
                    <div>
                        <span className="o-videos__date">
                            {playlist.thumbnailDate()}
                        </span>
                        <span className="o-videos__time">
                            {`${playlist.limit} video${
                                playlist.limit > 1 ? "s" : ""
                                }`}
                        </span>
                    </div>
                </div>
            </Link>
        </li>
    );
}
