import React, { useState } from "react";
import { useAuth } from "../../providers/auth-provider";
import { useLocalStorage } from "../../providers/local-storage-provider";
import { useResource } from "../../providers/resource-provider";
import { useAjaxEffect } from "../../utils";
import Carousel from "../carousel";
import LoadingIcon from "../loading-icon";
import VideoThumbnailSet from "../video-thumbnail-set";
import CarouselVideoThumbnail from "../video-thumbnail-set/carousel-video-thumbnail";

function ContinueWatchingCarousel() {
    const [loading, setLoading] = useState(false);
    const [videos, setVideos] = useState([]);

    const { resource } = useResource();
    const aexLocalStorage = useLocalStorage();

    const recentlyWatched = aexLocalStorage
        ? aexLocalStorage.getRecentlyWatched()
        : [];

    const { handle401 } = useAuth();

    useAjaxEffect({
        handle401,

        tags: {
            component: "ContinueWatchingCarousel",
            action: "retrieve continue watching videos",
        },

        requestRequired: () => recentlyWatched.length > 0,

        request: (cancelToken) => {
            setLoading(true);

            resource.setCancelToken(cancelToken);

            return resource.getVideosByIds(
                recentlyWatched.map((entry) => entry.id)
            );
        },

        onSuccess: (data, mounted) => {
            if (mounted) {
                setVideos(
                    data.map((video) => {
                        const match = recentlyWatched.find(
                            (entry) => entry.id === video.id
                        );

                        video.progressPercentage = match ? match.progressPercentage : 0;

                        return video;
                    })
                );
                setLoading(false);
            }
        },

        onError: (err, mounted) => {
            if (mounted) {
                console.log(err);
                setLoading(false);
            }
        },

        watch: [aexLocalStorage],

        cancelMessage:
            "Continue watching carousel data retrieval cancelled due to component unmount",
    });

    // TODO - Stephen to check CSS
    if (loading || !aexLocalStorage) {
        return (
            <div className="o-section o-section--loading">
                <LoadingIcon />
            </div>
        );
    }

    return videos.length > 0 ? (
        <>
            <div className="o-section">
                <div className="o-section--wrapper">
                    <div className="o-section--left">
                        <h2 className="t-continue-watching-carousel-title o-section__title">
                            Continue Watching
                        </h2>
                    </div>
                </div>
                {!loading ? (
                    <VideoThumbnailSet videos={videos}>
                        {({ videos }) => (
                            <Carousel>
                                {videos.map((video) => (
                                    <CarouselVideoThumbnail
                                        key={video.id}
                                        video={video}
                                    />
                                ))}
                            </Carousel>
                        )}
                    </VideoThumbnailSet>
                ) : (
                        <Carousel>{[]}</Carousel>
                    )}
            </div>
        </>
    ) : null;
}

export default ContinueWatchingCarousel;
