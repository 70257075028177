import React from "react";
import Imgix from "react-imgix";
import { Link } from "react-router-dom";

function SubscriptionFormLogo() {
    return (
        <Link to="/get-extra">
            <Imgix
                htmlAttributes={{ alt: "Andertons Extra Logo" }}
                className="o-logo o-logo--login"
                src={
                    "https://andertonsextra.imgix.net/static/logos/andertons-extra-logo-full.png"
                }
                imgixParams={{ q: 65 }}
                width={260}
                height={73}
            />
        </Link>
    );
}

export default SubscriptionFormLogo;
