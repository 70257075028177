import React, { createContext, useContext, useEffect, useState } from "react";
import Geolocation from "../../models/geolocation";

const GeolocationContext = createContext(null);

export const useGeolocation = () => useContext(GeolocationContext);

function GeolocationProvider({ children }) {
    const [geolocation, setGeolocation] = useState(null);

    useEffect(() => {
        const gds = new Geolocation();

        gds.getLocationData().then((res) => {
            setGeolocation(res);
        });
    }, []);

    return (
        <GeolocationContext.Provider value={geolocation}>
            {children}
        </GeolocationContext.Provider>
    );
}

export default GeolocationProvider;
