import BaseResponseModel from "../../base-response-model";

class CreatedCustomer extends BaseResponseModel {
    details() {
        return {
            userId: this.customer.user_id,
            email: this.customer.email,
            firstName: this.customer.first_name,
            lastName: this.customer.last_name,
            title: this.customer.title,
        };
    }
}

export default CreatedCustomer;
