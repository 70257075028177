import React from "react";
import { Route } from "react-router-dom";
import { useAuth } from "../../providers/auth-provider";

function AuthenticatedOnlyRoute({ topLevelCategories, overlayRef, ...props }) {
    const { authenticated, UnauthedRedirect } = useAuth();

    /**
     * Three possible outcomes here
     *
     * - User is authenticated:
     *      - Return requested route
     *
     * - User is not authenticated, but they do have a token:
     *      - Redirect to login (as they have signed in before)
     *
     * - User is not authenticated and they do not have a token:
     *      - Redirect to service proposition (appears they've never signed up)
     */
    return authenticated ? <Route {...props} /> : <UnauthedRedirect />;
}

export default AuthenticatedOnlyRoute;
