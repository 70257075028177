/**
 * Two arrays for static category IDs to load
 * Need these to be consistent between component
 * and fetchInitialData on server, so extracting
 * to separate file
 */

export default {
    shows: [
        5928, // Play Like
        6587, // Signature Sounds
    ],
};
