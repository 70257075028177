import React, { useEffect } from "react";
import chromecastConfig from "../../models/vixy-player/chromecast-config";

function ServicePropositionVideo() {
    // The Vixy ID of the video to display in this player
    const EXPECTED_VIDEO_ID = "0_rm4ugazz";

    // Post-render function
    useEffect(() => {
        // Called when the player dependencies are loaded to display the video
        const initPlayer = () => {
            window.kWidget.embed({
                targetId: "vixyVideoPlayer",
                wid: "_439",
                uiconf_id: 23455698,
                flashvars: {
                    ...chromecastConfig,
                },
                entry_id: EXPECTED_VIDEO_ID,
            });
        };

        // Set up the external dependency for the video player
        // If player script not yet present, create it.
        // Otherwise, initialise the player.
        if (document.getElementById("vixyVideoPlayerScript") === null) {
            let playerScript = document.createElement("script");
            playerScript.id = "vixyVideoPlayerScript";
            playerScript.src =
                "https://platform.vixyvideo.com/p/439/sp/43900/embedIframeJs/uiconf_id/23455698/partner_id/439";
            playerScript.defer = true;
            playerScript.async = true;
            playerScript.onload = function () {
                initPlayer();
            };
            document.body.appendChild(playerScript);
        } else {
            initPlayer();
        }
    }, []);

    // Render
    return (
        <div className="service-proposition-section-4">
            <div className="c-player c-player--proposition">
                <div className="c-player__wrapper">
                    <div className="c-player__video">
                        <div
                            id="vixyVideoPlayer"
                            className="service-proposition-section-4__content c-player__player"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ServicePropositionVideo;
