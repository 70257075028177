import React, { useState } from "react";
import { Link } from "react-router-dom";
import Carousel from "../carousel";
import Imgix from "react-imgix";
import {
    useResource,
    useCategory,
    useCarousels,
} from "../../providers/resource-provider";
import { useAjaxEffect } from "../../utils";
import { useAuth } from "../../providers/auth-provider";

function PresentersCarousel(props) {
    const { resource } = useResource();
    const { handleCategoryClick } = useCategory();
    const { handle401 } = useAuth();
    const {
        presenters: { presenters, setPresenters },
    } = useCarousels();
    const [loading, setLoading] = useState(!presenters);

    useAjaxEffect({
        handle401,

        tags: {
            component: "PresentersCarousel",
            action: "retrieve our presenters",
        },

        requestRequired: () => !presenters,

        request: (cancelToken) => {
            resource.setCancelToken(cancelToken);

            return resource.getOurPresenters();
        },

        onSuccess: (data, mounted) => {
            if (mounted) {
                setPresenters(data);
                setLoading(false);
            }
        },

        onError: (err, mounted) => {
            if (mounted) {
                console.log(err);
                setLoading(false);
            }
        },

        watch: [],

        cancelMessage:
            "Presenters carousel data retrieval cancelled due to component unmount",
    });

    const items = !loading
        ? presenters.map((presenter) => (
            <span key={presenter.id}>
                <Link
                    onClick={() => handleCategoryClick(presenter)}
                    to={presenter.link}
                    className="t-presenters-carousel-link slick-link"
                >
                    <picture className="slick-picture">
                        <Imgix
                            htmlAttributes={{
                                src: presenter.loading_portrait_image,
                                alt: `${presenter.name} carousel image`,
                            }}
                            className="t-presenters-carousel-image slick-img lazyload"
                            src={presenter.portrait_image}
                            imgixParams={{ q: 65 }}
                            attributeConfig={{
                                src: "data-src",
                                srcSet: "data-srcset",
                                sizes: "data-sizes",
                            }}
                            width={406}
                            height={625}
                        />
                    </picture>
                    <div className="slick-content slick-content--presenter">
                        <h3>{presenter.name}</h3>
                    </div>
                </Link>
            </span>
        ))
        : [];

    return (
        <>
            <div className="t-presenters-carousel-section o-section o-section--presenters">
                <div className="o-section--wrapper">
                    <div className="o-section--left">
                        <h2 className="o-section__title">Our Presenters</h2>
                    </div>
                </div>
                <Carousel className="t-presenters-carousel o-carousel">
                    {items}
                </Carousel>
            </div>
        </>
    );
}

export default PresentersCarousel;
