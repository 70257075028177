import BaseResponseModel from "../../base-response-model";

class Plan extends BaseResponseModel {
    static defaultPrimaryCTA = "Start watching now";

    getPrimaryCTA() {
        return this.primary_cta ?? Plan.defaultPrimaryCTA;
    }

    getCurrencyCode() {
        return this.currency_code;
    }
}

export default Plan;
