import React from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from "react-accessible-accordion";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ErrorBoundary from "../../components/error-boundary";
import ErrorMessage from "../../components/error-message";
import SidebarLayout from "../../components/sidebar-layout";

function ExtraBenefits(props) {
    return (
        <>
            <Helmet>
                <title>
                    Your Extra Benefits — {process.env.RAZZLE_APPLICATION_NAME}
                </title>
                <meta
                    name="description"
                    content="With Andertons Extra you get exclusive content, subscriber-only deals, ad free videos and discounted delivery on andertons.co.uk. Join today from just £2.99!"
                />
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <SidebarLayout
                myAccount
                communicationPreferences
                extraBenefits
                manageSubscription
                contact
            >
                <h1 className="t-extra-benefits-title">Your Extra Benefits</h1>
                <p>
                    Andertons Extra comes with a wide range of added benefits,
                    so we created this page to help you get the most out of your
                    subscription!
                </p>

                <p>
                    Find out how to take advantage of all the benefits of{" "}
                    <em>Extra</em>, below, and keep checking this page as we'll
                    be adding even more benefits very soon!
                </p>

                <ErrorBoundary
                    tags={{
                        page: "extra benefits",
                        section: "benefits list",
                    }}
                    fallback={() => (
                        <ErrorMessage message="Could not display benefits list" />
                    )}
                >
                    <BenefitsList />
                </ErrorBoundary>
            </SidebarLayout>
        </>
    );
}

export default ExtraBenefits;

function BenefitsList() {
    return (
        <>
            <h2>Video Benefits</h2>
            <Accordion allowZeroExpanded allowMultipleExpanded>
                <AccordionItem>
                    <AccordionItemHeading className="accordion__heading t-extra-benefits-accordion-item-heading">
                        <AccordionItemButton className="accordion__button t-extra-benefits-accordion-item-button">
                            Where is the exclusive content on Andertons Extra?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="accordion__panel t-extra-benefits-accordion-item-panel">
                        <p>
                            Our new and exclusive series <em>Play Like</em> can
                            be found in the "Shows" section of the main menu.
                        </p>

                        <p>
                            Presented by Waterbear Studios' Sam Bell,
                            subscribers can learn how to play like their
                            favourite guitarists, including Pete Honoré and
                            Rabea Massaad. New episodes added regularly!
                        </p>

                        <Link
                            to="/categories/shows/play-like"
                            className="btn btn--primary"
                        >
                            Watch Play Like Now
                        </Link>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            How do I browse the Andertons Extra back-catalogue?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            We've carefully curated the entire Andertons
                            back-catalogue so you can browse it in the best
                            possible way on <em>Andertons Extra</em>. Use the
                            main menu at the top of the page to browse!
                        </p>
                        <p>
                            Unlike YouTube where you have to rely on search or
                            luck to find more of your favourite Andertons
                            videos, we've split up <em>Extra</em> so you can
                            navigate in the way that you want to, to find the
                            content that interests you!
                        </p>
                        <ul>
                            <li>
                                Browse by{" "}
                                <Link to="/categories/presenters">
                                    Presenter
                                </Link>{" "}
                                to find more videos by your favourite
                                personalities
                            </li>
                            <li>
                                Browse by{" "}
                                <Link to="/categories/shows">Show</Link> to
                                watch more of your favourite video formats like
                                The Captain Meets and Sound Like
                            </li>
                            <li>
                                Dive into our{" "}
                                <Link to="/categories/archive">Archives</Link>{" "}
                                to watch old Andertons content by date!
                            </li>
                            <li>
                                Use the tags on each video page to find related
                                videos e.g.{" "}
                                <Link to="/search?q[]=pedals">#pedals</Link>
                            </li>
                            <li>
                                Browse by{" "}
                                <Link to="/categories/instruments">
                                    Instrument
                                </Link>{" "}
                                if you just want to see all the content for
                                electrics, acoustics, bass, drums, and keys
                            </li>
                            <li>
                                Check out our curated{" "}
                                <Link to="/playlists">Playlists</Link> to find
                                the video team's favourite content!
                            </li>
                            <li>
                                <Link to="/search">Search the site</Link> for
                                even more content!
                            </li>
                        </ul>

                        <Link to="/home" className="btn btn--primary">
                            Start watching here!
                        </Link>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Ad-free viewing
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            You're already in the right place to enjoy all of
                            the content Andertons produces, completely ad-free!
                            Just watch videos on this site to enjoy the great
                            experience!
                        </p>
                        <p>
                            We are really grateful for every member of our
                            audience, whether they be watching on YouTube or{" "}
                            <em>Extra</em>, so we are deliberately making sure
                            we don't cheat anyone out of Andertons' content!
                            However, we know you were getting frustrated with
                            the amount of adverts appearing on our videos on
                            YouTube.
                        </p>
                        <p>
                            Now that you're an <em>Extra</em> subscriber you are
                            supporting the creation of amazing content, but
                            rather than paying by watching YouTube ads, your
                            small subscription fee contributes directly towards
                            making more videos for you! You get the benefit of
                            knowing you're supporting the creation of awesome
                            content, and we can give you an ad-free experience
                            to enjoy!
                        </p>

                        <Link to="/home" className="btn btn--primary">
                            Start watching here!
                        </Link>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            How to watch Andertons Extra on your TV
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            If you have a compatible casting device, it's easy
                            to watch Andertons Extra on your TV. Currently, you
                            can cast from your phone, tablet or laptop to{" "}
                            <strong>Google Chromecast</strong> or{" "}
                            <strong>AirPlay</strong> devices including{" "}
                            <strong>Apple TV</strong> and many modern Smart TVs.
                        </p>

                        <ul>
                            <li>
                                Navigate to the video that you want to watch and
                                start playing it
                            </li>
                            <li>
                                On the video player, you should see a Google
                                Chromecast icon
                                <img
                                    className="display-inline"
                                    alt="Google Chromecast Icon"
                                    src="https://andertonsextra.imgix.net/static/pages/extra-benefits/chromecast-icon.png?h=20"
                                />
                                or an AirPlay icon
                                <img
                                    className="display-inline"
                                    alt="Apple AirPlay Icon"
                                    src="https://andertonsextra.imgix.net/static/pages/extra-benefits/airplay-icon.png?&h=20"
                                />
                            </li>
                            <li>
                                Click this icon and your device will scan for
                                compatible casting devices on your Wi-Fi network
                            </li>
                            <li>
                                Select your Chromecast or Apple TV (normally
                                these will have names that you set up such as
                                "Living Room Chromecast")
                            </li>
                            <li>
                                The video will start playing on your TV, and you
                                can control playback from your phone/computer
                            </li>
                        </ul>

                        <h3>
                            Using Andertons Extra like a mobile app for added
                            convenience
                        </h3>
                        <p>
                            For the best possible experience on your mobile
                            phone and tablet, you can save andertonsextra.com to
                            your phone's home screen, so that it acts like an
                            app!
                        </p>
                        <p>
                            If you do this, Andertons Extra will be at your
                            fingertips without having to type the website into
                            your phone's browser all the time, and you can start
                            casting even quicker by navigating on your phone,
                            then tapping the cast button.
                        </p>
                        <p>
                            To learn how to save andertonsextra.com on your
                            phone/tablet's home screen, scroll down on this page
                            to <em>Andertons Extra Mobile Phone App</em>.
                        </p>

                        <h3>Other ways to watch on TV</h3>
                        <p>
                            Smart TVs and games consoles including XBox and
                            Playstation often come with web browsers, where you
                            can visit andertonsextra.com and watch in full
                            screen mode.
                        </p>
                        <p>
                            Currently, we don't have native apps for TV
                            platforms, but we're looking at making these
                            available for even better compatibility with TVs.
                        </p>

                        <h3>
                            Having trouble casting to Chromecast or Apple TV?{" "}
                        </h3>
                        <p>
                            Whether your device is compatible depends on a
                            couple of things. If you have a Google Chromecast,
                            Apple TV, or AirPlay enabled device, and it's
                            plugged into your TV and working, then take a look
                            at the following troubleshooting steps below.
                        </p>

                        <ul>
                            <li>
                                <h4>
                                    Check you're using a compatible web browser
                                </h4>
                                <p>
                                    Because Chromecast is a Google product,
                                    you'll get the best casting support if your
                                    device uses Google Chrome. Most Android,
                                    Windows and MacOS devices using Google
                                    Chrome will allow you to cast to Chromecast.
                                    However, we have found that iOS devices
                                    (e.g. iPhone) do not offer casting options
                                    when using Google Chrome.
                                </p>
                                <p>
                                    Similarly, Apple devices using the Safari
                                    browser (and other browsers) generally
                                    support casting to AirPlay devices, but you
                                    may find that the support for non-Apple
                                    devices casting to AirPlay varies.
                                </p>
                            </li>
                            <li>
                                <h4>
                                    Check your devices are on the same Wi-Fi
                                    network
                                </h4>
                                <p>
                                    For most casting devices to work, they need
                                    to be able to "see" the device you are
                                    casting from, which means that both the
                                    Chromecast/AirPlay device/Apple TV and your
                                    casting phone or laptop should be on the
                                    same Wi-Fi (or wired) home network.
                                </p>
                            </li>
                            <li>
                                <h4>
                                    Check your device and browser is fully up to
                                    date
                                </h4>
                                <p>
                                    Some platforms may disable casting if you
                                    haven't updated to the latest version of
                                    your device's operating system / browser
                                    software.
                                </p>
                            </li>
                            <li>
                                <h4>
                                    Check you have the Google Home App (for
                                    Chromecast)
                                </h4>
                                <p>
                                    Setting up the Google Chromecast normally
                                    involves downloading the Google Home app for
                                    iOS or Android, so make sure that you have
                                    this on your device so it can communicate
                                    with the Chromecast.
                                </p>
                            </li>
                        </ul>

                        <Link to="/home" className="btn btn--primary">
                            Browse to a video and start casting!
                        </Link>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Andertons Extra mobile phone app
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Rather than downloading an app from the Apple App
                            Store / Google Play Store, you can just save
                            andertonsextra.com to your phone's home screen, and
                            enjoy the <em>Extra</em> experience at your
                            fingertips, just like a native app! Here's how...
                        </p>

                        <h4>Apple iOS Devices (iPhone, iPad etc)</h4>

                        <ul>
                            <li>
                                Visit andertonsextra.com in Safari (the default
                                web browser on Apple Devices)
                            </li>
                            <li>
                                Find the "Share" icon
                                {/* TODO: style this to be inline with appropriate padding */}
                                <img
                                    className="display-inline"
                                    alt="Apple Share Icon"
                                    src="https://andertonsextra.imgix.net/static/pages/extra-benefits/ios-share-icon.png?h=20"
                                />
                                and click it.
                            </li>
                            <li>
                                Look through the options and click on "Add to
                                Home Screen"
                            </li>
                            <li>Click "Add" to confirm</li>
                            <li>
                                You can now launch Andertons Extra as an app!
                            </li>
                        </ul>
                        <p>
                            Note: If you use other browsers e.g. Google Chrome
                            on iOS, you may also find a similar share icon which
                            can be used to add Andertons Extra to your home
                            screen.
                        </p>

                        <img
                            src="https://andertonsextra.imgix.net/static/pages/extra-benefits/ios-app-screenshot.jpg?w=600&q=37"
                            alt="iPhone Andertons Extra screenshot"
                        />

                        <h4>Android Devices</h4>

                        <ul>
                            <li>
                                Visit andertonsextra.com in your web browser
                                e.g. Google Chrome
                            </li>
                            <li>
                                In your browser look for a menu icon and click
                                it. It is usually three vertical
                                <img
                                    className="display-inline"
                                    alt="Menu Icon Vertical"
                                    src="https://andertonsextra.imgix.net/static/pages/extra-benefits/three-dots-icon.png?rot=90&h=16"
                                />
                                or horizontal dots
                                <img
                                    className="display-inline"
                                    alt="Menu Icon Horizontal"
                                    src="https://andertonsextra.imgix.net/static/pages/extra-benefits/three-dots-icon.png?h=16"
                                />
                                , or three horizontal lines
                                <img
                                    className="display-inline"
                                    alt="Hamburger Menu Icon"
                                    src="https://andertonsextra.imgix.net/static/pages/extra-benefits/hamburger-menu-icon.png?h=16"
                                />
                            </li>

                            <li>
                                Look for a button called "Add to Home Screen" or
                                "Add to..."
                            </li>

                            <li>
                                Follow the instructions and confirm. You can now
                                launch Andertons Extra as an app!
                            </li>
                        </ul>

                        <img
                            src="https://andertonsextra.imgix.net/static/pages/extra-benefits/android-app-screenshot.jpg?w=600&q=37"
                            alt="Android Andertons Extra screenshot"
                        />

                        <Link to="/home" className="btn btn--primary">
                            Andertons Extra Homepage
                        </Link>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>

            <h2>
                Reduced Shipping on {process.env.RAZZLE_ECOMMERCE_SITE_DOMAIN}
            </h2>

            <Accordion allowZeroExpanded allowMultipleExpanded>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            How to get free UK shipping with your Extra
                            subscription
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            All you need to do to take advantage of free UK
                            shipping is log onto your Andertons account on{" "}
                            {process.env.RAZZLE_ECOMMERCE_SITE_DOMAIN}. As long
                            as you have a valid <em>Extra</em> subscription,
                            we'll do the rest!
                        </p>
                        <p>
                            You should use the same email address and password
                            that you use for <em>Extra</em>, then simply shop as
                            normal. When you get to the checkout, if your order
                            is over £20 and your delivery address is in a
                            standard UK area, we will automatically deduct any
                            shipping charges.
                        </p>
                        <p>
                            Remember, if you are in a{" "}
                            <a
                                href="https://help.andertons.co.uk/hc/en-gb/articles/360009285599-Remote-Area-UK-Deliveries"
                                target="_blank"
                                rel="noopener"
                            >
                                remote UK delivery area
                            </a>
                            , or anywhere outside of the United Kingdom, you
                            won't get free shipping, but you will get a £5
                            discount on the{" "}
                            <a
                                href="https://help.andertons.co.uk/hc/en-gb/articles/360010579260-How-much-is-delivery-to-my-country-"
                                target="_blank"
                                rel="noopener"
                            >
                                normal shipping costs
                            </a>{" "}
                            to your location.
                        </p>

                        <a
                            href={process.env.RAZZLE_ECOMMERCE_SITE_BASE_URL}
                            target="_blank"
                            rel="noopener"
                            className="btn btn--primary"
                        >
                            Visit {process.env.RAZZLE_ECOMMERCE_SITE_DOMAIN} now
                        </a>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            How to get discounted international shipping with
                            your Extra subscription
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            All you need to do to take advantage of our
                            discounted international shipping benefit is log
                            onto your Andertons account on{" "}
                            {process.env.RAZZLE_ECOMMERCE_SITE_DOMAIN}. As long
                            as you have a valid <em>Extra</em> subscription,
                            we'll do the rest!
                        </p>

                        <p>
                            You should use the same email address and password
                            that you use for <em>Extra</em>, then simply shop as
                            normal. When you get to the checkout we will
                            calculate your shipping costs (which are based on
                            your country and the weight/size of the products you
                            are ordering), then discount this by £5.
                        </p>
                        <p>
                            For a guide to our regular international shipping
                            costs, visit the{" "}
                            <a
                                href="https://help.andertons.co.uk/hc/en-gb/articles/360010579260-How-much-is-delivery-to-my-country-"
                                target="_blank"
                                rel="noopener"
                            >
                                andertons.co.uk help centre
                            </a>
                            .
                        </p>

                        <a
                            href={process.env.RAZZLE_ECOMMERCE_SITE_BASE_URL}
                            target="_blank"
                            rel="noopener"
                            className="btn btn--primary"
                        >
                            Visit {process.env.RAZZLE_ECOMMERCE_SITE_DOMAIN} now
                        </a>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Do you ship to my country?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Currently Andertons ships to over 50 countries
                            worldwide. To find out if we can ship to you, visit
                            the{" "}
                            <a
                                href="https://help.andertons.co.uk/hc/en-gb/articles/360009209460-Can-You-Deliver-To-My-Country-"
                                target="_blank"
                                rel="noopener"
                            >
                                andertons.co.uk help centre
                            </a>
                            .
                        </p>

                        <a
                            href={process.env.RAZZLE_ECOMMERCE_SITE_BASE_URL}
                            target="_blank"
                            rel="noopener"
                            className="btn btn--primary"
                        >
                            Shop on {process.env.RAZZLE_ECOMMERCE_SITE_DOMAIN}{" "}
                            now
                        </a>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>

            <h2>
                Subscriber only deals on{" "}
                {process.env.RAZZLE_ECOMMERCE_SITE_DOMAIN}
            </h2>

            <Accordion allowZeroExpanded allowMultipleExpanded>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Where are my exclusive deals?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            You can head over to the{" "}
                            <a
                                href={`${process.env.RAZZLE_ECOMMERCE_SITE_BASE_URL}/extra-deals`}
                                target="_blank"
                                rel="noopener"
                            >
                                Andertons Extra deals page
                            </a>{" "}
                            on {process.env.RAZZLE_ECOMMERCE_SITE_DOMAIN} at any
                            time. Simply click the links from the Andertons
                            Extra site, or go to{" "}
                            {process.env.RAZZLE_ECOMMERCE_SITE_DOMAIN} and click
                            "Extra Deals".
                        </p>

                        <p>
                            These deals are only available to those with a valid
                            Andertons Extra subscription, so make sure you are
                            logged in to{" "}
                            {process.env.RAZZLE_ECOMMERCE_SITE_DOMAIN} and we'll
                            do the rest! When you get to checkout, we'll apply
                            your discounts automatically.
                        </p>

                        <a
                            href={`${process.env.RAZZLE_ECOMMERCE_SITE_BASE_URL}/extra-deals`}
                            target="_blank"
                            rel="noopener"
                            className="btn btn--primary"
                        >
                            View the deals now
                        </a>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            How do I take advantage of my Andertons Extra deals?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            All you need to do to get our <em>Extra</em>{" "}
                            subscriber discounts is log onto your Andertons
                            account on{" "}
                            {process.env.RAZZLE_ECOMMERCE_SITE_DOMAIN}. As long
                            as you have a valid <em>Extra</em> subscription,
                            we'll do the rest!
                        </p>
                        <p>
                            You should use the same email address and password
                            that you use for <em>Extra</em>, then simply add the
                            deals you want to your shopping cart. When you get
                            to the checkout, we will automatically apply any
                            subscriber discounts, as well as shipping discounts
                            which are also a benefit of your subscription.
                        </p>

                        <a
                            href={`${process.env.RAZZLE_ECOMMERCE_SITE_BASE_URL}/extra-deals`}
                            target="_blank"
                            rel="noopener"
                            className="btn btn--primary"
                        >
                            View the deals now
                        </a>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            When are new deals added?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            We'll constantly be adding new deals on the
                            Andertons Extra deals page, so check back regularly!
                            If you are subscribed to Andertons marketing emails,
                            we'll also send you emails containing the latest{" "}
                            <em>Extra</em> deals.
                        </p>
                        <p>
                            You don't need to be on the mailing list to take
                            advantage of the deals, as you can simply log on to{" "}
                            {process.env.RAZZLE_ECOMMERCE_SITE_DOMAIN} and shop,
                            and we'll handle your discounts automatically.
                        </p>
                        <p>
                            Not subscribed? No problem. Just log onto{" "}
                            {process.env.RAZZLE_ECOMMERCE_SITE_DOMAIN} and go to
                            the My Account area where you can change your
                            subscription preference at any time.
                            <img
                                src="https://andertonsextra.imgix.net/static/pages/extra-benefits/marketing-preference-screenshot.jpg?w=600&q40"
                                alt={`Email marketing preferences checkbox on ${process.env.RAZZLE_ECOMMERCE_SITE_DOMAIN}`}
                            />
                        </p>

                        <a
                            href={process.env.RAZZLE_ECOMMERCE_SITE_BASE_URL}
                            target="_blank"
                            rel="noopener"
                            className="btn btn--primary"
                        >
                            View subscriber-only deals now
                        </a>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>

            <Link to="/home" className="btn btn--primary btn--full">
                Start enjoying Andertons Extra now!
            </Link>

            <p>
                Need more help? Please use the{" "}
                <Link to="/contact">Contact Page</Link> to get in touch!
            </p>
        </>
    );
}
