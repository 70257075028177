import React from "react";
import Imgix from "react-imgix";

function HoldingFooter() {
    return (
        <footer className="t-holding-footer c-footer">
            <div className="c-footer__wrapper l-width">
                <div className="c-footer--left">
                    <p className="o-copyright">
                        &copy; {(() => new Date().getFullYear())()} Andertons
                        Music Company
                    </p>
                </div>
                <div className="c-footer--center">
                    <Imgix
                        htmlAttributes={{ alt: "Andertons Extra Logo" }}
                        className="o-logo o-logo--footer"
                        src={
                            "https://andertonsextra.imgix.net/static/logos/andertons-extra-logo-full.png"
                        }
                        imgixParams={{ q: 65 }}
                        width={160}
                        height={45}
                    />
                </div>
                <div className="c-footer--right">
                    <ul className="c-privacy">
                        <li className="c-privacy__item">
                            {
                                // ! Remove auth
                            }
                            <a href="/terms" className="c-privacy__link">
                                Legal
                            </a>
                        </li>
                        <li className="c-privacy__item">
                            <a href="/contact" className="c-privacy__link">
                                Report a Problem
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    );
}

export default HoldingFooter;
