/**
 * This object can be specified as an object within the `flashvars` key of the vixy video player
 * in order to style the player shown to the user on their Google Chromecast.
 * 
 * See: http://player.kaltura.com/modules/Chromecast/tests/ChromecastCustomReceiver.html for
 * options. Dev ticket: EXTRA-559
 */
const chromecastConfig = {
    chromecast: {
        'plugin': true, // true to enable chromecast functionality on the player
        'parent': 'controlsContainer', // where to display the chromecast icon. The other options are `null` and "topBarContainer"
        'debugKalturaPlayer': false,
        'receiverConfig': {
            'spinnerFillColor': '#d6001c',
            'progressFillColor': '#d6001c',
            'launchingTimeout': 2, // int Default: 5 (Minutes – The time until the receiver is shutdown when he’s in launching state)
            'loadingTimeout': 2, // in Default: 5 (Minutes – The time until the receiver is shutdown when he’s in loading state)
        }
    }
}

export default chromecastConfig;