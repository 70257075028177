import React from "react";

export default function Select({
    wrappingClassName,
    children,
    watch,
    register,
    validation,
    ...rest
}) {
    return (
        <div className={wrappingClassName}>
            <select ref={register(validation)} {...rest}>
                {children}
            </select>
        </div>
    );
}

Select.defaultProps = {
    validation: {},
    wrappingClassName: "c-form__dropdown",
    children: [],
};
