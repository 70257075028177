import BaseResponseModel from "../../base-response-model";

class User extends BaseResponseModel {
    constructor(props) {
        super(props);
        if (this.customer) {
            Object.keys(this.customer).forEach((key) => {
                this[key] = this.customer[key];
            });
            delete this.customer;
        }
    }

    hasToken() {
        return !!this.token;
    }

    address() {
        return {
            addressLine1: this.address_line_1,
            addressLine2: this.address_line_2,
            city: this.city,
            state: this.state,
            country: this.country,
            zipCode: this.zip_code,
            phone: this.phone,
        };
    }

    details() {
        return {
            email: this.email,
            userId: this.user_id,
            firstName: this.first_name,
            lastName: this.last_name,
            title: this.title,
        };
    }
}

export default User;
