import React from "react";

import { Link } from "react-router-dom";

const ServicePropositionButton = ({ blok }) => {
    return (
        <div className="service-proposition-section-4">
            <Link
                to={blok?.link?.url ? blok?.link?.url : "/join"}
                className="btn btn--primary btn--full"
            >
                {blok?.text && <span>{blok?.text}</span>}
            </Link>
        </div>
    );
};

export default ServicePropositionButton;
