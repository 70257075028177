import queryString from "query-string";
import React, { useRef, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import HasPaginationLinks from "../../components/has-pagination-links";
import LoadingIcon from "../../components/loading-icon";
import SearchBar from "../../components/search-bar";
import VideoThumbnailSet from "../../components/video-thumbnail-set";
import GridVideoThumbnail from "../../components/video-thumbnail-set/grid-video-thumbnail";
import ApiClient from "../../models/api-client";
import GoogleAnalytics from "../../models/google-analytics";
import * as GAEventParams from "../../models/google-analytics/event-parameters-models.js";
import { usePagination, useResource } from "../../providers/resource-provider";
import { parseSort } from "../../utils";

function Search({
    routeData: { results: initialResults },
}) {
    const { resource } = useResource();

    const pagination = usePagination();

    const [videos, setVideos] = useState(initialResults);

    const [loading, setLoading] = useState(false);

    const { current: isInitialMount } = useRef(true);

    // Send search analytics on pageload
    useEffect(()=>{
      sendSearchAnalytics(terms);
    }, [isInitialMount]);

    const onSearchComplete = (({ videos, pagination: responsePagination }) => {
        pagination.setNumberOfPages(responsePagination.getNumberOfPages());

        pagination.setTotalItems(responsePagination.getTotalItems());

        pagination.setShowPagination(responsePagination.getTotalItems() > 0);

        pagination.setPageSize(20);

        setVideos(videos);

        setLoading(false);

        // Send search analytics on refined searches
        sendSearchAnalytics(terms);
    });

    const onSearchError = (err) => {
        if (!ApiClient.isCancel(err)) {
            setVideos([]);
            setLoading(false);
        }
    };

    const search = ({ q: terms, sort, page, cancelToken }) => {
        setLoading(true);

        setVideos([]);

        resource.setCancelToken(cancelToken);

        if (!terms) {
            pagination.setShowPagination(false);
            pagination.setNumberOfPages(1);
        }

        return terms && terms.length > 0 ? resource
            .getSearchResults(terms, page, 20, parseSort(sort)) : Promise.resolve({
                data: [],
                pagination: {}
            });

    }

    /**
     * Sends details of the term/s a user searched for to Google Analytics.
     * @param  {Array}  terms  Array of strings representing search terms that
     *                         were specified in the `q[]` parameters of the
     *                         query string.
     * @return {Boolean}       True if the event was sent.
     */
    const sendSearchAnalytics = (terms) => {
      if(!terms || terms.length === 0) return false;

      /* TODO: Consider how to avoid sending this event when the terms haven't
      changed. Currently, we send Analytics when the user paginates through
      search or changes search order; these should probably be recorded
      differently to the initial site search to understand user journeys in more
      detail. See EXTRA-331 */

      const gaSearchTerm = new GAEventParams.SearchTerm(terms);
      return GoogleAnalytics.sendEvent('search', gaSearchTerm);
    }

    /**
     * We can reset the pagination when this page
     * loads for the first time if there are no
     * search terms
     */
    const location = useLocation();

    const { q: terms } = queryString.parse(location.search, {
        arrayFormat: "bracket",
    });

    return (
        <>
            <Helmet>
                <title>Search — {process.env.RAZZLE_APPLICATION_NAME}</title>
                <meta
                    name="description"
                    content="Wether you're a guitarist looking for the best of The Captain, or you need a specific product demo, you can search the entire library of Andertons videos here!"
                />
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <HasPaginationLinks className="l-padding">
                <div className="l-width">

                    <SearchBar
                        onSuccess={onSearchComplete}
                        onError={onSearchError}
                        callback={search} />

                    <div className="o-videos">
                        <ul className="o-videos__list">
                            {videos &&
                                videos.length > 0 &&
                                !loading ? (
                                    <VideoThumbnailSet videos={videos}>
                                        {({ videos }) =>
                                            videos.map((video) => (
                                                <GridVideoThumbnail
                                                    key={video.id}
                                                    video={video}
                                                />
                                            ))
                                        }
                                    </VideoThumbnailSet>
                                ) : (
                                    <>
                                        {!!terms ? (
                                            <li className="loading">
                                                <LoadingIcon />
                                            </li>
                                        ) : null}
                                    </>
                                )}

                            {
                                !!videos &&
                                videos.length < 1 &&
                                terms &&
                                terms.length > 0 &&
                                !loading && (
                                    <li className="no-results">No results!</li>
                                )}
                        </ul>
                    </div>
                </div>
            </HasPaginationLinks>
        </>
    );
}

export default Search;
