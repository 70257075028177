import queryString from "query-string";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import RouteParamSet from "../../models/route-param-set";
import { useAuth } from "../../providers/auth-provider";
import { useCategory, usePagination, usePlaylist, useResource, useVideo } from "../../providers/resource-provider";
import { useAjaxEffect } from "../../utils";
import GlobalLoadingIcon from "../global-loading-icon";

function InitialDataLoader({
    fetchInitialData,
    fetchRequired,
    onNoRequestRequired,
    match,
    children,
    routeData,
}) {
    const { resource } = useResource();
    const { auth, handle401, authenticated, token } = useAuth();
    const [data, setData] = useState(routeData);
    const { video, handleVideoClick } = useVideo();
    const { category, handleCategoryClick } = useCategory();
    const { playlist, handlePlaylistClick } = usePlaylist();
    const pagination = usePagination();

    const history = useHistory();
    const location = useLocation();

    const routeParams = new RouteParamSet({
        match,
        query: queryString.parse(location.search, {
            arrayFormat: "bracket",
        }),
        resource,
        auth,
        /**
         * Very important that we allow
         * the context resources to override
         * whatever is in the routeData.
         * 
         * Failing to do so will mean that
         * initial app data will be used
         * in fetchRequired
         * 
         * This is why ...data must
         * be first in this object
         */
        routeData: {
            ...data,
            video,
            category,
            playlist,
            pagination,
        },
        redirect: history.push,
        handleVideoClick,
        handleCategoryClick,
        handlePlaylistClick,
    });

    const [loading, setLoading] = useState(
        typeof fetchRequired === "function" ? fetchRequired(routeParams) : false
    );

    useAjaxEffect({
        handle401,

        tags: {
            component: "InitialDataLoader",
            action: "retrieve page initial data",
        },

        onNoRequestRequired: () => {
            if (typeof onNoRequestRequired === "function") {
                onNoRequestRequired(routeParams);
            }
        },

        requestRequired: () => {
            return typeof fetchInitialData === "function" &&
                typeof fetchRequired === "function" &&
                fetchRequired(routeParams)
        },

        request: (cancelToken) => {
            setLoading(true);

            resource.setCancelToken(cancelToken);

            return fetchInitialData(routeParams);
        },

        onSuccess: (data, mounted) => {
            if (mounted) {
                setData(data);
                setLoading(false);
            }
        },

        onError: (err, mounted) => {
            if (mounted) {
                console.log(err);
                setLoading(false);
                history.push(queryString.stringifyUrl({
                    url: '/error',
                    query: {
                        authenticated: authenticated,
                        "http-error": !!(
                            err.isAxiosError && err.response
                        ),
                        "status-code":
                            err.isAxiosError && err.response
                                ? err.response.status
                                : false,
                        "api-url":
                            err.isAxiosError && err.response
                                ? err.config.url
                                : err,
                        from: `${location.pathname}${location.search}`,
                        "previously-authenticated": token === "false",
                    }
                }))
            }
        },

        watch: [location.pathname],

        cancelMessage: "Initial data retrieval cancelled due to path change",
    });

    return loading ? (
        <div className="content-expand">
            <GlobalLoadingIcon />
        </div>
    ) : (
            children({
                routeData: data,
            })
        );
}

export default InitialDataLoader;
