import React, { useState } from "react";
import Switch from "react-switch";
import { toast } from "react-toastify";

function ToggleSwitch(props) {
    const {
        checked,
        onToggle,
        disabled,
        label,
        hideLabel,
        successMessage,
        errorMessage,
    } = props;

    const [isChecked, setIsChecked] = useState(checked);

    /**
     * Called whenever the toggle state is changed. Includes generic results handling to
     * update the UI to the correct state, even in the event of failure
     * @param {Boolean} newValue The state that the user is trying to set the toggle to
     */
    const onChange = (newValue) => {
        // Assume the new value is opposite to the current value and update UI immediately
        setIsChecked(newValue);

        // onToggle should expect the new preference value and return a promise resolving to a
        // boolean. If onToggle throws, assume the preference has not been set correctly.
        try {
            onToggle(newValue).then((result) => {
                handleResult(newValue, result, successMessage, errorMessage);
            });
        } catch (error) {
            console.error("toggle threw an error:", error.message);
            handleResult(newValue, !newValue, successMessage, errorMessage);
        }
    };

    /**
     * Compares the expected result (the resulting UI state) which was is actually returned
     * by the onToggle function. If onToggle indicates it failed to set the preference
     * correctly, correct the toggle UI and display an error if the caller wants.
     */
    const handleResult = (expectedValue, returnedValue) => {
        if (returnedValue !== expectedValue) {
            setIsChecked(returnedValue);
            if (errorMessage) {
                return toast(errorMessage);
            }
        }

        // Display a success message if the caller wants
        else {
            if (successMessage) {
                return toast(successMessage);
            }
        }
    };

    return (
        <label>
            <Switch
                checked={isChecked}
                onChange={onChange}
                disabled={disabled}
                aria-label={label}
            />
            {hideLabel ? null : <span>{label}</span>}
        </label>
    );
}

const checkedIcon = (
    <svg
        height="100%"
        width="100%"
        viewBox="-2 -5 17 21"
        style={{ position: "absolute", top: 0 }}
    >
        <path
            d="M11.264 0L5.26 6.004 2.103 2.847 0 4.95l5.26 5.26 8.108-8.107L11.264 0"
            fill="#fff"
            fillRule="evenodd"
        />
    </svg>
);

Switch.defaultProps = {
    checked: false,
    onChange: () => {},
    disabled: false,
    offColor: "#1f1f1f",
    onColor: "#960014",
    offHandleColor: "#fff",
    onHandleColor: "#ff0a2a",
    handleDiameter: 24,
    uncheckedIcon: null,
    checkedIcon: null,
    uncheckedHandleIcon: null,
    checkedHandleIcon: checkedIcon,
    boxShadow: undefined,
    activeBoxShadow: "0 0 2px 3px #3bf",
    height: 28,
    width: 56,
    className: undefined,
    borderRadius: undefined,
    id: undefined,
};

export default ToggleSwitch;
