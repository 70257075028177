import React from "react";
import VideoThumbnail from "../video-thumbnail";

function CarouselVideoThumbnail(props) {
    return (
        <VideoThumbnail
            linkClassName="slick-link"
            pictureClassName="slick-picture"
            imageClassName="slick__img lazyload"
            contentClassName="slick-content"
            overflowClassName="slick-content__overflow"
            overflowDateClassName="slick-content__date"
            overflowTimeClassName="slick-content__time"
            {...props}
        />
    );
}

export default CarouselVideoThumbnail;
