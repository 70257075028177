import queryString from 'query-string';
import React, { useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../../providers/auth-provider';
import { useAjaxEffect } from '../../utils';
import SearchBarForm from './search-bar-form';

export default function SearchBar({ callback, onSuccess, onError }) {

    const { handle401 } = useAuth();
    const [term, setTerm] = useState('');
    const [searchInProgress, setSearchInProgress] = useState(false);
    const history = useHistory();
    const location = useLocation();

    const query = queryString.parse(location.search, {
        arrayFormat: "bracket",
    });

    const isInitialMount = useRef(true);

    useAjaxEffect({
        handle401,

        tags: {
            component: "SearchBar",
            action: "retrieve search results",
        },

        requestRequired: () => !isInitialMount.current && !searchInProgress,

        onNoRequestRequired: () => {
            isInitialMount.current = false;
        },

        request: cancelToken => {
            setSearchInProgress(true);

            return callback({
                q: query.q,
                sort: query.sort ?? "created_at-desc",
                page: query.page ?? 1,
                cancelToken,
            })
        },

        onSuccess: (results, mounted) => {
            if (mounted) {
                setSearchInProgress(false);

                onSuccess(results);
            }
        },

        onError: (err, mounted) => {
            if (mounted) {
                onError(err);
            }
        },

        watch: [query.page, location.pathname, location.search],

        cancelMessage:
            "Search request cancelled due to component unmount",
    });

    const handleSortChange = (e) => {
        e.preventDefault();

        query.sort = options[e.target.value].paramValue;

        const newQuery = {
            ...query,
        };

        if (query.page) {
            newQuery.page = 1;
        }

        const url = queryString.stringifyUrl(
            {
                url: location.pathname,
                query: newQuery,
            },
            {
                arrayFormat: "bracket",
            }
        );

        history.push(url);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!term) {
            return;
        }

        const q = query.q ?? [];

        if (!q.includes(term)) {
            q.push(term);
        } else {
            setTerm("");

            return;
        }

        const newQuery = {
            ...query,
            q,
        };

        if (query.page) {
            newQuery.page = 1;
        }

        const url = queryString.stringifyUrl(
            {
                url: location.pathname,
                query: newQuery,
            },
            {
                arrayFormat: "bracket",
            }
        );

        history.push(url);

        setTerm("");
    };

    const handleTermClick = (e) => {
        e.preventDefault();

        const query = queryString.parse(location.search, {
            arrayFormat: "bracket",
        });

        query.q.splice(e.target.value, 1);

        const newQuery = {
            ...query,
        };

        if (query.page) {
            newQuery.page = 1;
        }

        const url = queryString.stringifyUrl(
            {
                url: location.pathname,
                query: newQuery,
            },
            {
                arrayFormat: "bracket",
            }
        );

        history.push(url);
    };

    /**
     * Options for the sort select
     */
    const options = [
        {
            friendlyName: "Latest First",
            paramValue: "created_at-desc",
        },
        {
            friendlyName: "Oldest First",
            paramValue: "created_at-asc",
        },
        {
            friendlyName: "Alphabetical A-Z",
            paramValue: "name-asc",
        },
        {
            friendlyName: "Alphabetical Z-A",
            paramValue: "name-desc",
        },
    ];

    return <div className="o-search">

        <SearchBarForm
            placeholder="Search"
            type="search"
            onChange={(e) => setTerm(e.target.value)}
            value={term}
            name="term"
            disabled={searchInProgress}
            onSubmit={handleSubmit}
            className="t-search-input o-search__input" />

        <div className="o-search__tags">
            {!!query.q && query.q.length > 0
                ? query.q.map((term, index) => (
                    <button
                        disabled={searchInProgress}
                        onClick={handleTermClick}
                        value={index}
                        key={`search_term_${index}`}
                        className="t-search-term o-search__term"
                    >
                        {term}
                    </button>
                ))
                : null}
        </div>
        <div className="o-search__select">
            <select
                className="t-search-sort-select"
                disabled={searchInProgress}
                defaultValue={options.findIndex(
                    (option) =>
                        option.paramValue ===
                        (query.sort ?? "created_at-desc")
                )}
                onChange={handleSortChange}
            >
                {options.map((option, index) => (
                    <option key={index} value={index}>
                        {option.friendlyName}
                    </option>
                ))}
            </select>
        </div>
    </div>;
}

SearchBar.defaultProps = {
    onSuccess: () => { },
    onError: () => { },
}
