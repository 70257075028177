import React from "react";
import { Helmet } from "react-helmet";
import Imgix from "react-imgix";
import { Link } from "react-router-dom";
import ErrorBoundary from "../../components/error-boundary";
import ErrorMessage from "../../components/error-message";
import FeaturedVideo from "../../components/featured-video";
import ProcessedText from "../../components/processed-text";
import YouMightAlsoLikeCarousel from "../../components/you-might-also-like-carousel";
import Category from "../../models/resource/category";
import { useCategory } from "../../providers/resource-provider";

function TopLevelPresenters(props) {
    const { category } = useCategory();

    return (
        <>
            <Helmet>
                <title>{category.name} — {process.env.RAZZLE_APPLICATION_NAME}</title>
                <meta
                    name="description"
                    content={category.description}
                />
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            {/* Category Details */}
            <ErrorBoundary
                tags={{
                    page: "top level presenters",
                    section: "category details",
                }}
                fallback={() => (
                    <ErrorMessage message="Could not display category details" />
                )}
            >
                <CategoryDetails category={category} />
            </ErrorBoundary>

            {/* Category Child List */}
            <ErrorBoundary
                tags={{
                    page: "top level presenters",
                    section: "category details",
                }}
                fallback={() => (
                    <ErrorMessage message="Could not display category children" />
                )}
            >
                <CategoryChildList childCategories={category.children} />
            </ErrorBoundary>

            {/* Featured video */}
            <ErrorBoundary
                tags={{
                    page: "top level presenters",
                    section: "featured video",
                }}
                fallback={() => (
                    <ErrorMessage message="Could not display featured video" />
                )}
            >
                <FeaturedVideo />
            </ErrorBoundary>

            {/* You Might Also Like Carousel */}
            <ErrorBoundary
                tags={{
                    page: "top level presenters",
                    section: "you might also like carousel",
                }}
                fallback={() => (
                    <ErrorMessage message="Could not display you might also like carousel" />
                )}
            >
                <YouMightAlsoLikeCarousel />
            </ErrorBoundary>
        </>
    );
}

export default TopLevelPresenters;

function MidLevelPresenterTeaser({ category }) {
    const { handleCategoryClick } = useCategory();

    return (
        <Link
            onClick={() => handleCategoryClick(category)}
            to={category.link}
            className="categories"
        >
            <Imgix
                htmlAttributes={{
                    src: category.loading_portrait_image,
                    alt: `${category.name} thumbnail image`,
                }}
                className="lazyload"
                src={category.portrait_image}
                imgixParams={{ q: 65 }}
                attributeConfig={{
                    src: "data-src",
                    srcSet: "data-srcset",
                    sizes: "data-sizes",
                }}
                width={375}
                height={375}
            />
        </Link>
    );
}

function BottomLevelPresenterTeaser({ category }) {
    const { handleCategoryClick } = useCategory();

    return (
        <Link onClick={() => handleCategoryClick(category)} to={category.link}>
            <Imgix
                htmlAttributes={{
                    src: category.loading_portrait_image,
                    alt: `${category.name} thumbnail image`,
                }}
                className="lazyload"
                src={category.portrait_image}
                imgixParams={{ q: 65 }}
                attributeConfig={{
                    src: "data-src",
                    srcSet: "data-srcset",
                    sizes: "data-sizes",
                }}
                width={375}
                height={375}
            />
        </Link>
    );
}

function CategoryChildList({ childCategories: children }) {
    return (
        <div className="l-padding">
            <div className="l-width">
                <div className="l-grid l-grid--2-s l-grid--4-m">
                    {children.map((child) => (
                        <CategoryChildTeaser category={child} key={child.id} />
                    ))}
                </div>
            </div>
        </div>
    );
}

function CategoryDetails({ category }) {
    return (
        <div className="o-hero">
            <picture className="o-hero__picture">
                <Imgix
                    htmlAttributes={{
                        src: category.loading_hero_image,
                        alt: `${category.name} hero image`,
                    }}
                    className="o-hero__img lazyload"
                    src={category.hero_image}
                    imgixParams={{ q: 65 }}
                    attributeConfig={{
                        src: "data-src",
                        srcSet: "data-srcset",
                        sizes: "data-sizes",
                    }}
                    width={1920}
                    height={500}
                />
            </picture>
            <div className="o-hero__info">
                <h1 className="o-hero__title">{category.name}</h1>
                <div className="o-hero__intro">
                    <ProcessedText>{category.description}</ProcessedText>
                </div>
            </div>
        </div>
    );
}

function CategoryChildTeaser({ category }) {
    return category.is_bottom_level ? (
        <BottomLevelPresenterTeaser category={new Category(category)} />
    ) : (
            <MidLevelPresenterTeaser category={new Category(category)} />
        );
}
