import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ApiClient from "./models/api-client";

export const toSlug = (string) =>
    string
        .replace(/[^A-Za-z0-9\s]/g, "")
        .replace(/ /g, "-")
        .toLowerCase();

export const scroll = (ref) =>
    ref ? window.scrollTo(0, ref.current.offsetTop) : null;

export const parseSort = (string) => {
    const splitSort = string.split("-");

    return {
        field: splitSort[0],
        order: splitSort[1],
    };
};

export const useDebounce = (value, delay) => {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
        () => {
            // Set debouncedValue to value (passed in) after the specified delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            // Return a cleanup function that will be called every time ...
            // ... useEffect is re-called. useEffect will only be re-called ...
            // ... if value changes (see the inputs array below).
            // This is how we prevent debouncedValue from changing if value is ...
            // ... changed within the delay period. Timeout gets cleared and restarted.
            // To put it in context, if the user is typing within our app's ...
            // ... search box, we don't want the debouncedValue to update until ...
            // ... they've stopped typing for more than 500ms.
            return () => {
                clearTimeout(handler);
            };
        },
        // Only re-call effect if value changes
        // You could also add the "delay" var to inputs array if you ...
        // ... need to be able to change that dynamically.
        // eslint-disable-next-line comma-dangle
        [value]
    );

    return debouncedValue;
};

export const msToTime = (duration, withSuffix = false) => {
    let seconds = Math.floor((duration / 1000) % 60);
    let minutes = Math.floor((duration / (1000 * 60)) % 60);
    let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = hours < 1 ? false : hours;
    minutes = minutes < 10 && !!hours ? `0${minutes}` : minutes;
    seconds = seconds < 10 ? `0${seconds}` : seconds;

    let output = "";

    if (hours) {
        output += `${hours}:`;
    } else {
        output += "";
    }

    output += `${minutes}:${seconds}`;

    if (withSuffix) {
        if (hours) {
            output += "h";
        } else {
            output += "m";
        }
    } else {
        output += "";
    }

    return output;
};

export const snakeToCamel = (str) =>
    str.replace(
        /([-_][a-z])/g,
        (group) => group.toUpperCase().replace("-", "").replace("_", "") // eslint-disable-line comma-dangle
    );

export class LocalStorage {
    static variableName = "andertonsExtraLocalStorage";

    /**
     * Create LocalStorage instance. We need the
     * userId to wrap our keys to make them user
     * specific - per user, per device
     */
    // ! Remove auth
    // constructor(userId, version) {
    constructor(userId = "open", version) {
        this.userId = userId;
        this.version = LocalStorage.get(this.getWrappedKey("version"));

        if (this.version !== version) {
            this.clear(this.version, version);
            this.setVersion(version);
        }

        this.recentlyWatched =
            LocalStorage.getObject(this.getWrappedKey("recently_watched")) ??
            [];
        this.youMightAlsoLike =
            LocalStorage.getObject(this.getWrappedKey("you_might_also_like")) ??
            [];
    }

    setVersion(version) {
        LocalStorage.set(this.getWrappedKey("version"), version);
        this.refresh("version", "get");
    }

    clear(oldVersion, newVersion) {
        LocalStorage.remove(this.getWrappedKey("recently_watched"));
        LocalStorage.remove(this.getWrappedKey("you_might_also_like"));
        LocalStorage.remove(this.getWrappedKey("version"));
        LocalStorage.remove(this.getWrappedKey("fcm_token"));

        console.log(
            `Andertons Extra localStorage has been cleared. Old version: ${oldVersion}, new version: ${newVersion}`
        );
    }

    /**
     * Update the instance's copy of
     * a given localStorage entry
     */
    refresh(key, method = "getObject") {
        this[snakeToCamel(key)] = LocalStorage[method](this.getWrappedKey(key));

        return this;
    }

    /**
     * Return a wrapped version of a basic
     * localStorage entry key with app name
     * and userId to make it user specific
     */
    getWrappedKey(key) {
        return `${process.env.RAZZLE_APPLICATION_NAME.toLowerCase().replace(
            /\s+/g,
            "_"
        )}_${key}_${this.userId}`;
    }

    /**
     * Static method to return the
     * instance of LocalStorage
     * Just keeps things tidier
     */
    static instance() {
        return window[LocalStorage.variableName];
    }

    /**
     * Create an instance of LocalStorage
     * and make it globally accessible
     */
    static init({ userId }) {
        if (
            typeof window !== "undefined" &&
            !!window.localStorage &&
            !window[LocalStorage.variableName]
        ) {
            window[LocalStorage.variableName] = new LocalStorage(
                userId,
                window.andertonsExtraVersion
            );
        }
    }

    /**
     * Check whether the globally accessible
     * instance of LocalStorage exists
     */
    static isInited() {
        return (
            typeof window !== "undefined" &&
            !!window.localStorage &&
            !!window[LocalStorage.variableName]
        );
    }

    /**
     * Getter for recentlyWatched
     */
    getRecentlyWatched() {
        return this.recentlyWatched;
    }

    /**
     * Getter for youMightAlsoLike
     */
    getYouMightAlsoLike() {
        return this.youMightAlsoLike;
    }

    /**
     * Getter for Firebase Cloud Messaging
     * push notification subscription token
     */
    getFcmToken() {
        return this.fcmToken;
    }

    /**
     * Ensure we're not keeping too many
     * recently watched entries
     */
    clearOldRecentlyWatched(currentId) {
        /**
         * Get the max number of entries we can have in
         * the array
         */
        const maxEntries =
            process.env.RAZZLE_MAX_YOU_MIGHT_ALSO_LIKE_ENTRIES ?? 20;

        const current = this.getRecentlyWatched();

        if (current.length > maxEntries) {
            const numberOfEntriesToRemove = current.length - maxEntries;

            const entriesToKeep = current.slice(
                0,
                current.length - (numberOfEntriesToRemove + 1)
            );

            if (!entriesToKeep.includes(currentId)) {
                entriesToKeep.push(currentId);
            }

            /**
             * We know that we push to the end of
             * the array when we add to it, so we can
             * just remove from the start of the array
             * and know that these are the oldest
             */
            this.setRecentlyWatched(entriesToKeep);
        }
    }

    /**
     * Persist a full recentlyWatched object
     * into localStorage and update the instance's
     * copy
     */
    setRecentlyWatched(recentlyWatched) {
        LocalStorage.setObject(
            this.getWrappedKey("recently_watched"),
            recentlyWatched
        );

        this.refresh("recently_watched");

        return this;
    }

    /**
     * Persist a full youMightAlsoLike array
     * into localStorage and update the instance's
     * copy
     */
    setYouMightAlsoLike(youMightAlsoLike) {
        LocalStorage.setObject(
            this.getWrappedKey("you_might_also_like"),
            youMightAlsoLike
        );

        this.refresh("you_might_also_like");

        return this;
    }

    /**
     * Setter for Firebase Cloud Messaging
     * push notification subscription token
     */
    setFcmToken(token) {
        LocalStorage.set(this.getWrappedKey("fcm_token"), token);
        this.refresh("fcm_token", "get");
        return this;
    }

    /**
     * Clears the Firebase Cloud Messaging
     * push notification subscription token
     */
    clearFcmToken() {
        LocalStorage.remove(this.getWrappedKey("fcm_token"));
    }

    /**
     * Update a single entry of the recentlyWatched
     * object and persist
     */
    setRecentlyWatchedEntry(id, progress, progressPercentage) {
        let current = this.getRecentlyWatched();

        if (current.map((entry) => entry.id).includes(id)) {
            current = current.map((entry) => {
                if (entry.id === id) {
                    return {
                        id,
                        progress,
                        progressPercentage,
                    };
                } else {
                    return entry;
                }
            });
        } else {
            current.push({
                id,
                progress,
                progressPercentage,
            });
        }

        this.setRecentlyWatched(current);
    }

    /**
     * Ensure we don't have too many
     * youMightAlsoLike entries
     */
    clearOldYouMightAlsoLike() {
        /**
         * Get the max number of entries we can have in
         * the array
         */
        const maxEntries =
            process.env.RAZZLE_MAX_YOU_MIGHT_ALSO_LIKE_ENTRIES ?? 20;

        const current = this.getYouMightAlsoLike();

        if (current.length > maxEntries) {
            const numberOfEntriesToRemove = current.length - maxEntries;

            /**
             * We know that we push to the end of
             * the array when we add to it, so we can
             * just remove from thge start of the array
             * and know that these are the oldest
             */
            this.setYouMightAlsoLike(
                current.slice(0, current.length - (numberOfEntriesToRemove + 1))
            );
        }
    }

    /**
     * Add a single youMightAlsoLike entry
     * and persist
     */
    addYouMightAlsoLike(id) {
        if (!this.getYouMightAlsoLike().includes(id)) {
            const current = this.getYouMightAlsoLike();

            current.push(id);

            this.setYouMightAlsoLike(current);
        }
    }

    static remove(key) {
        localStorage.removeItem(key);
    }

    static get(key) {
        return localStorage.getItem(key);
    }

    static getObject(key) {
        return JSON.parse(localStorage.getItem(key));
    }

    static set(key, val) {
        return localStorage.setItem(key, val);
    }

    static setObject(key, obj) {
        return localStorage.setItem(key, JSON.stringify(obj));
    }
}

export const getRegex = (name) => {
    switch (name) {
        case "password":
            /**
             * At least 6 chars, and at least one letter AND at least one number or symbol
             * Based on
             * https://stackoverflow.com/questions/7684815/regex-pattern-to-match-at-least-1-number-and-1-character-in-a-string
             */
            return /^(?=.*[0-9\W])(?=.*[a-zA-Z])([a-zA-Z0-9]+).{5,}$/i;

        case "name":
            return /^[a-zA-Z- ]+/i;

        case "address":
            return /^[A-Za-z\s\-_.,0-9]+$/i;

        case "phone":
            return /^[0-9-+ ]+$/i;

        case "email":
            return /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

        default:
            return "";
    }
};

export class ExtraCookies {
    static prefix = "andertons_extra_";

    static getWrappedKey(key) {
        return `${ExtraCookies.prefix}${key}`;
    }
}

export const countryByCode = (code) => {
    const result = countries.find(
        (country) => country.code.toLowerCase() === code.toLowerCase()
    );

    return result ? result.name : code;
};

export const countries = [
    {
        name: "Andorra",
        code: "AD",
    },
    {
        name: "Australia",
        code: "AU",
    },
    {
        name: "Austria",
        code: "AT",
    },
    {
        name: "Belarus",
        code: "BY",
    },
    {
        name: "Belgium",
        code: "BE",
    },
    {
        name: "Bosnia and Herzegovina",
        code: "BA",
    },
    {
        name: "Bulgaria",
        code: "BG",
    },
    {
        name: "Canada",
        code: "CA",
    },
    {
        name: "Canary Islands",
        code: "IC",
    },
    {
        name: "Croatia",
        code: "HR",
    },
    {
        name: "Czech Republic",
        code: "CZ",
    },
    {
        name: "Denmark",
        code: "DK",
    },
    {
        name: "Estonia",
        code: "EE",
    },
    {
        name: "Faroe Islands",
        code: "FO",
    },
    {
        name: "Finland",
        code: "FI",
    },
    {
        name: "France",
        code: "FR",
    },
    {
        name: "Germany",
        code: "DE",
    },
    {
        name: "Gibraltar",
        code: "GI",
    },
    {
        name: "Greece",
        code: "GR",
    },
    {
        name: "Guernsey",
        code: "GG",
    },
    {
        name: "Hong Kong S.A.R. of China",
        code: "HK",
    },
    {
        name: "Hungary",
        code: "HU",
    },
    {
        name: "Iceland",
        code: "IS",
    },
    {
        name: "Indonesia",
        code: "ID",
    },
    {
        name: "Ireland",
        code: "IE",
    },
    {
        name: "Isle of Man",
        code: "IM",
    },
    {
        name: "Israel",
        code: "IL",
    },
    {
        name: "Italy",
        code: "IT",
    },
    {
        name: "Japan",
        code: "JP",
    },
    {
        name: "Jersey",
        code: "JE",
    },
    {
        name: "Korea, South",
        code: "KR",
    },
    {
        name: "Kuwait",
        code: "KW",
    },
    {
        name: "Latvia",
        code: "LV",
    },
    {
        name: "Liechtenstein",
        code: "LI",
    },
    {
        name: "Lithuania",
        code: "LT",
    },
    {
        name: "Luxembourg",
        code: "LU",
    },
    {
        name: "Macedonia",
        code: "MK",
    },
    {
        name: "Malaysia",
        code: "MY",
    },
    {
        name: "Malta",
        code: "MT",
    },
    {
        name: "Mexico",
        code: "MX",
    },
    {
        name: "Moldova",
        code: "MD",
    },
    {
        name: "Monaco",
        code: "MC",
    },
    {
        name: "Montenegro",
        code: "ME",
    },
    {
        name: "Netherlands",
        code: "NL",
    },
    {
        name: "New Zealand",
        code: "NZ",
    },
    {
        name: "Norway",
        code: "NO",
    },
    {
        name: "Philippines",
        code: "PH",
    },
    {
        name: "Poland",
        code: "PL",
    },
    {
        name: "Portugal",
        code: "PT",
    },
    {
        name: "Romania",
        code: "RO",
    },
    {
        name: "San Marino",
        code: "SM",
    },
    {
        name: "Saudi Arabia",
        code: "SA",
    },
    {
        name: "Serbia",
        code: "RS",
    },
    {
        name: "Singapore",
        code: "SG",
    },
    {
        name: "Slovakia",
        code: "SK",
    },
    {
        name: "Slovenia",
        code: "SI",
    },
    {
        name: "South Africa",
        code: "ZA",
    },
    {
        name: "Spain",
        code: "ES",
    },
    {
        name: "Sweden",
        code: "SE",
    },
    {
        name: "Switzerland",
        code: "CH",
    },
    {
        name: "Taiwan",
        code: "TW",
    },
    {
        name: "Thailand",
        code: "TH",
    },
    {
        name: "Turkey",
        code: "TR",
    },
    {
        name: "United Arab Emirates",
        code: "AE",
    },
    {
        name: "United Kingdom",
        code: "GB",
    },
    {
        name: "United States",
        code: "US",
    },
];

export const stateByCode = (code) => {
    const result = states.find(
        (state) => state.code.toLowerCase() === code.toLowerCase()
    );

    return result ? result.name : code;
};

export const stateByName = (name) => {
    const result = states.find(
        (state) => state.name.toLowerCase() === name.toLowerCase()
    );

    return result ? result.code : name;
};

export const states = [
    {
        code: "AL",
        name: "Alabama",
    },
    {
        code: "AK",
        name: "Alaska",
    },
    {
        code: "AS",
        name: "American Samoa",
    },
    {
        code: "AZ",
        name: "Arizona",
    },
    {
        code: "AR",
        name: "Arkansas",
    },
    {
        code: "AA",
        name: "Armed Forces (aa)",
    },
    {
        code: "AE",
        name: "Armed Forces (ae)",
    },
    {
        code: "AP",
        name: "Armed Forces (ap)",
    },
    {
        code: "CA",
        name: "California",
    },
    {
        code: "CO",
        name: "Colorado",
    },
    {
        code: "CT",
        name: "Connecticut",
    },
    {
        code: "DE",
        name: "Delaware",
    },
    {
        code: "DC",
        name: "District Of Columbia",
    },
    {
        code: "FL",
        name: "Florida",
    },
    {
        code: "GA",
        name: "Georgia",
    },
    {
        code: "GU",
        name: "Guam",
    },
    {
        code: "HI",
        name: "Hawaii",
    },
    {
        code: "ID",
        name: "Idaho",
    },
    {
        code: "IL",
        name: "Illinois",
    },
    {
        code: "IN",
        name: "Indiana",
    },
    {
        code: "IA",
        name: "Iowa",
    },
    {
        code: "KS",
        name: "Kansas",
    },
    {
        code: "KY",
        name: "Kentucky",
    },
    {
        code: "LA",
        name: "Louisiana",
    },
    {
        code: "ME",
        name: "Maine",
    },
    {
        code: "MH",
        name: "Marshall Islands",
    },
    {
        code: "MD",
        name: "Maryland",
    },
    {
        code: "MA",
        name: "Massachusetts",
    },
    {
        code: "MI",
        name: "Michigan",
    },
    {
        code: "FM",
        name: "Micronesia",
    },
    {
        code: "MN",
        name: "Minnesota",
    },
    {
        code: "MS",
        name: "Mississippi",
    },
    {
        code: "MO",
        name: "Missouri",
    },
    {
        code: "MT",
        name: "Montana",
    },
    {
        code: "NE",
        name: "Nebraska",
    },
    {
        code: "NV",
        name: "Nevada",
    },
    {
        code: "NH",
        name: "New Hampshire",
    },
    {
        code: "NJ",
        name: "New Jersey",
    },
    {
        code: "NM",
        name: "New Mexico",
    },
    {
        code: "NY",
        name: "New York",
    },
    {
        code: "NC",
        name: "North Carolina",
    },
    {
        code: "ND",
        name: "North Dakota",
    },
    {
        code: "MP",
        name: "Northern Mariana Islands",
    },
    {
        code: "OH",
        name: "Ohio",
    },
    {
        code: "OK",
        name: "Oklahoma",
    },
    {
        code: "OR",
        name: "Oregon",
    },
    {
        code: "PW",
        name: "Palau",
    },
    {
        code: "PA",
        name: "Pennsylvania",
    },
    {
        code: "PR",
        name: "Puerto Rico",
    },
    {
        code: "RI",
        name: "Rhode Island",
    },
    {
        code: "SC",
        name: "South Carolina",
    },
    {
        code: "SD",
        name: "South Dakota",
    },
    {
        code: "TN",
        name: "Tennessee",
    },
    {
        code: "TX",
        name: "Texas",
    },
    {
        code: "UT",
        name: "Utah",
    },
    {
        code: "VT",
        name: "Vermont",
    },
    {
        code: "VI",
        name: "Virgin Islands",
    },
    {
        code: "VA",
        name: "Virginia",
    },
    {
        code: "WA",
        name: "Washington",
    },
    {
        code: "WV",
        name: "West Virginia",
    },
    {
        code: "WI",
        name: "Wisconsin",
    },
    {
        code: "WY",
        name: "Wyoming",
    },
];

export const scrollToTop = () => window.scrollTo(0, 0);

export const unexpectedErrorToast = (
    to = "/contact",
    opts = {
        msg:
            "An unexpected error occurred. Please try again later or click here contact us if this persists.",
    }
) => {
    toast(
        <span>
            <Link to={to}>{opts.msg}</Link>
        </span>
    );
};

export const useAjaxEffect = ({
    request,
    tags = {},
    requestRequired = () => true,
    onNoRequestRequired = () => {},
    onSuccess = () => {},
    onError = () => {},
    watch = [],
    cancelMessage = null,
    cleanup = () => {},
    log = process.env.NODE_ENV === "development",
    handle401 = () => {},
}) => {
    useEffect(() => {
        let mounted = true;

        let inProgress = true;

        const source = ApiClient.generateCancelTokenSource();

        if (requestRequired()) {
            request(source.token)
                .then((res) => {
                    inProgress = false;

                    return onSuccess(res, mounted);
                })
                .catch((err) => {
                    inProgress = false;
                    if (!ApiClient.isCancel(err)) {
                        Sentry.captureException(err, {
                            tags,
                        });

                        if (ApiClient.isStatus(err, 401)) {
                            handle401();
                        }

                        return onError(err, mounted);
                    }
                });

            return () => {
                mounted = false;

                source.cancel(
                    cancelMessage ??
                        "Ajax request cancelled to prevent state updates on unmounted component"
                );

                inProgress &&
                    log &&
                    console.log(
                        cancelMessage ??
                            "Ajax request cancelled to prevent state updates on unmounted component"
                    );

                cleanup();
            };
        } else {
            onNoRequestRequired();
        }
    }, watch);
};

/**
 * dd-mm-yyyy format to human readable
 */
export const dateToFriendly = (date) => {
    const getSuffix = (number) => {
        if (number > 3 && number < 21) {
            return "th";
        }

        switch (number % 10) {
            case 1:
                return "st";
            case 2:
                return "nd";
            case 3:
                return "rd";
            default:
                return "th";
        }
    };

    const months = {
        "01": "January", // eslint-disable-line quote-props
        "02": "February", // eslint-disable-line quote-props
        "03": "March", // eslint-disable-line quote-props
        "04": "April", // eslint-disable-line quote-props
        "05": "May", // eslint-disable-line quote-props
        "06": "June", // eslint-disable-line quote-props
        "07": "July", // eslint-disable-line quote-props
        "08": "August", // eslint-disable-line quote-props
        "09": "September", // eslint-disable-line quote-props
        10: "October", // eslint-disable-line quote-props
        11: "November", // eslint-disable-line quote-props
        12: "December", // eslint-disable-line quote-props
    };

    const parts = date.split("-");

    return `${parts[0]}${getSuffix(parts[0])} ${months[parts[1]]} ${parts[2]}`;
};

export const getHashFromString = (string) => {
    let hash = 0;
    let i;
    let char;

    for (i = 0; i < string.length; i++) {
        char = string.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
};
