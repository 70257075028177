import React from "react";
import { useHistory } from "react-router-dom";
import Form from "../../../components/form";
import Field from "../../../components/form/field";
import Select from "../../../components/form/select";
import SubmitButton from "../../../components/submit-button";
import CustomerDetails from "../../../models/auth/customer-details";
import { useSubscription } from "../../../providers/subscription-provider";
import { getRegex } from "../../../utils";
import Actions from "../common/actions";
import SubscriptionFormLogo from "../subscription-form-logo";

function NewCustomer() {
    const {
        customerDetails,
        setCustomerDetails,
        customerExistence,
    } = useSubscription();

    const history = useHistory();

    const onSubmit = (data) => {
        setCustomerDetails(new CustomerDetails(data));
        history.push("/join/address-details");
    };

    /* eslint-disable indent */
    return (
        <div className="l-login__wrapper">
            <div className="l-login__width">
                <SubscriptionFormLogo />

                <div className="l-login__form">
                    <h1 className="l-login__title">Your Details</h1>

                    <Form
                        className="c-form t-new-customer-form"
                        useFormParams={{
                            defaultValues: {
                                email:
                                    customerExistence && customerExistence.email
                                        ? customerExistence.email
                                        : "",
                                firstName:
                                    customerDetails && customerDetails.firstName
                                        ? customerDetails.firstName
                                        : "",
                                lastName:
                                    customerDetails && customerDetails.lastName
                                        ? customerDetails.lastName
                                        : "",
                            },
                        }}
                        onSubmit={onSubmit}
                    >
                        {({ watch, ...formMethods }) => (
                            <>
                                <Field
                                    watch={watch}
                                    {...formMethods}
                                    disabled
                                    validation={{
                                        required: "Email is required",
                                        pattern: {
                                            value: getRegex("email"),
                                            message:
                                                "Email must be a valid email address",
                                        },
                                    }}
                                    required
                                    id="email"
                                    type="email"
                                    name="email"
                                    displayName="Email"
                                />

                                <Field
                                    watch={watch}
                                    {...formMethods}
                                    is={Select}
                                    validation={{
                                        required: "Title is required",
                                        validate: (value) =>
                                            [
                                                "Mr",
                                                "Mrs",
                                                "Dr",
                                                "Miss",
                                                "Ms",
                                            ].includes(value) ||
                                            "Title must be one of Mr, Mrs, Dr, Miss, Ms",
                                    }}
                                    required
                                    id="title"
                                    name="title"
                                    displayName="Title"
                                >
                                    <option value="Mr">Mr</option>
                                    <option value="Mrs">Mrs</option>
                                    <option value="Dr">Dr</option>
                                    <option value="Miss">Miss</option>
                                    <option value="Ms">Ms</option>
                                </Field>

                                <Field
                                    watch={watch}
                                    {...formMethods}
                                    focusOnMount
                                    validation={{
                                        required: "First name is required",
                                        pattern: {
                                            value: getRegex("name"),
                                            message:
                                                "First name contains invalid characters",
                                        },
                                    }}
                                    required
                                    id="firstName"
                                    type="text"
                                    name="firstName"
                                    displayName="First name"
                                />

                                <Field
                                    watch={watch}
                                    {...formMethods}
                                    validation={{
                                        required: "Last name is required",
                                        pattern: {
                                            value: getRegex("name"),
                                            message:
                                                "Last name contains invalid characters",
                                        },
                                    }}
                                    required
                                    id="lastName"
                                    type="text"
                                    name="lastName"
                                    displayName="Last name"
                                />

                                <Field
                                    watch={watch}
                                    {...formMethods}
                                    validation={{
                                        required: "Password is required",
                                        minLength: {
                                            value: 6,
                                            message:
                                                "Password must be at least 6 characters",
                                        },
                                        pattern: {
                                            value: getRegex("password"),
                                            message:
                                                "Password must be at least 6 characters and contain at least one letter and at least one number or symbol",
                                        },
                                    }}
                                    required
                                    id="password"
                                    type="password"
                                    name="password"
                                    displayName="Password"
                                />

                                <Field
                                    watch={watch}
                                    {...formMethods}
                                    validation={{
                                        required:
                                            "Password confirmation is required",
                                        validate: (value) =>
                                            value === watch("password") ||
                                            "Password confirmation must match password",
                                    }}
                                    required
                                    id="passwordConfirm"
                                    type="password"
                                    name="passwordConfirm"
                                    displayName="Confirm Password"
                                />

                                <SubmitButton>Submit</SubmitButton>
                            </>
                        )}
                    </Form>

                    <div className="o-actions">
                        <Actions />
                        <div></div>
                    </div>
                </div>

                <p className="o-help">
                    {/* Already Subscribed?{" "}
                    <Link to="/login"
                        className="o-help__link">
                        Login Now
                    </Link> */}
                </p>
            </div>
        </div>
    );
    /* eslint-enable indent */
}

export default NewCustomer;
