import queryString from "query-string";
import ApiClient from "../api-client";
import Category from "./category";
import Meta from "./meta";
import Pagination from "./pagination";
import Playlist from "./playlist";
import Video from "./video";

class Resource {
    constructor({
        reqInterceptor = config => config,
        token = false
    } = {
            reqInterceptor: config => config,
            token: false
        }) {
        this.client = new ApiClient({
            baseUrl: process.env.RAZZLE_RESOURCE_BASE_URL,
            reqInterceptor,
        });
        this.token = token;
        this.entity = "";
        this.query = {};
        this.body = {};
        this.headers = {};
        this.method = "get";
    }

    setCancelToken(cancelToken) {
        this.client.setCancelToken(cancelToken);

        return this;
    }

    setBody(body) {
        this.body = body;

        return this;
    }

    setMethod(method) {
        this.method = method;

        return this;
    }

    addToHeaders(obj) {
        this.headers = {
            ...this.headers,
            ...obj,
        };

        return this;
    }

    addToBody(obj) {
        this.body = {
            ...this.body,
            ...obj,
        };

        return this;
    }

    setToken(token) {
        this.token = token;

        return this;
    }

    setEntity(entity) {
        this.entity = entity;

        return this;
    }

    topLevelCategories(id = false) {
        return this.setEntity(
            id ? `top-level-categories/${id}` : "top-level-categories"
        );
    }

    videos(id = false) {
        return this.setEntity(id ? `media/${id}` : "media");
    }

    sortBy(field, order) {
        this.query[`sort[${field}]`] = order;

        return this;
    }

    where(key, value) {
        this.query[key] = value;

        return this;
    }

    playlists(id = false) {
        return this.setEntity(id ? `playlists/${id}` : "playlists");
    }

    categories(id = false) {
        return this.setEntity(id ? `categories/${id}` : "categories");
    }

    children(id = false) {
        return this.setEntity(
            id ? `${this.entity}/children/${id}` : `${this.entity}/children`
        );
    }

    featuredMedia(id = false) {
        return this.setEntity(
            id
                ? `${this.entity}/featured-media/${id}`
                : `${this.entity}/featured-media`
        );
    }

    search(terms) {
        this.query = {
            ...this.query,
            q: terms,
        };

        return this.setEntity("search");
    }

    media(id = false) {
        return this.setEntity(
            id ? `${this.entity}/media/${id}` : `${this.entity}/media`
        );
    }

    meta() {
        return this.setEntity(`${this.entity}/meta`);
    }

    watchNext() {
        return this.setEntity(`${this.entity}/watch-next`);
    }

    ourPresenters() {
        return this.setEntity("our-presenters");
    }

    reset() {
        this.entity = "";
        this.query = {};
        this.body = {};
        this.headers = {};
        this.method = "get";
        this.setCancelToken(null);

        return this;
    }

    request() {
        this.client.setPath(this.entity).setQueryString(
            queryString.stringify(this.query, {
                arrayFormat: "bracket",
            })
        );

        const promise = this.client.request(
            this.method,
            this.body,
            this.headers
        );

        this.reset();

        return promise;
    }

    getSearchResults(terms, pageNumber, pageSize, { field, order }) {
        return this.addToHeaders({
            Authorization: `Bearer ${this.token}`,
        })
            .search(terms)
            .where("page", pageNumber)
            .where("per_page", pageSize)
            .sortBy(field, order)
            .request()
            .then((response) => ({
                videos: response.data.data.map((video) => new Video(video)),
                pagination: new Pagination(response.data.pagination),
            }));
    }

    getCategoriesByIds(ids) {
        return this.addToHeaders({
            Authorization: `Bearer ${this.token}`,
        })
            .categories()
            .where("ids", ids)
            .request()
            .then((response) =>
                response.data.data.map((video) => new Category(video))
            );
    }

    getVideosByIds(ids) {
        return this.addToHeaders({
            Authorization: `Bearer ${this.token}`,
        })
            .videos()
            .where("ids", ids)
            .request()
            .then((response) =>
                response.data.data.map((video) => new Video(video))
            );
    }

    getVideo(id) {
        return this.addToHeaders({
            Authorization: `Bearer ${this.token}`,
        })
            .videos(id)
            .request()
            .then((video) => new Video(video.data.data));
    }

    getTopLevelCategoryGrandchild(grandparentId, parentId, id) {
        return this.addToHeaders({
            Authorization: `Bearer ${this.token}`,
        })
            .topLevelCategories(grandparentId)
            .children(parentId)
            .children(id)
            .request()
            .then((category) => new Category(category.data.data));
    }

    getTopLevelCategoryGrandchildren(grandparentId, parentId) {
        return this.addToHeaders({
            Authorization: `Bearer ${this.token}`,
        })
            .topLevelCategories(grandparentId)
            .children(parentId)
            .children()
            .request()
            .then((categories) =>
                categories.data.data.map((category) => new Category(category))
            );
    }

    getTopLevelCategoryChild(parentId, id) {
        return this.addToHeaders({
            Authorization: `Bearer ${this.token}`,
        })
            .topLevelCategories(parentId)
            .children(id)
            .request()
            .then((category) => new Category(category.data.data));
    }

    getChildCategory(parentId, childId) {
        return this.addToHeaders({
            Authorization: `Bearer ${this.token}`,
        })
            .categories(parentId)
            .children(childId)
            .request()
            .then((category) => new Category(category.data.data));
    }

    getCategoryChildren(parentId) {
        return this.addToHeaders({
            Authorization: `Bearer ${this.token}`,
        })
            .categories(parentId)
            .children()
            .request()
            .then((categories) =>
                categories.data.data.map((category) => new Category(category))
            );
    }

    getCategory(id) {
        return this.addToHeaders({
            Authorization: `Bearer ${this.token}`,
        })
            .categories(id)
            .request()
            .then((category) => new Category(category.data.data));
    }

    getCategoriesByTopLevelParent(id) {
        return this.addToHeaders({
            Authorization: `Bearer ${this.token}`,
        })
            .topLevelCategories(id)
            .children()
            .request()
            .then((categories) =>
                categories.data.data.map((category) => new Category(category))
            );
    }

    getTopLevelCategories() {
        return this.addToHeaders({
            Authorization: `Bearer ${this.token}`,
        })
            .topLevelCategories()
            .request()
            .then((categories) =>
                categories.data.data.map((category) => new Category(category))
            );
    }

    getTopLevelCategory(id) {
        return this.addToHeaders({
            Authorization: `Bearer ${this.token}`,
        })
            .topLevelCategories(id)
            .request()
            .then((category) => new Category(category.data.data));
    }

    getCategoriesByParent(id) {
        return this.addToHeaders({
            Authorization: `Bearer ${this.token}`,
        })
            .categories(id)
            .children()
            .request()
            .then((categories) =>
                categories.data.data.map((category) => new Category(category))
            );
    }

    getPlaylists(page) {
        return this.addToHeaders({
            Authorization: `Bearer ${this.token}`,
        })
            .playlists()
            .where("page", page)
            .request()
            .then((response) => ({
                playlists: response.data.data.map(
                    (playlist) => new Playlist(playlist)
                ),
                pagination: new Pagination(response.data.pagination),
            }));
    }

    getPlaylist(id) {
        return this.addToHeaders({
            Authorization: `Bearer ${this.token}`,
        })
            .playlists(id)
            .request()
            .then((response) => new Playlist(response.data.data));
    }

    getMediaByCategory(id, page, perPage, searchTerms, sort) {
        this.addToHeaders({
            Authorization: `Bearer ${this.token}`,
        })
            .categories(id)
            .media()
            .where("page", page)
            .where("per_page", perPage)
            .where("q", searchTerms);

        if (sort) {
            this.sortBy(sort.field, sort.order);
        }

        return this.request().then((response) => ({
            videos: response.data.data.map((entry) => new Video(entry)),
            pagination: new Pagination(response.data.pagination),
        }));
    }

    getMediaByPlaylist(id, page, perPage) {
        return this.addToHeaders({
            Authorization: `Bearer ${this.token}`,
        })
            .playlists(id)
            .media()
            .where("page", page)
            .where("per_page", perPage)
            .request()
            .then((response) => ({
                videos: response.data.data.map((entry) => new Video(entry)),
                pagination: new Pagination(response.data.pagination),
            }));
    }

    getOurPresenters() {
        return this.addToHeaders({
            Authorization: `Bearer ${this.token}`,
        })
            .ourPresenters()
            .request()
            .then((response) =>
                response.data.data.map((presenter) => new Category(presenter))
            );
    }

    getFeaturedVideos(id) {
        return this.addToHeaders({
            Authorization: `Bearer ${this.token}`,
        })
            .categories(id)
            .featuredMedia()
            .request()
            .then((response) =>
                response.data.data.map((video) => new Video(video))
            );
    }

    getRecentlyAdded() {
        return this.addToHeaders({
            Authorization: `Bearer ${this.token}`,
        })
            .videos()
            .where("page", 1)
            .where("per_page", 10)
            .sortBy("created_at", "desc")
            .request()
            .then((response) =>
                response.data.data.map((video) => new Video(video))
            );
    }

    getTrendingNow() {
        return this.addToHeaders({
            Authorization: `Bearer ${this.token}`,
        })
            .playlists(Playlist.trendingNowId)
            .media()
            .where("page", 1)
            .where("per_page", 10)
            .request()
            .then((response) =>
                response.data.data.map((video) => new Video(video))
            );
    }

    getVideoMeta(id) {
        return this.addToHeaders({
            Authorization: `Bearer ${this.token}`,
        })
            .videos(id)
            .meta()
            .request()
            .then((response) => new Meta(response.data.data));
    }

    getWatchNext(id) {
        return this.addToHeaders({
            Authorization: `Bearer ${this.token}`,
        })
            .videos(id)
            .watchNext()
            .request()
            .then((response) =>
                response.data.data.map((video) => new Video(video))
            );
    }
}

export default Resource;
