import React from "react";
import Imgix from "react-imgix";

function GlobalLoadingIcon() {
    return (
        <div className="global-loading-icon-container">
            <Imgix
                htmlAttributes={{ alt: "Andertons Extra Logo" }}
                className="o-logo pulse"
                src={
                    "https://andertonsextra.imgix.net/static/logos/andertons-extra-x-logo-red.png"
                }
                imgixParams={{ q: 65 }}
                width={60}
                height={60}
            />
        </div>
    );
}

export default GlobalLoadingIcon;
