import React from "react";
import { ErrorMessage } from "react-hook-form";
import Radio from "../radio";
import Select from "../select";
import Text from "../text";

export default function Field({
    is: Component,
    children,
    className,
    name,
    id,
    displayName,
    errors,
    type,
    labelPosition,
    errorPosition,
    labelContents: LabelContents,
    ...rest
}) {
    const isSelect = Component.name === Select.name;
    const isRadio = Component.name === Radio.name;

    const label = (
        <label className={`t-${id ?? name}-field-label`} htmlFor={id ?? name}>
            {LabelContents === false ? displayName : <LabelContents />}
        </label>
    );

    const error = (
        <ErrorMessage errors={errors} name={name}>
            {({ message }) => <p className="has-error__message">{message}</p>}
        </ErrorMessage>
    );

    return type === "hidden" ? (
        <Component type={type} name={name} {...rest} id={id} />
    ) : (
            <div className={`${className} ${errors[name] ? "has-error" : ""}`}>
                {errorPosition === "above" && error}
                {labelPosition === "above" && !isRadio && label}
                <Component
                    id={id}
                    children={isSelect ? children : null}
                    type={type}
                    name={name}
                    {...rest}
                />
                {labelPosition === "below" && label}
                {errorPosition === "below" || isRadio && error}
                {isSelect ? null : children}
            </div>
        );

}

Field.defaultProps = {
    is: Text,
    labelPosition: "below",
    errorPosition: "above",
    children: [],
    className: "c-form__field",
    labelContents: false,
};
