import BaseResponseModel from "../../base-response-model";

class Currency extends BaseResponseModel {
    static getFormattedAmount(number, locale, currencyCode) {
        return new Intl.NumberFormat(locale, {
            style: "currency",
            currency: currencyCode,
        }).format(number);
    }

    getCurrencyCode() {
        return this.currency_code;
    }
}

export default Currency;
