import React, { useState } from "react";
import { useAuth } from "../../providers/auth-provider";
import { useLocalStorage } from "../../providers/local-storage-provider";
import { useResource } from "../../providers/resource-provider";
import { useAjaxEffect } from "../../utils";
import Carousel from "../carousel";
import VideoThumbnailSet from "../video-thumbnail-set";
import CarouselVideoThumbnail from "../video-thumbnail-set/carousel-video-thumbnail";

function WatchNextCarousel({ videoId }) {
    const [loading, setLoading] = useState(true);
    const [watchNext, setWatchNext] = useState(false);

    const { resource } = useResource();
    const aexLocalStorage = useLocalStorage();

    const { handle401 } = useAuth();

    useAjaxEffect({
        handle401,

        tags: {
            component: "WatchNextCarousel",
            action: "retrieve watch next videos",
        },

        requestRequired: () => aexLocalStorage,

        request: (cancelToken) => {
            resource.setCancelToken(cancelToken);

            return resource.getWatchNext(videoId);
        },

        onSuccess: (data, mounted) => {
            if (mounted) {
                setWatchNext(data);
                data.forEach((video) => {
                    aexLocalStorage.addYouMightAlsoLike(video.id);
                });
                setLoading(false);
            }
        },

        onError: (err, mounted) => {
            if (mounted) {
                console.log(err);
                setLoading(false);
            }
        },

        watch: [aexLocalStorage, videoId],

        cancelMessage:
            "Watch next carousel data retrieval cancelled due to component unmount",
    });

    return (
        !!watchNext &&
        watchNext.length > 0 && (
            <>
                <div className="o-section">
                    <div className="o-section--wrapper">
                        <div className="o-section--left">
                            <h2 className="o-section__title">Watch Next</h2>
                        </div>
                    </div>
                    {!loading ? (
                        <VideoThumbnailSet videos={watchNext}>
                            {({ videos }) => (
                                <Carousel>
                                    {videos.map((video) => (
                                        <CarouselVideoThumbnail
                                            key={video.id}
                                            video={video}
                                        />
                                    ))}
                                </Carousel>
                            )}
                        </VideoThumbnailSet>
                    ) : (
                            <Carousel>{[]}</Carousel>
                        )}
                </div>
            </>
        )
    );
}

export default WatchNextCarousel;
