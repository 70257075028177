/**
 * `/models/google-analytics/event-parameters-models.js`
 *
 * These classes allow you to easily construct objects suitable for sending to
 * GA as the parameters for a GA4 event.
 *
 * GA4 requires _plain_ objects to be sent along with the event, so each class has
 * has a "toPlainObject" method which returns only the object keys/values that are
 * used in the final event call.
 *
 * See: https://developers.google.com/gtagjs/reference/ga4-events
 */

/**
 * Base class for checking that the event parameters supplied are from a sanctioned
 * params model within the module.
 *
 * Provides a toPlainObject method to all extension classes.
 */
class BaseEventParams {

  toPlainObject() {
    return Object.assign({}, this.params);
  }

}

/**
 * Describes properties relating to an on-site purchase
 * https://developers.google.com/gtagjs/reference/ga4-events#purchase
 */
class Purchase extends BaseEventParams {

  constructor() {
    super();
    this.params = {};
    this.params.affiliation = process.env.RAZZLE_APPLICATION_NAME;
    this.params.coupon = "";
    this.params.currency = "";
    this.params.items = [];
    this.params.transaction_id = "";
    //this.params.shipping = 0.00;
    //this.params.tax = 0.00;
    this.params.value = 0.00;
  }

  /* Adds a PurchaseItem to the Purchase */
  addItem(item) {

    if(item instanceof PurchaseItem === false) {
      return console.error("addItem: Item must be an instance of PurchaseItem");
    }

    this.params.items.push(item.toPlainObject());
    this.params.value += item.params.price;
  }

  setCoupon(coupon) {
    this.params.coupon = coupon;
  }

  setCurrency(currency) {
    this.params.currency = currency;
  }

  setTransactionId(transaction_id) {
    this.params.transaction_id = transaction_id;
  }

}

/**
 * Describes an individual item on a purchase
 * https://developers.google.com/gtagjs/reference/ga4-events#purchase_item
 */
class PurchaseItem extends BaseEventParams {

  constructor(id, name) {

    super();
    this.params = {};

    if(!id || !name) throw new Error("PurchaseItem ID and Name must be supplied.");

    this.params.item_id = id;
    this.params.item_name = name;
    this.params.quantity = 1;
    //this.params.tax = 0.00;
    this.params.price = 0.00;
    this.params.currency = "";
    this.params.coupon = "";
    this.params.affiliation = process.env.RAZZLE_APPLICATION_NAME;

  }

  setPrice(price) {
    this.params.price = price;
  }

  setCurrency(currency) {
    this.params.currency = currency;
  }

  setCoupon(coupon) {
    this.params.coupon = coupon;
  }

}

/**
 * Describes a site search term entered by the user
 */
class SearchTerm extends BaseEventParams {

  constructor(term) {
    super();
    this.params = {};

    if(Array.isArray(term)) {
      term = term.map(el => el.replace(',','%2C')).join(', ');
    }

    this.params.search_term = term;
  }

}

export {
  BaseEventParams,
  Purchase,
  PurchaseItem,
  SearchTerm
}
