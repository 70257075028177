class RouteParamSet {
    constructor({
        match,
        query,
        resource,
        routeData,
        redirect,
        handleCategoryClick,
        handleVideoClick,
        handlePlaylistClick,
    }) {
        this.match = match;
        this.query = query;
        this.resource = resource;
        this.routeData = routeData;
        this.redirect = redirect;
        this.handleCategoryClick = handleCategoryClick;
        this.handleVideoClick = handleVideoClick;
        this.handlePlaylistClick = handlePlaylistClick;
    }
}

export default RouteParamSet;
