import Category from "../resource/category";
import Pagination from "../resource/pagination";
import Playlist from "../resource/playlist";
import Video from "../resource/video";

export default {
    videos: videos => videos.map(video => new Video(video)),

    results: videos => videos.map(video => new Video(video)),

    staticCategories: categories => categories.map(category => new Category(category)),

    category: category => {

        if (category.videos) {
            category.videos = category.videos.map(video => new Video(video));
        }

        return new Category(category);
    },

    pagination: pagination => new Pagination(pagination),

    video: video => new Video(video),

    topLevelCategories: categories => categories.map(category => new Category(category)),

    playlists: playlists => playlists.map(playlist => new Playlist(playlist)),

    playlist: playlist => {

        if (playlist.videos) {
            playlist.videos = playlist.videos.map(video => new Video(video));
        }

        return new Playlist(playlist);

    },
}