import React from "react";
import Imgix from "react-imgix";
import { Link } from "react-router-dom";
import { useVideo } from "../../../providers/resource-provider";

function QueueVideoThumbnail({ video, onClick, link, active }) {
    const { handleVideoClick } = useVideo();

    let handleClick = () => handleVideoClick(video);

    if (!!onClick && typeof onClick === "function") {
        handleClick = onClick;
    }

    return (
        <div className={"o-playlist__item"}>
            <Link
                onClick={() => handleClick(video)}
                to={link}
                className={"t-playlist-item-link o-playlist__link"}
            >
                <div className="o-playlist__thumbnail">
                    <picture className="o-playlist__picture">
                        <Imgix
                            htmlAttributes={{
                                src: video.loading_thumbnail_url,
                                alt: `${video.name} thumbnail image`,
                            }}
                            className="o-playlist__img lazyload"
                            src={video.thumbnail_url}
                            imgixParams={{ q: 65 }}
                            attributeConfig={{
                                src: "data-src",
                                srcSet: "data-srcset",
                                sizes: "data-sizes",
                            }}
                            width={479}
                            height={269}
                        />
                        <div
                            className="in-progress in-progress--overlay"
                            style={{
                                width: `${video.watch_progress}%`,
                            }}
                        ></div>
                    </picture>
                </div>
                <div className="o-playlist__content">
                    <div className="o-playlist__overflow">
                        <h3>{video.name}</h3>
                    </div>
                    <p className="o-playlist__length">
                        {video.thumbnailTime()}
                    </p>
                    {active && (
                        <span className="active-flag">
                            <span className="active-flag__content">
                                <span className="active-flag__dot"></span>
                                <span>Watching Now</span>
                            </span>
                        </span>
                    )}
                </div>
            </Link>
        </div>
    );
}

export default QueueVideoThumbnail;
