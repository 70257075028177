import React from "react";
import { useLocation } from "react-router-dom";
import DesktopUnauthenticatedNavbar from "./desktop-unauthenticated-navbar";
import hideUrls from "./hide-urls";

function UnauthenticatedNavbar(props) {

    const { pathname } = useLocation();

    return <DesktopUnauthenticatedNavbar hide={hideUrls.includes(pathname)} />;
}

export default UnauthenticatedNavbar;
