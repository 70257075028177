import React from "react";

function LoadingIcon() {
    return (
        <div className="loading-icon">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
}

export default LoadingIcon;
