import BaseResponseModel from "../../base-response-model";

class EmailPreferences extends BaseResponseModel {
    constructor(props) {
        super(props);
        if (this.emailPreferences) {
            Object.keys(this.emailPreferences).forEach((key) => {
                this[key] = this.emailPreferences[key];
            });
            delete this.emailPreferences;
        }
    }
}

export default EmailPreferences;
