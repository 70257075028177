import { useEffect } from "react";
import GoogleAnalytics from "../../../../models/google-analytics";
import * as GAEventParams from "../../../../models/google-analytics/event-parameters-models.js";
import Resource from "../../../../models/resource";
import { useAuth } from "../../../../providers/auth-provider";
import { useResource } from "../../../../providers/resource-provider";
import { useSubscription } from "../../../../providers/subscription-provider";

// https://www.chargebee.com/checkout-portal-docs/dropIn.html#overview

function ChargebeeCheckoutModal() {
    const { setResource } = useResource();

    const {
        selectedPlan,
        customerDetails,
        addressDetails,
        setAddressDetails,
        setCustomerDetails,
        optIn,
        setShowCheckout,
        showCheckout,
        couponCode,
    } = useSubscription();
    const { auth, signIn } = useAuth();

    useEffect(() => {
        if (showCheckout) {
            const chargebeeInstance = window.Chargebee.getInstance();

            const cart = chargebeeInstance.getCart();

            const customer = {
                first_name: customerDetails.firstName,
                last_name: customerDetails.lastName,
                // email: customerDetails.email, // Omitting email address, as IBM ID is our key field
                billing_address: {
                    line1: addressDetails.addressLine1,
                    line2: addressDetails.addressLine2,
                    state: addressDetails.state,
                    state_code: addressDetails.state,
                    city: addressDetails.city,
                    zip: addressDetails.zipCode,
                    country: addressDetails.country,
                },

                /**
                 * This is our custom field to link
                 * IBM records with ChargeBee records.
                 * The whole linked account model setup
                 * is based on this field
                 */
                cf_ibm_customer_id: customerDetails.userId,
            };

            cart.setCustomer(customer);

            const product = chargebeeInstance.initializeProduct(
                selectedPlan.id
            );

            /**
             * EXTRA-373 - Soft go live!
             * 
             * Set coupon
             */
            if (couponCode) {
                product.addCoupon(couponCode);
            }

            cart.replaceProduct(product);

            /**
             * Checkout callback functions
             */
            chargebeeInstance.setCheckoutCallbacks(() => ({
                close: () => setShowCheckout(false),

                success: () => {
                    /**
                     * Complete data capture and Authenticate customer
                     */
                    signIn({
                        email: customerDetails.email,
                        password: customerDetails.password,
                        onSuccess: ({
                            token,
                            user_id: userId
                        }) => {
                            setCustomerDetails(null);
                            setAddressDetails(null);

                            setResource(
                                new Resource({
                                    token,
                                })
                            );

                            return auth
                                .setToken(token)
                                .updateOptInAndSubscriptionStatus(
                                    userId,
                                    optIn
                                );
                        },
                    }).then(({
                        token,
                        user_id: userId
                    }) => {

                        /**
                         * Register purchase event in Google Analytics
                         */
                        return auth
                            .setToken(token)
                            .getSubscriptionDetails(userId);

                    })
                        .then(({
                            /**
                             * Destructure subscription data response
                             * to camelCase properties and avoid
                             * accessing nested properties
                             */
                            subscription: {
                                subscription_id: subscriptionId,
                                currency_code: currencyCode,
                                plan_id: planId,
                            },
                            plan: {
                                invoice_name: invoiceName,
                                price
                            }
                        }) => {

                            const gaPurchase = new GAEventParams.Purchase();
                            gaPurchase.setTransactionId(subscriptionId);
                            gaPurchase.setCurrency(currencyCode);

                            const gaItem = new GAEventParams.PurchaseItem(planId, invoiceName);
                            gaItem.setCurrency(currencyCode);
                            gaItem.setPrice(price / 100);

                            if (couponCode) {
                                gaPurchase.setCoupon(couponCode);
                                gaItem.setCoupon(couponCode);
                            }

                            gaPurchase.addItem(gaItem);

                            return GoogleAnalytics.sendEvent('purchase', gaPurchase);

                        })
                        .catch((error) => {
                            console.error(error);
                        });

                },
            }));

            cart.proceedToCheckout();
        }
    }, [showCheckout]);

    return null;
}

export default ChargebeeCheckoutModal;
