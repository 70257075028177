import React from "react";
import { Helmet } from "react-helmet";
import GlobalLoadingIcon from "../../components/global-loading-icon";
import { useAuth } from "../../providers/auth-provider";

function AuthHoldingPage({ redirect }) {
    const { authenticated, UnauthedRedirect } = useAuth();

    /**
     * This behaviour is only required when hitting
     * / to redirect to home page, login or service
     * prop depending on auth state
     */
    if (authenticated !== null && redirect) {
        return <UnauthedRedirect />
    }

    return (
        <>
            <Helmet>
                <title>{process.env.RAZZLE_DEFAULT_META_TITLE}</title>
                <meta name="description" content={process.env.RAZZLE_DEFAULT_META_DESCRIPTION} />
            </Helmet>

            <GlobalLoadingIcon />
        </>
    );
}

AuthHoldingPage.defaultProps = {
    redirect: true,
};

export default AuthHoldingPage;
