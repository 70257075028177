import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

export default function Form({ children, onSubmit, useFormParams, ...rest }) {
    const { handleSubmit, reset, errors, watch, register } = useForm(
        useFormParams
    );

    useEffect(reset, []);

    return (
        <form onSubmit={handleSubmit(onSubmit)} {...rest}>
            {children({
                errors,
                watch,
                register,
            })}
        </form>
    );
}

Form.defaultProps = {
    className: "c-form",
    children: [],
    onSubmit: () => {},
    useFormParams: {},
};
