import React, { useState } from "react";
import { Link } from "react-router-dom";
import Imgix from "react-imgix";
import {
    useVideo,
    useCategory,
    useResource,
} from "../../providers/resource-provider";
import { useLocalStorage } from "../../providers/local-storage-provider";
import { useAjaxEffect } from "../../utils";
import { useAuth } from "../../providers/auth-provider";

function FeaturedVideo(props) {
    const [loading, setLoading] = useState(false);
    const [videos, setVideos] = useState([]);
    const { handleVideoClick } = useVideo();
    const { category } = useCategory();
    const { handle401 } = useAuth();
    const aexLocalStorage = useLocalStorage();
    const { resource } = useResource();

    useAjaxEffect({
        tags: {
            component: "FeaturedVideo",
            action: "retrieve category featured videos",
        },

        requestRequired: () => !!category,

        request: (cancelToken) => {
            resource.setCancelToken(cancelToken);

            return resource.getFeaturedVideos(category.id);
        },

        onSuccess: (data, mounted) => {
            if (mounted) {
                setVideos(data);
                setLoading(false);
            }
        },

        onError: (err, mounted) => {
            if (mounted) {
                setVideos([]);
                setLoading(false);
            }
        },

        handle401,

        watch: [category],

        cancelMessage:
            "Featured video data retrieval cancelled due to component unmount",
    });

    /**
     * We'll just take the first featured video
     */
    const video = !loading ? videos[0] : false;

    let watchProgress = 0;

    if (video) {
        const recentlyWatched = aexLocalStorage
            ? aexLocalStorage.getRecentlyWatched()
            : [];
        const recentlyWatchedEntry = recentlyWatched.find(
            (entry) => entry.id === video.id
        );

        watchProgress = recentlyWatchedEntry
            ? recentlyWatchedEntry.progressPercentage
            : 0;
    }

    return (
        <>
            {video && (
                <>
                    <div className="o-featured">
                        <picture className="o-featured__background">
                            <Imgix
                                htmlAttributes={{
                                    src: video.loading_thumbnail_url,
                                    alt: `${video.name} background image`,
                                }}
                                className="lazyload"
                                src={video.featured_background_image}
                                imgixParams={{ q: 65 }}
                                attributeConfig={{
                                    src: "data-src",
                                    srcSet: "data-srcset",
                                    sizes: "data-sizes",
                                }}
                                width={1250}
                                height={703}
                            />
                        </picture>
                        <div className="o-featured__content">
                            <div className="o-featured--left">
                                <Link
                                    onClick={() => handleVideoClick(video)}
                                    to={video.link}
                                >
                                    <picture className="o-featured__picture">
                                        <Imgix
                                            htmlAttributes={{
                                                src:
                                                    video.loading_thumbnail_url,
                                                alt: `${video.name} thumbnail image`,
                                            }}
                                            className="o-featured__img lazyload"
                                            src={video.thumbnail_url}
                                            imgixParams={{ q: 65 }}
                                            attributeConfig={{
                                                src: "data-src",
                                                srcSet: "data-srcset",
                                                sizes: "data-sizes",
                                            }}
                                            width={1250}
                                            height={703}
                                        />
                                        <div
                                            className="in-progress in-progress--overlay"
                                            style={{
                                                width: `${watchProgress}%`,
                                            }}
                                        ></div>
                                    </picture>
                                </Link>
                            </div>
                            <div className="o-featured--right">
                                <h3 className="o-featured__title">
                                    {video.name}
                                </h3>
                                <p className="o-featured__intro">
                                    {video.video_summary}
                                </p>
                                <Link
                                    onClick={() => handleVideoClick(video)}
                                    to={video.link}
                                    className="btn btn--primary"
                                >
                                    Watch Now
                                </Link>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default FeaturedVideo;
