import React from "react";
import { Helmet } from "react-helmet";
import Imgix from "react-imgix";
import ErrorBoundary from "../../components/error-boundary";
import ErrorMessage from "../../components/error-message";
import PresentersCarousel from "../../components/presenters-carousel";
import ProcessedText from "../../components/processed-text";
import YouMightAlsoLikeCarousel from "../../components/you-might-also-like-carousel";
import Category from "../../models/resource/category";
import { BottomLevelCategoryTeaser, MidLevelCategoryTeaser } from "../top-level-category";

function ExclusiveToExtra({
    routeData: { staticCategories },
}) {
    return (
        <>
            <Helmet>
                <title>Exclusive to Extra — {process.env.RAZZLE_APPLICATION_NAME}</title>
                <meta
                    name="description"
                    content="Exclusive Andertons Extra Content"
                />
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            {/* Page Details */}
            <ErrorBoundary
                tags={{
                    page: "exclusive to extra",
                    section: "page details",
                }}
                fallback={() => (
                    <ErrorMessage message="Could not display page details" />
                )}
            >
                <PageDetails />
            </ErrorBoundary>

            {/* Static show grid */}
            <div className="o-section o-black">
                <div className="l-width">
                    <ErrorBoundary
                        tags={{
                            page: "exclusive to extra",
                            section: "shows grid",
                        }}
                        fallback={() => (
                            <ErrorMessage message="Could not display shows grid" />
                        )}
                    >
                        <ShowsList staticCategories={staticCategories} />
                    </ErrorBoundary>
                </div>
            </div>


            {/* You Might Also Like Carousel */}
            <ErrorBoundary
                tags={{
                    page: "top level category",
                    section: "you might also like carousel",
                }}
                fallback={() => (
                    <ErrorMessage message="Could not display you might also like carousel" />
                )}
            >
                <YouMightAlsoLikeCarousel />
            </ErrorBoundary>

            {/* Our Presenters Carousel */}
            <ErrorBoundary
                tags={{
                    page: "top level category",
                    section: "presenters carousel",
                }}
                fallback={() => (
                    <ErrorMessage message="Could not display presenters carousel" />
                )}
            >
                <PresentersCarousel />
            </ErrorBoundary>
        </>
    );
}

export default ExclusiveToExtra;

function PageDetails() {
    return (
        <div className="o-hero">
            <picture className="o-hero__picture">
                <Imgix
                    htmlAttributes={{
                        src: 'https://andertonsextra.imgix.net/static/pages/exclusive-to-extra/hero.jpg?auto=format&q=65&ixlib=react-9.0.2&h=500&w=1920',
                        alt: `Exclusive to Extra hero image`,
                    }}
                    className="o-hero__img lazyload"
                    src='https://andertonsextra.imgix.net/static/pages/exclusive-to-extra/hero.jpg?auto=format&q=65&ixlib=react-9.0.2&h=500&w=1920'
                    imgixParams={{ q: 65 }}
                    attributeConfig={{
                        src: "data-src",
                        srcSet: "data-srcset",
                        sizes: "data-sizes",
                    }}
                    width={1920}
                    height={500}
                />
            </picture>
            <div className="o-hero__info">
                <h1 className="o-hero__title">Exclusive to Extra</h1>
                <div className="o-hero__intro">
                    <ProcessedText>
                        With Andertons Extra, you get access to exclusive 
                        new shows, only available to Extra subscribers.

                        Browse your subscriber-only content right here. 
                        We'll be adding brand new content all the time, 
                        so check back regularly to see what's new!
                    </ProcessedText>
                </div>
            </div>
        </div>
    );
}

function ShowsList({ staticCategories }) {
    return (
        <div className="l-padding">
            <div className="l-width">
                <div className="t-shows-list l-grid l-grid--2-s l-grid--4-m">
                    {staticCategories.map((staticCategory) => (
                        <CategoryChildTeaser category={staticCategory} key={staticCategory.id} />
                    ))}
                </div>
            </div>
        </div>
    );
}

function CategoryChildTeaser({ category }) {
    return category.is_bottom_level ? (
        <BottomLevelCategoryTeaser category={new Category(category)} />
    ) : (
            <MidLevelCategoryTeaser category={new Category(category)} />
        );
}