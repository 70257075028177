import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useAuth } from "../../providers/auth-provider";

function Sidebar(props) {
    const { signOut, authenticated } = useAuth();

    const location = useLocation();
    const history = useHistory();

    const handleSignOut = () => {
        signOut(() => history.push("/login"));
    };

    const options = {
        // ! Remove auth
        // myAccount: {
        //     path: "/my-account",
        //     text: "My Account",
        // },
        // communicationPreferences: {
        //     path: "/communication-preferences",
        //     text: "Communication Preferences",
        // },
        // extraBenefits: {
        //     path: "/extra-benefits",
        //     text: "Extra Benefits",
        // },
        // manageSubscription: {
        //     path: "/my-account/manage-subscription",
        //     text: "Manage Subscription",
        // },
        termsAndConditions: {
            path: "/terms",
            text: "Terms & Conditions",
        },
        copyright: {
            path: "/copyright",
            text: "Copyright Claim",
        },

        /**
         * Keep this item last as it is
         * likely to conditionally render
         * so will reduce jankiness
         */
        contact: {
            path: "/contact",
            text: "Report a Problem",
        },
    };

    return (
        <ul className="o-account">
            {Object.keys(options)
                .filter((option) => !!props[option])
                .map((option) => options[option])
                .map((item) => (
                    <li
                        key={item.path}
                        className={`o-account__item ${
                            location.pathname === item.path ? "is-active" : ""
                        }`}
                    >
                        <Link to={item.path} className="o-account__link">
                            {item.text}
                        </Link>
                    </li>
                ))}

            {
                // ! Remove auth
            }
            {/* {authenticated && (
                <li className="o-account__item">
                    <button
                        className="t-sign-out-button o-account__link"
                        onClick={handleSignOut}
                    >
                        Log Out
                    </button>
                </li>
            )} */}
        </ul>
    );
}

Sidebar.defaultProps = {
    myAccount: false,
    extraBenefits: false,
    manageSubscription: false,
    contact: false,
    joinHelp: false,
    legal: false,
    termsAndConditions: false,
    copyright: false,
};

export default Sidebar;
