import React from "react";
import Imgix from "react-imgix";
import { Link } from "react-router-dom";
import { useVideo } from "../../../providers/resource-provider";

function VideoThumbnail({
    video,
    onClick,
    link,
    linkClassName,
    pictureClassName,
    imageClassName,
    contentClassName,
    overflowClassName,
    overflowDateClassName,
    overflowTimeClassName,
}) {
    const { handleVideoClick } = useVideo();

    let handleClick = () => handleVideoClick(video);

    if (!!onClick && typeof onClick === "function") {
        handleClick = onClick;
    }

    return (
        <Link
            onClick={() => handleClick(video)}
            to={link ?? video.link}
            className={linkClassName}
        >
            <picture className={pictureClassName}>
                <Imgix
                    htmlAttributes={{
                        src: video.loading_thumbnail_url,
                        alt: `${video.name} thumbnail image`,
                    }}
                    className={imageClassName}
                    src={video.thumbnail_url}
                    imgixParams={{
                        q: 65,
                    }}
                    attributeConfig={{
                        src: "data-src",
                        srcSet: "data-srcset",
                        sizes: "data-sizes",
                    }}
                    width={500}
                    height={295}
                />

                <div
                    className="in-progress in-progress--overlay"
                    style={{
                        width: `${video.watch_progress}%`,
                    }}
                ></div>
            </picture>
            <div className={contentClassName}>
                <div className={overflowClassName}>
                    <h3>{video.name}</h3>
                </div>
                <div>
                    <span className={overflowDateClassName}>
                        {video.thumbnailDate()}
                    </span>
                    <span className={overflowTimeClassName}>
                        {video.thumbnailTime()}
                    </span>
                </div>
            </div>
        </Link>
    );
}

VideoThumbnail.defaultProps = {
    linkClassName: "o-videos__item",
    pictureClassName: "o-videos__picture",
    imageClassName: "o-videos__img lazyload",
    contentClassName: "o-videos__content",
    overflowClassName: "o-videos__overflow",
    overflowDateClassName: "o-videos__date",
    overflowTimeClassName: "o-videos__time",
};

export default VideoThumbnail;
