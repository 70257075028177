import React from "react";
import AuthProvider from "./auth-provider";
import GeolocationProvider from "./geolocation-provider";
import LocalStorageProvider from "./local-storage-provider";
import NoticeProvider from "./notice-provider";
import ResourceProvider from "./resource-provider";
import SubscriptionProvider from "./subscription-provider";
import PushNotificationProvider from "./push-notification-provider";

function Providers({ children, routeData }) {
    return (
        <GeolocationProvider>
            <AuthProvider>
                <ResourceProvider routeData={routeData}>
                    <SubscriptionProvider>
                        <LocalStorageProvider>
                            <NoticeProvider>
                                <PushNotificationProvider>
                                    {children}
                                </PushNotificationProvider>
                            </NoticeProvider>
                        </LocalStorageProvider>
                    </SubscriptionProvider>
                </ResourceProvider>
            </AuthProvider>
        </GeolocationProvider>
    );
}

Providers.defaultProps = {
    authenticated: false,
    routeData: {},
    children: [],
};

export default Providers;
