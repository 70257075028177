import React from "react";
import { Helmet } from "react-helmet";
import SidebarLayout from "../../components/sidebar-layout";
import { useAuth } from "../../providers/auth-provider";

/* eslint-disable max-lines */
function Terms() {
    const { authenticated } = useAuth();

    return (
        <>
            <Helmet>
                <title>
                    Terms, Conditions & Privacy —{" "}
                    {process.env.RAZZLE_APPLICATION_NAME}
                </title>
                <meta
                    name="description"
                    content="Click to view our Terms & Conditions and Privacy Policy for the Andertons Extra service."
                />
            </Helmet>
            <SidebarLayout
                legal
                termsAndConditions
                copyright
                contact={authenticated}
            >
                <h1>Terms & Conditions / Privacy Policy</h1>

                <p>
                    This page outlines the general terms of service for
                    Andertons Extra and describes our data privacy policy.
                </p>

                <hr />

                <h2 id="terms-conditions">Andertons Extra Terms of Service</h2>

                <ol>
                    <li>
                        <h3>Use of The Service</h3>

                        <ol>
                            <li>
                                <h4>Access to The Service</h4>

                                <p>
                                    Anyone under the
                                    age of 13 should be supervised by an adult
                                    when using the service. You must use the
                                    service only as intended and not attempt in
                                    any way to sabotage or devalue the content,
                                    categorisation or website itself.
                                </p>
                            </li>

                            <li>
                                <h4>Personal Information &amp; Security</h4>

                                <p>
                                    Andertons understands that your personal
                                    information is of the utmost importance and
                                    are committed to storing your data in a safe
                                    and secure way. By using Andertons
                                    Extra you agree to allow Andertons to store
                                    any personal data relevant to the service.
                                    We will store this data on our own servers
                                    and not share it with any third parties
                                    unless otherwise stated. Andertons uses
                                    analytics and tracking services to improve
                                    the user experience on Andertons extra. By
                                    using the site you are agreeing to the use
                                    of these data services.
                                </p>
                            </li>

                            <li>
                                <h4>Fair Usage &amp; Abuse</h4>

                                <p>
                                    By using Andertons Extra
                                    you agree only to use the service for your
                                    own personal viewing and viewing within your
                                    household. Use of the service must not be
                                    shared with parties external to your
                                    household. Andertons Extra should be used
                                    for private viewing only, public showings of
                                    any content from the platform is strictly
                                    forbidden.
                                </p>
                                <p>
                                    As an Andertons Extra user you agree not to
                                    use the platform in any malicious ways
                                    including but not limited to: Any form of
                                    sublicensing
                                </p>
                                <ul>
                                    <li>
                                        Copying, downloading, modifying or
                                        redistributing any content stored on
                                        Andertons Extra
                                    </li>
                                    <li>
                                        Using any automated software to access
                                        or navigate around Andertons Extra
                                    </li>
                                    <li>
                                        Inserting any form of code or program
                                        intended to change any part of Andertons
                                        Extra
                                    </li>
                                    <li>
                                        Using any datamining or similar data
                                        extraction process to gain access to
                                        private data
                                    </li>
                                </ul>
                            </li>
                        </ol>
                    </li>

                    <li>
                        <h3>Complaints Policy</h3>

                        <p>
                            Andertons takes complaints seriously and aims to act
                            on and resolve any complaints received in a discrete
                            and timely manner. If you or any Andertons Extra
                            user has a complaint, please raise it using our
                            ‘Report a Problem’ form found at the bottom of every
                            Andertons Extra webpage.
                        </p>
                    </li>

                    <li>
                        <h3>Intellectual Property</h3>

                        <p>
                            As an Andertons Extra user you agree not to
                            download or keep an archive of any content on
                            Andertons Extra. You must also not reproduce any
                            content on Andertons Extra on any other site without
                            permission. You must not attempt in any way to
                            reverse engineer the Andertons Extra site for any
                            reason.
                        </p>
                    </li>

                    <li>
                        <h3>Copyright Infringement Policy</h3>

                        <p>
                            All content available on Andertons Extra is owned by
                            Andertons Music Company and is protected under
                            copyright law. Any unlawful copying, distributing,
                            editing or downloading of any content on the site is
                            forbidden. Andertons Music Company retains the right
                            to revoke your account if you are found to have had
                            any part in any of the above malpractices.
                        </p>
                    </li>

                    <li>
                        <h3>Warranty Disclaimer</h3>
                        <p>
                            YOU AGREE THAT THE ANDERTONS EXTRA SERVICE IS
                            PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT EXPRESS
                            OR IMPLIED WARRANTY OR CONDITION OF ANY KIND
                            INCLUDING WARRANTIES OF MERCHANTABILITY, FITNESS FOR
                            A PARTICULAR PURPOSE AND NONINFRINGEMENT. YOUR
                            STATUTORY RIGHTS AS A CONSUMER REMAIN UNAFFECTED.
                        </p>
                    </li>

                    <li>
                        <h3>Indemnification</h3>
                        <p>
                            You agree to indemnify and hold Andertons harmless
                            from and against all damages, losses, and expenses
                            of any kind including reasonable legal fees and
                            costs related to your use of the Andertons Extra
                            service or your violation of any law or the rights
                            of a third party through using the service.
                        </p>
                    </li>

                    <li>
                        <h3>Additional Terms & Conditions</h3>

                        <ol>
                            <li>
                                <h4>Third Parties</h4>
                                <p>
                                    In addition to these terms of service, which
                                    you enter into by using the Andertons Extra
                                    service, you should be aware of and comply
                                    with all applicable third party provider
                                    terms.
                                </p>

                                <p>
                                    A comprehensive list of third parties is
                                    shown within our Privacy Policy.
                                </p>
                            </li>

                            <li>
                                <h4>Errors and Omissions</h4>
                                <p>
                                    To the best of our knowledge, the
                                    information displayed on this website,
                                    including these terms and conditions, is
                                    correct at time of writing, and we will
                                    endeavour to correct any incorrect
                                    information quickly as soon as it comes to
                                    our attention. Errors and ommissions
                                    excepted.
                                </p>
                            </li>
                        </ol>
                    </li>

                    <li>
                        <h3>Changes and Amendments</h3>
                        <p>
                            We shall have the right, at our sole discretion, to
                            modify, add, or remove any terms or conditions from
                            these Terms of Service without notice or liability
                            to you.
                        </p>
                        <p>
                            Any changes to these Terms shall be effective
                            immediately following the posting of such changes.
                        </p>
                        <p>
                            By using the service, you agree to review this page
                            occasionally and agree that your continued use of
                            the service shall constitute your acceptance of all
                            such changes.
                        </p>
                        <p>
                            We will endeavour to notify users of the service, by
                            email, of any changes to these terms and conditions
                            that we believe will have a significant, material
                            impact to you, the end user.
                        </p>
                    </li>

                    <li>
                        <h3>Feedback and Concerns</h3>

                        <ol>
                            <li>
                                <h4>Your Feedback</h4>
                                <p>
                                    If you need to raise a concern or provide
                                    feedback on the Andertons Extra service,
                                    we'd be more than happy to hear from you.
                                </p>
                                <p>
                                    You can write to us
                                    below or contact us online at{" "}
                                    <a
                                        href={
                                            process.env
                                                .RAZZLE_ECOMMERCE_SITE_BASE_URL
                                        }
                                        target="_blank"
                                        rel="noopener"
                                        title="Visit www.andertons.co.uk"
                                    >
                                        {
                                            process.env
                                                .RAZZLE_ECOMMERCE_SITE_DOMAIN
                                        }
                                    </a>
                                    .
                                </p>
                            </li>

                            <li>
                                <h4>Contact Details</h4>
                                <address>
                                    Andertons Music Co.
                                    <br />
                                    58 & 59 Woodbridge Road, Guildford, Surrey
                                    <br />
                                    GU1 4RF, United Kingdom
                                    <br />
                                    Telephone: 01483 456777
                                    <br />
                                    VAT Number: 211 457 986
                                    <br />
                                </address>
                            </li>
                        </ol>
                    </li>
                </ol>

                <hr />

                <h2>Andertons Extra Privacy Policy</h2>

                <p>
                    Andertons Music Co. is committed to preserving the privacy
                    of our users through responsible and minimal collection &
                    use of personal data (or PII - personally identifiable
                    information) and anonymised user data. We use this data to
                    facilitate and improve the core Andertons Extra service.
                </p>

                <p>
                    We pledge to keep your information safe, process it
                    securely, use it only for the purposes stated, always for
                    the good of you, the end user, and never to sell it to third
                    parties.
                </p>

                <p>
                    For your protection, we're registered under the Data
                    Protection Act 1998 and have given all appropriate
                    notifications to the Information Commissioner.
                </p>

                <p>
                    Andertons Music Co. is the controller of your personal
                    information for the Andertons Extra service. We additionally
                    use third-party data processors, the details of whom are
                    provided below.
                </p>

                <p>
                    This privacy policy outlines in detail how Andertons Extra
                    uses data, where data is processed and stored, and by whom,
                    and how we use features such as cookies to enhance the
                    experience for end users of the Andertons Extra service.
                </p>

                <ol>
                    <li>
                        <h3>Data Protection</h3>

                        <ol>
                            <li>
                                <h4>Secure processing of data</h4>

                                <p>
                                    All communications from Andertons Extra that
                                    involve transmission of information
                                    (including PII and payment information)
                                    between your browser and servers owned or
                                    provisioned by Andertons Music Co. are done
                                    over https (SSL) connections. https is a
                                    secure connection protocol, widely used on
                                    the internet to transmit encrypted
                                    information between computers.
                                </p>

                                <p>
                                    To verify that your connection is secure at
                                    all times, you must look for the padlock
                                    symbol in your web browser, di not
                                    submit any personal information if this is not visible. 
                                    For further information on the
                                    veracity and cryptographic strength of our
                                    SSL certificate, you can inspect the
                                    certificate details using your web browser.
                                </p>
                            </li>

                            <li>
                                <h4>How we use your personal information</h4>

                                <p>
                                    The purpose of processing and retaining
                                    users' personal information, where relevant, 
                                    is to communicate with them
                                    about the Andertons Extra service, primarily
                                    via email.
                                </p>

                                <p>
                                    The details provided are processed by a
                                    small number of third party providers, and
                                    stored on servers which are owned, managed
                                    or provisioned by Andertons Music Co. and
                                    our trusted third party technology
                                    providers.
                                </p>

                                <p>
                                    Andertons will retain personal information
                                    for the purposes of providing the Andertons
                                    Extra service only as long as necessary. If,
                                    at any time, you wish for your personal
                                    information to be erased from Andertons'
                                    systems, please see the{" "}
                                    <em>Right To Be Forgotten</em> section
                                    below.
                                </p>
                            </li>

                            <li>
                                <h4>How we use anonymise user data</h4>

                                <p>
                                    By using the AndertonsExtra.com website, you
                                    are inherently providing anonymous user data
                                    to Andertons Music Co. which may be
                                    analysed, solely, by a small number of
                                    employees of Andertons Music Co. and our
                                    trusted I.T. partners, in order to provide a
                                    better experience for all users.
                                </p>

                                <p>
                                    The purpose of collection anonymous user
                                    data is to analyse, in aggregate, user
                                    behaviour, and ultimately provide the best
                                    possible experience for users of the
                                    Andertons Extra service.
                                </p>

                            </li>

                            <li>
                                <h4>Third parties</h4>

                                <p>
                                    Andertons use the following 3rd party
                                    providers to facilitate the Andertons Extra
                                    service. Services marked with an asterisk
                                    (*) either have access-to or process your
                                    personal data, whereas services without an
                                    asterisk are not processors of your personal
                                    data, but may use anonymised user data in
                                    order to inform Andertons Extra staff of
                                    general user behaviour traits or to
                                    facilitate a feature of the
                                    AndertonsExtra.com website.
                                </p>

                                <p>
                                    Our trusted partners each have their own
                                    privacy policies and terms and conditions.
                                    Andertons Music Co. has no control over
                                    these, and they may change over time. We
                                    will endeavour to monitor any such changes,
                                    and terminate our use of their services,
                                    should we deem their privacy policy to be in
                                    conflict with our own or in any way a threat
                                    to your privacy.
                                </p>

                                <ul>
                                    <li>DB-IP*</li>
                                    <li>Deeper Than Blue*</li>
                                    <li>Emarsys*</li>
                                    <li>HCL Commerce*</li>
                                    <li>Imgix</li>
                                    <li>Google Analytics</li>
                                    <li>Google Firebase</li>
                                    <li>Google Cloud Platform*</li>
                                    <li>Sparkstone*</li>
                                    <li>Vixy Video Platform</li>
                                    <li>Westlake I.T.*</li>
                                    <li>Zendesk*</li>
                                </ul>

                                <p>
                                    Where a service has access to / is
                                    processing your personal information, more
                                    detail is provided in the next section:{" "}
                                    <em>
                                        Storage and processing of your
                                        information
                                    </em>
                                    .
                                </p>
                            </li>

                            <li>
                                <h4>
                                    Storage and processing of your information
                                </h4>

                                <p>
                                    When you submit personal information to
                                    Andertons Extra, you are allowing us to
                                    store or process this information for the
                                    purposes of providing the Andertons Extra
                                    service and its benefits. We are unable to
                                    provide the service advertised without
                                    processing (and, in some cases, storing)
                                    these details.
                                </p>

                                <p>
                                    The following sections describe how the
                                    providers above may view or process your PII
                                    (personal data).
                                </p>

                                <ol>

                                    <li>
                                        <h5>DB-IP</h5>

                                        <p>
                                            DB-IP receive your IP address which
                                            does not contain PII per se but does
                                            give us a hint to your location in
                                            the world. We use this information
                                            to personalise AndertonsExtra.com to
                                            your locale, for example by offering
                                            you the most appropriate currency
                                            for the region you are in.
                                        </p>
                                    </li>

                                    <li>
                                        <h5>
                                            Deeper Than Blue, HCL Commerce,
                                            Sparkstone & Westlake I.T.
                                        </h5>

                                        <p>
                                            These are trusted technology
                                            partners who help us to run our{" "}
                                            {
                                                process.env
                                                    .RAZZLE_ECOMMERCE_SITE_DOMAIN
                                            }{" "}
                                            online store, retail back-end
                                            systems and company I.T network.
                                        </p>

                                        <p>
                                            Employees at these
                                            providers could view your data. Your
                                            data will only be accessed by these
                                            third parties if Andertons request
                                            that they do so, for the specific
                                            purpose of resolving an issue or
                                            improving the services we provide to
                                            our customers.
                                        </p>

                                        <p>
                                            Your personal data is stored and
                                            processed on servers in the
                                            following locations: United Kingdom,
                                            Germany.
                                        </p>
                                    </li>

                                    <li>
                                        <h5>Emarsys</h5>

                                        <p>
                                            Emarsys is the tool we use to
                                            facilitate sending marketing emails
                                            to users of{" "}
                                            {
                                                process.env
                                                    .RAZZLE_ECOMMERCE_SITE_DOMAIN
                                            }{" "}
                                            and AndertonsExtra.com who opt-in to
                                            our marketing mailing list. If you
                                            are opted in, we may supply Emarsys
                                            with further data such as your
                                            Andertons retail purchase history.
                                            This information enables us to
                                            segment our email list and send
                                            more-relevant, less-“spammy” emails.
                                        </p>

                                        <p>
                                            Your personal data is stored and
                                            processed on servers in the
                                            following locations: Countries
                                            Within The European Union
                                        </p>
                                    </li>

                                    <li>
                                        <h5>Google Cloud Platform</h5>

                                        <p>
                                            We host the AndertonsExtra.com
                                            website on GCP. Google provide us
                                            with private environments (virtual
                                            servers) where we run our bespoke
                                            website application. Google do not
                                            have access to your personal data.
                                        </p>

                                        <p>
                                            When you log in, you are sending
                                            your encrypted email address and
                                            password, over a secure SSL
                                            connection, to our website
                                            application, which will then process
                                            these to verify your identity.
                                        </p>

                                        <p>
                                            Google Cloud Platform does not store
                                            any PII on behalf of the Andertons
                                            Extra service.
                                        </p>

                                        <p>
                                            Your login data is processed on
                                            servers in the following locations:
                                            United Kingdom, The Netherlands
                                        </p>
                                    </li>

                                    <li>
                                        <h5>Zendesk</h5>

                                        <p>
                                            If you choose to contact us via any
                                            of the forms provided on
                                            AndertonsExtra.com, you are
                                            submitting personally identifiable
                                            information which will be processed
                                            and stored by Zendesk which is a
                                            customer services communication
                                            management tool.
                                        </p>

                                        <p>
                                            Your email address and name may be
                                            stored for the purposes of
                                            responding to your communication.
                                        </p>

                                        <p>
                                            Your personal data is stored and
                                            processed in servers in the
                                            following locations: United States,
                                            Countries within the European
                                            Economic Area.
                                        </p>
                                    </li>
                                </ol>
                            </li>

                            <li>
                                <h4>Distribution of information</h4>

                                <p>
                                    We may share information with governmental
                                    agencies or private companies assisting us
                                    in fraud prevention or investigation. We may
                                    do so only when: (1) permitted or required
                                    by law; (2) trying to protect against or
                                    prevent actual or potential fraud or
                                    unauthorised transactions; or, (3)
                                    investigating fraud which has already taken
                                    place. We will not provide your information
                                    to any other company or organisation for
                                    marketing purposes.
                                </p>
                            </li>
                        </ol>
                    </li>

                    <li>
                        <h3>Cookies & Device Storage</h3>

                        <p>
                            Andertons Extra uses cookies and device storage to
                            deliver the best possible experience to our
                            users.
                        </p>

                        <p>
                            A cookie is a piece of data that is stored on your
                            device in order to remember a piece of information
                            so that when you return to the website at a later
                            time, we can effectively provide an experience which
                            is better for you in a number of ways; typical
                            usages of cookies and device storage include
                            personalisation, security, analysis, and recall of
                            information.
                        </p>

                        <p>
                            The following headings describe how we use cookies
                            and device storage to provide a better experience
                            for AndertonsExtra.com users:
                        </p>

                        <ol>
                            <li>
                                <h4>
                                    Keeping you logged in to AndertonsExtra.com
                                </h4>

                                <p>
                                    We use cookies so that you don’t have to log
                                    in every single time you visit Andertons
                                    Extra. In short: we “remember” you by
                                    assigning your device an{" "}
                                    <em>authentication token</em> after you log
                                    in for the first time. You will remain
                                    logged in until this cookie expires, is
                                    deleted, or you choose to log out.
                                </p>
                            </li>

                            <li>
                                <h4>
                                    Personalising your Andertons Extra
                                    experience
                                </h4>

                                <p>
                                    We use cookies to store information on your
                                    device which we can then use to provide a
                                    more personalised website experience. For
                                    example: we send your IP address to a 3rd
                                    party service (DB-IP) who tell us where in
                                    the world they think the user of that IP
                                    address may be located (geolocation
                                    detection).
                                </p>

                                <p>
                                    This tells us, for example, that you may be
                                    in the UK, and that your primary language is
                                    probably English and primary currency is
                                    probably £GBP. We store this information in
                                    a cookie then use it during subsequent
                                    visits to the site to show you prices in
                                    £GBP, and{" "}
                                    {process.env.RAZZLE_ECOMMERCE_SITE_DOMAIN}{" "}
                                    delivery benefits which are relevant to the
                                    UK.
                                </p>
                            </li>

                            <li>
                                <h4>Improving site performance</h4>

                                <p>
                                    Your browser allows us to store small
                                    amounts of data in a local cache (device
                                    storage). We leverage this functionality to
                                    provide a faster site experience for you. By
                                    storing files and data in your browser
                                    cache, you do not have to download them more
                                    than once. This reduces your bandwidth
                                    consumption, and means that the site loads
                                    pages faster.
                                </p>
                            </li>

                            <li>
                                <h4>Analytics & Push Notifications</h4>

                                <p>
                                    In order to improve the overall experience
                                    for all users and understand user behaviour
                                    and website errors, we use a website traffic
                                    analysis tool (Google Analytics) which is
                                    supported by cookies. These cookies enable
                                    the analytics tool to differentiate between
                                    different users.
                                </p>
                                <p>
                                    If you opt in to Push Notifications, we will
                                    store a cookie to save this preference. This
                                    enables us to send you notifications to your
                                    device(s) using Google Firebase. Google
                                    Analytics receives no PII and any data collected is anonymous.
                                </p>
                                <p>
                                    Google Analytics receives no PII and the
                                    data we analyse is aggregated and anonymous.
                                    If you wish to read more about how cookies
                                    facilitate Google Analytics,{" "}
                                    <a
                                        href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        click here
                                    </a>
                                    .
                                </p>
                            </li>
                        </ol>

                        <p>
                            Note: Cookies are an important part of the Andertons
                            Extra website application. Should you wish to use
                            AndertonsExtra.com with some or all cookies
                            disabled, or if you wish to use ad-blocking
                            technologies that may suppress cookies, we are
                            unable to guarantee normal functioning of the
                            platform.
                        </p>
                    </li>

                    <li>
                        <h3>Right To Be Forgotten</h3>

                        <p>
                            Users who provide personal data to Andertons Music
                            Co. also have a “right to be forgotten”; that is the
                            right for all the PII (personally identifiable
                            information) that they have provided to Andertons to
                            be permanently erased from all of Andertons'
                            information systems.
                        </p>

                        <p>
                            Should you be an existing Andertons Extra or
                            Andertons retail customer, you can exercise your
                            right to be forgotten at any time.
                        </p>

                        <p>
                            To request that all your personal data is deleted,{" "}
                            <a
                                href="https://help.andertons.co.uk/hc/en-gb/requests/new"
                                target="_blank"
                                title="Contact Andertons for GDPR issues"
                            >
                                click here
                            </a>{" "}
                            to contact the Andertons Music Co. customer services
                            team, who will ensure your data is removed from all
                            Andertons systems.
                        </p>
                    </li>

                    <li>
                        <h3>Leaving This Website</h3>

                        <p>
                            Our website may contain links to enable you to visit
                            other websites of interest easily. However, once you
                            have used these links to leave our site you should
                            note that we do not have any control over that other
                            website. Therefore, we cannot be responsible for the
                            protection and privacy of any information which you
                            provide whilst visiting such sites and such sites
                            are not governed by this privacy statement. You
                            should exercise caution and look at the privacy
                            statement applicable to the website in question.
                        </p>

                        <p>
                            Having said this, we abide by this privacy policy to
                            the greatest extent we can, and we aim to only link
                            to safe and reputable sites. All external services
                            are governed by their own terms and privacy
                            policies.
                        </p>
                    </li>

                    <li>
                        <h3>Further Privacy Concerns</h3>

                        <p>
                            If you have any questions, concerns, or comments
                            about our privacy policy please log into your
                            Andertons Extra account where you can contact us by
                            email. If you are not an Andertons Extra user,
                            please visit{" "}
                            <a
                                href={
                                    process.env.RAZZLE_ECOMMERCE_SITE_BASE_URL
                                }
                                target="_blank"
                                rel="noopener"
                                title="Visit www.andertons.co.uk"
                            >
                                Andertons.co.uk
                            </a>{" "}
                            and contact us there.
                        </p>

                        <p>
                            We reserve the right to make changes to this policy.
                            Any changes to this policy will be posted.
                        </p>
                    </li>
                </ol>
            </SidebarLayout>
        </>
    );
}

export default Terms;
/* eslint-enable max-lines */
