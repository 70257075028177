import BaseResponseModel from "../../base-response-model";

class Playlist extends BaseResponseModel {
    static trendingNowId = "0_nqxv00b9";

    constructor(props) {
        super(props);
        this.link = `/playlists/${this.id}/${this.slug}`;
        this.loading_hero_image = `${this.hero_image}?blur=800&px=20&auto=format`;
        this.loading_thumbnail_url = `${this.thumbnail_url}?blur=800&px=20&auto=format`;
    }

    thumbnailDate() {
        const getSuffix = (number) => {
            if (number > 3 && number < 21) {
                return "th";
            }

            switch (number % 10) {
                case 1:
                    return "st";
                case 2:
                    return "nd";
                case 3:
                    return "rd";
                default:
                    return "th";
            }
        };

        const months = {
            "01": "January",
            "02": "February",
            "03": "March",
            "04": "April",
            "05": "May",
            "06": "June",
            "07": "July",
            "08": "August",
            "09": "September",
            10: "October",
            11: "November",
            12: "December",
        };

        const parts = this.created_at.split("-");

        return `${parts[0]}${getSuffix(parts[0])} ${months[parts[1]]} ${
            parts[2]
            }`;
    }
}

export default Playlist;
