import React from "react";
import { CookiesProvider } from "react-cookie";
import { hydrate } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import DeserializedResponse from "./models/deserialized-response";

/**
 * Grab the state from a global variable injected
 * into the server-generated HTML
 */
const { shellData, routeData } = window.__INITIAL_DATA__;

/**
 * Allow the passed state to be garbage-collected
 */
delete window.__INITIAL_DATA__;

/**
 * Initialise window variable to
 * store whether we've finished hydrating
 * This will be set to false post-hydration
 * Will let us know if we're in a SSR or CSR
 * flow
 */
window.hydrating = true;

/**
 * Hydrate the application, passing initial data
 *
 * Hydrating the app allows client side React to
 * "take over" from a server side rendered React page.
 */
hydrate(
    <CookiesProvider>
        <BrowserRouter>
            <App shellData={DeserializedResponse.get(shellData)} routeData={DeserializedResponse.get(routeData)} />
        </BrowserRouter>
    </CookiesProvider>,
    document.getElementById("root"),
    () => {
        window.hydrating = false;
    }
);

/**
 * Allow hot reloading
 */
if (module.hot) {
    module.hot.accept();
}
