import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { ExtraCookies } from "../../utils";
import { usePushNotification } from "../../providers/push-notification-provider";

const COOKIE_NAME = "notification-previous-visit";

function FirstLoadCookie() {
    const history = useHistory();
    const [cookies, setCookie, removeCookie] = useCookies();
    const {
        getNotificationPreference,
        browserSupportsNotifications,
        notificationsActive,
    } = usePushNotification();

    useEffect(() => {
        // {/* env-level config for turning notifications on */}
        if (notificationsActive()) {
            const firstVisit = !cookies[
                ExtraCookies.getWrappedKey(COOKIE_NAME)
            ];
            const currentlyUnsubscribed = !getNotificationPreference();

            if (
                firstVisit &&
                currentlyUnsubscribed &&
                browserSupportsNotifications()
            ) {
                toast(
                    'Andertons Extra now supports Push Notifications on this device! Click here or go to "Communication Preferences" to turn them on!',
                    {
                        onClick: () => {
                            history.push("/communication-preferences");
                        },
                        autoClose: 20000, // 20 secs before hide (longer than default)
                    }
                );
            }

            setCookie(ExtraCookies.getWrappedKey(COOKIE_NAME), true, {
                maxAge: process.env.RAZZLE_USER_COOKIE_MAX_AGE_SECONDS,
            });
        }
    }, []);
    return null;
}

export default FirstLoadCookie;
