// Import events parameters models to validate against
import * as GAEventParams  from './event-parameters-models';

/**
 *
 * `/models/google-analytics`
 *
 * This utility class makes it easy to fire Google Analytics functions in various
 * places in the Extra front end application. The util can be imported at the
 * root of the app then called to send events to the GA script e.g. pageviews,
 * goal conversions and custom events.
 *
 */
class GoogleAnalytics {

  constructor(lastUrl = null) {
    this._lastUrl = lastUrl; // Keeps track of the most recent url which was used to send a pageview
  }

  /**
   * Sends a pageview event to Google Analytics with the current page URL and
   * title. This is fired when React Helmet detects a page change (see: App.js)
   * so it no longer needs to use the location hook from React Router.

   * @param  {String} url The url including query string that we intend to record
   *                      the pageview for. Compared against the last url used to
   *                      avoid pageview tracking duplication.
   */
  sendPageview(url) {
    try {
      // Only send the pageview event if the current url differs from the last url.
      if(url === this.lastUrl) return;
      window.gtag('config', process.env.RAZZLE_GA_TRACKING_ID);
    } catch (e) {
      // If gtag is undefined it probably means that this is the initial server
      // pageload of the app. If this is the case, it's ok to fail silently
      // because the pageload data will be sent by the GA init script, upon
      // successful instantiantion of the gtag variable. See EXTRA-112
    } finally {
      // Update the last url used regardless
      this.lastUrl = url;
    }
  }


  /**
   * Fire a GA event on demand, using the helper class. Can be used statically.
   * See: https://developers.google.com/gtagjs/reference/ga4-events for the GA
   * API reference material.
   *
   * @param  {String}        eventName   Name of recommended or custom event
   * @param  {GAEventParams} eventParams Arbitrary object of parameters for the event
   *                                     Valid params objects are specified in event-parameters-models.js
   * @param  {Boolean}       retry       Indicates that this is the second attempt at sending the event
   *                                     and we should therefore not requeue the function if it is still
   *                                     failing (to avoid getting stuck in a loop if `gtag` doesn't init).
   * @return {Boolean}                   Returns true if the event successfully sent.
   */
  static sendEvent(eventName, eventParams, retry = false) {

    if(eventParams instanceof GAEventParams.BaseEventParams === false) {
      console.error("Could not send event as event parameters are invalid");
      return false;
    }

    // If gtag isn't defined, this is probably the first server side pageview
    // and we are therefore waiting for `window.gtag` to init before we can send
    // the event. Good old, ugly setTimeout. Works quite well here. Set to 1000
    // because the gtag init is async.
    if(typeof window.gtag === "undefined" && !retry) {
      setTimeout(this.sendEvent, 1000, eventName, eventParams, true);
      return false;
    }

    // Convert the mutable object into a plain object for sending
    eventParams = eventParams.toPlainObject();

    try {
      window.gtag('event', eventName, eventParams);
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }


  /**
   * Getters and setters
   */
  get lastUrl() {
    return this._lastUrl;
  }

  set lastUrl(url) {
    this._lastUrl = url;
  }

}

export default GoogleAnalytics;
